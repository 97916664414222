import { observer } from "mobx-react";
import React, { useContext, useState } from "react";
import { Image, TouchableOpacity, View } from "react-native";
import { Button, Text } from "react-native-elements";
import { RouteComponentProps } from "react-router";
import BackButton from "../components/common/BackButton";
import FullPageActivityIndicator from "../components/common/FullPageActivityIndicator";
import { HeaderTitleSubtitle } from "../components/headerItems/HeaderTitleSubtitle";
import { LargeHeaderIcon } from "../components/headerItems/LargeHeaderIcon";
import { TpIconCog, TpIconTeam3 } from "../components/Icon";
import Icon from "../components/Icon/Icon";
import { ContentFixed, ContentScroll } from "../components/layout/Content";
import { FullPageWrapper } from "../components/layout/FullPageWrapper";
import { Header } from "../components/layout/Header";
import Modal from "../components/modal/Modal";
import { APP_PATHS } from "../config";
import AddPaymentMethodForm from "../containers/AddPaymentMethodForm";
import EditPaymentMethodForm from "../containers/EditPaymentMethodForm";
import { theme } from "../lib/styles/theme";
import { PaymentMethod } from "../lib/types/interfaces/shopping/payment-method";
import { elevationShadowStyle } from "../lib/utils/elevationShadowStyle";
import { RootStoreContext } from "../stores/RootStore";

interface Props extends RouteComponentProps {}

const OrganizationSettingsPaymentMethodsScreen = observer(({ history }) => {
  const { organizationStore } = useContext(RootStoreContext);
  const [currentPM, setCurrentPM] = useState<PaymentMethod | null>();
  const [currentPMId, setCurrentPMId] = useState("");
  const [showPMModal, setShowPMModal] = useState(false);

  if (organizationStore.organizationStoreLoading) {
    return <FullPageActivityIndicator />;
  }

  let orgLogo;
  if (organizationStore.organization) {
    if (organizationStore.organization.logoUrl128x128) {
      orgLogo = (
        <Image
          style={{ width: 64, height: 64 }}
          source={{
            uri: organizationStore.organization.logoUrl128x128.replace(
              "http:",
              "https:"
            ),
          }}
        />
      );
    } else {
      orgLogo = <TpIconTeam3 size={64} />;
    }
  }
  return (
    <FullPageWrapper>
      <Modal isVisible={showPMModal} direction="toTop" closeFn={setShowPMModal}>
        {showPMModal && currentPMId ? (
          <EditPaymentMethodForm
            objectId={organizationStore.organizationId}
            objectType="organization"
            doneAction={() => {
              setShowPMModal(false);
              setCurrentPMId("");
              setCurrentPM(null);
            }}
            pm={currentPM}
          />
        ) : (
          <AddPaymentMethodForm
            objectId={organizationStore.organizationId}
            objectType="organization"
            doneAction={() => setShowPMModal(false)}
          />
        )}
      </Modal>

      <Header>
        <BackButton to={APP_PATHS.organization.settings.home} />
        <LargeHeaderIcon>{orgLogo}</LargeHeaderIcon>
        <HeaderTitleSubtitle
          subtitle={
            organizationStore.organization
              ? organizationStore.organization.name
              : " "
          }
          title="Betalsätt"
        />
        {/* <HeaderMenuButton /> */}
      </Header>
      <ContentFixed>
        <View
          style={{ marginTop: 40, paddingHorizontal: 15, paddingBottom: 20 }}
        >
          <Text>
            Här kan du hantera de swishnummer som är föreningsövergripande.
          </Text>
          <Text style={{ marginTop: 15 }}>
            Under respektive kassas inställningar kan swishnummer låsas som
            förval vid öppning.
          </Text>
          <View style={{ marginTop: 20 }}>
            <Button
              title="Lägg till swishnummer"
              onPress={() => setShowPMModal(true)}
            />
          </View>
        </View>
      </ContentFixed>
      <ContentScroll>
        {organizationStore.organization.paymentMethods &&
        organizationStore.organization.paymentMethods.length > 0 ? (
          organizationStore.organization.paymentMethods.map((pm) => {
            return (
              <View style={{ marginVertical: 5 }} key={pm.id}>
                <TouchableOpacity
                  // style={{ paddingVertical: 10 }}
                  onPress={() => {
                    setCurrentPMId(pm.id);
                    setCurrentPM(pm);
                    setShowPMModal(true);
                  }}
                >
                  <PMItem pm={pm} />
                </TouchableOpacity>
              </View>
            );
          })
        ) : (
          <View style={{ marginVertical: 5 }}>
            <Text>Inga betalsätt inlagda.</Text>
          </View>
        )}
      </ContentScroll>
    </FullPageWrapper>
  );
});

export default OrganizationSettingsPaymentMethodsScreen;

interface PMItemProps {
  pm: PaymentMethod;
}

const PMItem: React.FC<PMItemProps> = ({ pm }) => {
  return (
    <View
      style={{
        marginHorizontal: 10,
        marginVertical: 5,
        backgroundColor: "#fff",
        paddingHorizontal: 15,
        paddingVertical: 10,
        borderRadius: 8,
        justifyContent: "flex-start",
        alignItems: "center",
        flexDirection: "row",
        ...elevationShadowStyle(10),
        alignSelf: "stretch",
      }}
    >
      <View
        style={{
          width: "100%",
          alignSelf: "stretch",
          flexDirection: "row",
        }}
      >
        <View
          style={{
            width: "100%",
            flexDirection: "row",
            alignSelf: "stretch",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <View style={{ flexDirection: "row" }}>
            <View>
              <Icon name="tp-icon-paymentmethods" size={40} />
            </View>
            <View style={{ paddingVertical: 5, paddingHorizontal: 15 }}>
              <View>
                <Text style={{ fontWeight: "bold" }}>
                  {pm.description || "Ingen angiven"}
                </Text>
                <Text style={{ fontStyle: "italic" }}>
                  {pm.method} {pm.recipient ? pm.recipient : ""}
                </Text>
              </View>
            </View>
          </View>
          <View
            style={{
              width: 60,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <TpIconCog size={18} color={theme.colors.primary} />
          </View>
        </View>
      </View>
    </View>
  );
};
