import { LinearGradient } from "expo-linear-gradient";
import { observer } from "mobx-react";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { Dimensions, Image, View } from "react-native";
import { Text } from "react-native-elements";
import { RouteComponentProps } from "react-router";
import BackButton from "../components/common/BackButton";
import FullPageActivityIndicator from "../components/common/FullPageActivityIndicator";
import { HeaderTitleSubtitle } from "../components/headerItems/HeaderTitleSubtitle";
import { LargeHeaderIcon } from "../components/headerItems/LargeHeaderIcon";
import { TpIconTeam3 } from "../components/Icon";
import { ContentScroll } from "../components/layout/Content";
import { FullPageWrapper } from "../components/layout/FullPageWrapper";
import { Header } from "../components/layout/Header";
import { APP_PATHS } from "../config";
import { elevationShadowStyle } from "../lib/utils/elevationShadowStyle";
import { sortByQuantity } from "../lib/utils/sort";
import { RootStoreContext } from "../stores/RootStore";
interface Props extends RouteComponentProps {}

const PointOfSaleReportScreen: React.FC<Props> = observer(
  ({ match, history }) => {
    // if (match.params.)
    const { reportStore, organizationStore, pointOfSaleStore } = useContext(
      RootStoreContext
    );
    const { report } = reportStore;

    const screenWidth = Dimensions.get("window").width;
    const barData = {
      labels: ["January", "February", "March", "April", "May", "June"],
      datasets: [
        {
          data: [20, 45, 28, 80, 99, 43],
        },
      ],
    };

    console.log("RAPPORT DATA: ", reportStore.report);

    console.log(moment.locale());

    let orgLogo;
    if (organizationStore.organization) {
      if (organizationStore.organization.logoUrl128x128) {
        orgLogo = (
          <Image
            style={{ width: 64, height: 64 }}
            source={{
              uri: organizationStore.organization.logoUrl128x128.replace(
                "http:",
                "https:"
              ),
            }}
          />
        );
      } else {
        orgLogo = <TpIconTeam3 size={64} />;
      }
    }
    return (
      <FullPageWrapper>
        <Header>
          <BackButton to={APP_PATHS.pointofsale.reports} />

          <LargeHeaderIcon>{orgLogo}</LargeHeaderIcon>
          <HeaderTitleSubtitle
            // subtitle={user.name ? user.name : " "}
            subtitle={
              pointOfSaleStore.pointOfSale
                ? pointOfSaleStore.pointOfSale.name
                : " "
            }
            title="Rapport"
          />
          {/* <HeaderMenuButton /> */}
        </Header>
        <View style={{ height: 40 }} />
        <ContentScroll>
          <View style={{ padding: 15 }}>
            <LinearGradient
              start={[0, 0]}
              end={[1, 1]}
              colors={["#5FC4E1", "#00A13A"]}
              style={{
                position: "relative",
                backgroundColor: "#fff",
                // marginHorizontal: 30,
                alignSelf: "stretch",
                borderRadius: 8,
                // paddingBottom: 20,
                padding: 20,
                ...elevationShadowStyle(10),
              }}
            >
              <View>
                <Text h4 style={{ color: "#fff", marginBottom: 20 }}>
                  {moment(report.date)
                    .lang("SV")
                    .format("D MMMM, YYYY")
                    .toLocaleLowerCase()}
                </Text>
                <View>
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      paddingVertical: 2,
                    }}
                  >
                    <Text style={{ color: "#ffffff", fontWeight: "bold" }}>
                      Total försäljning
                    </Text>
                    <Text style={{ color: "#ffffff", fontWeight: "bold" }}>
                      {reportStore.report.totalAmount || "0"} kr
                    </Text>
                  </View>
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      paddingVertical: 2,
                    }}
                  >
                    <Text style={{ color: "#ffffff" }}>Totalt återköp</Text>
                    <Text style={{ color: "#ffffff", fontWeight: "bold" }}>
                      {reportStore.report.returnsTotal || "0"} kr
                    </Text>
                  </View>

                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      paddingVertical: 2,
                    }}
                  >
                    <Text style={{ color: "#ffffff" }}>Antal köp</Text>
                    <Text style={{ color: "#ffffff", fontWeight: "bold" }}>
                      {reportStore.report.numberOfSales}
                    </Text>
                  </View>

                  {report.numberOfReturns > 0 && (
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        paddingVertical: 2,
                      }}
                    >
                      <Text style={{ color: "#ffffff" }}>Antal återköp</Text>
                      <Text style={{ color: "#ffffff", fontWeight: "bold" }}>
                        {reportStore.report.numberOfReturns}
                      </Text>
                    </View>
                  )}
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      paddingVertical: 2,
                    }}
                  >
                    <Text style={{ color: "#ffffff" }}>
                      Ingående växelkassa
                    </Text>
                    <Text style={{ color: "#ffffff", fontWeight: "bold" }}>
                      {reportStore.report.cashIn} kr
                    </Text>
                  </View>

                  {report.cashSales && (
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        paddingVertical: 2,
                      }}
                    >
                      <Text style={{ color: "#ffffff" }}>
                        Kontant försäljning
                      </Text>
                      <Text style={{ color: "#ffffff", fontWeight: "bold" }}>
                        {reportStore.report.cashSales} kr
                      </Text>
                    </View>
                  )}
                </View>
              </View>
            </LinearGradient>
            <View style={{ marginVertical: 30 }}>
              <ReportTable id={report.id} />
            </View>
            {report && report.salesByItem && report.salesByItem.length > 0 && (
              <></>
            )}
            {/* <ReportStats reportId={report.id} /> */}
          </View>
        </ContentScroll>
      </FullPageWrapper>
    );
  }
);

export default PointOfSaleReportScreen;

interface ReportTableProps {
  id: string;
}

const ReportTable: React.FC<ReportTableProps> = ({ id }) => {
  const { reportStore } = useContext(RootStoreContext);
  const [loading, setLoading] = useState(true);
  const [report, setReport] = useState({
    items: [],
    quantities: [],
    values: [],
  });
  const [fullReport, setFullReport] = useState();

  useEffect(() => {
    reportStore._getReport(id).then((res) => {
      setFullReport(res);
      console.log("FULL REport: ", res);

      let tmpDataItems = [];
      let tmpDataQuantities = [];
      let tmpDataValues = [];

      res.salesByItem.sort(sortByQuantity).map((item) => {
        tmpDataItems.push(`${item.itemName} (${item.unitPriceInclVat}kr)`);
        tmpDataQuantities.push(item.quantity || 0);
        tmpDataValues.push(item.priceInclVat);
      });

      // setReport(res.salesByItem.sort(sortByQuantity).slice());
      setReport({
        items: tmpDataItems,
        quantities: tmpDataQuantities,
        values: tmpDataValues,
      });
      setLoading(false);
      // let tmpData = [];
      // res.salesByItem.sort(sortByQuantity).map((item) => {
      //   tmpData.push({
      //     label: item.itemName,
      //     value: item.quantity,
      //   });
      // });
      // setData(tmpData);
    });
  }, []);

  if (loading) return <FullPageActivityIndicator />;
  console.log("TABLE REPOPRT: ", report);
  // const { data } = input;

  const highestQuantity = report.quantities[0];
  console.log("high: ", highestQuantity);
  return (
    <View style={{ alignSelf: "stretch" }}>
      {/* Names */}
      <View style={{ flexDirection: "row" }}>
        {report && report.items && (
          <View style={{ flexGrow: 1 }}>
            <Text style={{ fontWeight: "bold", marginBottom: 5 }}>Produkt</Text>
            {report.items.map((item, idx) => {
              return (
                <View key={`report-item-${idx}`}>
                  <View>
                    <Text>{item || "I don't know"}</Text>
                  </View>
                  <LinearGradient
                    start={[0, 0]}
                    end={[1, 1]}
                    colors={["#5FC4E1", "#00A13A"]}
                    style={{
                      height: 4,
                      marginTop: 2,
                      marginBottom: 4,
                      // backgroundColor: "red",
                      width: `${
                        (report.quantities[idx] / highestQuantity) * 100
                      }%`,
                      // position: "relative",
                      // backgroundColor: "#fff",
                      // // marginHorizontal: 30,
                      // alignSelf: "stretch",
                      // borderRadius: 8,
                      // // paddingBottom: 20,
                      // padding: 20,
                      // ...elevationShadowStyle(10),
                    }}
                  ></LinearGradient>
                </View>
              );
            })}
          </View>
        )}
        {/* quantities */}
        {report && report.quantities && (
          <View style={{ marginHorizontal: 10 }}>
            <Text style={{ fontWeight: "bold", marginBottom: 5 }}>Antal</Text>
            {report.quantities.map((item, idx) => {
              return (
                <View
                  key={`report-quantities-${idx}`}
                  style={{ marginBottom: 10 }}
                >
                  <Text style={{ textAlign: "center" }}>
                    {item || "I don't know"}
                  </Text>
                </View>
              );
            })}
          </View>
        )}
        {/* Values */}
        {report && report.values && (
          <View>
            <Text style={{ fontWeight: "bold", marginBottom: 5 }}>Värde</Text>
            {report.values.map((item, idx) => {
              return (
                <View key={`report-values-${idx}`} style={{ marginBottom: 10 }}>
                  <Text style={{ textAlign: "right" }}>
                    {item || "I don't know"}kr{" "}
                  </Text>
                </View>
              );
            })}
          </View>
        )}
      </View>
    </View>
  );
};
