const APP_PATHS = {
  onboarding: {
    onboarding: "/welcome",
    login: "/login",
    about: "/about",
    invited: "/invited",
    profile: "/profile",
  },
  me: "/me",
  organization: {
    home: "/organization/home",
    select: "/organization/select",
    settings: {
      home: "/organization/settings",
      details: "/organization/settings/details",
      reciept: "/organization/settings/reciept",
      usermanagement: "/organization/settings/usermanagement",
      paymentmethods: "/organization/settings/paymentmethods",
      groups: "/organization/settings/groups",
      reportavailability: "/organization/settings/reportavailability",
      reportrecievers: "/organization/settings/reportrecievers",
      printmaterial: "/organization/settings/printmaterial",
    },
    users: "/organization/users",
    admins: "/organization/admins",
    superadmins: "/organization/superadmins",
    invite: "/organization/invite",
    create: "/organization/create",
    reports: "/organization/reports",
  },
  pointofsale: {
    select: "/pointofsale/select",
    home: "/pointofsale/home",

    settings: {
      home: "/pointofsale/settings",
      details: "/pointofsale/settings/details",
      paymentmethods: "/pointofsale/settings/paymentmethods",
    },
    items: "/pointofsale/items",
    categories: "/pointofsale/categories",
    reports: "/pointofsale/reports",
    report: "/pointofsale/report",
    create: "/pointofsale/create",
  },
  register: {
    open: "/register/open",
    close: "/register/close",
    register: "/register",
    history: "/register/history",
  },
};

export default APP_PATHS;
