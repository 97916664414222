import { Formik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { Picker, Switch, View } from "react-native";
import { Button, Input, Text } from "react-native-elements";
import FullPageActivityIndicator from "../components/common/FullPageActivityIndicator";
import Icon from "../components/Icon/Icon";
import IconPIcker from "../components/Icon/IconPIcker";
import ModalDoneCheck from "../components/modal/ModalDoneCheck";
import { EMPTY_GUID } from "../config";
import { Item } from "../lib/types/product/item";
import { sortByName } from "../lib/utils/sort";
import { RootStoreContext } from "../stores/RootStore";

interface EditItemFormProps {
  itemId?: string;
  editItem?: Item;
  doneAction?: (b: boolean) => void;
}

const EditItemForm: React.FC<EditItemFormProps> = ({
  itemId,
  editItem,
  doneAction
}) => {
  // console.log("EDIT FORM ITEMID:", itemId);

  const [loading, setLoading] = useState(true);
  const [done, setDone] = useState(false);
  const [item, setItem] = useState(editItem);
  const { pointOfSaleStore } = useContext(RootStoreContext);
  const [selectingIcon, setSelectingIcon] = useState(false);

  useEffect(() => {
    const getItemAsync = async () => {
      pointOfSaleStore._getItem(itemId).then(res => {
        setItem(res);
        setLoading(false);
      });
      // setItem(getItem);
      // setLoading(false);
    };
    if (!item) getItemAsync();
  }, []);
  // console.log("EDIT FORM ITEM:", item);

  if (loading) return <FullPageActivityIndicator />;
  if (done) return <ModalDoneCheck doneAction={doneAction} />;
  return (
    <Formik
      initialValues={{
        id: item.id,
        description: item.description,
        primaryCategoryId: item.primaryCategoryId,
        // type: item.type,
        priceInclVat: item.priceInclVat.toString(),
        icon: item.icon,
        openPrice: item.openPrice
      }}
      onSubmit={(data, { setSubmitting }) => {
        // console.log("FORMIK DATA: ", data);
        const formData = {
          ...data,
          priceInclVat: parseInt(data.priceInclVat)
        };
        if (pointOfSaleStore._updateItem(formData)) {
          setSubmitting(false);
          setDone(true);
          // if (typeof doneAction !== undefined) {
          //   // console.log("action called");
          //   setTimeout(() => {
          //     // console.log("Stäng!");
          //     doneAction(false);
          //   }, 300);
          // }
        } else {
          // console.log("CRAP!");
        }
      }}
    >
      {({
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue
      }) => (
        <View>
          {selectingIcon ? (
            <>
              <IconPIcker
                selectAction={setFieldValue}
                doneAction={setSelectingIcon}
              />
            </>
          ) : (
            <>
              <Text h3>Redigera produkt</Text>
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                  marginVertical: 15
                }}
              >
                <Icon name={values.icon} size={48} />
                <Button
                  title="Byt ikon"
                  onPress={() => setSelectingIcon(true)}
                />
              </View>
              <Input
                label="Namn"
                value={values.description}
                onChangeText={handleChange("description")}
                onBlur={handleBlur("description")}
              />
              <Input
                label="Pris"
                value={values.priceInclVat}
                onChangeText={text => {
                  const parsedPrice = parseInt(text);
                  if (Number.isInteger(parsedPrice)) {
                    setFieldValue("priceInclVat", parsedPrice);
                  }
                  if (text === "") {
                    setFieldValue("priceInclVat", "");
                  }
                }}
                onBlur={handleBlur("priceInclVat")}
                containerStyle={{
                  marginVertical: 15
                }}
              />
              <View style={{ flexDirection: "row" }}>
                <Switch
                  value={values.openPrice}
                  onValueChange={value => setFieldValue("openPrice", value)}
                />
                <Text style={{ marginLeft: 20 }}>
                  Artikeln ska ha rörligt pris.
                </Text>
              </View>

              {/* KATEGORI */}
              {pointOfSaleStore.pointOfSale.categories &&
                pointOfSaleStore.pointOfSale.categories.length > 0 && (
                  <View style={{ marginVertical: 30 }}>
                    <Text>Ska produkten ligga i en kategori?</Text>
                    <Picker
                      selectedValue={values.primaryCategoryId}
                      onValueChange={itemValue =>
                        setFieldValue("primaryCategoryId", itemValue)
                      }
                    >
                      <Picker.Item value={EMPTY_GUID} label="Ingen kategori" />
                      {pointOfSaleStore.pointOfSale.categories
                        .slice()
                        .sort(sortByName)
                        .map(category => {
                          return (
                            <Picker.Item
                              key={category.id}
                              value={category.id}
                              label={category.name}
                            />
                          );
                        })}
                    </Picker>
                  </View>
                )}

              <Button
                containerStyle={{
                  marginVertical: 15
                }}
                title={isSubmitting ? "Sparar..." : "Spara"}
                onPress={() => handleSubmit()}
                disabled={
                  !values.description ||
                  // !values.icon ||
                  !(values.priceInclVat || values.openPrice) ||
                  isSubmitting
                }
              />
            </>
          )}
        </View>
      )}
    </Formik>
  );
};

export default EditItemForm;
