import { Formik } from "formik";
import React, { useContext, useState } from "react";
import { Picker, Switch, View } from "react-native";
import { Button, Input, Text } from "react-native-elements";
import Icon from "../components/Icon/Icon";
import IconPIcker from "../components/Icon/IconPIcker";
import ModalDoneCheck from "../components/modal/ModalDoneCheck";
import { sortByName } from "../lib/utils/sort";
import { RootStoreContext } from "../stores/RootStore";

interface EditItemFormProps {
  doneAction?: (b: boolean) => void;
}

const AddItemForm: React.FC<EditItemFormProps> = ({ doneAction }) => {
  // const [item, setItem] = useState();
  const [done, setDone] = useState(false);
  const { pointOfSaleStore } = useContext(RootStoreContext);
  const [selectingIcon, setSelectingIcon] = useState(false);

  const [showIconModal, setShowIconModal] = useState(false);
  if (done) return <ModalDoneCheck doneAction={doneAction} />;
  return (
    <>
      <Formik
        initialValues={{
          description: "",
          // type: item.type,
          priceInclVat: "",
          icon: "tp-box",
          openPrice: false,
          primaryCategoryId: undefined
        }}
        onSubmit={(data, { setSubmitting }) => {
          // console.log("FORMIK DATA: ", data);
          const formData = {
            ...data,
            priceInclVat: parseInt(data.priceInclVat)
          };
          if (pointOfSaleStore._addItem(formData)) {
            setSubmitting(false);
            setDone(true);
            // if (typeof doneAction !== undefined) {
            //   // console.log("action called");
            //   setTimeout(() => {
            //     // console.log("Stäng!");
            //     doneAction(false);
            //   }, 300);
            // }
          } else {
            // console.log("CRAP!");
          }
        }}
      >
        {({
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue
        }) => {
          console.log("Rendered");
          return (
            <View style={{ position: "relative" }}>
              {selectingIcon ? (
                <>
                  <IconPIcker
                    selectAction={setFieldValue}
                    doneAction={setSelectingIcon}
                  />
                </>
              ) : (
                <>
                  <Text h3>Lägg till produkt</Text>
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-evenly",
                      marginVertical: 15
                    }}
                  >
                    <Icon name={values.icon} size={48} />
                    <Button
                      title="Välj ikon"
                      onPress={() => setSelectingIcon(true)}
                    />
                  </View>
                  <Input
                    label="Namn"
                    value={values.description}
                    onChangeText={handleChange("description")}
                    // onBlur={handleBlur("description")}
                  />
                  <Input
                    label="Pris"
                    value={values.priceInclVat}
                    onChangeText={text => {
                      // handleChange("priceInclVat");
                      const parsedPrice = text.replace(/\D/g, "");
                      if (
                        Number.isInteger(parseInt(parsedPrice)) ||
                        parsedPrice === ""
                      ) {
                        setFieldValue("priceInclVat", parsedPrice);
                        setFieldValue("openPrice", false);
                      }
                    }}
                    // onBlur={handleBlur("priceInclVat")}
                    containerStyle={{
                      marginVertical: 15
                    }}
                  />
                  <View style={{ flexDirection: "row" }}>
                    <Switch
                      value={values.openPrice}
                      onValueChange={value => {
                        setFieldValue("openPrice", value);
                        if (value) {
                          setFieldValue("priceInclVat", "");
                        }
                      }}
                    />
                    <Text style={{ marginLeft: 20 }}>
                      Artikeln ska ha rörligt pris.
                    </Text>
                  </View>

                  {/* KATEGORI */}
                  {pointOfSaleStore.pointOfSale.categories &&
                    pointOfSaleStore.pointOfSale.categories.length > 0 && (
                      <View style={{ marginVertical: 30 }}>
                        <Text>Ska produkten ligga i en kategori?</Text>
                        <Picker
                          selectedValue={values.primaryCategoryId || ""}
                          onValueChange={itemValue =>
                            setFieldValue("primaryCategoryId", itemValue)
                          }
                        >
                          <Picker.Item value={null} label="Ingen kategori" />
                          {pointOfSaleStore.pointOfSale.categories
                            .slice()
                            .sort(sortByName)
                            .map(category => {
                              return (
                                <Picker.Item
                                  key={category.id}
                                  value={category.id}
                                  label={category.name}
                                />
                              );
                            })}
                        </Picker>
                      </View>
                    )}

                  <Button
                    containerStyle={{
                      marginVertical: 15
                    }}
                    title={isSubmitting ? "Sparar..." : "Spara"}
                    onPress={() => handleSubmit()}
                    disabled={
                      !values.description ||
                      // !values.icon ||
                      !(values.priceInclVat || values.openPrice) ||
                      isSubmitting
                    }
                  />
                </>
              )}
            </View>
          );
        }}
      </Formik>
    </>
  );
};

export default AddItemForm;
