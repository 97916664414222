import { LinearGradient } from "expo-linear-gradient";
import { observer } from "mobx-react-lite";
import React, { useContext } from "react";
import { View } from "react-native";
import { Button, Text } from "react-native-elements";
import { RootStoreContext } from "../../stores/RootStore";

const TotalSumDisplay = observer(() => {
  const { registerStore } = useContext(RootStoreContext);
  return (
    <LinearGradient
      start={[0, 0]}
      end={[1, 1]}
      colors={["#5FC4E1", "#00A13A"]}
      // style={{ flex: 1, borderRadius: 8 }}
    >
      <View
        style={{
          flexDirection: "row-reverse",
          justifyContent: "space-between",
          alignItems: "center",
          padding: 15,
          alignSelf: "stretch"
        }}
      >
        <View
          style={{ height: 40, alignItems: "center", flexDirection: "row" }}
        >
          <Text h4 style={{ color: "white", fontWeight: "bold" }}>
            {registerStore.isReturn ? "-" : ""}
            {registerStore.totalSum || 0} kr
          </Text>
        </View>
        {registerStore.isShopping && (
          <Button
            title={`Avbryt ${registerStore.isReturn ? "återköp" : " köp"}`}
            onPress={() => registerStore._emptyCart()}
          />
        )}
      </View>
    </LinearGradient>
  );
});

export default TotalSumDisplay;
