import { Formik } from "formik";
import React, { useContext, useState } from "react";
import { View } from "react-native";
import { Button, Input, Text } from "react-native-elements";
import { RouteComponentProps } from "react-router";
import { ContentScroll } from "../components/layout/Content";
import { FullPageWrapper } from "../components/layout/FullPageWrapper";
import { APP_PATHS } from "../config";
import { theme } from "../lib/styles/theme";
import { RootStoreContext } from "../stores/RootStore";

interface Props extends RouteComponentProps {}

const ProfileScreen: React.FC<Props> = ({ history }) => {
  return (
    <FullPageWrapper>
      <ContentScroll>
        <ForcedUserProfileForm
          doneAction={() => history.push(APP_PATHS.organization.select)}
          // forcedUpdate
        />
      </ContentScroll>
    </FullPageWrapper>
  );
};

export default ProfileScreen;

interface ForcedUserProfileFormProps {
  doneAction?: (b: boolean) => void;
  // forcedUpdate?: boolean;
}

export const ForcedUserProfileForm: React.FC<ForcedUserProfileFormProps> = ({
  doneAction,
}) => {
  const [done, setDone] = useState(false);
  const { userStore } = useContext(RootStoreContext);
  const { user } = userStore;
  const [errorMessage, setErrorMessage] = useState("");
  if (done) {
    doneAction;
    //
    // return <ModalDoneCheck doneAction={doneAction} />;
  }

  return (
    <Formik
      initialValues={{
        firstName: user.firstName,
        lastName: user.lastName,
        emailAddress: user.emailAddress,
        mobileNumber: user.mobileNumber,
      }}
      onSubmit={(data, { setSubmitting }) => {
        if (
          data.emailAddress === "" ||
          data.firstName === "" ||
          data.lastName === "" ||
          data.mobileNumber === ""
        ) {
          setErrorMessage("Alla fält måste fyllas i.");
          setSubmitting(false);
        } else {
          userStore._updateUser(data).then(() => {
            setSubmitting(false);
            doneAction(true);
            // setDone(true);
          });
        }
      }}
    >
      {({
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue,
      }) => (
        <View>
          <Text h3>Hej främling!</Text>
          <Text style={{ marginVertical: 15 }}>
            För att göra det lättare för din förening att veta vem som är vem
            skulle vi vilja att du fyllde i resten av dina uppgifter här under.
          </Text>
          <View style={{ paddingVertical: 15 }}>
            <Input
              label="Förnamn"
              value={values.firstName}
              onChangeText={handleChange("firstName")}
              onBlur={handleBlur("firstName")}
            />
          </View>
          <View>
            <Input
              label="Efternamn"
              value={values.lastName}
              onChangeText={handleChange("lastName")}
              onBlur={handleBlur("lastName")}
            />
          </View>
          <View style={{ paddingVertical: 15 }}>
            <Input
              label="Mobilnummer"
              value={values.mobileNumber}
              onChangeText={handleChange("mobileNumber")}
              onBlur={handleBlur("mobileNumber")}
            />
          </View>
          <View style={{ paddingBottom: 15 }}>
            <Input
              label="Epostadress"
              value={values.emailAddress}
              onChangeText={handleChange("emailAddress")}
              onBlur={handleBlur("emailAddress")}
            />
          </View>
          <Button
            title={isSubmitting ? "Sparar.." : "Spara"}
            onPress={() => handleSubmit()}
            disabled={isSubmitting}
          />
          {!!errorMessage && (
            <>
              <Text
                style={{
                  marginTop: 20,
                  color: theme.colors.danger,
                  fontWeight: "bold",
                }}
              >
                {errorMessage}
              </Text>
            </>
          )}
        </View>
      )}
    </Formik>
  );
};
