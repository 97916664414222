import { observer } from "mobx-react";
import React, { useContext } from "react";
import { ScrollView } from "react-native";
import { RouteComponentProps, withRouter } from "react-router";
import { PointOfSale } from "../../lib/types/shopping/point-of-sale";
import { RootStoreContext } from "../../stores/RootStore";
import { OpenPointOfSaleCard } from "./OpenPointOfSaleCard";

interface Props extends RouteComponentProps {
  pointOfSales: PointOfSale[];
  openOnly?: boolean;
}

const PointOfSaleList: React.FC<Props> = observer(
  ({ history, pointOfSales, openOnly }) => {
    const { pointOfSaleStore } = useContext(RootStoreContext);

    return (
      <>
        <ScrollView
          horizontal={true}
          showsHorizontalScrollIndicator={false}
          decelerationRate="fast"
          style={{ flexGrow: 0 }}
          contentContainerStyle={{
            flexDirection: "row",
            padding: 15
          }}
        >
          {pointOfSales.length > 0 &&
            pointOfSales.map((p, idx) => {
              if (openOnly && !p.session) return null;
              return <OpenPointOfSaleCard key={`pos-card-${idx}`} pos={p} />;
            })}
        </ScrollView>
      </>
    );
  }
);

export default withRouter(PointOfSaleList);
