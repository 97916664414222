import React, { useEffect, useState } from "react";
import { Dimensions, StyleSheet, TouchableOpacity, View } from "react-native";
import { Text } from "react-native-elements";
import defaultIcons from "../../lib/icons/defaultIcons";
import { theme } from "../../lib/styles/theme";
import { sortByDescription } from "../../lib/utils/sort";
import { Heading4 } from "../common/Headings";
import { ContentScroll } from "../layout/Content";
import Icon from "./Icon";

interface Props {
  selectAction: (s: string, a: any) => void;
  doneAction?: (b: boolean) => void;
}

const IconPIcker: React.FC<Props> = ({ selectAction, doneAction }) => {
  const [selectedIconName, setSelectedIconName] = useState(null);
  const [selectedIcon, setSelectedIcon] = useState(null);
  const [selectedColor, setSelectedColor] = useState();
  const [icons, setIcons] = useState([]);
  const [iconFilter, setIconFilter] = useState("");

  // Load all icons
  useEffect(() => {
    setIcons(defaultIcons);
  }, []);
  // Select an icon
  useEffect(() => {
    setSelectedIcon(
      <Icon name={selectedIconName} size={48} color={selectedColor} />
    );
  }, [selectedIconName, selectedColor]);

  return (
    <View style={{ height: Dimensions.get("window").height - 100 }}>
      <ContentScroll>
        {icons.map((iconCat, idx) => {
          return (
            <View
              key={`icon-picker-category-${idx}`}
              style={{ marginBottom: 20 }}
            >
              <Heading4 text={iconCat.category} />
              <View style={{ height: 10 }} />
              {iconCat.icons.sort(sortByDescription).map((icon, idx2) => {
                return (
                  <TouchableOpacity
                    onPress={() => {
                      selectAction("icon", icon.name);
                      doneAction(false);
                    }}
                    key={`icon-picker-icon-${idx}-${idx2}`}
                  >
                    <View
                      style={{ flexDirection: "row", alignItems: "center" }}
                    >
                      <View style={styles.pickerButton}>
                        <Icon name={icon.name} size={32} />
                      </View>
                      <Text>{icon.description}</Text>
                    </View>
                  </TouchableOpacity>
                );
              })}
            </View>
          );
        })}
      </ContentScroll>
    </View>
  );
};

export default IconPIcker;

const styles = StyleSheet.create({
  pickerButton: {
    height: 40,
    width: 40,
    backgroundColor: "#fff",
    borderWidth: 1,
    borderRadius: 4,
    borderColor: theme.colors.brand,
    justifyContent: "center",
    alignItems: "center",
    margin: 5,
    marginRight: 15
  }
});
