import { action, observable } from "mobx";
import { persist } from "mobx-persist";
import { API_ULRS } from "../config";
import axios from "../lib/axios";
import { OrderItem } from "../lib/types/interfaces/shopping/order-item";
import { Item } from "../lib/types/product/item";
import { ActivePaymentMethod } from "../lib/types/shopping/active-payment-method";
import { RootStore } from "./RootStore";

export enum RegisterSteps {
  Shopping = "Shopping",
  Basket = "Basket",
  Payment = "Payment",
  Return = "Return",
  Reciept = "Reciept"
}
export interface CartItem extends Item {
  quantity: number;
  pricePerItem?: number;
  pricePerItemInclVat?: number;
  name?: string;
  item?: Item;
}
export class RegisterStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @persist("object") @observable cartItems: CartItem[] = [];
  @persist @observable totalSum: number = 0;
  @persist @observable isShopping: boolean = false;
  @persist @observable isReturn: boolean = false;
  @persist @observable currentStep: RegisterSteps = RegisterSteps.Shopping;
  @persist("object")
  @observable
  paymentMethod: ActivePaymentMethod | null = null;
  @persist @observable transactionId: string | undefined;
  @persist @observable transactionReference: string | undefined;
  @persist @observable showOpenPriceModal: boolean = false;
  @persist("object") @observable openPriceItemToAdd: Item | null = null;

  @action
  _init = () => {};

  @action
  _isItemInCart = (itemId: string, itemPriceInclVat: number) => {
    let idx2 = -1;
    this.cartItems.map((ci, idx) => {
      if (ci.id === itemId && ci.priceInclVat === itemPriceInclVat) {
        idx2 = idx;
      }
      return true;
    });
    return idx2;
  };

  @action
  _showOpenPrice = async (item: Item) => {
    this.showOpenPriceModal = true;
    this.openPriceItemToAdd = item;
  };

  @action
  _addOpenPriceToCart = async (item: Item) => {
    this._addToCart(item);
  };

  @action
  _addToCart = async (item: Item) => {
    const newCartItems = Object.assign([], this.cartItems) as CartItem[];
    let newItem = Object.assign({}, item) as CartItem;

    const itemInCartIdx = this._isItemInCart(newItem.id, newItem.priceInclVat);

    if (itemInCartIdx !== -1) {
      // already in cart, update quantity
      newCartItems[itemInCartIdx].quantity += 1;
    } else {
      // add item to cart
      newItem.quantity = 1;
      newCartItems.push(newItem);
    }

    // update state
    this.totalSum = this.totalSum + newItem.priceInclVat;
    this.cartItems = newCartItems;
    this.isShopping = true;

    // console.log("CART: ", this.cartItems);
  };

  @action
  _removeFromCart = async (item: Item) => {
    const newCartItems = Object.assign([], this.cartItems) as CartItem[];
    const itemInCartIdx = this._isItemInCart(item.id, item.priceInclVat);

    // remove single if quantity is > 1
    if (newCartItems[itemInCartIdx].quantity > 1) {
      newCartItems[itemInCartIdx].quantity -= 1;
    } else {
      // remove item
      newCartItems.splice(itemInCartIdx, 1);
    }

    this.totalSum = this.totalSum - item.priceInclVat;
    this.cartItems = newCartItems;
    if (this.cartItems.length < 1) {
      this._emptyCart();
    }

    // console.log("CART: ", this.cartItems);
  };

  @action
  _cartAppearances = (itemId: string) => {
    let appearances = 0;
    this.cartItems.map(ci => {
      if (ci.id === itemId) {
        appearances += ci.quantity;
      }
      return true;
    });
    return appearances;
  };

  @action
  _categoryCartAppearances = (categoryItems: [Item]) => {
    let appearances = 0;
    if (!categoryItems) {
      return 0;
    }

    // loop through this category's items
    categoryItems.map(catI => {
      this.cartItems.map(ci => {
        if (ci.id === catI.id) {
          appearances += ci.quantity;
        }
        return true;
      });
      // console.log(catI);
    });

    return appearances;
  };

  @action
  _toggleReturn = () => {
    this.isReturn = !this.isReturn;
  };

  @action
  _emptyCart = () => {
    this.cartItems = [];
    this.totalSum = 0;
    this.isShopping = false;
    this.isReturn = false;
    this.currentStep = RegisterSteps.Shopping;
    this.paymentMethod = null;
    this.transactionId = null;
    this.transactionReference = null;
  };

  @action
  _getTransactionId = async (): Promise<string> => {
    const reqUrl = `${API_ULRS.url}/organization/${this.rootStore.organizationStore.organizationId}/pointofsale/${this.rootStore.pointOfSaleStore.pointofSaleId}/transactionid`;
    return new Promise((resolve, reject) => {
      axios
        .get(reqUrl)
        .then(res => {
          // console.log("TRANSACTIONID: ", res.data.key);
          this.transactionId = res.data.key;
          resolve(res.data.key);
        })
        .catch(err => {
          console.error(err);
          reject(err);
        });
    });
  };

  @action
  _createOrder = async (): Promise<string> => {
    const orderDetails = {
      amountPaied: this.totalSum,
      paymentMethod: this.paymentMethod.paymentMethodKey,
      paymentReceipent: this.paymentMethod.paymentDetails,
      paymentReference: this.transactionId,
      comment: "",
      items: this.generateOrderItems()
    };

    const reqUrl = this.isReturn
      ? `${API_ULRS.url}/order/${this.rootStore.pointOfSaleStore.pointofSaleId}/return`
      : `${API_ULRS.url}/order/${this.rootStore.pointOfSaleStore.pointofSaleId}/create`;

    //  make the order
    return new Promise((resolve, reject) => {
      axios
        .post(reqUrl, JSON.stringify(orderDetails))
        .then(res => {
          // console.log("ORDER RESPONSE", res.data.data);
          this.transactionReference = res.data.data.transactionReference;
          resolve(res.data.data.transactionReference);
        })
        .catch(err => {
          console.error(err);
          reject(err);
        });
    });
  };

  @action
  _settleOrder = (): Promise<boolean> => {
    const reqUrl = `${API_ULRS.url}/order/${this.rootStore.pointOfSaleStore.pointofSaleId}/settle/${this.transactionReference}`;

    return new Promise((resolve, reject) => {
      axios
        .get(reqUrl)
        .then(res => {
          // console.log(res.data.data);
          resolve(true);
        })
        .catch(err => {
          console.error(err);
          reject(false);
        });
    });
  };

  @action
  _cashIn = (data): Promise<boolean> => {
    const reqUrl = `${API_ULRS.url}/organization/${this.rootStore.organizationStore.organizationId}/pointofsale/${this.rootStore.pointOfSaleStore.pointofSaleId}/in`;

    return new Promise((resolve, reject) => {
      axios
        .post(reqUrl, data)
        .then(() => resolve(true))
        .catch(err => {
          console.error(err);
          reject(false);
        });
    });
  };
  @action
  _cashOut = (data): Promise<boolean> => {
    const reqUrl = `${API_ULRS.url}/organization/${this.rootStore.organizationStore.organizationId}/pointofsale/${this.rootStore.pointOfSaleStore.pointofSaleId}/out`;

    return new Promise((resolve, reject) => {
      axios
        .post(reqUrl, data)
        .then(() => resolve(true))
        .catch(err => {
          console.error(err);
          reject(false);
        });
    });
  };

  @action
  _makeReturn = (data, transactionReference): Promise<boolean> => {
    const reqUrl = `${API_ULRS.url}/order/${this.rootStore.pointOfSaleStore.pointofSaleId}/return/${transactionReference}`;
    return new Promise((resolve, reject) => {
      try {
        axios.post(reqUrl, JSON.stringify(data)).then(res => {
          console.log("RETURNDATA: ", res.data);
          if (res.data.success) {
            this.transactionReference = res.data.data.transactionReference;
            this.totalSum = res.data.data.amountPaied;
            this.cartItems = res.data.data.items;
            console.log(
              "current transactionreference",
              this.transactionReference
            );
            resolve(true);
          } else {
            reject(false);
          }
        });
      } catch (error) {
        reject(false);
      }
    });
  };

  generateOrderItems = () => {
    let orderItems: OrderItem[] = [];
    this.cartItems.map(ci => {
      orderItems.push({
        id: ci.id,
        pricePerItem: ci.price,
        pricePerItemInclVat: ci.priceInclVat,
        vatAmount: null,
        quantity: ci.quantity,
        item: ci
      });
    });
    return orderItems;
  };
}

// export const UserStoreContext = createContext(new RegisterStore());
