import { Formik } from "formik";
import React, { useContext, useState } from "react";
import { View } from "react-native";
import { Button, Input, Text } from "react-native-elements";
import ModalDoneCheck from "../components/modal/ModalDoneCheck";
import { API_ULRS } from "../config";
import axiosInstance from "../lib/axios";
import { RootStoreContext } from "../stores/RootStore";

interface AddItemFormProps {
  doneAction?: (b: boolean) => void;
}

const AddGroupForm: React.FC<AddItemFormProps> = ({ doneAction }) => {
  // console.log("EDIT FORM ITEMID:", groupId);

  // const [loading, setLoading] = useState(true);
  const [done, setDone] = useState(false);
  const { organizationStore } = useContext(RootStoreContext);

  // console.log("EDIT FORM ITEM:", item);

  // if (loading) return <FullPageActivityIndicator />;
  if (done) return <ModalDoneCheck doneAction={doneAction} />;
  return (
    <Formik
      initialValues={{
        name: ""
      }}
      onSubmit={(data, { setSubmitting }) => {
        // console.log("FORMIK DATA: ", data);
        const reqUrl = `${API_ULRS.url}/organization/${organizationStore.organizationId}/groups/`;

        axiosInstance.post(reqUrl, JSON.stringify(data)).then(res => {
          organizationStore._refetchOrganization();
          setSubmitting(false);
          setDone(true);
        });
      }}
    >
      {({
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue
      }) => (
        <View>
          <Text h3>Lägg till en grupp</Text>
          <View style={{ height: 20 }} />
          <Input
            label="Namn"
            value={values.name}
            onChangeText={handleChange("name")}
            onBlur={handleBlur("name")}
          />
          <Button
            containerStyle={{
              marginVertical: 15
            }}
            title={isSubmitting ? "Sparar..." : "Spara"}
            onPress={() => handleSubmit()}
            disabled={!values.name || isSubmitting}
          />
        </View>
      )}
    </Formik>
  );
};

export default AddGroupForm;
