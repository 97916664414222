import { action, observable } from "mobx";
import { persist } from "mobx-persist";
import { API_ULRS, DEBUG_PRIVILEGES } from "../config";
import axios from "../lib/axios";
import { PaymentMethod } from "../lib/types/interfaces/shopping/payment-method";
import { Category } from "../lib/types/product/category";
import { Item } from "../lib/types/product/item";
import { PointOfSale } from "../lib/types/shopping/point-of-sale";
import { RootStore } from "./RootStore";
export class PointOfSaleStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @persist @observable pointofSaleId: string | null;
  @persist("object") @observable pointOfSale: PointOfSale | null;
  @observable pointOfSaleStoreLoading: boolean = false;
  @observable closeData: any | null = null;

  @action
  _availablePaymentMethods = (): Promise<PaymentMethod[] | null> => {
    return new Promise((resolve, reject) => {
      return reject(null);
    });
  };

  @action
  _openPointOfSale = (data = null): Promise<boolean> => {
    const reqUrl = `${API_ULRS.url}/organization/${this.rootStore.organizationStore.organizationId}/pointofsale/${this.pointofSaleId}/open`;

    let reqData = {
      paymentMethods: [],
      cashIn: null,
      allowComment: null,
      groupsResponsible: null,
    };
    if (data) {
      // cash
      if (data.pmCash) {
        reqData.paymentMethods.push({
          active: true,
          method: "cash",
        });
      }
      if (data.pmSwish) {
        reqData.paymentMethods.push({
          active: true,
          method: "swish",
          recipient: data.pmSwishRecipient,
        });
      }
      if (data.pmExternal) {
        reqData.paymentMethods.push({
          active: true,
          method: "externalcard",
        });
      }
      if (data.groupsResponsible.length) {
        reqData = {
          ...reqData,
          groupsResponsible: data.groupsResponsible,
        };
      }
      reqData.cashIn = data.pmCashIn ? parseInt(data.pmCashIn) : 0;
      reqData.allowComment = data.allowComment || null;
    }

    console.log("Open data I'm sending: ", reqData);
    return new Promise((resolve, reject) => {
      axios
        .post(reqUrl, JSON.stringify(reqData) || {})
        .then((res) => {
          console.log("OPEN POS RESPONSE: ", res.data);
          this.pointOfSale.session = res.data;
          this._refetchPointOfSale();
          resolve(true);
        })
        .catch((err) => {
          console.error(err);
          reject(false);
        });
    });
  };

  @action
  _prepareClosePointOfSale = (): Promise<boolean> => {
    const reqUrl = `${API_ULRS.url}/organization/${this.rootStore.organizationStore.organizationId}/pointofsale/${this.pointofSaleId}/close`;

    return new Promise((resolve, reject) => {
      axios
        .get(reqUrl)
        .then((res) => {
          this.closeData = res.data;
          console.log("PREPARE CLOSE DATA: ", res.data);
          resolve(true);
        })
        .catch((err) => {
          console.error(err);
          reject(false);
        });
    });
  };

  @action
  _closePointOfSale = (data = null): Promise<boolean> => {
    const reqUrl = `${API_ULRS.url}/organization/${this.rootStore.organizationStore.organizationId}/pointofsale/${this.pointofSaleId}/close`;

    console.log("CLOSE REQUIST INITIAL DATA:", data);

    const reqData = {
      cash: parseInt(data.cashCounted) || 0,
      comment: data.comment || "Avslutad utan kommentar.",
    };

    console.log("CLOSE REQUEST DATA:", reqData);

    return new Promise((resolve, reject) => {
      axios
        .post(reqUrl, JSON.stringify(reqData))
        .then((res) => {
          this.closeData = null;
          console.log("CLOSE DATA:", res.data);
          resolve(true);
        })
        .catch((err) => {
          console.error(err);
          reject(false);
        });
    });
  };

  @action
  _fetchPointOfSale = (loading = true) => {
    if (loading) {
      this.pointOfSaleStoreLoading = true;
    }
    const reqUrl = `${API_ULRS.url}/organization/${this.rootStore.organizationStore.organizationId}/pointofsale/${this.pointofSaleId}`;
    // // try {
    //+ // console.log("sending request: ", reqUrl);
    axios.get(reqUrl).then((res) => {
      this.pointOfSale = res.data.data;
      //+ // console.log(res.data.data);
      if (loading) {
        this.pointOfSaleStoreLoading = false;
      }
    });

    if (DEBUG_PRIVILEGES) {
      axios
        .get(`${API_ULRS.url}/privileges/${this.pointofSaleId}`)
        .then((res) => {
          console.log("POS PRIVILEGES", res.data.data);
        });
    }
  };

  @action
  _refetchPointOfSale = () => {
    this._fetchPointOfSale(false);
    // const reqUrl = `${API_ULRS.url}/organization/${this.rootStore.organizationStore.organizationId}/pointofsale/${this.pointofSaleId}`;
    // // try {
    //+ // console.log("sending request: ", reqUrl);
    // axios.get(reqUrl).then(res => {
    // this.pointOfSale = res.data.data;
    //+ // console.log(res.data.data);
    // this.pointOfSaleStoreLoading = false;
    // });
  };

  @action
  _updatePointOfSale = async (data) => {
    const reqUrl = `${API_ULRS.url}/organization/${this.rootStore.organizationStore.organizationId}/pointofsale/${this.pointofSaleId}`;

    axios
      .post(reqUrl, data)
      .then((res) => {
        this.pointOfSale = res.data.data;
        this.rootStore.organizationStore._refetchOrganization();
        return true;
      })
      .catch((error) => {
        console.error(error);
        return false;
      });
  };

  @action
  _getItem = async (itemId: string): Promise<Item> => {
    //   try {
    const reqUrl = `${API_ULRS.url}/organization/${this.rootStore.organizationStore.organizationId}/item/${itemId}`;
    const res = await axios.get(reqUrl);
    return res.data.data;
  };
  @action
  _updateItem = async (data) => {
    //   try {
    const reqUrl = `${API_ULRS.url}/organization/${this.rootStore.organizationStore.organizationId}/item/${data.id}`;
    axios
      .post(reqUrl, data)
      .then((res) => {
        this._refetchPointOfSale();
        return true;
      })
      .catch((error) => {
        console.error(error);
        return false;
      });
  };
  @action
  _addItem = async (data) => {
    const reqUrl = `${API_ULRS.url}/organization/${this.rootStore.organizationStore.organizationId}/pointofsale/${this.pointofSaleId}/createItem`;
    axios
      .post(reqUrl, data)
      .then((res) => {
        this._refetchPointOfSale();
        return true;
      })
      .catch((error) => {
        console.error(error);
        return false;
      });
  };
  @action
  _deleteItem = async (itemId: string) => {
    const reqUrl = `${API_ULRS.url}/organization/${this.rootStore.organizationStore.organizationId}/item/${itemId}`;
    axios
      .delete(reqUrl)
      .then((res) => {
        this._refetchPointOfSale();
        return true;
      })
      .catch((error) => {
        console.error(error);
        return false;
      });
  };

  @action
  _addCategory = (data): Promise<boolean> => {
    return new Promise((resolve, reject) => {
      const reqUrl = `${API_ULRS.url}/organization/${this.rootStore.organizationStore.organizationId}/pointofsale/${this.pointofSaleId}/category`;
      axios
        .post(reqUrl, data)
        .then((res) => {
          // console.log(res.data);
          this._refetchPointOfSale();
          resolve(true);
        })
        .catch((err) => {
          console.error(err);
          reject(false);
        });
    });
  };

  @action
  _getCategory = async (categoryId: string): Promise<Category> => {
    //   try {
    const reqUrl = `${API_ULRS.url}/organization/${this.rootStore.organizationStore.organizationId}/category/${categoryId}`;
    const res = await axios.get(reqUrl);
    return res.data.data;
  };

  @action
  _updateCategory = (data): Promise<boolean> => {
    return new Promise((resolve, reject) => {
      const reqUrl = `${API_ULRS.url}/organization/${this.rootStore.organizationStore.organizationId}/category/${data.id}`;
      axios
        .post(reqUrl, JSON.stringify(data))
        .then((res) => {
          // console.log(res.data);
          this._refetchPointOfSale();
          resolve(true);
        })
        .catch((err) => {
          console.error(err);
          reject(false);
        });
    });
  };

  @action
  _removeCategory = (id): Promise<boolean> => {
    return new Promise((resolve, reject) => {
      try {
        const reqUrl = `${API_ULRS.url}/organization/${this.rootStore.organizationStore.organizationId}/category/${id}`;
        axios.delete(reqUrl).then((res) => {
          this._refetchPointOfSale();
          resolve(true);
        });
      } catch (error) {
        console.log(error);
        reject(false);
      }
    });
  };

  @action
  _createPointOfsale = async (values) => {
    // let history = useHistory();
    try {
      const createGroup = !!values.createGroup;
      console.log("CREATE GROUP: ", createGroup);

      // let groupData;
      // 1 If we need to greate a new group
      // *****************
      // FLOW FOR NON-GROUP-CREATING POS CREATION
      if (createGroup) {
        console.log("CREATING GROUP");
        const { data: groupData } = await axios.post(
          `${API_ULRS.url}/organization/${values.organizationId}/groups`,
          JSON.stringify({
            name: values.createGroup,
          })
        );
        // 2. Create the point of sale
        console.log("CREATING POS");
        const { data: posData } = await axios.post(
          `${API_ULRS.url}/organization/${values.organizationId}/pointofsale`,
          JSON.stringify({
            ...values,
            groupId: groupData.data.id,
          })
        );
        console.log("POS-DATA I GOT: ", posData);
        this.pointofSaleId = posData.data.id;

        // Create PM?
        if (values.pm_recipient) {
          console.log("I should add PM");
          const { data: pmData } = await axios.post(
            `${API_ULRS.url}/organization/${this.rootStore.organizationStore.organizationId}/pointofsale/${posData.data.id}/paymentmethod/add`,
            JSON.stringify({
              method: "swish",
              description: values.pm_description || "Kassaswish",
              recipient: values.pm_recipient,
              active: true,
              locked: false,
            })
          );
          console.log("PMDATA: ", pmData);
        }

        // If copypos
        if (values.copyPosId.length > 3) {
          // KOPIERA EN KASSA
          const { data: itemData } = await axios.get(
            // organization / { organizationId } / pointofsale / { posId } / copyproductsfrom / { sourcePosId }
            `${API_ULRS.url}/organization/${values.organizationId}/pointofsale/${posData.data.id}/copyproductsfrom/${values.copyPosId}`
          );
        } else if (values.selectedTemplateId) {
          const { data: itemData } = await axios.get(
            // organization / { organizationId } / pointofsale / { posId } / copyproductsfrom / { sourcePosId }
            `${API_ULRS.url}/organization/${values.organizationId}/pointofsale/${posData.data.id}/copyproductsfrom/${values.selectedTemplateId}`
          );
          // } else if (values.selectedTemplate.items) {
          //   const { data: itemData } = await axios.post(
          //     `${API_ULRS.url}/organization/${values.organizationId}/pointofsale/${posData.data.id}/createItems`,
          //     JSON.stringify({ items: values.selectedTemplate.items })
          //   );
          //   console.log(itemData);
        }

        if (posData) {
          return posData.data.id;
        }

        // *****************
        // FLOW FOR NON-GROUP-CREATING POS CREATION
      } else {
        // 2. Create the point of sale
        console.log("CREATING POS");
        const { data: posData } = await axios.post(
          `${API_ULRS.url}/organization/${values.organizationId}/pointofsale`,
          JSON.stringify(values)
        );
        console.log("POS-DATA I GOT: ", posData);
        this.pointofSaleId = posData.data.id;

        // Create PM?
        if (values.pm_recipient) {
          console.log("I should add PM");
          const { data: pmData } = await axios.post(
            `${API_ULRS.url}/organization/${this.rootStore.organizationStore.organizationId}/pointofsale/${posData.data.id}/paymentmethod/add`,
            JSON.stringify({
              method: "swish",
              description: values.pm_description || "Kassaswish",
              recipient: values.pm_recipient,
              active: true,
              locked: false,
            })
          );
          console.log("PMDATA: ", pmData);
        }

        console.log("*********** ITEM DATA");
        console.log("selectedTemplate.items", values.selectedTemplate.items);
        console.log("copyPosId", values.copyPosId);
        // 3. Create items in pos
        if (values.copyPosId.length > 3) {
          // KOPIERA EN KASSA
          const { data: itemData } = await axios.get(
            // organization / { organizationId } / pointofsale / { posId } / copyproductsfrom / { sourcePosId }
            `${API_ULRS.url}/organization/${values.organizationId}/pointofsale/${posData.data.id}/copyproductsfrom/${values.copyPosId}`
          );
        } else if (values.selectedTemplateId) {
          const { data: itemData } = await axios.get(
            // organization / { organizationId } / pointofsale / { posId } / copyproductsfrom / { sourcePosId }
            `${API_ULRS.url}/organization/${values.organizationId}/pointofsale/${posData.data.id}/copyproductsfrom/${values.selectedTemplateId}`
          );
          // } else if (values.selectedTemplate.items) {
          //   const { data: itemData } = await axios.post(
          //     `${API_ULRS.url}/organization/${values.organizationId}/pointofsale/${posData.data.id}/createItems`,
          //     JSON.stringify({ items: values.selectedTemplate.items })
          //   );
          //   console.log(itemData);
        }

        if (posData) {
          return posData.data.id;
        }
      }

      console.log("DONE! Let's go");
      // history.push(`/home/${orgData.data.id}`);

      // 2. Uplaod the logo
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  @action
  _fetchZReports = async (): Promise<any> => {
    return new Promise((resolve, reject) => {
      try {
        const reqUrl = `${API_ULRS.url}/organization/${this.rootStore.organizationStore.organizationId}/reports/zreports`;
        axios.get(reqUrl).then((res) => {
          let resArr = [];
          res.data.data.map((report) => {
            if (report.posId === this.pointofSaleId) {
              resArr.push(report);
            }
          });
          resolve(resArr);
        });
        // 1. hitta alla rapporter
      } catch (error) {
        reject(error);
      }
    });
  };

  @action
  _sendReport = async (values: any, reportId: string): Promise<any> => {
    return new Promise((resolve, reject) => {
      try {
        const reqUrl = `${API_ULRS.url}/reports/zreport/${reportId}/mail`;
        axios.post(reqUrl, JSON.stringify(values)).then((res) => {
          if (res.data.success) {
            resolve(true);
          }
        });
      } catch (error) {
        reject(error);
      }
    });
  };

  @action
  _deletePointOfSale = async (posId): Promise<boolean> => {
    const reqUrl = `${API_ULRS.url}/organization/${this.rootStore.organizationStore.organizationId}/pointofsale/${posId}`;
    return new Promise((resolve, reject) => {
      axios
        .delete(reqUrl)
        .then((res) => {
          // console.log("TRANSACTIONID: ", res.data.key);
          this.rootStore.organizationStore._refetchOrganization();
          resolve(true);
        })
        .catch((err) => {
          console.error(err);
          reject(false);
        });
    });
  };

  @action
  _addPaymentMethodToPointOfSale = async (data): Promise<boolean> => {
    const reqUrl = `${API_ULRS.url}/organization/${this.rootStore.organizationStore.organizationId}/pointofsale/${this.pointofSaleId}/paymentmethod/add`;
    return new Promise((resolve, reject) => {
      axios
        .post(reqUrl, JSON.stringify(data))
        .then((res) => {
          // console.log("TRANSACTIONID: ", res.data.key);
          this._refetchPointOfSale();
          resolve(true);
        })
        .catch((err) => {
          console.error(err);
          reject(false);
        });
    });
  };

  _removePaymentMethodFromPointOfSale = async (pmId): Promise<boolean> => {
    const reqUrl = `${API_ULRS.url}/organization/${this.rootStore.organizationStore.organizationId}/pointofsale/${this.pointofSaleId}/paymentmethod/${pmId}`;
    return new Promise((resolve, reject) => {
      axios
        .delete(reqUrl)
        .then((res) => {
          this._refetchPointOfSale();
          resolve(true);
        })
        .catch((err) => {
          console.error(err);
          reject(false);
        });
    });
  };

  @action
  _updatePaymentMethodInPointOfSale = async (data): Promise<boolean> => {
    const reqUrl = `${API_ULRS.url}/organization/${this.rootStore.organizationStore.organizationId}/pointofsale/${this.pointofSaleId}/paymentmethod/${data.pmId}`;
    return new Promise((resolve, reject) => {
      axios
        .post(reqUrl, JSON.stringify(data))
        .then((res) => {
          // console.log("TRANSACTIONID: ", res.data.key);
          this._refetchPointOfSale();
          resolve(true);
        })
        .catch((err) => {
          console.error(err);
          reject(false);
        });
    });
  };

  @action
  _getXReport = (): Promise<any> => {
    //   try {
    return new Promise((resolve, reject) => {
      const reqUrl = `${API_ULRS.url}/reports/${this.pointofSaleId}/xreport`;
      axios.get(reqUrl).then((res) => {
        if (res.data.success && res.data.data) {
          resolve(res.data.data);
        } else {
          reject(null);
        }
      });
    });
  };
}

// export const OrganizationStoreContext = createContext(new OrganizationStore());
