import { action, observable } from "mobx";
import { persist } from "mobx-persist";
import { RootStore } from "./RootStore";

export class RouterStore {
  @persist @observable pathname = "";

  @persist("object") @observable location = null;

  history = null;

  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;

    this.push = this.push.bind(this);
    this.replace = this.replace.bind(this);
    this.go = this.go.bind(this);
    this.goBack = this.goBack.bind(this);
    this.goForward = this.goForward.bind(this);
  }

  @action
  _updateLocation(newState) {
    this.location = newState;
  }

  /*
   * History methods
   */
  push = location => {
    this.history.push(location);
  };
  replace = location => {
    this.history.replace(location);
  };
  go = n => {
    this.history.go(n);
  };
  goBack = () => {
    this.history.goBack();
  };
  goForward = () => {
    this.history.goForward();
  };
}
