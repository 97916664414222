import React from "react";
import { Text, View } from "react-native";
import { Privilege } from "../../lib/types/shopping/privilege";

interface Props {
  WrappedComponent: any;
  piriviliges: Privilege[];
  code: string;
  FallBackComponent?: any;
}

export const withAuthorization = (
  WrappedComponent,
  privileges,
  code,
  FallBackComponent?
) => {
  const authorized = privileges.filter(function(privilege) {
    return privilege.code === code;
  });

  // console.log(authorized);
  if (authorized.length > 0) {
    return WrappedComponent;
  } else {
    if (FallBackComponent) {
      return FallBackComponent;
    }
    return (
      <View>
        <Text>N/A</Text>
      </View>
    );
  }
};
