import { Formik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { View } from "react-native";
import { Button, Input, Text } from "react-native-elements";
import FullPageActivityIndicator from "../components/common/FullPageActivityIndicator";
import ModalDoneCheck from "../components/modal/ModalDoneCheck";
import { Category } from "../lib/types/product/category";
import { RootStoreContext } from "../stores/RootStore";

interface EditCategoryFormProps {
  categoryId?: string;
  editCategory?: Category;
  doneAction?: (b: boolean) => void;
}

const EditCategoryForm: React.FC<EditCategoryFormProps> = ({
  doneAction,
  categoryId,
  editCategory
}) => {
  // const [item, setItem] = useState();
  const [loading, setLoading] = useState(true);
  const [done, setDone] = useState(false);
  const { pointOfSaleStore } = useContext(RootStoreContext);
  const [category, setCategory] = useState(editCategory);

  useEffect(() => {
    const getCategoryAsync = async () => {
      pointOfSaleStore._getCategory(categoryId).then(res => {
        setCategory(res);
        setLoading(false);
      });
      // setItem(getItem);
      // setLoading(false);
    };
    if (!category) getCategoryAsync();
  }, []);

  if (loading) return <FullPageActivityIndicator />;
  if (done) return <ModalDoneCheck doneAction={doneAction} />;

  return (
    <Formik
      initialValues={{
        id: category.id,
        name: category.name
        // type: item.type,
      }}
      onSubmit={(data, { setSubmitting }) => {
        // console.log("FORMIK DATA: ", data);
        if (pointOfSaleStore._updateCategory(data)) {
          setSubmitting(false);
          setDone(true);
          // if (typeof doneAction !== undefined) {
          //   // console.log("action called");
          //   setTimeout(() => {
          //     // console.log("Stäng!");
          //     doneAction(false);
          //   }, 300);
          // }
        } else {
          // console.log("CRAP!");
        }
      }}
    >
      {({
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue
      }) => (
        <View>
          <Text h3>Redigera kategori</Text>
          <View style={{ paddingVertical: 15 }}>
            <Input
              label="Namn"
              value={values.name}
              onChangeText={handleChange("name")}
              onBlur={handleBlur("name")}
            />
          </View>

          <Button
            title={isSubmitting ? "Sparar..." : "Spara"}
            onPress={() => handleSubmit()}
            disabled={isSubmitting}
          />
        </View>
      )}
    </Formik>
  );
};

export default EditCategoryForm;
