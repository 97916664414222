import { Formik } from "formik";
import React, { useContext, useState } from "react";
import { View } from "react-native";
import { Button, Input, Text } from "react-native-elements";
import ModalDoneCheck from "../components/modal/ModalDoneCheck";
import { RootStoreContext } from "../stores/RootStore";

interface CashInFormProps {
  doneAction?: (b: boolean) => void;
}

const CashInForm: React.FC<CashInFormProps> = ({ doneAction }) => {
  // const [item, setItem] = useState();
  const [done, setDone] = useState(false);
  const { registerStore } = useContext(RootStoreContext);
  if (done) return <ModalDoneCheck doneAction={doneAction} />;
  return (
    <Formik
      initialValues={{
        amount: "",
        comment: "",
        by: ""
      }}
      onSubmit={(data, { setSubmitting }) => {
        // console.log("FORMIK DATA: ", data);
        const formData = {
          ...data,
          comment: `${data.comment} - Insättningen gjordes av ${data.by}`
        };

        if (registerStore._cashIn(formData)) {
          setSubmitting(false);
          setDone(true);
          // if (typeof doneAction !== undefined) {
          //   // console.log("action called");
          //   setTimeout(() => {
          //     // console.log("Stäng!");
          //     doneAction(false);
          //   }, 300);
          // }
        } else {
          // console.log("CRAP!");
        }
      }}
    >
      {({
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue
      }) => (
        <View>
          <Text h3>Gör en insättning</Text>
          <View style={{ paddingVertical: 15 }}>
            <Input
              label="Summa"
              value={values.amount}
              onChangeText={handleChange("amount")}
              onBlur={handleBlur("amount")}
            />
          </View>
          <View style={{ paddingVertical: 15 }}>
            <Input
              label="Kommentar"
              value={values.comment}
              onChangeText={handleChange("comment")}
              onBlur={handleBlur("comment")}
            />
          </View>
          <View style={{ paddingVertical: 15 }}>
            <Input
              label="Insättningen gjordes av"
              value={values.by}
              onChangeText={handleChange("by")}
              onBlur={handleBlur("by")}
            />
          </View>
          <Button
            title={isSubmitting ? "Sätter in.." : "Gör insättning"}
            onPress={() => handleSubmit()}
            disabled={isSubmitting}
          />
        </View>
      )}
    </Formik>
  );
};

export default CashInForm;
