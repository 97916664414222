import { Formik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { View } from "react-native";
import { Button, Input, Text } from "react-native-elements";
import { RouteComponentProps, useParams } from "react-router";
import FullPageActivityIndicator from "../components/common/FullPageActivityIndicator";
import { API_ULRS, APP_PATHS } from "../config";
import { default as axios, default as axiosInstance } from "../lib/axios";
import { RootStoreContext } from "../stores/RootStore";

interface Props extends RouteComponentProps {}

const InvitedScreen: React.FC<Props> = ({ history }) => {
  const { orgid } = useParams();
  const { userStore } = useContext(RootStoreContext);
  const [inviteOrgData, setInviteOrgData] = useState();
  const [loading, setLoading] = useState(true);
  const [verifying, setVerifying] = useState(false);

  // TODO: redirect if no orgid

  useEffect(() => {
    const getOrgData = () => {
      axiosInstance
        .get(`${API_ULRS.url}/organization/${orgid}/name`)
        .then(res => {
          console.log(res);
          setInviteOrgData(res.data.data);
          setLoading(false);
        });
    };
    getOrgData();
  }, [orgid]);

  if (loading) return <FullPageActivityIndicator />;

  // TODO: if bad data

  return (
    <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
      <Text style={{ fontSize: 22 }}>Välkommen till TeamPay</Text>
      <Text style={{ marginVertical: 15 }}>Du har blivit inbjuden till</Text>
      <Text style={{ fontSize: 18, marginBottom: 30 }}>
        {inviteOrgData.name}
      </Text>
      <Text>Vill du ansluta till föreningen?</Text>
      {userStore.userId && (
        <View>
          <Button
            title={`Anslut`}
            onPress={() => {
              // /organization/9392f714-0c42-4d5d-8ea4-0949f2ba0c34/adduser/522b0634-4bd5-4852-801f-75574604405f
              const reqUrl = `${API_ULRS.url}/organization/${orgid}/adduser/${userStore.userId}`;
              axios.get(reqUrl).then(res => {
                if (res.data.success) {
                  userStore._refetchUser().then(res => {
                    history.push(APP_PATHS.organization.select);
                  });
                }
              });
            }}
            style={{ marginVertical: 15 }}
          />
          <Button
            type="outline"
            title={`Nej tack`}
            onPress={() => history.push(APP_PATHS.organization.select)}
          />
        </View>
      )}
      {!userStore.userId && (
        <View>
          <Text>Logga in för att koppla din användare till föreningen.</Text>
          <Formik
            initialValues={{
              organizationId: inviteOrgData.id,
              code: "",
              mobileNumber: ""
            }}
            onSubmit={(data, { setSubmitting }) => {
              if (!verifying) {
                // send code for verification
                if (userStore._connect(data)) {
                  // console.log(data);
                  setSubmitting(false);
                  setVerifying(true);
                } else {
                  setSubmitting(false);
                  console.error("CRAAAP!");
                }
              } else {
                // login
                userStore
                  ._verify(data)
                  .then(() => {
                    // console.log(data);
                    setSubmitting(false);
                    history.push(APP_PATHS.organization.select);
                  })
                  .catch(err => {
                    console.error(err);
                    setVerifying(false);
                  });
              }
              // userStore
              //   ._verify(data)
              //   .then(() => {
              //     // console.log(data);
              //     setSubmitting(false);
              //     history.push(APP_PATHS.organization.select);
              //   })
              //   .catch(err => {
              //     console.error(err);
              //     setVerifying(false);
              //   });
            }}
          >
            {({
              values,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting
            }) => (
              <>
                {/* FORM */}

                {!verifying && (
                  <>
                    <View style={{ marginVertical: 15 }}>
                      <Input
                        label="Ditt mobilnummer"
                        autoFocus
                        value={values.mobileNumber}
                        onBlur={handleBlur("mobileNumber")}
                        onChangeText={handleChange("mobileNumber")}
                        onSubmitEditing={() => handleSubmit()}
                      />
                    </View>
                    <Button
                      loading={isSubmitting}
                      onPress={() => handleSubmit()}
                      disabled={isSubmitting}
                      title={isSubmitting ? "Skickar..." : "Skicka min kod!"}
                    />
                  </>
                )}
                {verifying && (
                  <>
                    <View style={{ marginVertical: 15 }}>
                      <Input
                        label="Din verifieringskod"
                        autoFocus
                        value={values.code}
                        onBlur={handleBlur("code")}
                        onChangeText={handleChange("code")}
                        onSubmitEditing={() => handleSubmit()}
                      />
                    </View>
                    <Button
                      loading={isSubmitting}
                      onPress={() => handleSubmit()}
                      disabled={isSubmitting}
                      title={isSubmitting ? "Skickar..." : "Skicka min kod!"}
                    />
                  </>
                )}
              </>
            )}
          </Formik>
        </View>
      )}
    </View>
  );
};

export default InvitedScreen;
