import { observer } from "mobx-react";
import React, { useContext } from "react";
import {
  Dimensions,
  Image,
  ScrollView,
  Text,
  TouchableOpacity,
  View
} from "react-native";
import { Input } from "react-native-elements";
import { RouteComponentProps, withRouter } from "react-router";
import { APP_PATHS } from "../../config";
import { theme } from "../../lib/styles/theme";
import { UserInOrganization } from "../../lib/types/users/user-in-organization";
import { elevationShadowStyle } from "../../lib/utils/elevationShadowStyle";
import { RootStoreContext } from "../../stores/RootStore";
import { TpIconTeam1 } from "../Icon";
import Icon from "../Icon/Icon";

interface Props {
  orgs: UserInOrganization[];
  filterText: string;
  setFilterText: (s: string) => void;
  filter: boolean;
}

const screenWidth = Math.round(Dimensions.get("window").width);
const numColumns = Math.floor(screenWidth / 160);
// console.log("NUMCOLS: ", numColumns);
export const OrganizationSelectList: React.FC<Props> = ({
  orgs,
  filter,
  filterText,
  setFilterText
}) => {
  return (
    <>
      {filter && (
        <View style={{ paddingVertical: 5, paddingHorizontal: 15 }}>
          <Input
            label="Sök förening"
            value={filterText}
            placeholder="Minst 2 bokstäver"
            onChangeText={setFilterText}
          />
          {/* <TextInput
            label="Sök förening"
            value={filterText}
            placeholder="Minst 2 bokstäver"
            onChange={setFilterText}
            textStyle={{ color: "#fff" }}
          /> */}
        </View>
      )}
      {!filter && <View style={{ height: 15 }}></View>}
      <ScrollView horizontal={false} decelerationRate="fast">
        <View
          style={{
            flex: 1,
            flexDirection: "row",
            flexWrap: "wrap",
            padding: 10
            // alignItems: "flex-start"
          }}
        >
          {orgs.map(org => {
            let logo = null;
            if (org.organization.icon && org.organization.icon.length > 3) {
              logo = org.organization.icon;
            }
            if (
              org.organization.logoUrl64x64 &&
              org.organization.logoUrl64x64.length > 10
            ) {
              logo = org.organization.logoUrl64x64;
            }
            return (
              <OrganizationSelectListItem
                active={org.organization.active}
                id={org.organization.id}
                name={org.organization.name}
                logo={logo || null}
                key={`org-select-list-${org.organization.id}`}
              />
            );
          })}
        </View>
      </ScrollView>
    </>
  );
};

interface OrganizationSelectListItemWithOutRouterProps
  extends RouteComponentProps {
  active: boolean;
  name: string;
  id: string;
  logo?: string;
}
const OrganizationSelectListItemWithOutRouter: React.FC<OrganizationSelectListItemWithOutRouterProps> = observer(
  ({ name, id, logo, history, active }) => {
    const { organizationStore } = useContext(RootStoreContext);
    let Logo;

    // console.log(`${name} är ${active ? "aktive" : "inte aktiv"}`);
    if (logo) {
      // console.log(`Found logo for ${name}`);
      if (logo.length === 0) {
        Logo = <TpIconTeam1 size={48} color={active ? "" : "red"} />;
      }
      if (logo.length < 15) {
        // console.log(`Found Symbol for ${name} - ${logo}`);
        if (logo === "tp-icon-1") {
          Logo = <Icon name={`tp-icon-team-1`} size={48} />;
        } else {
          // console.log(`Found IMAGE for ${name}`);
          Logo = <Icon name={logo} size={48} />;
        }
      } else {
        Logo = (
          <Image
            source={{ uri: logo }}
            style={{
              width: 48,
              height: 48,
              resizeMode: "contain"
            }}
          />
        );
      }
    } else {
      Logo = <TpIconTeam1 size={48} />;
    }
    return (
      <TouchableOpacity
        // onPress={() => console.log(`clicked ${name} (${id})`)}
        onPress={() => {
          organizationStore.organizationId = id;
          // setCurrentOrganization(id);
          // console.log(`clicked ${name} (${id})`);
          history.push(APP_PATHS.organization.home);
        }}
        style={{
          width: `${100 / numColumns}%`,
          position: "relative",
          overflow: "hidden"
        }}
      >
        {!active && (
          <View
            style={{
              position: "absolute",
              top: 25,
              right: -50,
              paddingHorizontal: 50,
              backgroundColor: theme.colors.danger,
              transform: [{ rotate: "45deg" }],
              zIndex: 10
            }}
          >
            <Text style={{ color: "white" }}>Saknar avtal</Text>
          </View>
        )}
        <View
          style={{
            justifyContent: "center",
            alignItems: "center",
            // position: "relative",
            flex: 1,
            backgroundColor: "#ffffff",
            alignSelf: "stretch",
            borderRadius: 10,
            padding: 10,
            margin: 5,

            ...elevationShadowStyle(5)
          }}
        >
          <View
            style={{
              width: 48,
              height: 48,
              justifyContent: "center",
              alignItems: "center",
              marginBottom: 10
            }}
          >
            {Logo}
            {/* <Text>[l]</Text> */}
          </View>
          <View
            style={{
              flex: 1,
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <Text
              style={{
                flex: 1,
                fontSize: 12,
                textAlign: "center"
              }}
            >
              {name}
            </Text>
          </View>
        </View>
        {/* </LinearGradient> */}
      </TouchableOpacity>
    );
  }
);

const OrganizationSelectListItem = withRouter(
  OrganizationSelectListItemWithOutRouter
);
