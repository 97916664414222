import { LinearGradient } from "expo-linear-gradient";
import React, { useContext } from "react";
import { Text, TouchableOpacity, View } from "react-native";
import { RouteComponentProps, withRouter } from "react-router";
import { APP_PATHS } from "../../config";
// import TpIconRegister from "../../assets/icons/system/TpIconRegister";
// import { OrganizationContext } from "../../../lib/contexts/OrganizationContext";
import { theme } from "../../lib/styles/theme";
import { PointOfSale } from "../../lib/types/shopping/point-of-sale";
// import { defaultPos } from "../../lib/utils/devHelpers";
import { elevationShadowStyle } from "../../lib/utils/elevationShadowStyle";
import { RootStoreContext } from "../../stores/RootStore";
import { TpIconRegister } from "../Icon";
interface Props extends RouteComponentProps {
  pos?: PointOfSale;
}

const findGroupName = (groupId, groups) => {
  const group = groups.filter(group => group.id === groupId);
  return group[0].name;
};

export const PointOfSaleCardWithoutRouter: React.FC<Props> = ({
  pos,
  history
}) => {
  const { organizationStore, pointOfSaleStore } = useContext(RootStoreContext);
  return (
    <View
      style={{
        backgroundColor: pos.session ? "rgb(0, 161, 58);" : "#fff",
        borderRadius: 8,
        marginLeft: 10,
        marginRight: 10,
        flexGrow: 1,
        width: 220,
        height: 90,
        ...elevationShadowStyle(10, theme.colors.brand)
      }}
    >
      <TouchableOpacity
        onPress={() => {
          pointOfSaleStore.pointofSaleId = pos.id;
          history.push(APP_PATHS.pointofsale.home);
        }}
      >
        <View
          style={{
            paddingVertical: 20,
            paddingHorizontal: 15,
            overflow: "hidden"
          }}
        >
          <Text>{pos.name}</Text>
          <Text>
            {findGroupName(pos.groupId, organizationStore.organization.groups)}
          </Text>
        </View>
      </TouchableOpacity>
    </View>
  );
};

export const PointOfSaleCard = withRouter(PointOfSaleCardWithoutRouter);

const OpenPointOfSaleCardWithoutRouter: React.FC<Props> = ({
  pos,
  history
}) => {
  const { organizationStore, pointOfSaleStore } = useContext(RootStoreContext);

  if (!pos.session) {
    return (
      <>
        <View
          style={{
            borderRadius: 8,
            marginLeft: 10,
            marginRight: 10,
            flexGrow: 1,
            width: 220,
            height: 90,
            backgroundColor: "#fff",
            ...elevationShadowStyle(7, theme.colors.brand)
          }}
        >
          <TouchableOpacity
            style={{
              flex: 1,
              borderRadius: 8,
              borderColor: theme.colors.brand,
              borderWidth: 1
            }}
            onPress={() => {
              pointOfSaleStore.pointofSaleId = pos.id;
              history.push(APP_PATHS.pointofsale.home);
            }}
          >
            <View
              style={{
                flex: 1,
                paddingHorizontal: 15,
                overflow: "hidden",
                alignItems: "center",
                flexDirection: "row"
                // backgroundColor: "blue",
              }}
            >
              <TpIconRegister size={32} />

              <View
                style={{
                  flex: 1,
                  flexGrow: 1,
                  marginLeft: 10
                  // backgroundColor: "green"
                  // alignSelf: "stretch",
                  // flexGrow: 0
                }}
              >
                <View
                  style={{
                    // width: "100%",
                    flexGrow: 1,
                    flex: 1,
                    alignItems: "flex-end",
                    justifyContent: "flex-end"
                  }}
                >
                  <Text
                    style={{
                      width: "100%",
                      fontSize: 15,
                      // color: "#ffffff",
                      // flexGrow: 1,
                      // flex: 1,
                      textAlignVertical: "bottom",
                      marginBottom: 3
                      // backgroundColor: "red"
                    }}
                  >
                    {pos.name}
                  </Text>
                </View>
                <View
                  style={{
                    flexGrow: 1,
                    flex: 1
                  }}
                >
                  <Text
                    style={{
                      width: "100%",
                      fontSize: 12,
                      textTransform: "uppercase",
                      textAlignVertical: "top",
                      marginTop: 3
                      // color: "#ffffff"
                      // flexGrow: 1,
                      // flex: 1,
                      // flexWrap: "wrap",

                      // backgroundColor: "green"
                    }}
                  >
                    {findGroupName(
                      pos.groupId,
                      organizationStore.organization.groups
                    )}
                  </Text>
                </View>
              </View>
            </View>
          </TouchableOpacity>
        </View>
      </>
    );
  }
  return (
    <>
      <View
        style={{
          borderRadius: 8,
          marginLeft: 10,
          marginRight: 10,
          flexGrow: 1,
          width: 220,
          height: 90,
          ...elevationShadowStyle(7, theme.colors.brand)
        }}
      >
        <LinearGradient
          start={[0, 0]}
          end={[1, 1]}
          colors={["#5FC4E1", "#00A13A"]}
          style={{ flex: 1, borderRadius: 8 }}
        >
          <TouchableOpacity
            style={{ flex: 1 }}
            onPress={() => {
              pointOfSaleStore.pointofSaleId = pos.id;
              history.push(APP_PATHS.pointofsale.home);
            }}
          >
            <View
              style={{
                flex: 1,
                paddingHorizontal: 15,
                overflow: "hidden",
                alignItems: "center",
                flexDirection: "row"
                // backgroundColor: "blue",
              }}
            >
              <TpIconRegister size={32} color="#ffffff" />

              <View
                style={{
                  flex: 1,
                  flexGrow: 1,
                  marginLeft: 10
                  // backgroundColor: "green"
                  // alignSelf: "stretch",
                  // flexGrow: 0
                }}
              >
                <View
                  style={{
                    // width: "100%",
                    flexGrow: 1,
                    flex: 1,
                    alignItems: "flex-end",
                    justifyContent: "flex-end"
                  }}
                >
                  <Text
                    style={{
                      width: "100%",
                      fontSize: 15,
                      color: "#ffffff",
                      // flexGrow: 1,
                      // flex: 1,
                      textAlignVertical: "bottom",
                      marginBottom: 3
                      // backgroundColor: "red"
                    }}
                  >
                    {pos.name}
                  </Text>
                </View>
                <View
                  style={{
                    flexGrow: 1,
                    flex: 1
                  }}
                >
                  <Text
                    style={{
                      width: "100%",
                      fontSize: 12,
                      textTransform: "uppercase",
                      textAlignVertical: "top",
                      marginTop: 3,
                      color: "#ffffff"
                      // flexGrow: 1,
                      // flex: 1,
                      // flexWrap: "wrap",

                      // backgroundColor: "green"
                    }}
                  >
                    {findGroupName(
                      pos.groupId,
                      organizationStore.organization.groups
                    )}
                  </Text>
                </View>
              </View>
            </View>
          </TouchableOpacity>
        </LinearGradient>
      </View>
    </>
  );
};
export const OpenPointOfSaleCard = withRouter(OpenPointOfSaleCardWithoutRouter);
