import { Formik } from "formik";
import React, { useContext, useState } from "react";
import { View } from "react-native";
import { Button, Input, Text } from "react-native-elements";
import ModalDoneCheck from "../components/modal/ModalDoneCheck";
import { RootStoreContext } from "../stores/RootStore";

interface AddCategoryFormProps {
  doneAction?: (b: boolean) => void;
}

const AddCategoryForm: React.FC<AddCategoryFormProps> = ({ doneAction }) => {
  // const [item, setItem] = useState();
  const [done, setDone] = useState(false);
  const { pointOfSaleStore } = useContext(RootStoreContext);
  if (done) return <ModalDoneCheck doneAction={doneAction} />;
  return (
    <Formik
      initialValues={{
        name: ""
        // type: item.type,
      }}
      onSubmit={(data, { setSubmitting }) => {
        // console.log("FORMIK DATA: ", data);
        if (pointOfSaleStore._addCategory(data)) {
          setSubmitting(false);
          setDone(true);
          // if (typeof doneAction !== undefined) {
          //   // console.log("action called");
          //   setTimeout(() => {
          //     // console.log("Stäng!");
          //     doneAction(false);
          //   }, 300);
          // }
        } else {
          // console.log("CRAP!");
        }
      }}
    >
      {({
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue
      }) => (
        <View>
          <Text h3>Lägg till kategori</Text>
          <View style={{ paddingVertical: 15 }}>
            <Input
              label="Namn"
              value={values.name}
              onChangeText={handleChange("name")}
              onBlur={handleBlur("name")}
            />
          </View>

          <Button
            title={isSubmitting ? "Sparar..." : "Spara"}
            onPress={() => handleSubmit()}
            disabled={isSubmitting}
          />
        </View>
      )}
    </Formik>
  );
};

export default AddCategoryForm;
