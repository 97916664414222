import React, { useContext } from "react";
import { Image, View } from "react-native";
import { Button, Text } from "react-native-elements";
import { RouteComponentProps } from "react-router";
import BackButton from "../components/common/BackButton";
import { HeaderTitleSubtitle } from "../components/headerItems/HeaderTitleSubtitle";
import { LargeHeaderIcon } from "../components/headerItems/LargeHeaderIcon";
import { TpIconTeam3 } from "../components/Icon";
import Icon from "../components/Icon/Icon";
import { ContentFixed, ContentScroll } from "../components/layout/Content";
import { FullPageWrapper } from "../components/layout/FullPageWrapper";
import { Header } from "../components/layout/Header";
import SettingsRow from "../components/settings/SettingsRow";
import { APP_PATHS } from "../config";
import { theme } from "../lib/styles/theme";
import { RootStoreContext } from "../stores/RootStore";

interface Props extends RouteComponentProps {}

const OrganizationSettingsMainScreen: React.FC<Props> = ({ history }) => {
  const { organizationStore } = useContext(RootStoreContext);

  let orgLogo;
  if (organizationStore.organization) {
    if (organizationStore.organization.logoUrl128x128) {
      orgLogo = (
        <Image
          style={{ width: 64, height: 64 }}
          source={{
            uri: organizationStore.organization.logoUrl128x128.replace(
              "http:",
              "https:"
            ),
          }}
        />
      );
    } else {
      orgLogo = <TpIconTeam3 size={64} />;
    }
  }
  return (
    <FullPageWrapper>
      <Header>
        <BackButton to={APP_PATHS.organization.home} />
        <LargeHeaderIcon>{orgLogo}</LargeHeaderIcon>
        <HeaderTitleSubtitle
          subtitle={
            organizationStore.organization
              ? organizationStore.organization.name
              : " "
          }
          title="Inställningar"
        />
        {/* <HeaderMenuButton /> */}
      </Header>
      <ContentFixed>
        <View style={{ marginTop: 40, paddingHorizontal: 15 }}>
          <Text>Här kan du göra diverse inställningar för din förening.</Text>
          <View style={{ marginTop: 15, alignItems: "flex-end" }}>
            <Button
              title="Skriv ut TeamPay-material"
              onPress={() =>
                history.push(APP_PATHS.organization.settings.printmaterial)
              }
            />
          </View>
        </View>
      </ContentFixed>
      <ContentScroll>
        <SettingsTitle title="Användare" icon="tp-icon-user" />
        <SettingsRow
          title="Hantera användare"
          subtitle="Sök och redigera befintliga användare"
          path={APP_PATHS.organization.users}
        />
        <SettingsRow
          title="Hantera administratörer"
          subtitle="Hantera användare med utökade rättigheter"
          path={APP_PATHS.organization.admins}
        />
        {/* <SettingsRow
          title="Hantera superadministratörer"
          subtitle="Hantera användare alla rättigheter"
          path={APP_PATHS.organization.superadmins}
        /> */}
        <SettingsRow
          title="Användarhantering"
          subtitle="Gäster eller permanenta användare?"
          path={APP_PATHS.organization.settings.usermanagement}
        />

        <SettingsTitle title="Grupper" icon="tp-icon-users" />
        <SettingsRow
          title="Hantera grupper"
          subtitle="Redigera organisationens grupper"
          path={APP_PATHS.organization.settings.groups}
        />

        <SettingsTitle title="Betalsätt" icon="tp-icon-paymentmethods" />
        <SettingsRow
          title="Hantera betalsätt"
          subtitle="Redigera organisationens betalsätt"
          path={APP_PATHS.organization.settings.paymentmethods}
        />

        <SettingsTitle title="Rapporter" icon="tp-icon-folder" />
        <SettingsRow
          title="Rapporttillgänglighet"
          subtitle="Vem ska få se era rapporter?"
          path={APP_PATHS.organization.settings.reportavailability}
        />
        <SettingsRow
          title="Rapportmottagare"
          subtitle="Vem ska få era rapporter?"
          path={APP_PATHS.organization.settings.reportrecievers}
        />

        <SettingsTitle title="Allmänt" icon="tp-icon-cog" />
        <SettingsRow
          title="Föreningsuppgifter"
          subtitle="Namn, organisationsnummer, etc"
          path={APP_PATHS.organization.settings.details}
        />
        <SettingsRow
          title="Kontakt- &amp; kvittouppgifter"
          subtitle="Obligatorisk information för kvitton"
          path={APP_PATHS.organization.settings.reciept}
        />
        {/* <SettingsRow
          title="Logotyp"
          subtitle="Här kan ni ladda upp eller ändra logotyp. "
          path={APP_PATHS.organization.settings.home}
        /> */}
        <View style={{ height: 40 }} />
      </ContentScroll>
    </FullPageWrapper>
  );
};

export default OrganizationSettingsMainScreen;

interface SettingsTitleProps {
  title: string;
  icon?: string;
}

const SettingsTitle: React.FC<SettingsTitleProps> = ({ title, icon }) => (
  <View
    style={{
      flexDirection: "row",
      alignItems: "center",
      marginTop: 30,
      backgroundColor: theme.colors.primary,
      borderRadius: 4,
      padding: 8,
    }}
  >
    {icon && <Icon name={icon} color="#ffffff" size={24} />}
    <Text
      style={{
        color: "#ffffff",
        marginLeft: icon ? 20 : 0,
        textTransform: "uppercase",
        fontSize: 14,
        fontWeight: "bold",
      }}
    >
      {title}
    </Text>
  </View>
);
