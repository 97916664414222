import { Formik } from "formik";
import { observer } from "mobx-react";
import React, { useContext, useState } from "react";
import { View } from "react-native";
import { Button, Input } from "react-native-elements";
import ModalDoneCheck from "../components/modal/ModalDoneCheck";
import { RootStoreContext } from "../stores/RootStore";

interface SendReportFormProps {
  doneAction?: (b: boolean) => void;
  reportToSend: string;
}

const SendReportForm: React.FC<SendReportFormProps> = observer(
  ({ reportToSend, doneAction }) => {
    const [done, setDone] = useState(false);
    const { pointOfSaleStore, organizationStore } = useContext(
      RootStoreContext
    );
    const { pointOfSale } = pointOfSaleStore;
    if (done) return <ModalDoneCheck doneAction={doneAction} />;
    return (
      <Formik
        initialValues={{
          reportReceipients: ""

          // id: organizationStore.organization.id,
          // name: organizationStore.organization.name,
          // orgNumber: organizationStore.organization.orgNumber
        }}
        onSubmit={(data, { setSubmitting }) => {
          // console.log("FORMIK DATA: ", data);
          if (pointOfSaleStore._sendReport(data, reportToSend)) {
            setSubmitting(false);
            setDone(true);
          } else {
            // console.log("CRAP!");
          }
        }}
      >
        {({
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue
        }) => (
          <View>
            <View style={{ paddingVertical: 15 }}>
              <Input
                label="Mottagare"
                value={values.reportReceipients}
                onChangeText={handleChange("reportReceipients")}
                onBlur={handleBlur("reportReceipients")}
              />
            </View>
            <Button
              title={isSubmitting ? "Skickar..." : "Skicka"}
              onPress={() => handleSubmit()}
              disabled={isSubmitting}
            />
          </View>
        )}
      </Formik>
    );
  }
);

export default SendReportForm;
