import { observer } from "mobx-react";
import React, { useContext, useState } from "react";
import { Image, Switch, TouchableOpacity, View } from "react-native";
import { Button, Text } from "react-native-elements";
import { RouteComponentProps } from "react-router";
import BackButton from "../components/common/BackButton";
import FullPageActivityIndicator from "../components/common/FullPageActivityIndicator";
import { HeaderTitleSubtitle } from "../components/headerItems/HeaderTitleSubtitle";
import { LargeHeaderIcon } from "../components/headerItems/LargeHeaderIcon";
import { TpIconCog, TpIconTeam3 } from "../components/Icon";
import Icon from "../components/Icon/Icon";
import { ContentFixed, ContentScroll } from "../components/layout/Content";
import { FullPageWrapper } from "../components/layout/FullPageWrapper";
import { Header } from "../components/layout/Header";
import Modal from "../components/modal/Modal";
import { API_ULRS, APP_PATHS } from "../config";
import AddPaymentMethodForm from "../containers/AddPaymentMethodForm";
import EditPaymentMethodForm from "../containers/EditPaymentMethodForm";
import axiosInstance from "../lib/axios";
import { theme } from "../lib/styles/theme";
import { PaymentMethod } from "../lib/types/interfaces/shopping/payment-method";
import { elevationShadowStyle } from "../lib/utils/elevationShadowStyle";
import { RootStoreContext } from "../stores/RootStore";

interface Props extends RouteComponentProps {}

const PointOfSaleSettingsPaymentMethodsScreen = observer(({ history }) => {
  const { organizationStore, pointOfSaleStore } = useContext(RootStoreContext);
  const [currentPM, setCurrentPM] = useState<PaymentMethod | null>();
  const [currentPMId, setCurrentPMId] = useState("");
  const [showPMModal, setShowPMModal] = useState(false);
  const [lockCard, setLockCard] = useState(
    !!pointOfSaleStore.pointOfSale.paymentMethods.filter(
      (pm) => pm.method === "externalcard"
    ).length
  );

  if (pointOfSaleStore.pointOfSaleStoreLoading) {
    return <FullPageActivityIndicator />;
  }

  const updateCard = () => {
    if (
      !!pointOfSaleStore.pointOfSale.paymentMethods.filter(
        (pm) => pm.method === "externalcard"
      ).length
    ) {
      const pm = pointOfSaleStore.pointOfSale.paymentMethods.filter(
        (pm) => pm.method === "externalcard"
      );

      // om det finns "externalcard", ta bort den
      axiosInstance
        .delete(
          `${API_ULRS.url}/organization/${organizationStore.organizationId}/pointofsale/${pointOfSaleStore.pointofSaleId}/paymentmethod/${pm[0].id}` // url
        )
        .then((res) => {
          setLockCard(false);
          pointOfSaleStore._refetchPointOfSale();
        });
    } else {
      // annars, lägg till den
      axiosInstance
        .post(
          `${API_ULRS.url}/organization/${organizationStore.organizationId}/pointofsale/${pointOfSaleStore.pointofSaleId}/paymentmethod/add`, // url
          {
            method: "externalcard",
            locked: true,
          }
        )
        .then((res) => {
          setLockCard(true);
          pointOfSaleStore._refetchPointOfSale();
        });
    }
  };

  let orgLogo;
  if (organizationStore.organization) {
    if (organizationStore.organization.logoUrl128x128) {
      orgLogo = (
        <Image
          style={{ width: 64, height: 64 }}
          source={{
            uri: organizationStore.organization.logoUrl128x128.replace(
              "http:",
              "https:"
            ),
          }}
        />
      );
    } else {
      orgLogo = <TpIconTeam3 size={64} />;
    }
  }
  return (
    <FullPageWrapper>
      <Modal isVisible={showPMModal} direction="toTop" closeFn={setShowPMModal}>
        {showPMModal && currentPMId ? (
          <EditPaymentMethodForm
            objectId={pointOfSaleStore.pointofSaleId}
            objectType="pointOfSale"
            doneAction={() => setShowPMModal(false)}
            pm={currentPM}
          />
        ) : (
          <AddPaymentMethodForm
            objectId={pointOfSaleStore.pointofSaleId}
            objectType="pointOfSale"
            doneAction={() => setShowPMModal(false)}
          />
        )}
      </Modal>

      <Header>
        <BackButton to={APP_PATHS.pointofsale.settings.home} />
        <LargeHeaderIcon>{orgLogo}</LargeHeaderIcon>
        <HeaderTitleSubtitle
          subtitle={
            pointOfSaleStore.pointOfSale
              ? pointOfSaleStore.pointOfSale.name
              : " "
          }
          title="Betalsätt"
        />
        {/* <HeaderMenuButton /> */}
      </Header>
      <ContentFixed>
        <View
          style={{ marginTop: 40, paddingHorizontal: 15, paddingBottom: 20 }}
        >
          <Text>
            Här kan du hantera de betalsätt som tillhör den här kassan.
          </Text>
          {pointOfSaleStore.pointOfSale.paymentMethods &&
            pointOfSaleStore.pointOfSale.paymentMethods.filter(
              (pm) => pm.method === "swish"
            ).length < 1 && (
              <View style={{ marginTop: 20 }}>
                <Button
                  title="Lägg till swishnummer"
                  onPress={() => setShowPMModal(true)}
                />
              </View>
            )}
        </View>
      </ContentFixed>
      <ContentScroll>
        {pointOfSaleStore.pointOfSale.paymentMethods &&
        pointOfSaleStore.pointOfSale.paymentMethods.length > 0 ? (
          pointOfSaleStore.pointOfSale.paymentMethods
            .filter((pm) => pm.method === "swish")
            .map((pm) => {
              return (
                <View style={{ marginVertical: 5 }} key={pm.id}>
                  <TouchableOpacity
                    // style={{ paddingVertical: 10 }}
                    onPress={() => {
                      setCurrentPMId(pm.id);
                      setCurrentPM(pm);
                      setShowPMModal(true);
                    }}
                  >
                    <PMItem pm={pm} />
                  </TouchableOpacity>
                </View>
              );
            })
        ) : (
          <View style={{ marginVertical: 5 }}>
            <Text>Inga betalsätt inlagda.</Text>
          </View>
        )}
        <View style={{ marginTop: 20 }}>
          <Text style={{ fontWeight: "bold" }}>Låsa kort</Text>
          <Text>
            Här kan du ställa in så att kort alltid är aktiverat som betalsätt
            vid öppningen av denna kassa.
          </Text>
          <View style={{ marginTop: 10, flexDirection: "row" }}>
            <Switch value={lockCard} onValueChange={(_) => updateCard()} />
            <Text style={{ marginLeft: 20 }}>
              Lås kortanvändning på den här kassan
            </Text>
          </View>
        </View>
        {/* <View style={{ marginTop: 20 }}>
          <Text style={{ fontWeight: "bold" }}>Lås kontanter</Text>
          <Text>Lorem ipsum</Text>
          <View style={{ marginTop: 10, flexDirection: "row" }}>
            <Switch />
            <Text style={{ marginLeft: 20 }}>
              Lås kontantanvändning på den här kassan
            </Text>
          </View>
        </View> */}
      </ContentScroll>
    </FullPageWrapper>
  );
});

export default PointOfSaleSettingsPaymentMethodsScreen;

interface PMItemProps {
  pm: PaymentMethod;
}

const PMItem: React.FC<PMItemProps> = ({ pm }) => {
  return (
    <View
      style={{
        marginHorizontal: 10,
        marginVertical: 5,
        backgroundColor: "#fff",
        paddingHorizontal: 15,
        paddingVertical: 10,
        borderRadius: 8,
        justifyContent: "flex-start",
        alignItems: "center",
        flexDirection: "row",
        ...elevationShadowStyle(10),
        alignSelf: "stretch",
      }}
    >
      <View
        style={{
          width: "100%",
          alignSelf: "stretch",
          flexDirection: "row",
        }}
      >
        <View
          style={{
            width: "100%",
            flexDirection: "row",
            alignSelf: "stretch",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <View style={{ flexDirection: "row" }}>
            <View>
              <Icon name="tp-icon-paymentmethods" size={40} />
            </View>
            <View style={{ paddingVertical: 5, paddingHorizontal: 15 }}>
              <View>
                <Text style={{ fontWeight: "bold" }}>
                  {pm.description || "Ingen angiven"}
                </Text>
                <Text style={{ fontStyle: "italic" }}>
                  {pm.method} {pm.recipient ? pm.recipient : ""}
                </Text>
              </View>
            </View>
          </View>
          {pm.locked && (
            <View
              style={{
                width: 20,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Icon
                size={18}
                color={theme.colors.primary}
                name="tp-icon-lock"
              />
            </View>
          )}
          <View
            style={{
              width: 30,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <TpIconCog size={18} color={theme.colors.primary} />
          </View>
        </View>
      </View>
    </View>
  );
};
