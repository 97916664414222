import { observer } from "mobx-react";
import React, { useContext, useEffect, useState } from "react";
import { Image, Linking, View } from "react-native";
import { Button, Text } from "react-native-elements";
import { RouteComponentProps } from "react-router";
import BackButton from "../components/common/BackButton";
import FullPageActivityIndicator from "../components/common/FullPageActivityIndicator";
import { HeaderTitleSubtitle } from "../components/headerItems/HeaderTitleSubtitle";
import { LargeHeaderIcon } from "../components/headerItems/LargeHeaderIcon";
import { TpIconTeam3 } from "../components/Icon";
import { ContentFixed, ContentScroll } from "../components/layout/Content";
import { FullPageWrapper } from "../components/layout/FullPageWrapper";
import { Header } from "../components/layout/Header";
import Modal from "../components/modal/Modal";
import { API_ULRS, APP_PATHS } from "../config";
import SendReportForm from "../containers/SendReportForm";
import { RootStoreContext } from "../stores/RootStore";

interface Props extends RouteComponentProps {}

const PointOfSaleReportsScreen: React.FC<Props> = observer(
  ({ history, location, match }) => {
    const { pointOfSaleStore, organizationStore } = useContext(
      RootStoreContext
    );
    const [loading, setLoading] = useState(true);
    const [zReports, setZReports] = useState([]);
    const [showSendReportModal, setShowSendReportModal] = useState(false);
    const [reportToSend, setReportToSend] = useState(null);

    useEffect(() => {
      const getZReports = async () => {
        const zReports = await pointOfSaleStore._fetchZReports();
        setZReports(zReports);
        setLoading(false);
      };
      pointOfSaleStore._fetchPointOfSale();
      getZReports();
    }, []);

    if (pointOfSaleStore.pointOfSaleStoreLoading)
      return <FullPageActivityIndicator color="green" />;

    let orgLogo;
    if (organizationStore.organization) {
      if (organizationStore.organization.logoUrl128x128) {
        orgLogo = (
          <Image
            style={{ width: 64, height: 64 }}
            source={{
              uri: organizationStore.organization.logoUrl128x128
                .replace("http:", "https:")
                .replace("http:", "https:")
            }}
          />
        );
      } else {
        orgLogo = <TpIconTeam3 size={64} />;
      }
    }
    console.log("RAPPORTER: ", zReports);
    return (
      <FullPageWrapper>
        <Modal
          direction="toTop"
          isVisible={showSendReportModal}
          closeFn={setShowSendReportModal}
        >
          <SendReportForm
            doneAction={setShowSendReportModal}
            reportToSend={reportToSend}
          />
        </Modal>
        <Header>
          <BackButton to={APP_PATHS.pointofsale.home} />
          <LargeHeaderIcon>{orgLogo}</LargeHeaderIcon>
          <HeaderTitleSubtitle
            // subtitle={user.name ? user.name : " "}
            subtitle={
              pointOfSaleStore.pointOfSale
                ? pointOfSaleStore.pointOfSale.name
                : " "
            }
            title="Rapporter"
          />
        </Header>
        <ContentFixed>
          <View style={{ marginTop: 40, paddingHorizontal: 15 }}>
            <Text>Här hittar du kassans dagsrapporter.</Text>
          </View>
        </ContentFixed>
        <ContentScroll>
          {loading && <FullPageActivityIndicator />}
          {!loading && !zReports && <Text>inga rapporter</Text>}
          {!loading && zReports.length > 0 && (
            <ZReportList
              history={history}
              location={location}
              match={match}
              zReports={zReports}
              setShowSendReportModal={setShowSendReportModal}
              showSendReportModal={showSendReportModal}
              setReportToSend={setReportToSend}
            />
          )}
        </ContentScroll>
      </FullPageWrapper>
    );
  }
);

export default PointOfSaleReportsScreen;

interface ZReportListProps extends RouteComponentProps {
  zReports: any[];
  setReportToSend?: (s: string) => void;
  setShowSendReportModal?: (b: boolean) => void;
  showSendReportModal?: boolean;
}
const ZReportList: React.FC<ZReportListProps> = ({
  zReports,
  setShowSendReportModal,
  setReportToSend,
  history
}) => {
  const { reportStore } = useContext(RootStoreContext);
  // console.log(zReports);
  return (
    <View>
      {zReports.map(report => (
        <View key={report.id} style={{ marginBottom: 30 }}>
          <Text h4>{new Date(report.date).toLocaleDateString("sv-SE")}</Text>
          <Text style={{ marginVertical: 10 }}>
            Total försäljning: {report.totalAmount} kr
          </Text>
          <View
            style={{
              alignSelf: "stretch",
              flexDirection: "row",
              justifyContent: "space-between"
            }}
          >
            <Button
              onPress={() => {
                reportStore.report = report;
                history.push(APP_PATHS.pointofsale.report);
              }}
              title="Statistik"
            />
            <Button
              type="outline"
              onPress={() =>
                Linking.openURL(`${API_ULRS.link}/Report/ZReport/${report.id}`)
              }
              title="Rapporten"
            />
            <Button
              type="outline"
              onPress={() => {
                setReportToSend(report.id);
                setShowSendReportModal(true);
              }}
              title="Skicka"
            />
          </View>
        </View>
      ))}
    </View>
  );
};
