import { observable, reaction } from "mobx";
import { create } from "mobx-persist";
import { createContext } from "react";
import { AsyncStorage } from "react-native";
import { CounterStore } from "./CoutnerStore";
import { OrganizationStore } from "./OrganizationStore";
import { PointOfSaleStore } from "./PointOfSaleStore";
import { RegisterStore } from "./RegisterStore";
import { ReportStore } from "./ReportStore";
import { RouterStore } from "./RouterStore";
import { UserStore } from "./UserStore";

const hydrate = create({
  storage: AsyncStorage,
  jsonify: true
});

export class RootStore {
  routerStore = new RouterStore(this);
  organizationStore = new OrganizationStore(this);
  userStore = new UserStore(this);
  counterStore = new CounterStore(this);
  pointOfSaleStore = new PointOfSaleStore(this);
  registerStore = new RegisterStore(this);
  reportStore = new ReportStore(this);

  @observable hydrated = false;

  constructor() {
    var promise1 = hydrate("tpRouter", this.routerStore);
    var promise2 = hydrate("tpCounter", this.counterStore);
    var promise3 = hydrate("tpUser", this.userStore);
    var promise4 = hydrate("tpOrganization", this.organizationStore);
    var promise5 = hydrate("tpPointOfSale", this.pointOfSaleStore);
    var promise6 = hydrate("tpRegister", this.registerStore);
    var promise7 = hydrate("tpReport", this.reportStore);

    Promise.all([
      promise1,
      promise2,
      promise3,
      promise4,
      promise5,
      promise6,
      promise7
    ]).then(() => {
      this.hydrated = true;
    });
  }

  updateUser = reaction(
    () => this.userStore.userId,
    id => {
      if (this.userStore.authToken && this.userStore.userId) {
        // console.log("STORE UPDATED THE ORGID: ", id);
        this.userStore._fetchUser();
      }
    }
  );

  updateOrg = reaction(
    () => this.organizationStore.organizationId,
    id => {
      if (this.userStore.authToken && this.organizationStore.organizationId) {
        // console.log("STORE UPDATED THE ORGID: ", id);
        this.organizationStore._fetchOrganization();
      }
    }
  );

  updatePos = reaction(
    () => this.pointOfSaleStore.pointofSaleId,
    id => {
      if (this.userStore.authToken && this.pointOfSaleStore.pointofSaleId) {
        // console.log("STORE UPDATED THE POSID: ", id);
        this.pointOfSaleStore._fetchPointOfSale();
      }
    }
  );
}

export const RootStoreContext = createContext(new RootStore());
