import React from "react";
import { StyleSheet, Text } from "react-native";

interface Props {
  text?: string;
  color?: string;
}

export const DisplayHeading: React.FC<Props> = ({
  text = "Rubrik!",
  color
}) => {
  return <Text style={[styles.display, { color }]}>{text}</Text>;
};
export const Heading1: React.FC<Props> = ({ text = "Rubrik!", color }) => {
  return <Text style={[styles.h1, { color }]}>{text}</Text>;
};

export const Heading2: React.FC<Props> = ({ text = "Rubrik!", color }) => {
  return <Text style={[styles.h2, { color }]}>{text}</Text>;
};

export const Heading3: React.FC<Props> = ({ text = "Rubrik!", color }) => {
  return <Text style={[styles.h3, { color }]}>{text}</Text>;
};

export const Heading4: React.FC<Props> = ({ text = "Rubrik!", color }) => {
  return <Text style={[styles.h4, { color }]}>{text}</Text>;
};

const styles = StyleSheet.create({
  display: {
    fontSize: 30,
    color: "#fff"
  },
  h1: {
    fontSize: 24,
    fontWeight: "bold",
    color: "#000000",
    opacity: 0.6
  },
  h2: {
    fontSize: 20,
    fontWeight: "bold",
    color: "#000000",
    opacity: 0.6
  },
  h3: {
    fontSize: 18,
    fontWeight: "bold",
    color: "#000000",
    opacity: 0.6
  },
  h4: {
    fontSize: 16,
    fontWeight: "bold",
    color: "#000000",
    opacity: 0.6
  }
});
