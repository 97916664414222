import { observer } from "mobx-react";
import React, { useContext, useEffect, useState } from "react";
import { View } from "react-native";
import { Button, Text } from "react-native-elements";
import Icon from "../../components/Icon/Icon";
import { ContentFixed, ContentScroll } from "../../components/layout/Content";
import RegisterHeader from "../../components/register/RegisterHeader";
import { sortByPaymentMethod } from "../../lib/utils/sort";
import { RegisterSteps } from "../../stores/RegisterStore";
import { RootStoreContext } from "../../stores/RootStore";

const BasketContainer = observer(() => {
  const { registerStore, pointOfSaleStore } = useContext(RootStoreContext);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const initAsync = async () => {
      registerStore._getTransactionId().then(() => setLoading(false));
    };
    initAsync();
  }, []);
  return (
    <View style={{ flex: 1, justifyContent: "space-between" }}>
      <RegisterHeader />
      <ContentFixed>
        <View style={{ paddingHorizontal: 15, paddingTop: 15 }}>
          <View
            style={{ flexDirection: "row", justifyContent: "space-between" }}
          >
            <Button
              type="outline"
              title={`Avbryt ${registerStore.isReturn ? "återköp" : " köp"}`}
              onPress={() => registerStore._emptyCart()}
            />
            <Button
              type="outline"
              title="Lägg till varor"
              onPress={() =>
                (registerStore.currentStep = RegisterSteps.Shopping)
              }
            />
          </View>
          <View style={{ marginVertical: 20 }}>
            <Text>
              Kolla så att varukorgen stämmer och välj sedan betalsätt.
            </Text>
          </View>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              borderBottomColor: "#efefef",
              borderBottomWidth: 1
            }}
          >
            <Text h4>Totalt</Text>
            <Text h4>{registerStore.totalSum} kr</Text>
          </View>
        </View>
      </ContentFixed>
      <ContentScroll>
        <View>
          {registerStore.cartItems.map(node => {
            return (
              <View
                key={`${node.id}-${node.priceInclVat}`}
                style={{
                  alignSelf: "stretch",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginVertical: 5
                }}
              >
                <View style={{ flexDirection: "row", alignItems: "center" }}>
                  <Icon name={node.icon} size={24} />
                  <Text style={{ marginLeft: 15 }}>
                    {node.description} ({node.priceInclVat} kr)
                  </Text>
                </View>
                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "center"
                  }}
                >
                  <Button
                    // containerStyle={{ borderWidth: 1, borderColor: "black" }}
                    titleStyle={{ fontWeight: "bold", fontSize: 16 }}
                    type="clear"
                    title="+"
                    onPress={() => registerStore._addToCart(node)}
                  />
                  <Text style={{ marginHorizontal: 10 }}>{node.quantity}</Text>
                  <Button
                    titleStyle={{ fontWeight: "bold", fontSize: 16 }}
                    type="clear"
                    title="-"
                    onPress={() => registerStore._removeFromCart(node)}
                  />
                </View>
              </View>
            );
          })}
        </View>
      </ContentScroll>
      <ContentFixed>
        <View
          style={{
            padding: 15,
            alignItems: "center",
            alignSelf: "stretch"
          }}
        >
          <Text style={{ marginBottom: 10 }}>Välj betalsätt</Text>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-evenly",
              alignSelf: "stretch"
            }}
          >
            {pointOfSaleStore.pointOfSale.session.paymentMethods
              .sort(sortByPaymentMethod)
              .map(node => {
                if (
                  registerStore.isReturn &&
                  node.paymentMethodKey !== "cash"
                ) {
                  return null;
                }
                let label;
                switch (node.paymentMethodKey) {
                  case "swish":
                    label = "Swish";
                    break;

                  case "externalcard":
                    label = "Kort";
                    break;
                  case "cash":
                    label = "Kontant";
                    break;
                  default:
                    label = "XX";
                }

                return (
                  <Button
                    disabled={loading}
                    key={node.id}
                    title={label}
                    onPress={() => {
                      registerStore.paymentMethod = node;
                      registerStore.currentStep = registerStore.isReturn
                        ? RegisterSteps.Return
                        : RegisterSteps.Payment;
                    }}
                  />
                );
              })}
          </View>
        </View>
      </ContentFixed>
    </View>
  );
});

export default BasketContainer;
