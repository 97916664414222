import { Formik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { View } from "react-native";
import { Button, Input, Text } from "react-native-elements";
import FullPageActivityIndicator from "../components/common/FullPageActivityIndicator";
import ModalDoneCheck from "../components/modal/ModalDoneCheck";
import { theme } from "../lib/styles/theme";
import { User } from "../lib/types/users/user";
import { RootStoreContext } from "../stores/RootStore";

interface UserProfileFormProps {
  doneAction?: (b: boolean) => void;
  userId: string;
  forcedUpdate?: boolean;
  removeAdmin?: boolean;
  removeAdminFrom?: string;
}

const UserForm: React.FC<UserProfileFormProps> = ({
  doneAction,
  userId,
  forcedUpdate = false,
  removeAdmin = false,
  removeAdminFrom,
}) => {
  const [loading, setLoading] = useState(true);
  const [done, setDone] = useState(false);
  const { userStore } = useContext(RootStoreContext);
  const [user, setUser] = useState<User | null>();
  const { organizationStore } = useContext(RootStoreContext);
  // const { user } = userStore;

  useEffect(() => {
    const getUser = async () => {
      setUser(await userStore.getUser(userId));
      setLoading(false);
    };
    getUser();
  }, []);

  if (loading) {
    return <FullPageActivityIndicator />;
  }
  if (done) {
    return <ModalDoneCheck doneAction={doneAction} />;
  }

  return (
    <Formik
      initialValues={{
        id: user.id,
        firstName: user.firstName || "",
        lastName: user.lastName || "",
        emailAddress: user.emailAddress || "",
        mobileNumber: user.mobileNumber || "",
      }}
      onSubmit={(data, { setSubmitting }) => {
        if (userStore.saveUser(data)) {
          setSubmitting(false);
          setDone(true);
        }
      }}
    >
      {({
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue,
      }) => (
        <View>
          <Text h3>Redigera profil</Text>
          <View style={{ paddingVertical: 15 }}>
            <Input
              label="Förnamn"
              value={values.firstName}
              onChangeText={handleChange("firstName")}
              onBlur={handleBlur("firstName")}
            />
          </View>
          <View>
            <Input
              label="Efternamn"
              value={values.lastName}
              onChangeText={handleChange("lastName")}
              onBlur={handleBlur("lastName")}
            />
          </View>
          <View style={{ paddingVertical: 15 }}>
            <Input
              label="Mobilnummer"
              value={values.mobileNumber}
              onChangeText={handleChange("mobileNumber")}
              onBlur={handleBlur("mobileNumber")}
            />
          </View>
          <View style={{ paddingBottom: 15 }}>
            <Input
              label="Epostadress"
              value={values.emailAddress}
              onChangeText={handleChange("emailAddress")}
              onBlur={handleBlur("emailAddress")}
            />
          </View>
          <Button
            title={isSubmitting ? "Sparar.." : "Spara"}
            onPress={() => handleSubmit()}
            disabled={isSubmitting}
          />

          {removeAdmin && (
            <Button
              title="Ta bort adminrättigheter"
              containerStyle={{ marginTop: 15 }}
              onPress={() => {
                // removeAdminFrom
                organizationStore._removeUserFromGroup(
                  removeAdminFrom,
                  user.id
                );
                setDone(true);
                console.log(`Removing ${user.id} from ${removeAdminFrom}`);
              }}
            />
          )}

          <Button
            type="outline"
            buttonStyle={{
              backgroundColor: theme.colors.danger,
              borderColor: theme.colors.danger,
            }}
            titleStyle={{ color: "#ffffff" }}
            containerStyle={{ marginTop: 15 }}
            title="TA BORT FRÅN ORGANISATIONEN"
            onPress={() => {
              // TODO: platform
              userStore.removeUserFromOrganization(user.id);
              setDone(true);
            }}
            disabled={isSubmitting}
          />
        </View>
      )}
    </Formik>
  );
};

export default UserForm;
