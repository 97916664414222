import { Theme } from "styled-components/native";

// interface ITpTheme extends Theme {
//   paddings: {
//     tiny?: string;
//     small?: string;
//     default?: string;
//     large?: string;
//   };
//   colors: {
//     brand?: string;
//     primary?: string;
//     secondary?: string;
//     danger?: string;
//     info?: string;
//     grey?: string;
//     greyLight?: string;
//     greyDark?: string;
//   };
// }

export const theme: Theme = {
  paddings: {
    tiny: "8px",
    small: "16px",
    default: "32px",
    large: "48px"
  },
  colors: {
    brand: "#00A13A",
    primary: "#00A13A",
    secondary: "#5fc4e1",
    danger: "#e5005b",
    black: "rgba(0,0,0,0.95)",
    text: "rgba(0,0,0,0.95)"
  }
};
