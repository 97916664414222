import { observer } from "mobx-react";
import React, { useContext, useState } from "react";
import {
  Dimensions,
  Picker,
  StyleSheet,
  Switch,
  TouchableOpacity,
  View,
} from "react-native";
import { Button, Input, Text } from "react-native-elements";
import { Redirect, RouteComponentProps } from "react-router";
import { DisplayHeading, Heading2 } from "../components/common/Headings";
import { TitlePage } from "../components/layout/TitlePage";
import { OnboardingOpenpointofsale } from "../components/OnBoardingIcon";
import { DefaultWizard } from "../components/wizards/DefaultWizard";
import { APP_PATHS } from "../config";
import { theme } from "../lib/styles/theme";
import { RootStoreContext } from "../stores/RootStore";

interface Props extends RouteComponentProps {}
const OpenPointOfSaleScreen: React.FC<Props> = observer(({ history }) => {
  const smallScreen = Dimensions.get("window").width < 375;

  const [wizardActive, setWizardActive] = useState(false);
  const [wizardDone, setWizardDone] = useState({ done: false, redirect: null });

  const { pointOfSaleStore, organizationStore } = useContext(RootStoreContext);
  const { pointOfSale } = pointOfSaleStore;

  // console.log(pointOfSale.paymentMethods);
  const _onWizardComplete = (str) => {
    history.push(APP_PATHS.register.register);
    // console.log("I GOT HERE I GOT HERE!");
    // setWizardDone({
    //   done: true,
    //   redirect: APP_PATHS.register.register
    // });
  };

  if (wizardDone && wizardDone.done && wizardDone.redirect) {
    return <Redirect to={wizardDone.redirect} />;
  }
  // console.log(
  //   "FILTERTEST",
  //   !!pointOfSale.paymentMethods.filter(
  //     (pm) => pm.method === "swish" && pm.locked
  //   ).length
  // );
  // console.log(
  //   "FILTERTEST-KORT",
  //   !!pointOfSale.paymentMethods.filter(
  //     (pm) => pm.method === "externalcard" && pm.locked
  //   ).length
  // );

  if (wizardActive) {
    return (
      <DefaultWizard
        initialValues={{
          pmCash: true,
          pmCashIn: "",
          pmSwishLocked:
            pointOfSale.paymentMethods.length > 0 &&
            !!pointOfSale.paymentMethods.filter(
              (pm) => pm.method === "swish" && pm.locked
            ).length
              ? true
              : false,
          pmSwish: !!pointOfSale.paymentMethods.filter(
            (pm) => pm.method === "swish"
          ).length
            ? true
            : false,
          pmSwishRecipient:
            pointOfSale.paymentMethods.length > 0
              ? pointOfSale.paymentMethods.filter(
                  (pm) => pm.method === "swish"
                )[0].recipient
              : "",
          pmExternalLocked:
            pointOfSale.paymentMethods.length > 0 &&
            pointOfSale.paymentMethods.filter(
              (pm) => pm.method === "externalcard" && pm.locked
            ).length
              ? true
              : false,
          pmExternal:
            pointOfSale.paymentMethods.length > 0 &&
            pointOfSale.paymentMethods.filter(
              (pm) => pm.method === "externalcard"
            ).length
              ? true
              : false,
          allowComment: false,
          groupsResponsible: pointOfSale.groupId
            ? [{ id: pointOfSale.groupId }]
            : [],
          addingGroup: false,
          groupToAdd: "",
        }}
        errComponent={
          <>
            <Heading2 text="Oooops!" />
            <Text style={{ paddingVertical: 15 }}>
              Det verkar som att inte alla fält är ifyllda ordentligt för att vi
              ska kunna avsluta guiden.
            </Text>
            <Text>
              I det här fallet saknades betalsätt helt. Gå tillbaka och aktivera
              minst ett betalsätt för att öppna kassan.
            </Text>
          </>
        }
        cancel={() => history.push(APP_PATHS.pointofsale.home)}
        onSubmit={async (values) => {
          console.log("VAAAAALUEEESSSS: ", values);
          if (
            !!(!!values.pmCash && values.pmCashIn !== "") ||
            !!(!!values.pmSwish && !!values.pmSwishRecipient) ||
            !!values.pmExternal
          ) {
            console.log("Finns betalsätt, öppna!");
            const res = await pointOfSaleStore._openPointOfSale(values);
            return res;
          } else {
            console.log("INGA BETALSÄTT - Alerta!");
            return false;
          }
        }}
        // onSubmit={console.log(this.values)}
        afterSubmit={_onWizardComplete}
      >
        {/* CASH */}
        <DefaultWizard.Step>
          {({ onChangeValue, values, onSubmit, afterSubmit }) => (
            <>
              <Heading2 text="1/3. Kontanthantering" />
              <Text style={{ paddingVertical: 15 }}>
                Här aktiveras kontanter som betalsätt. Räkna och fyll i beloppet
                på växelkassan om en sådan finns.
              </Text>
              <Text style={{ fontStyle: "italic", marginBottom: 15 }}>
                Vid eventuell retur kan inte återbetalning ske via swish eller
                kort, endast med kontanter.
              </Text>
              <Text style={{ fontWeight: "bold", marginBottom: 15 }}>
                Vill du använda kontanter?
              </Text>

              <View style={{ flexDirection: "row", marginBottom: 15 }}>
                <Switch
                  value={values.pmCash}
                  onValueChange={(value) => onChangeValue("pmCash", value)}
                />
                <Text style={{ marginLeft: 20 }}>
                  Ja, jag vill använda kontanter
                </Text>
              </View>
              {values.pmCash && (
                <>
                  <Text style={{ marginTop: 30, fontWeight: "bold" }}>
                    Ingående växelkassa
                  </Text>
                  <Input
                    value={values.pmCashIn}
                    onChangeText={(text) => {
                      onChangeValue("pmCashIn", text);
                    }}
                  />
                  {!values.pmCashIn && (
                    <Text style={{ marginTop: 15, fontStyle: "italic" }}>
                      En växelkassa måste fyllas i, skriv 0 om det inte finns
                      någon.
                    </Text>
                  )}
                </>
              )}
            </>
          )}
        </DefaultWizard.Step>

        {/* Swish */}

        <DefaultWizard.Step finishLabel="Öppna kassan">
          {({ onChangeValue, values, onSubmit, afterSubmit }) => (
            <>
              <Heading2 text="2/3. Övriga betalsätt" />
              <Text style={{ paddingVertical: 15 }}>
                Ett swishnummer kan förinställas för att alltid gälla för denna
                kassa. Om så inte skett fylls siffrorna i manuellt i angivet
                fält.
              </Text>
              <Text style={{ paddingBottom: 15 }}>
                Här aktiveras ytterligare betalsätt aktuella för det här
                tillfället.
              </Text>
              {/* SWISH */}
              <Text style={{ fontWeight: "bold", marginBottom: 15 }}>
                Vill du använda swish?
              </Text>
              {pointOfSale.paymentMethods.filter((pm) => pm.method === "swish")
                .length > 0 ? (
                <>
                  {values.pmSwishLocked ? (
                    <>
                      <Text style={{ fontStyle: "italic", paddingBottom: 10 }}>
                        En swish är redan upplagd och låst för den här kassan.
                      </Text>
                      <Text style={{ paddingBottom: 15, fontStyle: "italic" }}>
                        Swishnummer:{" "}
                        <Text style={{ fontWeight: "bold" }}>
                          {
                            pointOfSale.paymentMethods.filter(
                              (pm) => pm.method === "swish"
                            )[0].recipient
                          }
                        </Text>
                      </Text>
                    </>
                  ) : (
                    <>
                      <Text style={{ fontStyle: "italic", paddingBottom: 10 }}>
                        En swish är redan upplagd för den här kassan.
                      </Text>
                      <View style={{ flexDirection: "row", marginBottom: 15 }}>
                        <Switch
                          value={values.pmSwish}
                          onValueChange={(value) =>
                            onChangeValue("pmSwish", value)
                          }
                        />
                        <Text style={{ marginLeft: 20 }}>
                          Ja, jag vill använda swish
                        </Text>
                      </View>
                      {values.pmSwish && (
                        <View style={{ marginBottom: 15 }}>
                          <Text style={{ fontWeight: "bold" }}>
                            Swishnummer{" "}
                            <Text style={{ fontWeight: "normal" }}>
                              som ska ta emot betalningar
                            </Text>
                          </Text>
                          <Input
                            value={values.pmSwishRecipient}
                            onChangeText={(text) => {
                              onChangeValue("pmSwishRecipient", text);
                            }}
                          />
                        </View>
                      )}
                    </>
                  )}
                </>
              ) : (
                <>
                  <View style={{ flexDirection: "row", marginBottom: 15 }}>
                    <Switch
                      value={values.pmSwish}
                      onValueChange={(value) => onChangeValue("pmSwish", value)}
                    />
                    <Text style={{ marginLeft: 20 }}>
                      Ja, jag vill använda swish
                    </Text>
                  </View>
                  {values.pmSwish && (
                    <View style={{ marginBottom: 15 }}>
                      <Text style={{ fontWeight: "bold" }}>
                        Swishnummer{" "}
                        <Text style={{ fontWeight: "normal" }}>
                          som ska ta emot betalningar
                        </Text>
                      </Text>
                      <Input
                        value={values.pmSwishRecipient}
                        onChangeText={(text) => {
                          onChangeValue("pmSwishRecipient", text);
                        }}
                      />
                      <View>
                        <Text style={{ fontWeight: "bold", marginTop: 15 }}>
                          Du kan även välja ett av föreningens sparade
                          swishnummer
                        </Text>
                        <Picker
                          selectedValue={values.pm_recipient}
                          onValueChange={(value, idx) => {
                            onChangeValue("pmSwishRecipient", value);
                          }}
                        >
                          <Picker.Item
                            key="none"
                            value=""
                            label="Välj swishnummer"
                          />
                          {organizationStore.organization.paymentMethods
                            .length > 0 &&
                            organizationStore.organization.paymentMethods.map(
                              (pm) => (
                                <Picker.Item
                                  key={pm.id}
                                  value={pm.recipient}
                                  label={pm.description}
                                />
                              )
                            )}
                        </Picker>
                      </View>
                    </View>
                  )}
                </>
              )}
              {/* EXTERN KORTLÄSARE */}
              {values.pmExternalLocked ? (
                <>
                  <Text style={{ fontWeight: "bold", marginVertical: 15 }}>
                    Kortanvändning är aktiverad och låst på den här kassan.
                  </Text>
                </>
              ) : (
                <>
                  <Text style={{ fontWeight: "bold", marginVertical: 15 }}>
                    Vill du registrera köp med extern kortläsare?
                  </Text>
                  <View style={{ flexDirection: "row", marginBottom: 15 }}>
                    <Switch
                      value={values.pmExternal}
                      onValueChange={(value) =>
                        onChangeValue("pmExternal", value)
                      }
                    />
                    <Text style={{ marginLeft: 20 }}>
                      Ja, jag vill använda extern kortläsare
                    </Text>
                  </View>
                </>
              )}
            </>
          )}
        </DefaultWizard.Step>
        <DefaultWizard.Step>
          {({ onChangeValue, values, onSubmit, afterSubmit }) => (
            <>
              <Heading2 text="3/3. Ansvariga" />
              <Text style={{ paddingVertical: 15 }}>
                Om du vill kan den här försäljningen markeras med en eller flera
                ansvariga grupper, vilket kan göra det enklare att t.ex fördela
                förtjänst. Ansvariga visas i rapporten för dagsavslutet.
              </Text>
              {values.groupsResponsible.length > 0 && (
                <>
                  <Text style={{ fontWeight: "bold" }}>Ansvariga:</Text>
                  {values.groupsResponsible.map((gId, idx) => {
                    const group = organizationStore._returnGroupNameFromId(gId);
                    console.log(group);
                    return (
                      <View
                        key={idx}
                        style={{
                          marginVertical: 5,
                          alignSelf: "stretch",
                          justifyContent: "space-between",
                          alignContent: "center",
                          flexDirection: "row",
                        }}
                      >
                        <Text>{group.name}</Text>
                        <Button
                          title={`ta bort`}
                          onPress={() => {
                            let tmpArray = values.groupsResponsible.slice();
                            tmpArray.splice(idx, 1);
                            onChangeValue("groupsResponsible", tmpArray);
                          }}
                        />
                      </View>
                    );
                  })}
                </>
              )}
              <View style={{ marginVertical: 15 }}></View>
              <View style={{ marginVertical: 15 }}>
                <Text style={{ fontWeight: "bold", marginBottom: 10 }}>
                  Ange ansvarig grupp
                </Text>

                {!values.addingGroup && (
                  <Picker
                    selectedValue={values.groupId}
                    // style={{ height: 50, width: 100 }}
                    onValueChange={(itemValue, itemIndex) => {
                      if (itemValue === "create") {
                        onChangeValue("addingGroup", true);
                      }
                      if (
                        itemValue !== "" &&
                        itemValue !== "create" &&
                        values.groupsResponsible.filter(
                          (group) => group.id === itemValue
                        ).length < 1
                      ) {
                        console.log("SELECTED: ", itemValue);
                        let tmpArray = values.groupsResponsible.slice();
                        tmpArray.push({ id: itemValue });
                        console.log("groupsResponsible: ", tmpArray);
                        onChangeValue("groupsResponsible", tmpArray);
                      }
                    }}
                  >
                    <Picker.Item
                      label="Välj grupp"
                      value=""
                      key="select-group-picker-item"
                    />
                    {organizationStore.organization.groups.map((node) => (
                      <Picker.Item
                        label={node.name}
                        value={node.id}
                        key={node.id}
                      />
                    ))}
                    {/* <Picker.Item
                      label=""
                      value=""
                      key="empty-group-picker-item"
                    />
                    <Picker.Item
                      label="Skapa en ny grupp"
                      value="create"
                      key="create-group-picker-item"
                    /> */}
                  </Picker>
                )}
                {values.addingGroup && (
                  <View style={{ flexDirection: "row" }}>
                    {/* <Input  /> */}
                    <Button
                      title="Spara"
                      onPress={() => {
                        console.log("Add group");
                      }}
                    />
                  </View>
                )}
              </View>
            </>
          )}
        </DefaultWizard.Step>
      </DefaultWizard>
    );
  }

  return (
    <>
      <TitlePage reverse padding={smallScreen ? 15 : 45}>
        <OnboardingOpenpointofsale
          width={smallScreen ? 128 : 192}
          height={smallScreen ? 128 : 192}
        />

        <DisplayHeading color="#ffffff" text="Öppna kassan" />
        <View style={{ alignSelf: "stretch", maxWidth: 400 }}>
          <Text style={{ color: "#fff", textAlign: "center" }}>
            Börja med att aktivera de betalsätt som ska användas; kontanter,
            swish och/eller kort. Vid betalning med kort måste en kortläsare
            finnas till hands. Bjud in fler som kan ta betalt för snabba köer
            med bättre service och högre förtjänster.
          </Text>
        </View>
        <Button
          type="outline"
          containerStyle={{ backgroundColor: "#fff" }}
          title="Sätt igång!"
          onPress={() => setWizardActive(true)}
        />
      </TitlePage>
      <TouchableOpacity
        style={styles.close}
        onPress={() => history.push(APP_PATHS.pointofsale.home)}
      >
        <Text style={{ color: "#ffffff", fontWeight: "bold" }}>X</Text>
      </TouchableOpacity>
    </>
  );
});
export default OpenPointOfSaleScreen;

const styles = StyleSheet.create({
  close: {
    position: "absolute",
    top: 20,
    left: 20,
    width: 40,
    height: 40,
    borderRadius: 20,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: theme.colors.danger,
  },
});
