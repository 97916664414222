import { observer } from "mobx-react";
import React, { useContext, useEffect, useState } from "react";
import { Image, TouchableOpacity, View } from "react-native";
import { Text } from "react-native-elements";
import { RouteComponentProps } from "react-router";
import BackButton from "../components/common/BackButton";
import FullPageActivityIndicator from "../components/common/FullPageActivityIndicator";
import { QuickButton } from "../components/common/QuickButton";
import { withAuthorization } from "../components/containers/withAuthorization";
import { HeaderTitleSubtitle } from "../components/headerItems/HeaderTitleSubtitle";
import { LargeHeaderIcon } from "../components/headerItems/LargeHeaderIcon";
import { TpIconTeam3 } from "../components/Icon";
import { ContentFixed, ContentScroll } from "../components/layout/Content";
import { FullPageWrapper } from "../components/layout/FullPageWrapper";
import { Header } from "../components/layout/Header";
import Modal from "../components/modal/Modal";
import PointOfSaleCategoryListItem from "../components/pointofsale/PointOfSaleCategoryListItem";
import { APP_PATHS } from "../config";
import AddCategoryForm from "../containers/AddCategoryForm";
import EditCategoryForm from "../containers/EditCategoryForm";
import { sortByName } from "../lib/utils/sort";
import { RootStoreContext } from "../stores/RootStore";

interface Props extends RouteComponentProps {}

const PointOfSalesCategoriesScreen: React.FC<Props> = observer(
  ({ history }) => {
    const [showEditItem, setShowEditItem] = useState(false);
    const [showEditItemId, setShowEditItemId] = useState();

    const [showAddItem, setShowAddItem] = useState(false);
    const { pointOfSaleStore, organizationStore } = useContext(
      RootStoreContext
    );

    useEffect(() => {
      pointOfSaleStore._fetchPointOfSale();
    }, []);

    if (pointOfSaleStore.pointOfSaleStoreLoading)
      return <FullPageActivityIndicator color="green" />;

    const { pointOfSale } = pointOfSaleStore;

    if (pointOfSaleStore.pointOfSaleStoreLoading)
      return <FullPageActivityIndicator />;

    let orgLogo;
    if (organizationStore.organization) {
      if (organizationStore.organization.logoUrl128x128) {
        orgLogo = (
          <Image
            style={{ width: 64, height: 64 }}
            source={{
              uri: organizationStore.organization.logoUrl128x128.replace(
                "http:",
                "https:"
              )
            }}
          />
        );
      } else {
        orgLogo = <TpIconTeam3 size={64} />;
      }
    }
    // console.log(pointOfSale.categories);
    return (
      <FullPageWrapper>
        <Modal
          isVisible={showEditItem}
          closeFn={setShowEditItem}
          direction="toTop"
        >
          {showEditItem && (
            <EditCategoryForm
              doneAction={setShowEditItem}
              categoryId={showEditItemId}
            />
          )}
        </Modal>
        <Modal
          isVisible={showAddItem}
          closeFn={setShowAddItem}
          direction="toTop"
        >
          {showAddItem && <AddCategoryForm doneAction={setShowAddItem} />}
        </Modal>

        <Header>
          <BackButton to={APP_PATHS.pointofsale.items} />
          <LargeHeaderIcon>{orgLogo}</LargeHeaderIcon>
          <HeaderTitleSubtitle
            // subtitle={user.name ? user.name : " "}
            subtitle={
              pointOfSaleStore.pointOfSale
                ? pointOfSaleStore.pointOfSale.name
                : " "
            }
            title="Kategorier"
          />
          {/* <HeaderMenuButton /> */}
        </Header>

        <ContentFixed>
          <View
            style={{
              alignSelf: "flex-end",
              alignItems: "flex-end",
              paddingVertical: 10,
              marginTop: 10
            }}
          >
            {withAuthorization(
              <QuickButton
                onPress={() => setShowAddItem(true)}
                title="Lägg till ny kategori"
              />,
              pointOfSaleStore.pointOfSale.priviliges,
              "PointOfSale:CreateItem",
              <></>
            )}
          </View>
        </ContentFixed>
        <ContentFixed>
          <View style={{ padding: 15 }}>
            <Text>
              Kategorier kan användas för att gruppera flera produkter under en
              knapp i kassan. T.ex att samla läsk, kaffe och te under kategorin
              'Dryck'.
            </Text>
          </View>
        </ContentFixed>
        <ContentScroll>
          {pointOfSale &&
            pointOfSale.categories &&
            pointOfSale.categories.length > 0 &&
            pointOfSale.categories
              .slice()
              .sort(sortByName)
              .map(node => {
                return (
                  <TouchableOpacity
                    onPress={() => {
                      setShowEditItemId(node.id);
                      setShowEditItem(true);
                    }}
                  >
                    <PointOfSaleCategoryListItem item={node} />
                  </TouchableOpacity>
                );
                // return (
                //   <View
                //     key={node.id}
                //     style={{
                //       flexDirection: "row",
                //       justifyContent: "space-between"
                //     }}
                //   >
                //     <Button
                //       title={`${node.name}`}
                //       onPress={() => {
                //         setShowEditItemId(node.id);
                //         setShowEditItem(true);
                //       }}
                //     />
                //     <Button
                //       title="X"
                //       onPress={() => {
                //         // console.log("Delete clicked!");
                //         if (Platform.OS === "web") {
                //           if (
                //             confirm(`Vill du verkligen ta bort ${node.name}?`)
                //           ) {
                //             pointOfSaleStore._deleteItem(node.id);
                //           } else {
                //             // console.log("Cancel Pressed");
                //           }
                //         } else {
                //           Alert.alert(
                //             "Är du säker?",
                //             `Vill du verkligen ta bort ${node.name}?`,
                //             [
                //               {
                //                 text: "Avbryt",
                //                 onPress: () => console.log("Cancel Pressed"),
                //                 style: "cancel"
                //               },
                //               {
                //                 text: "Ta bort",
                //                 onPress: () =>
                //                   pointOfSaleStore._deleteItem(node.id)
                //               }
                //             ],
                //             { cancelable: false }
                //           );
                //         }
                //       }}
                //     />
                //   </View>
                // );
              })}
        </ContentScroll>
      </FullPageWrapper>
    );
  }
);

export default PointOfSalesCategoriesScreen;
