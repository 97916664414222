import { LinearGradient } from "expo-linear-gradient";
import { Formik } from "formik";
import { observer } from "mobx-react";
import React, { useContext, useEffect, useState } from "react";
import {
  Dimensions,
  ScrollView,
  StyleSheet,
  Switch,
  TouchableOpacity,
  View,
} from "react-native";
import { Button, Input, Text } from "react-native-elements";
import { RouteComponentProps, withRouter } from "react-router";
import Icon from "../../components/Icon/Icon";
import { ContentScroll } from "../../components/layout/Content";
import Modal from "../../components/modal/Modal";
import { Paragraph } from "../../components/primitives/Paragraph";
import RegisterCategoryButton from "../../components/register/RegisterCategoryButton";
import RegisterHeader from "../../components/register/RegisterHeader";
import RegisterItemButton from "../../components/register/RegisterItemButton";
import TotalSumDisplay from "../../components/register/TotalSumDisplay";
import { APP_PATHS } from "../../config";
import { theme } from "../../lib/styles/theme";
import { elevationShadowStyle } from "../../lib/utils/elevationShadowStyle";
import { sortByDescription } from "../../lib/utils/sort";
import { RegisterSteps } from "../../stores/RegisterStore";
import { RootStoreContext } from "../../stores/RootStore";
import CashInForm from "../CashInForm";
import CashOutForm from "../CashOutForm";
import PaymentMethodModal from "../PaymentMethodModal";

const screenWidth = Dimensions.get("window").width;
let itemCols = 3;
let itemIconSize = 24;
if (screenWidth > 360) {
  itemCols = 4;
}
if (screenWidth > 450) itemCols = 5;
if (screenWidth > 768) {
  itemCols = 6;
  itemIconSize = 32;
}
if (screenWidth > 960) {
  itemCols = 7;
  itemIconSize = 48;
}
const itemWidth = Math.floor((screenWidth - 20 - 10 * itemCols) / itemCols);

interface Props extends RouteComponentProps {}

const RegsiterContainerWithOutRouter: React.FC<Props> = observer(
  ({ history }) => {
    const { registerStore, pointOfSaleStore } = useContext(RootStoreContext);
    const [registerItems, setRegisterItems] = useState([]);
    const [registerItemsCategory, setRegisterItemsCategory] = useState("");
    const [showRegisterModal, setShowRegisterModal] = useState(false);

    const [showCashInModal, setShowCashInModal] = useState(false);
    const [showCashOutModal, setShowCashOutModal] = useState(false);
    const [showPMModal, setShowPMModal] = useState(false);
    const [showXReport, setShowXReport] = useState(false);
    const [xReport, setXReport] = useState({});

    // const { pointOfSale } = pointOfSaleStore;

    console.log("I rerendered!");
    console.log(pointOfSaleStore.pointOfSale.displayItems);

    // useEffect(() => {
    //   const getXReport = async () => {

    //   };
    // }, [showXReport]);

    useEffect(() => {
      if (!registerItemsCategory) {
        const rItems = pointOfSaleStore.pointOfSale.displayItems
          .slice()
          .sort(sortByDescription);
        setRegisterItems(rItems || []);
      } else {
        const rItems = pointOfSaleStore.pointOfSale.displayItems.filter(
          (item) => item.id === registerItemsCategory
        );
        // .items.slice()
        // .sort(sortByDescription);
        console.log("rItems:", rItems);
        setRegisterItems(rItems[0].items || []);
      }
    }, [registerItemsCategory]);

    console.log("REGISTER ITEMS TO DISPLAY", registerItems);
    return (
      <>
        <View style={{ flex: 1 }}>
          <RegisterHeader
            showMenuIcon={true}
            onMenuClick={setShowRegisterModal}
          />
          <TotalSumDisplay />
          <ScrollView contentContainerStyle={{ flex: 1 }}>
            <View
              style={{
                backgroundColor: registerStore.isReturn
                  ? theme.colors.danger
                  : "#ffffff",
                flexDirection: "row",
                flexWrap: "wrap",
                flex: 1,
                justifyContent: "flex-start",
                alignContent: "flex-start",
                // padding: 0
                paddingHorizontal: 10,
                paddingTop: 20,
              }}
            >
              <>
                {registerItemsCategory && (
                  <TouchableOpacity
                    style={styles.wrapper}
                    // onLongPress={() => registerStore._removeFromCart(item)}
                    onPress={() => {
                      setRegisterItemsCategory("");
                      console.log("SHOW CATEGORY");
                    }}
                    // onPress={() => registerStore._addToCart(item)}
                  >
                    <View style={styles.container}>
                      <View style={{ transform: [{ rotate: "180deg" }] }}>
                        <Icon name="tp-icon-arrow" size={itemIconSize} />
                      </View>
                      <Text style={styles.price}>Tillbaka</Text>
                      <Text style={styles.description}>till alla varor</Text>
                    </View>
                  </TouchableOpacity>
                )}
                {registerItems.map((node) => {
                  // console.log("I rerendered too!");
                  if (node.objectType === "Category") {
                    console.log("CATEGORYITEMS: ", node.items);
                    const categoryBadgeCount = registerStore._categoryCartAppearances(
                      node.items
                    );
                    return (
                      <RegisterCategoryButton
                        category={node}
                        key={node.id}
                        setRegisterItemsCategory={setRegisterItemsCategory}
                        badgeCount={categoryBadgeCount}
                        // badgeCount
                      />
                    );
                  }
                  const badgeCount = registerStore._cartAppearances(node.id);
                  return (
                    <RegisterItemButton
                      item={node}
                      key={node.id}
                      badgeCount={badgeCount}
                    />
                  );
                })}
              </>
              {/* {pointOfSaleStore.pointOfSale.items
            .slice()
            .sort(sortByDescription)
            .map(node => {
              const badgeCount = registerStore._cartAppearances(node.id);
              console.log("I rerendered too!");
              return (
                <RegisterItemButton
                  item={node}
                  key={node.id}
                  badgeCount={badgeCount}
                />
              );
            })} */}
            </View>
          </ScrollView>
          {registerStore.isShopping && registerStore.cartItems.length > 0 && (
            <View
              style={{
                marginBottom: "auto",
                padding: 15,
                backgroundColor: registerStore.isReturn
                  ? theme.colors.danger
                  : "#ffffff",
              }}
            >
              <Button
                title={
                  registerStore.isReturn
                    ? "Genomför återköp"
                    : "Till varukorgen & betalning"
                }
                onPress={() => {
                  // console.log("Next click");
                  registerStore.currentStep = registerStore.isReturn
                    ? RegisterSteps.Return
                    : RegisterSteps.Basket;
                }}
              />
            </View>
          )}
          {/*
      <Divider />
      <Button
        type={registerStore.isReturn ? "solid" : "clear"}
        title={registerStore.isReturn ? "Avsluta retur" : "Påbörja retur"}
        onPress={() => registerStore._toggleReturn()}
      /> */}
          <Modal
            direction="toLeft"
            isVisible={showRegisterModal}
            closeFn={setShowRegisterModal}
          >
            {/* LÄMNA KASSAN */}
            <View style={{ marginBottom: 15 }}>
              <Text
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  marginBottom: 15,
                }}
              >
                Klar med ditt pass?
              </Text>
              <Button
                type="outline"
                title="Lämna kassan"
                onPress={() => history.push(APP_PATHS.pointofsale.home)}
              />
              <Button
                style={{ marginTop: 20 }}
                title="Gör dagsavslut"
                onPress={() => history.push(APP_PATHS.register.close)}
              />
              <Text
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  marginBottom: 15,
                  marginTop: 15,
                }}
              >
                Hur går det just nu?
              </Text>
              <Button
                type="outline"
                title="Visa X-rapport"
                onPress={async () => {
                  const res = await pointOfSaleStore._getXReport();
                  // .then((res) => {
                  //   if (res) {
                  //   }
                  // });
                  console.log("GOT X REPORT", res);
                  setXReport(res);
                  setShowRegisterModal(false);
                  setShowXReport(true);
                }}
              />
            </View>
            {/* DAGSAVSLUT */}
            {/* <View style={{ marginBottom: 30 }}>
              <Text
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  marginBottom: 15
                }}
              >
                Klar med dagens försäljning?
              </Text>
            </View> */}
            {/* Aterköp */}
            <Text
              style={{
                textAlign: "center",
                fontWeight: "bold",
                marginBottom: 15,
              }}
            >
              Återköp, insättning/utttag & betalsätt
            </Text>
            <View
              style={{
                marginBottom: 15,
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Text>Aktivera återköpsläge</Text>
              <Switch
                value={registerStore.isReturn}
                onValueChange={(value) => {
                  registerStore.isReturn = value;
                  setShowRegisterModal(false);
                }}
              />
            </View>
            <View style={{ marginBottom: 0 }}>
              <Button
                type="outline"
                title="Visa köphistorik"
                onPress={() => history.push(APP_PATHS.register.history)}
              />
            </View>

            {/* INSTÄTTNINGAR */}
            <View
              style={{
                height: 1,
                alignSelf: "stretch",
                backgroundColor: "#cdcdcd",
                marginVertical: 15,
              }}
            />
            {/* <Text
              style={{
                textAlign: "center",

                fontWeight: "bold",
                marginBottom: 15,
              }}
            >
              Gör en insättning eller ett uttag
            </Text> */}
            <View
              style={{
                flex: 1,
                flexDirection: "row",
                alignSelf: "stretch",
              }}
            >
              <Button
                type="outline"
                containerStyle={{
                  flex: 1,
                  flexGrow: 1,
                  alignSelf: "stretch",
                  paddingRight: 5,
                }}
                onPress={() => {
                  setShowCashInModal(true);
                  setShowRegisterModal(false);
                }}
                title="Gör insättning"
              />
              <Button
                type="outline"
                containerStyle={{
                  flex: 1,
                  flexGrow: 1,
                  alignSelf: "stretch",
                  paddingLeft: 5,
                }}
                onPress={() => {
                  setShowCashOutModal(true);
                  setShowRegisterModal(false);
                }}
                title="Gör uttag"
              />
            </View>
            <View
              style={{
                height: 1,
                alignSelf: "stretch",
                backgroundColor: "#cdcdcd",
                marginVertical: 15,
              }}
            />
            <View>
              {/* <Text
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  marginTop: 30,
                  marginBottom: 15,
                }}
              >
                Inställningar för betalsätt
              </Text> */}
              <Button
                type="outline"
                onPress={() => {
                  setShowPMModal(true);
                  setShowRegisterModal(false);
                }}
                title=" Redigera aktiva betalsätt"
              />
            </View>
          </Modal>
          <Modal
            isVisible={showCashInModal}
            closeFn={setShowCashInModal}
            direction="toTop"
          >
            {showCashInModal && <CashInForm doneAction={setShowCashInModal} />}
          </Modal>
          <Modal
            isVisible={showCashOutModal}
            closeFn={setShowCashOutModal}
            direction="toTop"
          >
            {showCashOutModal && (
              <CashOutForm doneAction={setShowCashOutModal} />
            )}
          </Modal>
          <Modal
            isVisible={showPMModal}
            closeFn={setShowPMModal}
            direction="toTop"
          >
            {showPMModal && <PaymentMethodModal doneAction={setShowPMModal} />}
          </Modal>
          <Modal
            isVisible={registerStore.showOpenPriceModal}
            closeFn={() => {
              registerStore.openPriceItemToAdd = null;
              registerStore.showOpenPriceModal = false;
            }}
            direction="toTop"
          >
            {registerStore.showOpenPriceModal ? (
              <View>
                <Text h4>Ange pris</Text>
                <Formik
                  initialValues={{
                    item: registerStore.openPriceItemToAdd,
                    priceInclVat: undefined,
                  }}
                  onSubmit={(data, { setSubmitting, resetForm }) => {
                    console.log("OPENPRICEDATA: ", data);
                    // ACTION
                    let itemToAdd = data.item;
                    itemToAdd.priceInclVat = parseInt(data.priceInclVat);
                    // Add to car
                    registerStore._addToCart(itemToAdd);
                    // hide modal
                    registerStore.openPriceItemToAdd = null;
                    registerStore.showOpenPriceModal = false;
                    // clear form
                    resetForm();
                    setSubmitting(false);
                  }}
                >
                  {({
                    values,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue,
                  }) => {
                    console.log("Rendered");
                    return (
                      <View>
                        <Input
                          value={values.priceInclVat}
                          onChangeText={(text) => {
                            // handleChange("priceInclVat");
                            const parsedPrice = text.replace(/\D/g, "");
                            if (
                              Number.isInteger(parseInt(parsedPrice)) ||
                              parsedPrice === ""
                            ) {
                              setFieldValue("priceInclVat", parsedPrice);
                            }
                          }}
                          // onBlur={handleBlur("priceInclVat")}
                          containerStyle={{
                            marginVertical: 15,
                          }}
                        />
                        <Button
                          containerStyle={{
                            marginVertical: 15,
                          }}
                          title={isSubmitting ? "Sparar..." : "Spara"}
                          onPress={() => handleSubmit()}
                          disabled={!values.priceInclVat || isSubmitting}
                        />
                      </View>
                    );
                  }}
                </Formik>
              </View>
            ) : (
              <></>
            )}
          </Modal>
          <Modal
            isVisible={showXReport}
            direction="toTop"
            closeFn={() => setShowXReport(false)}
          >
            <View
              style={{
                flex: 1,
                alignSelf: "stretch",
                alignContent: "stretch",
                marginHorizontal: -15,
              }}
            >
              <Text h3>Försäljning just nu</Text>
              <ContentScroll>
                {xReport && (
                  <>
                    <LinearGradient
                      start={[0, 0]}
                      end={[1, 1]}
                      colors={["#5FC4E1", "#00A13A"]}
                      style={{
                        position: "relative",
                        backgroundColor: "#fff",
                        // marginHorizontal: 30,
                        alignSelf: "stretch",
                        borderRadius: 8,
                        paddingBottom: 20,
                        paddingTop: 20,
                        ...elevationShadowStyle(10),
                      }}
                    >
                      <View>
                        <Paragraph center color="#fff">
                          Försäljning just nu
                        </Paragraph>
                        {/* Total först */}
                        <View style={{ marginHorizontal: 15 }}>
                          <View
                            style={{
                              flexDirection: "row",
                              justifyContent: "space-between",
                            }}
                          >
                            <Text style={{ color: "#ffffff" }}>
                              Total försäljning
                            </Text>
                            <Text
                              style={{
                                color: "#ffffff",
                                fontWeight: "bold",
                              }}
                            >
                              {xReport.totalSales} kr
                            </Text>
                          </View>
                          {/* Antal köp */}
                          <View
                            style={{
                              flexDirection: "row",
                              justifyContent: "space-between",
                            }}
                          >
                            <Text style={{ color: "#ffffff" }}>Antal köp</Text>
                            <Text
                              style={{
                                color: "#ffffff",
                                fontWeight: "bold",
                              }}
                            >
                              {xReport.numberOfSales} st
                            </Text>
                          </View>
                          {/* Ingående växel */}
                          <View
                            style={{
                              flexDirection: "row",
                              justifyContent: "space-between",
                            }}
                          >
                            <Text style={{ color: "#ffffff" }}>
                              Ingående växelkassa
                            </Text>
                            <Text
                              style={{
                                color: "#ffffff",
                                fontWeight: "bold",
                              }}
                            >
                              {xReport.cashIn} kr
                            </Text>
                          </View>
                          {/* Nuvarande kassa */}
                          <View
                            style={{
                              flexDirection: "row",
                              justifyContent: "space-between",
                            }}
                          >
                            <Text style={{ color: "#ffffff" }}>
                              Nuvarande kassa
                            </Text>
                            <Text
                              style={{
                                color: "#ffffff",
                                fontWeight: "bold",
                              }}
                            >
                              {xReport.cashSales + xReport.cashIn} kr
                            </Text>
                          </View>
                        </View>
                      </View>
                    </LinearGradient>
                  </>
                )}
              </ContentScroll>
            </View>
          </Modal>
        </View>
      </>
    );
  }
);

const RegsiterContainer = withRouter(RegsiterContainerWithOutRouter);
export default RegsiterContainer;

const styles = StyleSheet.create({
  wrapper: {
    position: "relative",

    width: itemWidth,
    height: itemWidth,
    margin: 5,
    paddingTop: 5,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: "#000",
    backgroundColor: "white",
    ...elevationShadowStyle(3, "rgba(0,0,0,0.3)"),
  },

  badge: {
    position: "absolute",
    top: -8,
    right: 2,
    padding: 3,
  },
  bagdeStyle: {
    backgroundColor: theme.colors.danger,
  },
  badgeText: {
    // fontSize: 13
    fontWeight: "bold",
  },
  container: {
    width: itemWidth,
    height: itemWidth,
    flex: 1,
    alignItems: "center",
    paddingTop: 3,
  },
  price: {
    marginVertical: 3,
    fontSize: 14,
    fontWeight: "bold",
  },
  description: {
    fontSize: 11,
    textAlign: "center",
    alignSelf: "stretch",
  },
});
