import { Formik } from "formik";
import { observer } from "mobx-react";
import React, { useContext, useState } from "react";
import { Image, View } from "react-native";
import { Button, Input, Text } from "react-native-elements";
import { RouteComponentProps } from "react-router";
import BackButton from "../components/common/BackButton";
import { HeaderTitleSubtitle } from "../components/headerItems/HeaderTitleSubtitle";
import { LargeHeaderIcon } from "../components/headerItems/LargeHeaderIcon";
import { TpIconTeam3 } from "../components/Icon";
import { Content } from "../components/layout/Content";
import { FullPageWrapper } from "../components/layout/FullPageWrapper";
import { Header } from "../components/layout/Header";
import ModalDoneCheck from "../components/modal/ModalDoneCheck";
import APP_PATHS from "../config/appPaths";
import { RootStoreContext } from "../stores/RootStore";

interface Props extends RouteComponentProps {}

const OrganizationReportRecieversScreen = observer(({ history }) => {
  const { organizationStore } = useContext(RootStoreContext);
  const [done, setDone] = useState(false);

  let orgLogo;
  if (organizationStore.organization) {
    if (organizationStore.organization.logoUrl128x128) {
      orgLogo = (
        <Image
          style={{ width: 64, height: 64 }}
          source={{
            uri: organizationStore.organization.logoUrl128x128.replace(
              "http:",
              "https:"
            ),
          }}
        />
      );
    } else {
      orgLogo = <TpIconTeam3 size={64} />;
    }
  }

  return (
    <FullPageWrapper>
      <Header>
        <BackButton to={APP_PATHS.organization.settings.home} />
        <LargeHeaderIcon>{orgLogo}</LargeHeaderIcon>
        <HeaderTitleSubtitle
          subtitle={
            organizationStore.organization
              ? organizationStore.organization.name
              : " "
          }
          title="Rapportmottagare"
        />
        {/* <HeaderMenuButton /> */}
      </Header>

      <Content>
        <View style={{ paddingHorizontal: 15, marginVertical: 40 }}>
          <Text h4>Rapportmottagare</Text>
          <Text style={{ paddingVertical: 15 }}>
            Fyll i de mejladresser som ni vill ska ta emot Z-Rapporter från
            föreningens försäljning, använd ett kommatecken för att separera
            flera adresser. tex: info@forening.se, ekonomi@forening.se,
            lagledare@forening.se
          </Text>
          {done ? (
            <>
              <ModalDoneCheck
                doneAction={() =>
                  history.push(APP_PATHS.organization.settings.home)
                }
              />
            </>
          ) : (
            <Formik
              initialValues={{
                id: organizationStore.organizationId,
                reportReceiverEmails:
                  organizationStore.organization.reportReceiverEmails,
              }}
              onSubmit={(data, { setSubmitting }) => {
                // console.log("FORMIK DATA: ", data);
                if (organizationStore._updateOrganization(data)) {
                  setDone(true);
                  setSubmitting(false);
                } else {
                  // console.log("CRAP!");
                }
              }}
            >
              {({
                values,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue,
              }) => {
                console.log("Rendered");
                return (
                  <View>
                    <Input
                      value={values.reportReceiverEmails}
                      onChangeText={(text) =>
                        setFieldValue("reportReceiverEmails", text)
                      }
                      // onBlur={handleBlur("priceInclVat")}
                      containerStyle={{
                        marginVertical: 15,
                      }}
                    />
                    <Button
                      containerStyle={{
                        marginVertical: 15,
                      }}
                      title={isSubmitting ? "Sparar..." : "Spara"}
                      onPress={() => handleSubmit()}
                      disabled={isSubmitting}
                    />
                  </View>
                );
              }}
            </Formik>
          )}
        </View>
      </Content>
    </FullPageWrapper>
  );
});

export default OrganizationReportRecieversScreen;
