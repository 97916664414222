import React, { ReactNode } from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import { Text } from "react-native-elements";
import { theme } from "../../lib/styles/theme";
import { FullPageWrapper } from "../layout/FullPageWrapper";
// import { FullPageDefaultLayout } from "../../elements/FullPageDefaultLayout";
import { DefaultWizardStep } from "./DefaultWizardStep";
interface Props {
  children: ReactNode[];
  initialValues: {};
  onSubmit: ({}) => void;
  afterSubmit: (s: string) => void;
  cancel?: any;
  errComponent: ReactNode;
}

interface WizardState {
  index: number;
  values: {};
  canProceed: boolean;
}

export class DefaultWizard extends React.PureComponent<Props> {
  static Step = (props) => <DefaultWizardStep {...props} />;

  state: WizardState = {
    index: 0,
    values: { ...this.props.initialValues },
    canProceed: true,
  };

  _nextStep = () => {
    if (
      this.state.canProceed &&
      this.state.index !== this.props.children.length - 1
    ) {
      this.setState((prevState: WizardState) => ({
        index: prevState.index + 1,
      }));
    }
  };

  _prevStep = () => {
    if (this.state.index > 0) {
      this.setState((prevState: WizardState) => ({
        index: prevState.index - 1,
      }));
    }
  };

  _onChangeValues = (name, value) => {
    this.setState((prevState: WizardState) => ({
      ...prevState,
      values: {
        ...prevState.values,
        [name]: value,
      },
    }));
  };

  _onSetCanProceed = (canProceed) => {
    this.setState((prevState: WizardState) => ({
      ...prevState,
      canProceed,
    }));
  };

  render() {
    console.log(this.state);
    const { children } = this.props;
    return (
      <FullPageWrapper>
        <View
          style={{
            padding: 30,
            paddingTop: 80,
            flex: 1,
            width: "100%",
            maxWidth: 450,
            alignSelf: "center",
          }}
        >
          {React.Children.map(children, (el, idx) => {
            if (idx === this.state.index) {
              return React.cloneElement(el as React.ReactElement<any>, {
                setCanProceed: this._onSetCanProceed,
                canProceed: this.state.canProceed,
                history: this.props.afterSubmit,
                currentIndex: this.state.index,
                values: this.state.values,
                onChangeValue: this._onChangeValues,
                onSubmit: this.props.onSubmit,
                afterSubmit: this.props.afterSubmit,
                nextStep: this._nextStep,
                prevStep: this._prevStep,
                isLast: this.state.index === this.props.children.length - 1,
                errComponent: this.props.errComponent,
              });
            }

            return null;
          })}
          <TouchableOpacity style={styles.close} onPress={this.props.cancel}>
            <Text style={{ color: "#ffffff", fontWeight: "bold" }}>X</Text>
          </TouchableOpacity>
        </View>
      </FullPageWrapper>
    );
  }
}

const styles = StyleSheet.create({
  close: {
    position: "absolute",
    top: 20,
    left: 20,
    width: 40,
    height: 40,
    borderRadius: 20,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: theme.colors.danger,
    zIndex: 1000,
  },
});
