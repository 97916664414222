import Constants from "expo-constants";
import * as ImagePicker from "expo-image-picker";
import * as Permissions from "expo-permissions";
import React, { useContext, useEffect, useState } from "react";
import {
  Dimensions,
  Image,
  Platform,
  StyleSheet,
  Switch,
  TouchableOpacity,
  View
} from "react-native";
import { Button, Input, Text } from "react-native-elements";
import { Redirect, RouteComponentProps } from "react-router";
import { DisplayHeading, Heading2 } from "../components/common/Headings";
// import { TitlePage } from "../components/wrappers/TitlePage";
// import { DisplayHeading, Heading2, Heading4 } from "../elements/Headings";
import {
  TpIconTeam1,
  TpIconTeam2,
  TpIconTeam3,
  TpIconTeam4,
  TpIconTeam5
} from "../components/Icon";
import { Content, ContentScroll } from "../components/layout/Content";
import { TitlePage } from "../components/layout/TitlePage";
import { OnboardingCreateorganization } from "../components/OnBoardingIcon";
// import { TemplateCard } from "../components/template/TemplateCard";
// import { CreateOrganizationWizard } from "../containers/CreateOrganizationWizard";
import { DefaultWizard } from "../components/wizards/DefaultWizard";
import { APP_PATHS } from "../config";
import { theme } from "../lib/styles/theme";
import { RootStoreContext } from "../stores/RootStore";

interface Props extends RouteComponentProps {}
const OrganizationCreateScreen: React.FC<Props> = ({ history }) => {
  const smallScreen = Dimensions.get("window").width < 375;
  const [wizardActive, setWizardActive] = useState(false);
  const [wizardDone, setWizardDone] = useState({ done: false, redirect: null });
  const { organizationStore } = useContext(RootStoreContext);
  const _onWizardComplete = str => {
    console.log("I GOT HERE I GOT HERE!");
    setWizardDone({
      done: true,
      redirect: APP_PATHS.organization.home
    });
  };

  if (wizardDone && wizardDone.done && wizardDone.redirect) {
    return <Redirect to={wizardDone.redirect} />;
  }

  if (wizardActive) {
    // useCreateOrganization({ name: "Test89" }).then(data => {
    //   console.log(data);
    // });

    return (
      <DefaultWizard
        initialValues={{
          name: "",
          orgNumber: "",
          email: "",
          url: "",
          address1: "",
          address2: "",
          zip: "",
          city: "",
          phone: "",
          logo: "",
          icon: "tp-icon-1",
          selectedTemplate: "",
          selectedTemplateId: "",
          seletedTemplateItems: [],
          defaultRoleOnInvite: "Guest",
          pm_recipient: "",
          pm_description: "",
          reportReceiverEmails: ""
        }}
        cancel={() => history.push(APP_PATHS.organization.select)}
        onSubmit={organizationStore._createOrganization}
        afterSubmit={_onWizardComplete}
      >
        <DefaultWizard.Step nextLabel="Fortsätt">
          {({ onChangeValue, values, onSubmit, afterSubmit }) => (
            <>
              <Heading2 text="1/7. Allmänt om föreningen" />
              <Text style={{ paddingVertical: 15 }}>
                Vi börjar enkelt, föreningens namn och organisationsnummer.
              </Text>
              <Text>
                Undvik gärna förkortningar, t.ex. skriv 'Framtidens IF' istället
                för 'FIF'.
              </Text>
              <Text style={{ fontWeight: "bold", marginTop: 30 }}>
                Föreningens namn
              </Text>
              <Input
                value={values.name}
                onChangeText={text => onChangeValue("name", text)}
              />

              <Text style={{ fontWeight: "bold", marginTop: 30 }}>
                Organisationsnummer
              </Text>
              <Input
                value={values.orgNumber}
                onChangeText={text => onChangeValue("orgNumber", text)}
              />
            </>
          )}
        </DefaultWizard.Step>

        <DefaultWizard.Step nextLabel="Fortsätt">
          {({ onChangeValue, values, onSubmit, afterSubmit }) => (
            <>
              <Heading2 text="2/7. Kontaktuppgifter" />
              <Text style={{ paddingVertical: 15 }}>
                Hur kan vi nå er?
                <Text style={{ fontStyle: "italic" }}>
                  Visas även på kvitton.
                </Text>
              </Text>
              <Text style={{ fontWeight: "bold", marginTop: 15 }}>
                Föreningens epostadress
              </Text>
              <Input
                containerStyle={{ marginBottom: 15 }}
                value={values.email}
                onChangeText={text => onChangeValue("email", text)}
              />
              <Text style={{ fontWeight: "bold", marginTop: 15 }}>
                Föreningens hemsida
              </Text>
              <Input
                containerStyle={{ marginBottom: 15 }}
                value={values.url}
                onChangeText={text => onChangeValue("url", text)}
              />
              <Text style={{ fontWeight: "bold", marginTop: 15 }}>
                Föreningens telefonnummer
              </Text>
              <Input
                containerStyle={{ marginBottom: 15 }}
                value={values.phone}
                onChangeText={text => onChangeValue("phone", text)}
              />
            </>
          )}
        </DefaultWizard.Step>
        <DefaultWizard.Step nextLabel="Fortsätt">
          {({ onChangeValue, values, onSubmit, afterSubmit }) => (
            <>
              <Heading2 text="3/7. Adressuppgifter" />
              <Text style={{ paddingVertical: 15 }}>
                För att kunna visa korrekt information på kvitton och för att
                kunna nå er behöver vi även adressuppgifter.
              </Text>
              <Text style={{ fontWeight: "bold", marginTop: 15 }}>
                Adressrad 1
              </Text>
              <Input
                value={values.address1}
                onChangeText={text => onChangeValue("address1", text)}
              />
              <Text style={{ fontWeight: "bold", marginTop: 15 }}>
                Adressrad 2
              </Text>
              <Input
                value={values.address2}
                onChangeText={text => onChangeValue("address2", text)}
              />
              <Text style={{ fontWeight: "bold", marginTop: 15 }}>
                Postnummer
              </Text>
              <Input
                value={values.zip}
                onChangeText={text => onChangeValue("zip", text)}
              />
              <Text style={{ fontWeight: "bold", marginTop: 15 }}>Postort</Text>
              <Input
                value={values.city}
                onChangeText={text => onChangeValue("city", text)}
              />
            </>
          )}
        </DefaultWizard.Step>
        {/* <DefaultWizard.Step>
          {onChangeValue => (
            <>
              <Paragraph>Step2</Paragraph>
            </>
          )}
        </DefaultWizard.Step> */}
        <DefaultWizard.Step>
          {({ onChangeValue, values, onSubmit, afterSubmit }) => {
            const [image, setImage] = useState<string>();
            const [errorMsg, setErrorMsg] = useState<string>("");
            const [saved, setSaved] = useState(false);

            useEffect(() => {
              const getPermissionsAsync = async () => {
                if (Constants.platform.ios) {
                  // alert("Du behöver ge appen lov att välja bland dina bilder.");
                  try {
                    const { status } = await Permissions.askAsync(
                      Permissions.CAMERA_ROLL
                    );
                    if (status !== "granted") {
                      alert(
                        "Sorry, we need camera roll permissions to make this work!"
                      );
                    }
                  } catch (error) {}
                }
              };
              getPermissionsAsync();
            }, []);

            const _pickImage = async () => {
              const platformOptions: ImagePicker.ImagePickerOptions = Platform.select(
                {
                  web: {
                    mediaTypes: ImagePicker.MediaTypeOptions.Images,
                    base64: true
                  },
                  default: {
                    mediaTypes: ImagePicker.MediaTypeOptions.Images,
                    allowsEditing: true,
                    aspect: [1, 1],
                    base64: true
                  }
                }
              );
              let result: any = await ImagePicker.launchImageLibraryAsync(
                platformOptions
              );

              console.log(result);

              if (!result.cancelled) {
                if (Platform.OS === "web") {
                  setImage(result.uri.split(",")[1]);
                  onChangeValue("logo", result.uri.split(",")[1]);
                } else {
                  setImage(result.base64);
                  onChangeValue("logo", result.base64);
                }
              }
            };

            function formatBytes(bytes, decimals = 2) {
              if (bytes === 0) return "0 Bytes";

              const k = 1024;
              const dm = decimals < 0 ? 0 : decimals;
              const sizes = [
                "Bytes",
                "KB",
                "MB",
                "GB",
                "TB",
                "PB",
                "EB",
                "ZB",
                "YB"
              ];

              const i = Math.floor(Math.log(bytes) / Math.log(k));

              return (
                parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) +
                " " +
                sizes[i]
              );
            }

            if (image) {
              // calc size
              // 3 * (LengthInCharacters / 4)
              const imageSize = 3 * (image.length / 4);
              console.log(
                "BILDSTORLEK: ",
                formatBytes(imageSize) + " | " + imageSize + " bytes"
              );
            }
            return (
              <>
                <Content>
                  <Heading2 text="4/7. Förenings symbol" />
                  <Text style={{ paddingVertical: 15 }}>
                    För att enklare kunna identifiera din förening kan du ladda
                    upp en logotyp eller välja en symbol som liknar den. Du kan
                    ändra detta senare under föreningens "Inställningar".
                  </Text>
                  <Text style={{ fontWeight: "bold" }}>
                    Välj en symbol för föreningen.{" "}
                  </Text>
                  <View
                    style={{
                      marginTop: 15,
                      flexDirection: "row",
                      justifyContent: "space-between"
                    }}
                  >
                    <TouchableOpacity
                      onPress={() => onChangeValue("icon", "tp-icon-team-1")}
                    >
                      <TpIconTeam1
                        size={48}
                        style={{
                          color:
                            values.icon === "tp-icon-team-1" &&
                            theme.colors.brand
                        }}
                      />
                    </TouchableOpacity>
                    <TouchableOpacity
                      onPress={() => onChangeValue("icon", "tp-icon-team-2")}
                    >
                      <TpIconTeam2
                        size={48}
                        style={{
                          color:
                            values.icon === "tp-icon-team-2" &&
                            theme.colors.brand
                        }}
                      />
                    </TouchableOpacity>
                    <TouchableOpacity
                      onPress={() => onChangeValue("icon", "tp-icon-team-3")}
                    >
                      <TpIconTeam3
                        size={48}
                        style={{
                          color:
                            values.icon === "tp-icon-team-3" &&
                            theme.colors.brand
                        }}
                      />
                    </TouchableOpacity>
                    <TouchableOpacity
                      onPress={() => onChangeValue("icon", "tp-icon-team-4")}
                    >
                      <TpIconTeam4
                        size={48}
                        style={{
                          color:
                            values.icon === "tp-icon-team-4" &&
                            theme.colors.brand
                        }}
                      />
                    </TouchableOpacity>
                    <TouchableOpacity
                      onPress={() => onChangeValue("icon", "tp-icon-team-5")}
                    >
                      <TpIconTeam5
                        size={48}
                        style={{
                          color:
                            values.icon === "tp-icon-team-5" &&
                            theme.colors.brand
                        }}
                      />
                    </TouchableOpacity>
                  </View>
                  <Text style={{ fontWeight: "bold", marginTop: 30 }}>
                    Du kan även välja att ladda upp en logotyp
                  </Text>
                  <Text style={{ paddingVertical: 15 }}>
                    Logotypen bör vara fyrkantigt beskuren för bästa resultat.
                  </Text>
                  <Button title="Välj logotyp" onPress={_pickImage} />
                </Content>
                <ContentScroll>
                  {image && (
                    <Image
                      style={{
                        width: 128,
                        height: 128,
                        alignSelf: "center",
                        resizeMode: "contain",
                        borderWidth: 1,
                        borderColor: "rgba rgba(0,255,0,0.3)"
                      }}
                      source={{
                        uri: `data:image/jpeg;base64,${image}`
                      }}
                    />
                  )}
                </ContentScroll>
              </>
            );
          }}
        </DefaultWizard.Step>

        <DefaultWizard.Step finishLabel="Skapa förening">
          {({ onChangeValue, values, onSubmit, afterSubmit }) => {
            const [
              defaultRoleOnInviteBoolean,
              setDefaultRoleOnInviteBoolean
            ] = useState(false);

            useEffect(() => {
              onChangeValue(
                "defaultRoleOnInvite",
                defaultRoleOnInviteBoolean ? "User" : "Guest"
              );
            }, [defaultRoleOnInviteBoolean]);

            return (
              <>
                <Heading2 text="5/7. Användarhantering" />
                <Text style={{ paddingVertical: 15 }}>
                  Med TeamPay är det enkelt att bjuda in fler användare för att
                  hjälpas åt med betalningar för god service och lönsamhet.
                </Text>
                <Text>
                  Här kan ni välja om ni vill jobba med permanenta användare som
                  stannar kvar och har tillgång till systemet på obegränsad tid,
                  eller gästanvändare där användarna bara har tillgång under en
                  viss tid.
                </Text>
                <Text style={{ paddingBottom: 30 }}>
                  I en större förening kan gästsystemet vara lite enklare att
                  administrera och hantera.
                </Text>
                <View
                  style={{ flexDirection: "row", justifyContent: "center" }}
                >
                  <View>
                    <Text>Gästanvändare</Text>
                  </View>
                  <View style={{ marginHorizontal: 10 }}>
                    <Switch
                      value={defaultRoleOnInviteBoolean}
                      onValueChange={value =>
                        setDefaultRoleOnInviteBoolean(value)
                      }
                    />
                  </View>
                  <View>
                    <Text>Permanenta</Text>
                  </View>
                </View>
                <Text style={{ fontStyle: "italic", marginTop: 30 }}>
                  Du kan ändra detta senare under 'Inställningar' på
                  föreningssidan.
                </Text>
              </>
            );
          }}
        </DefaultWizard.Step>
        <DefaultWizard.Step finishLabel="Skapa förening">
          {({ onChangeValue, values, onSubmit, afterSubmit }) => {
            return (
              <>
                <Heading2 text="6/7. Betalsätt" />
                <Text style={{ paddingVertical: 15 }}>
                  Här kan du lägga till ett ’föreningsswish´ som senare kan
                  användas som förinställt och låst nummer vid skapandet av
                  kassor.
                </Text>
                <Text style={{ fontWeight: "bold", marginTop: 30 }}>
                  Swishnummer
                </Text>
                <Input
                  value={values.pm_recipient}
                  onChangeText={text => onChangeValue("pm_recipient", text)}
                />
                <Text style={{ fontWeight: "bold", marginTop: 30 }}>
                  Beskrivning (t.ex "Huvudswish Framtidens IF")
                </Text>
                <Input
                  value={values.pm_description}
                  onChangeText={text => onChangeValue("pm_description", text)}
                />
                <Text style={{ fontStyle: "italic", marginTop: 30 }}>
                  Du kan även lämna detta blankt och senare lägga till din
                  föreningsswish under 'Inställningar' på föreningssidan.
                </Text>
              </>
            );
          }}
        </DefaultWizard.Step>
        <DefaultWizard.Step finishLabel="Skapa förening">
          {({ onChangeValue, values, onSubmit, afterSubmit }) => {
            return (
              <>
                <Heading2 text="7/7. Rapportmottagare" />
                <Text style={{ paddingVertical: 15 }}>
                  När det görs ett dagsavslut skapas en rapport över dagens
                  försäljning (en sk. Z-rapport), denna rapport skickas per
                  automatik till den epostadress som angetts för föreningen.
                </Text>
                <Text>
                  Vill du kan vi även skicka rapporten till andra epostadresser,
                  t.ex en försäljningsansvarig, en kassör eller revisor.
                </Text>
                <Text style={{ fontWeight: "bold", marginTop: 30 }}>
                  Fyll i mottagare
                </Text>
                <Text>Separera flera med kommatecken (,)</Text>
                <Input
                  value={values.reportReceiverEmails}
                  onChangeText={text =>
                    onChangeValue("reportReceiverEmails", text)
                  }
                />
                <Text style={{ fontStyle: "italic", marginTop: 30 }}>
                  Du kan även lämna detta blankt och senare lägga till
                  rapportmottagare under 'Inställningar' på föreningssidan.
                </Text>
              </>
            );
          }}
        </DefaultWizard.Step>
      </DefaultWizard>
    );
  }

  return (
    <>
      <TitlePage reverse padding={smallScreen ? 15 : 45}>
        <OnboardingCreateorganization
          width={smallScreen ? 128 : 192}
          height={smallScreen ? 128 : 192}
        />

        <DisplayHeading color="#ffffff" text="Skapa en ny förening" />
        <View style={{ alignSelf: "stretch", maxWidth: 400 }}>
          <Text
            style={{ color: "#ffffff", textAlign: "center", marginBottom: 15 }}
          >
            Vad roligt att du vill börja använda TeamPay i din organisation, för
            att komma igång behövs några enkla steg som bara tar ett par
            minuter.
          </Text>
          <Text style={{ color: "#ffffff", textAlign: "center" }}>
            När du skapat din förening påbörjas en 14 dagars fri testperiod, för
            att fortsätta använda TeamPay efter det måste en ansvarig skriva ett
            avtal. Instruktioner om detta kommer via mejl.
          </Text>
        </View>
        <Button
          type="outline"
          title="Sätt igång!"
          containerStyle={{ backgroundColor: "#fff" }}
          onPress={() => setWizardActive(true)}
        />
      </TitlePage>
      <TouchableOpacity
        style={styles.close}
        onPress={() => history.push(APP_PATHS.organization.select)}
      >
        <Text style={{ color: "#ffffff", fontWeight: "bold" }}>X</Text>
      </TouchableOpacity>
    </>
  );
};

export default OrganizationCreateScreen;

const styles = StyleSheet.create({
  close: {
    position: "absolute",
    top: 20,
    left: 20,
    width: 40,
    height: 40,
    borderRadius: 20,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: theme.colors.danger
  }
});

// <DefaultWizard.Step>
//   {({ onChangeValue, values, onSubmit, afterSubmit }) => {
//     return (
//       <>
//         <Heading2 text="5/5. Bättre tillsammans" />
//         <Text style={{ paddingVertical: 15 }}>
//           Nu är din förening redo att skapas och det enda som saknas är
//           någon att jobba med.
//         </Text>
//         <Text style={{ paddingBottom: 30 }}>
//           Det här är inte heller ett tvång och du kan när som helst
//           bjuda in fler personer, via sms eller mejl eller en QR-kod som
//           de kan scanna av med sin mobil.
//         </Text>
//         <Heading4 text="Vill du bjuda in någon redan nu?" />
//         <Text style={{ paddingTop: 15 }}>
//           Nu är din förening uppsatt och det enda som saknas är någon
//           att jobba med.
//         </Text>
//       </>
//     );
//   }}
// </DefaultWizard.Step>;

// <DefaultWizard.Step>
//   {({ onChangeValue, values, onSubmit, afterSubmit }) => {
//     const [templates, setTemplates] = useState(null);
//     const [loadingTemplate, setLoadingTeamplates] = useState(true);
//     useEffect(() => {
//       const getTemplates = async () => {
//         const { data } = await axios.get(
//           // `https://backend-stage.teampay.se/products/78157ef3-ad96-43ee-ae68-7f202c3c9f5c/templates`
//           `https://backend.teampay.se/products/78157ef3-ad96-43ee-ae68-7f202c3c9f5c/templates`
//         );
//         // console.log(data);
//         setTemplates(data.data.sort(sortByName));
//         setLoadingTeamplates(false);
//       };
//       getTemplates();
//     }, []);
//     useEffect(() => {
//       if (values.selectedTemplateId != "") {
//         const getTemplate = async () => {
//           const { data } = await axios.get(
//             // `https://backend-stage.teampay.se/products/78157ef3-ad96-43ee-ae68-7f202c3c9f5c/templates`
//             `https://backend.teampay.se/products/78157ef3-ad96-43ee-ae68-7f202c3c9f5c/templates/${values.selectedTemplateId}`
//           );

//           onChangeValue("selectedTemplate", data.data);
//           console.log("FETCHED: ", data.data);
//         };
//         getTemplate();
//       }
//     }, [values.selectedTemplateId]);
//     // if (!templates) {
//     //   return null;
//     // }
//     return (
//       <>
//         <Heading2 text="3/5. Första kassan" />
//         <Text style={{ paddingTop: 15 }}>
//           Kassor är TeamPay's hjärta. Här kan du genom en mall enkelt skapa
//           din första kassa. En kassa som tilldelas hela föreningen och som
//           är lämplig att testa med.
//         </Text>
//         <Text style={{ paddingVertical: 15 }}>
//           Senare kan du själva skapa hur många kassor du vill, tex en för
//           parkering, kiosk, entre eller varför inte lotterier eller
//           souvernirer.
//         </Text>
//         {loadingTemplate && <FullPageActivityIndicator />}
//         {templates && (
//           <>
//             <Heading4 text="Välj en mall att utgå ifrån" />
//             <ScrollView
//               style={{ marginHorizontal: -30, paddingVertical: 20 }}
//               horizontal={true}
//               showsHorizontalScrollIndicator={false}
//               decelerationRate="fast"
//             >
//               {/* <TouchableOpacity onPress={() => null}> */}
//               <View style={{ flexDirection: "row", paddingHorizontal: 20 }}>
//                 {templates &&
//                   templates.map(template => {
//                     let active = false;
//                     if (
//                       values &&
//                       !!values.selectedTemplateId &&
//                       template.id === values.selectedTemplateId
//                     ) {
//                       active = true;
//                     }
//                     return (
//                       <TemplateCard
//                         selected={active}
//                         key={`template-list-item-${template.id}`}
//                         template={template}
//                         onPress={() => {
//                           onChangeValue("selectedTemplateId", template.id);
//                           onChangeValue("selectedTemplate", template);
//                         }}
//                       />
//                     );
//                   })}
//               </View>
//               {/* </TouchableOpacity> */}
//             </ScrollView>
//             {values && values.selectedTemplate && (
//               <ScrollView horizontal={false} decelerationRate="fast">
//                 <Heading4 text={values.selectedTemplate.name} />

//                 {values.selectedTemplate.items &&
//                   values.selectedTemplate.items.length && (
//                     <>
//                       <Text>{`${values.selectedTemplate.items.length} ${
//                         values.selectedTemplate.items.length === 1
//                           ? " vara"
//                           : " varor"
//                       }`}</Text>

//                       {values.selectedTemplate.items.map(item => (
//                         <View key={`item-list-${item.id}`}>
//                           <Text style={{ fontWeight: "bold" }}>
//                             {item.description}{" "}
//                             <Text style={{ fontWeight: "normal" }}>
//                               {" "}
//                               ({item.priceInclVat} kr)
//                             </Text>
//                           </Text>
//                         </View>
//                       ))}
//                     </>
//                   )}
//               </ScrollView>
//             )}
//           </>
//         )}
//       </>
//     );
//   }}
// </DefaultWizard.Step>;
