import { observer } from "mobx-react";
import React, { useContext } from "react";
import { Image, Switch, View } from "react-native";
import { Text } from "react-native-elements";
import { RouteComponentProps } from "react-router";
import BackButton from "../components/common/BackButton";
import FullPageActivityIndicator from "../components/common/FullPageActivityIndicator";
import { HeaderTitleSubtitle } from "../components/headerItems/HeaderTitleSubtitle";
import { LargeHeaderIcon } from "../components/headerItems/LargeHeaderIcon";
import { TpIconTeam3 } from "../components/Icon";
import { Content } from "../components/layout/Content";
import { FullPageWrapper } from "../components/layout/FullPageWrapper";
import { Header } from "../components/layout/Header";
import APP_PATHS from "../config/appPaths";
import { RootStoreContext } from "../stores/RootStore";

interface Props extends RouteComponentProps {}

const OrganizationReportAvailabilityScreen = observer(({ history }) => {
  const { organizationStore } = useContext(RootStoreContext);

  let orgLogo;
  if (organizationStore.organization) {
    if (organizationStore.organization.logoUrl128x128) {
      orgLogo = (
        <Image
          style={{ width: 64, height: 64 }}
          source={{
            uri: organizationStore.organization.logoUrl128x128.replace(
              "http:",
              "https:"
            )
          }}
        />
      );
    } else {
      orgLogo = <TpIconTeam3 size={64} />;
    }
  }
  return (
    <FullPageWrapper>
      <Header>
        <BackButton to={APP_PATHS.organization.settings.home} />
        <LargeHeaderIcon>{orgLogo}</LargeHeaderIcon>
        <HeaderTitleSubtitle
          subtitle={
            organizationStore.organization
              ? organizationStore.organization.name
              : " "
          }
          title="Rapporttillgänglighet"
        />
        {/* <HeaderMenuButton /> */}
      </Header>

      <Content>
        <View style={{ paddingHorizontal: 15, marginVertical: 40 }}>
          <Text h4>Rapporttillgänglighet</Text>
          <Text style={{ paddingVertical: 15 }}>
            Här kan du välja att aktivera begränsad tillgång till rapporter,
            vilket betyder att endast Administratörer i föreningen får tillgång
            till historiska rapporter.
          </Text>
          <View style={{ flexDirection: "row", marginBottom: 30 }}>
            <Switch
              value={organizationStore.organization.adminOnlyReports}
              onValueChange={value => {
                organizationStore._updateOrganization({
                  adminOnlyReports: value ? true : false
                });
              }}
            />
            <Text style={{ marginLeft: 10 }}>
              Använd begränsade rapporter
              {organizationStore.organizationStoreLoading && (
                <FullPageActivityIndicator />
              )}
            </Text>
          </View>
        </View>
      </Content>
    </FullPageWrapper>
  );
});

export default OrganizationReportAvailabilityScreen;
