import { LinearGradient } from "expo-linear-gradient";
import { observer } from "mobx-react";
import React, { useContext, useEffect, useState } from "react";
import { ActivityIndicator, Image, TouchableOpacity, View } from "react-native";
import { Button, Text } from "react-native-elements";
import { RouteComponentProps } from "react-router";
import BackButton from "../components/common/BackButton";
import FullPageActivityIndicator from "../components/common/FullPageActivityIndicator";
import { QuickButton } from "../components/common/QuickButton";
import { withAuthorization } from "../components/containers/withAuthorization";
import { HeaderTitleSubtitle } from "../components/headerItems/HeaderTitleSubtitle";
import { LargeHeaderIcon } from "../components/headerItems/LargeHeaderIcon";
import {
  TpIconClosedString,
  TpIconOpenString,
  TpIconRegister,
  TpIconTeam3
} from "../components/Icon";
import { Content, ContentFixed } from "../components/layout/Content";
import { Footer } from "../components/layout/Footer";
import { FullPageWrapper } from "../components/layout/FullPageWrapper";
import { Header } from "../components/layout/Header";
import Modal from "../components/modal/Modal";
import { Paragraph } from "../components/primitives/Paragraph";
import { APP_PATHS } from "../config";
import AddItemForm from "../containers/AddItemForm";
import { theme } from "../lib/styles/theme";
import { elevationShadowStyle } from "../lib/utils/elevationShadowStyle";
import { RootStoreContext } from "../stores/RootStore";

interface Props extends RouteComponentProps {}

const PointOfSaleHomeScreen: React.FC<Props> = observer(({ history }) => {
  const { pointOfSaleStore, organizationStore } = useContext(RootStoreContext);
  const [showAddItem, setShowAddItem] = useState(false);

  useEffect(() => {
    pointOfSaleStore._fetchPointOfSale();
  }, []);

  if (pointOfSaleStore.pointOfSaleStoreLoading)
    return <FullPageActivityIndicator color="green" />;

  let orgLogo;
  if (organizationStore.organization) {
    if (organizationStore.organization.logoUrl128x128) {
      orgLogo = (
        <Image
          style={{ width: 64, height: 64 }}
          source={{
            uri: organizationStore.organization.logoUrl128x128.replace(
              "http:",
              "https:"
            )
          }}
        />
      );
    } else {
      orgLogo = <TpIconTeam3 size={64} />;
    }
  }

  const { pointOfSale } = pointOfSaleStore;
  console.log("POS HOME RENDERED!");
  return (
    <>
      <FullPageWrapper>
        <Modal
          isVisible={showAddItem}
          closeFn={setShowAddItem}
          direction="toTop"
        >
          {showAddItem && <AddItemForm doneAction={setShowAddItem} />}
        </Modal>
        <Header>
          {/* <View style={{ backgroundColor: "red" }}> */}
          <BackButton to={APP_PATHS.organization.home} />
          {/* </View> */}
          <LargeHeaderIcon>{orgLogo}</LargeHeaderIcon>
          <HeaderTitleSubtitle
            // subtitle={user.name ? user.name : " "}
            subtitle={
              organizationStore.organization
                ? organizationStore.organization.name
                : " "
            }
            title={
              pointOfSaleStore.pointOfSale
                ? pointOfSaleStore.pointOfSale.name
                : " "
            }
          />
          {/* <HeaderMenuButton /> */}
        </Header>

        {!pointOfSaleStore.pointOfSaleStoreLoading &&
        !organizationStore.organizationStoreLoading &&
        pointOfSaleStore.pointOfSale ? (
          <>
            <ContentFixed>
              <View
                style={{
                  alignSelf: "flex-end",
                  alignItems: "flex-end",
                  paddingVertical: 10,
                  marginTop: 10
                }}
              >
                {withAuthorization(
                  <QuickButton
                    onPress={() => setShowAddItem(true)}
                    title="Lägg till ny produkt"
                  />,
                  pointOfSaleStore.pointOfSale.priviliges,
                  "PointOfSale:CreateItem",
                  <QuickButton
                    disabled={true}
                    onPress={() => {}}
                    title="Lägg till ny produkt"
                  />
                )}
                {withAuthorization(
                  <QuickButton
                    onPress={() => history.push(APP_PATHS.pointofsale.items)}
                    title="Hantera produkter"
                    outline
                  />,
                  pointOfSaleStore.pointOfSale.priviliges,
                  "PointOfSale:ModifyItem",
                  <QuickButton
                    disabled={true}
                    onPress={() => {}}
                    title="Hantera produkter"
                    outline
                  />
                )}
              </View>
            </ContentFixed>
            <Content>
              <View
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  flexGrow: 1
                }}
              >
                {organizationStore.organization.active ? (
                  <>
                    {pointOfSaleStore.pointOfSale.session ? (
                      <>
                        <LinearGradient
                          start={[0, 0]}
                          end={[1, 1]}
                          colors={["#5FC4E1", "#00A13A"]}
                          style={{
                            position: "relative",
                            backgroundColor: "#fff",
                            marginHorizontal: 30,
                            alignSelf: "stretch",
                            borderRadius: 8,
                            // paddingBottom: 20,
                            paddingTop: 20,
                            ...elevationShadowStyle(10)
                          }}
                        >
                          <View>
                            <Paragraph center color="#fff">
                              Försäljning just nu
                            </Paragraph>
                            <View style={{ marginHorizontal: 15 }}>
                              <View
                                style={{
                                  flexDirection: "row",
                                  justifyContent: "space-between"
                                }}
                              >
                                <Text style={{ color: "#ffffff" }}>
                                  Total försäljning
                                </Text>
                                <Text
                                  style={{
                                    color: "#ffffff",
                                    fontWeight: "bold"
                                  }}
                                >
                                  {
                                    pointOfSaleStore.pointOfSale.session
                                      .totalSales
                                  }{" "}
                                  kr
                                </Text>
                              </View>
                              <View
                                style={{
                                  flexDirection: "row",
                                  justifyContent: "space-between"
                                }}
                              >
                                <Text style={{ color: "#ffffff" }}>
                                  Ingående växelkassa
                                </Text>
                                <Text
                                  style={{
                                    color: "#ffffff",
                                    fontWeight: "bold"
                                  }}
                                >
                                  {
                                    pointOfSaleStore.pointOfSale.session
                                      .cashAmountIn
                                  }{" "}
                                  kr
                                </Text>
                              </View>
                            </View>
                            <View
                              style={{
                                alignSelf: "center",
                                marginBottom: -30,
                                zIndex: 100
                              }}
                            >
                              <TouchableOpacity
                                onPress={() => {
                                  pointOfSaleStore
                                    ._openPointOfSale()
                                    .then(() => {
                                      history.push(APP_PATHS.register.register);
                                    });
                                }}
                              >
                                <View
                                  style={{
                                    marginTop: 20,
                                    padding: 15,
                                    backgroundColor: theme.colors.danger,
                                    flexDirection: "row",
                                    borderRadius: 8,
                                    ...elevationShadowStyle(
                                      10,
                                      theme.colors.danger
                                    )
                                  }}
                                >
                                  <TpIconRegister
                                    style={{
                                      color: "#ffffff",
                                      marginRight: 15
                                    }}
                                    size={30}
                                  />
                                  <Paragraph color="#fff">
                                    Anslut till kassan
                                  </Paragraph>
                                </View>
                              </TouchableOpacity>
                            </View>
                          </View>
                        </LinearGradient>
                        <TpIconOpenString
                          size={60}
                          color={theme.colors.brand}
                          style={{ marginTop: 20 }}
                        />
                      </>
                    ) : (
                      <>
                        <View
                          style={{
                            backgroundColor: "#fff",
                            marginHorizontal: 30,
                            alignSelf: "stretch",
                            borderRadius: 8,
                            // paddingBottom: 20,
                            paddingTop: 20,
                            ...elevationShadowStyle(10)
                          }}
                        >
                          <View>
                            <Paragraph center>
                              Den här kassan är inte öppen just nu!
                            </Paragraph>
                            {pointOfSaleStore.pointOfSale.items.length > 0 ? (
                              <View
                                style={{
                                  alignSelf: "center",
                                  marginBottom: -30,
                                  zIndex: 100
                                }}
                              >
                                <TouchableOpacity
                                  onPress={() => {
                                    history.push(APP_PATHS.register.open);
                                  }}
                                >
                                  <View
                                    style={{
                                      marginTop: 20,
                                      padding: 15,
                                      backgroundColor: theme.colors.brand,
                                      flexDirection: "row",
                                      borderRadius: 8,
                                      ...elevationShadowStyle(
                                        10,
                                        theme.colors.brand
                                      )
                                    }}
                                  >
                                    <TpIconRegister
                                      style={{
                                        color: "#ffffff",
                                        marginRight: 15
                                      }}
                                      size={30}
                                    />
                                    <Paragraph color="#fff">
                                      Öppna kassan
                                    </Paragraph>
                                  </View>
                                </TouchableOpacity>
                              </View>
                            ) : (
                              <View
                                style={{
                                  alignSelf: "center",
                                  marginBottom: -30,
                                  zIndex: 100
                                }}
                              >
                                <TouchableOpacity
                                  onPress={() => {
                                    history.push(APP_PATHS.pointofsale.items);
                                  }}
                                >
                                  <View
                                    style={{
                                      alignSelf: "center",
                                      marginTop: 20,
                                      padding: 15,
                                      backgroundColor: theme.colors.brand,
                                      // flexDirection: "row",
                                      borderRadius: 8,
                                      ...elevationShadowStyle(
                                        10,
                                        theme.colors.brand
                                      )
                                    }}
                                  >
                                    {/* <TpIconRegister
                                      style={{
                                        color: "#ffffff",
                                        marginRight: 15
                                      }}
                                      size={30}
                                    /> */}
                                    <Paragraph color="#fff">
                                      Kassan har inga produkter.
                                    </Paragraph>
                                    <Paragraph color="#fff">
                                      Klicka här för att lägga till.
                                    </Paragraph>
                                  </View>
                                </TouchableOpacity>
                              </View>
                            )}
                          </View>
                        </View>
                        <TpIconClosedString
                          size={60}
                          color={theme.colors.danger}
                          style={{ marginTop: 20 }}
                        />
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <ContentFixed>
                      <View style={{ padding: 20 }}>
                        <Text>Föreningen saknar ett aktivt avtal.</Text>
                      </View>
                    </ContentFixed>
                  </>
                )}
              </View>
            </Content>
            <Footer>
              <View>
                {withAuthorization(
                  <Button
                    onPress={() =>
                      history.push(APP_PATHS.pointofsale.settings.home)
                    }
                    title="Inställningar"
                    type="outline"
                  />,
                  pointOfSaleStore.pointOfSale.priviliges,
                  "PointOfSale:Modify",
                  <Button
                    disabled={true}
                    onPress={() => {}}
                    title="Inställningar"
                    type="outline"
                  />
                )}
              </View>
              <View>
                {withAuthorization(
                  <Button
                    onPress={() => history.push(APP_PATHS.pointofsale.reports)}
                    title="Rapporter"
                  />,
                  pointOfSaleStore.pointOfSale.priviliges,
                  "PointOfSale:Reports",
                  <Button
                    disabled={true}
                    onPress={() => {}}
                    title="Rapporter"
                  />
                )}
              </View>
            </Footer>
          </>
        ) : (
          <Content center>
            <ActivityIndicator />
          </Content>
        )}
      </FullPageWrapper>
    </>
  );
});

export default PointOfSaleHomeScreen;
