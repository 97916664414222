import { Formik } from "formik";
import React, { useContext, useState } from "react";
import { Picker, View } from "react-native";
import { Button, Input, Text } from "react-native-elements";
import { withAuthorization } from "../components/containers/withAuthorization";
import { GeneratedQRCode } from "../components/GeneratedQRCode";
import ModalDoneCheck from "../components/modal/ModalDoneCheck";
import { API_ULRS } from "../config";
import axiosInstance from "../lib/axios";
import { theme } from "../lib/styles/theme";
import { RootStoreContext } from "../stores/RootStore";

interface AddUserFormProps {
  doneAction?: (b: boolean) => void;
  addAdminInitially?: boolean;
}

const AddUserForm: React.FC<AddUserFormProps> = ({
  doneAction,
  addAdminInitially,
}) => {
  const [addAdmin, setAddAdmin] = useState(addAdminInitially || false);
  const [done, setDone] = useState(false);
  const { organizationStore } = useContext(RootStoreContext);

  if (done) return <ModalDoneCheck doneAction={doneAction} />;

  const inviteUrl = `${API_ULRS.app}/invited/${organizationStore.organizationId}`;
  return (
    <View
      style={{
        alignItems: "center",
      }}
    >
      <View style={{ marginBottom: 15 }}>
        <Text h4>
          {addAdmin ? "Lägg till administratör" : "Lägg till användare"}
        </Text>
      </View>
      {withAuthorization(
        <>
          <View style={{ alignSelf: "stretch" }}>
            <Button
              type="outline"
              title={
                addAdmin ? "Lägg till användare" : "Lägg till administratör"
              }
              onPress={() => setAddAdmin(!addAdmin)}
            />
          </View>
        </>,
        organizationStore.organization.priviliges,
        "Organization:GrantAdmin",
        <></>
      )}

      {/* TODO: Flytta till privileges */}

      {addAdmin ? (
        <>
          <Formik
            initialValues={{
              phone: "",
              groupId: organizationStore.organization.groups[0].id,
              // organizationId: organizationStore.organizationId,
              role: "Admin",
              groupToCreate: "",
            }}
            onSubmit={async (data, { setSubmitting }) => {
              console.log("FORMIK DATA: ", data);
              if (data.groupId === "create-group") {
                axiosInstance
                  .post(
                    `${API_ULRS.url}/organization/${organizationStore.organizationId}/groups`,
                    {
                      name: data.groupToCreate,
                    }
                  )
                  .then((res) => {
                    const formData = {
                      ...data,
                      groupId: res.data.data.id,
                    };
                    console.log(res);
                    organizationStore._addUser(formData).then((res) => {
                      if (res) {
                        setSubmitting(false);
                        setDone(true);
                      } else {
                      }
                      console.log("CRAP!");
                    });
                  });
              } else {
                const formData = {
                  ...data,
                };
                organizationStore._addUser(formData).then((res) => {
                  if (res) {
                    setSubmitting(false);
                    setDone(true);
                  } else {
                  }
                  console.log("CRAP!");
                });
              }
            }}
          >
            {({
              values,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
            }) => (
              <View style={{ alignSelf: "stretch", marginBottom: 15 }}>
                {values.groupId !== "create-group" && (
                  <View style={{ marginVertical: 15 }}>
                    <Text>1. Välj gruppnivå för administratören</Text>

                    <View style={{ marginVertical: 15 }}>
                      <Picker
                        selectedValue={values.groupId || ""}
                        onValueChange={(itemValue) => {
                          if (itemValue !== "") {
                            setFieldValue("groupId", itemValue);
                          }
                        }}
                      >
                        {organizationStore.organization.groups
                          .slice()
                          .map((group, idx) => {
                            return (
                              <Picker.Item
                                key={group.id}
                                value={group.id}
                                label={group.name}
                              />
                            );
                          })}

                        <Picker.Item
                          key="empty-picker-item"
                          value=""
                          label=""
                        />

                        <Picker.Item
                          key="create-new-group-picker-item"
                          value="create-group"
                          label="Skapa en ny grupp"
                        />
                      </Picker>
                      <Text style={{ marginTop: 10 }}>
                        En administratör för 'Hela föreningen' blir automatiskt
                        administratör i samtliga grupper och kan även redigera
                        föreningsövergripande inställningar. Väljer man en
                        enskild grupp får användaren bara de rättigheterna för
                        gruppens kassor.
                      </Text>
                    </View>
                  </View>
                )}
                {values.groupId === "create-group" && (
                  <View style={{ marginVertical: 15 }}>
                    <Text>1. Skapa en ny grupp</Text>
                    <View style={{ marginVertical: 15 }}>
                      <Input
                        label="Gruppnamn"
                        value={values.groupToCreate}
                        // onChangeText={handleChange("phone")}
                        onChangeText={(text) =>
                          setFieldValue("groupToCreate", text)
                        }
                        onBlur={handleBlur("groupToCreate")}
                      />
                      <Text style={{ marginTop: 10 }}></Text>
                    </View>
                  </View>
                )}
                <View style={{ marginBottom: 15 }}>
                  <Text>2. Ange administratörens mobilnummer</Text>
                  <View style={{ marginVertical: 15 }}>
                    <Input
                      keyboardType="phone-pad"
                      label="Mobilnummer"
                      value={values.phone}
                      // onChangeText={handleChange("phone")}
                      onChangeText={(text) =>
                        setFieldValue("phone", text.replace(/\D/g, ""))
                      }
                      onBlur={handleBlur("phone")}
                    />
                  </View>
                </View>
                <Button
                  title={isSubmitting ? "Bjuder in..." : "Bjud in"}
                  onPress={() => handleSubmit()}
                  disabled={isSubmitting}
                />
              </View>
            )}
          </Formik>
        </>
      ) : (
        <>
          <Text style={{ marginVertical: 15 }}>
            Låt den nya användaren scanna denna kod
          </Text>
          <View style={{ alignSelf: "center" }}>
            <GeneratedQRCode
              url={inviteUrl}
              color={theme.colors.secondary}
              size={200}
            />
          </View>
          <Text style={{ marginVertical: 15 }}>
            eller fyll i den nya användarens nummer nedan.
          </Text>
          <Formik
            initialValues={{
              phone: "",
              organizationId: organizationStore.organizationId,
            }}
            onSubmit={(data, { setSubmitting }) => {
              // console.log("FORMIK DATA: ", data);
              const formData = {
                ...data,
              };
              organizationStore._addUser(formData).then((res) => {
                if (res) {
                  setSubmitting(false);
                  setDone(true);
                } else {
                }
                console.log("CRAP!");
              });
            }}
          >
            {({
              values,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
            }) => (
              <View style={{ alignSelf: "stretch", marginBottom: 15 }}>
                <View style={{ marginBottom: 15 }}>
                  <Input
                    keyboardType="phone-pad"
                    label="Mobilnummer"
                    value={values.phone}
                    // onChangeText={handleChange("phone")}
                    onChangeText={(text) =>
                      setFieldValue("phone", text.replace(/\D/g, ""))
                    }
                    onBlur={handleBlur("phone")}
                  />
                </View>
                <Button
                  title={isSubmitting ? "Bjuder in..." : "Bjud in"}
                  onPress={() => handleSubmit()}
                  disabled={isSubmitting}
                />
              </View>
            )}
          </Formik>
        </>
      )}
    </View>
  );
};

export default AddUserForm;
