import { Formik } from "formik";
import { observer } from "mobx-react";
import React, { useContext, useState } from "react";
import { Picker, View } from "react-native";
import { Button, Input, Text } from "react-native-elements";
import ModalDoneCheck from "../components/modal/ModalDoneCheck";
import { RootStoreContext } from "../stores/RootStore";

interface Props {
  doneAction?: (b: boolean) => void;
}

const PointOfSaleDetailsForm: React.FC<Props> = observer(({ doneAction }) => {
  const { pointOfSaleStore, organizationStore } = useContext(RootStoreContext);
  const { pointOfSale } = pointOfSaleStore;
  const [done, setDone] = useState(false);
  if (done) return <ModalDoneCheck doneAction={doneAction} />;
  return (
    <Formik
      initialValues={{
        name: pointOfSale.name,
        groupId: pointOfSale.groupId,
        autoGroup: pointOfSale.autoGroup

        // id: organizationStore.organization.id,
        // name: organizationStore.organization.name,
        // orgNumber: organizationStore.organization.orgNumber
      }}
      onSubmit={(data, { setSubmitting }) => {
        // console.log("FORMIK DATA: ", data);
        if (pointOfSaleStore._updatePointOfSale(data)) {
          setSubmitting(false);
          setDone(true);
        } else {
          // console.log("CRAP!");
        }
      }}
    >
      {({
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue
      }) => (
        <View>
          <View style={{ paddingVertical: 15 }}>
            <Input
              label="Namn"
              value={values.name}
              onChangeText={handleChange("name")}
              onBlur={handleBlur("name")}
            />
          </View>
          <Text style={{ fontWeight: "bold", marginBottom: 8 }}>
            Kassan tillhör
          </Text>
          <Picker
            selectedValue={values.groupId}
            // style={{ height: 50, width: 100 }}
            onValueChange={(itemValue, itemIndex) =>
              setFieldValue("groupId", itemValue)
            }
          >
            {organizationStore.organization.groups.map(node => (
              <Picker.Item label={node.name} value={node.id} key={node.id} />
            ))}
          </Picker>
          <View style={{ height: 15 }} />
          <Button
            title={isSubmitting ? "Sparar..." : "Spara"}
            onPress={() => handleSubmit()}
            disabled={isSubmitting}
          />
        </View>
      )}
    </Formik>
  );
});

export default PointOfSaleDetailsForm;
