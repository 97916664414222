import { action, observable } from "mobx";
import { persist } from "mobx-persist";
import { API_ULRS } from "../config";
import axios from "../lib/axios";
import { RootStore } from "./RootStore";

export class ReportStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }
  @persist @observable count = 0;
  @persist("object") @observable report: any = {};

  @action
  _getReport = async (reportId): Promise<any> => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${API_ULRS.url}/reports/zreport/${reportId}`)
        .then(res => {
          resolve(res.data.data);
        })
        .catch(error => {
          console.error(error);
          reject(error);
        });
    });
  };
}
