import { LinearGradient } from "expo-linear-gradient";
import React from "react";
interface Props {
  center?: boolean;
  centerV?: boolean;
  centerH?: boolean;
  padding?: number;
  backButton?: boolean;
  backButtonLabel?: string | JSX.Element;
  reverse?: boolean;
}

export const GradientWrapper: React.FC<Props> = ({
  children,
  backButton = false,
  backButtonLabel,
  padding = 30,
  center = true,
  centerH = false,
  centerV = false,
  reverse = false
}) => {
  let centerStyle = {};
  if (centerV) {
    centerStyle = {
      alignItems: "center"
    };
  }
  if (centerH) {
    centerStyle = {
      justifyContent: "center"
    };
  }
  if (center) {
    centerStyle = {
      alignItems: "center",
      justifyContent: "center"
    };
  }

  console.log("BackButton: ", backButton);

  return (
    <LinearGradient
      start={[0, 0]}
      end={[1, 1]}
      colors={reverse ? ["#5FC4E1", "#00A13A"] : ["#00A13A", "#5FC4E1"]}
      style={[
        {
          padding,
          position: "relative",
          flex: 1
        },
        centerStyle
      ]}
    >
      {children}
      {/* {backButton && <BackButton backButtonLabel={backButtonLabel} />} */}
    </LinearGradient>
  );
};
