// import { createIconSet } from "react-native-vector-icons";
// import customFontGlyph from "../../assets/fonts/custom-font-icon.json";

// export default createIconSet(
//   customFontGlyph,
//   "custom-font-icon",
//   "custom-font-icon.ttf"
// );

// import * as React from "react";
// import { createIconSet } from "@expo/vector-icons";

import { createIconSetFromIcoMoon } from "@expo/vector-icons";
import icoMoonConfig from "../../assets/fonts/teampay.json";

const expoAssetId = require("../../assets/fonts/teampay.ttf");
export default createIconSetFromIcoMoon(icoMoonConfig, "TeamPay", expoAssetId);
