import React from "react";
import Icon from "./Icon";

export const TpBag = props => <Icon {...props} name="tp-bag" />;
export const TpBaguette = props => <Icon {...props} name="tp-baguette" />;
export const TpBottle = props => <Icon {...props} name="tp-bottle" />;
export const TpBox = props => <Icon {...props} name="tp-box" />;
export const TpBun = props => <Icon {...props} name="tp-bun" />;
export const TpCalendar = props => <Icon {...props} name="tp-calendar" />;
export const TpCan = props => <Icon {...props} name="tp-can" />;
export const TpCandy = props => <Icon {...props} name="tp-candy" />;
export const TpCandybagLarge = props => (
  <Icon {...props} name="tp-candybag-large" />
);
export const TpCandybag = props => <Icon {...props} name="tp-candybag" />;
export const TpCandybarLarge = props => (
  <Icon {...props} name="tp-candybar-large" />
);
export const TpCandybar = props => <Icon {...props} name="tp-candybar" />;
export const TpChips = props => <Icon {...props} name="tp-chips" />;
export const TpClothing = props => <Icon {...props} name="tp-clothing" />;
export const TpChocolatebar = props => (
  <Icon {...props} name="tp-chocolatebar" />
);
export const TpCoffee = props => <Icon {...props} name="tp-coffee" />;
export const TpColdsandwich = props => (
  <Icon {...props} name="tp-coldsandwich" />
);
export const TpCrate = props => <Icon {...props} name="tp-crate" />;
export const TpDiscountbooklet = props => (
  <Icon {...props} name="tp-discountbooklet" />
);
export const TpEnergydrink = props => <Icon {...props} name="tp-energydrink" />;
export const TpFood = props => <Icon {...props} name="tp-food" />;
export const TpFries = props => <Icon {...props} name="tp-fries" />;
export const TpFruit = props => <Icon {...props} name="tp-fruit" />;
export const TpGamedeal = props => <Icon {...props} name="tp-gamedeal" />;
export const TpGlas = props => <Icon {...props} name="tp-glas" />;
export const TpHamburger = props => <Icon {...props} name="tp-hamburger" />;
export const TpHamburgermeal = props => (
  <Icon {...props} name="tp-hamburgermeal" />
);
export const TpHamburgermealcan = props => (
  <Icon {...props} name="tp-hamburgermealcan" />
);
export const TpHamburgermealglas = props => (
  <Icon {...props} name="tp-hamburgermealglas" />
);
export const TpHotdog = props => <Icon {...props} name="tp-hotdog" />;
export const TpIcecream = props => <Icon {...props} name="tp-icecream" />;
export const TpIconArrowO = props => <Icon {...props} name="tp-icon-arrow-o" />;
export const TpIconArrow = props => <Icon {...props} name="tp-icon-arrow" />;
export const TpIconBarcodeScanner = props => (
  <Icon {...props} name="tp-icon-barcode-scanner" />
);
export const TpIconCheckO = props => <Icon {...props} name="tp-icon-check-o" />;
export const TpIconCheck = props => <Icon {...props} name="tp-icon-check" />;
export const TpIconCloseO = props => <Icon {...props} name="tp-icon-close-o" />;
export const TpIconClose = props => <Icon {...props} name="tp-icon-close" />;
export const TpIconClosedString = props => (
  <Icon {...props} name="tp-icon-closed-string" />
);
export const TpIconClosed = props => <Icon {...props} name="tp-icon-closed" />;
export const TpIconCog = props => <Icon {...props} name="tp-icon-cog" />;
export const TpIconExternalDisplay = props => (
  <Icon {...props} name="tp-icon-external-display" />
);
export const TpIconFilter = props => <Icon {...props} name="tp-icon-filter" />;
export const TpIconFolder = props => <Icon {...props} name="tp-icon-folder" />;
export const TpIconMenu = props => <Icon {...props} name="tp-icon-menu" />;
export const TpIconMinusO = props => <Icon {...props} name="tp-icon-minus-o" />;
export const TpIconMinus = props => <Icon {...props} name="tp-icon-minus" />;
export const TpIconOpenString = props => (
  <Icon {...props} name="tp-icon-open-string" />
);
export const TpIconOpen = props => <Icon {...props} name="tp-icon-open" />;
export const TpIconPlusO = props => <Icon {...props} name="tp-icon-plus-o" />;
export const TpIconPlus = props => <Icon {...props} name="tp-icon-plus" />;
export const TpIconQrScanner = props => (
  <Icon {...props} name="tp-icon-qr-scanner" />
);
export const TpIconRegister = props => (
  <Icon {...props} name="tp-icon-register" />
);
export const TpIconSearch = props => <Icon {...props} name="tp-icon-search" />;
export const TpIconShoppingCart = props => (
  <Icon {...props} name="tp-icon-shopping-cart" />
);
export const TpIconTeam1 = props => <Icon {...props} name="tp-icon-team-1" />;
export const TpIconTeam2 = props => <Icon {...props} name="tp-icon-team-2" />;
export const TpIconTeam3 = props => <Icon {...props} name="tp-icon-team-3" />;
export const TpIconTeam4 = props => <Icon {...props} name="tp-icon-team-4" />;
export const TpIconTeam5 = props => <Icon {...props} name="tp-icon-team-5" />;
export const TpIconTrash = props => <Icon {...props} name="tp-icon-trash" />;
export const TpIconUser = props => <Icon {...props} name="tp-icon-user" />;
export const TpIconUsers = props => <Icon {...props} name="tp-icon-users" />;
export const TpJuice = props => <Icon {...props} name="tp-juice" />;
export const TpLollipop = props => <Icon {...props} name="tp-lollipop" />;
export const TpLotteryticket = props => (
  <Icon {...props} name="tp-lotteryticket" />
);
export const TpMatchprogram = props => (
  <Icon {...props} name="tp-matchprogram" />
);
export const TpMeal = props => <Icon {...props} name="tp-meal" />;
export const TpMealcan = props => <Icon {...props} name="tp-mealcan" />;
export const TpMealglas = props => <Icon {...props} name="tp-mealglas" />;
export const TpParking = props => <Icon {...props} name="tp-parking" />;
export const TpPizza = props => <Icon {...props} name="tp-pizza" />;
export const TpPopcorn = props => <Icon {...props} name="tp-popcorn" />;
export const TpSallad = props => <Icon {...props} name="tp-sallad" />;
export const TpShopping = props => <Icon {...props} name="tp-shopping" />;
export const TpTea = props => <Icon {...props} name="tp-tea" />;
export const TpTicket = props => <Icon {...props} name="tp-ticket" />;
export const TpWarmsandwich = props => (
  <Icon {...props} name="tp-warmsandwich" />
);
