import { Formik } from "formik";
import React, { useContext, useState } from "react";
import { Switch, View } from "react-native";
import { Button, Input, Text } from "react-native-elements";
import ModalDoneCheck from "../components/modal/ModalDoneCheck";
import { API_ULRS } from "../config";
import axios from "../lib/axios";
import { RootStoreContext } from "../stores/RootStore";

interface Props {
  doneAction?: (b: boolean) => void;
}

const PaymentMethodModal: React.FC<Props> = ({ doneAction }) => {
  const [done, setDone] = useState(false);
  const { pointOfSaleStore, organizationStore } = useContext(RootStoreContext);

  if (done) return <ModalDoneCheck doneAction={doneAction} />;
  // console.log("PMs:");
  // console.log(pointOfSaleStore.pointOfSale.session.paymentMethods);
  const pms = pointOfSaleStore.pointOfSale.session.paymentMethods.slice();
  const currentSwish = pms.filter((pm) => pm.paymentMethodKey === "swish")[0];
  const currentCash = pms.filter((pm) => pm.paymentMethodKey === "cash")[0];
  const currentCard = pms.filter(
    (pm) => pm.paymentMethodKey === "externalcard"
  )[0];

  return (
    <Formik
      initialValues={{
        pmCashId: currentCash ? currentCash.id : "",
        pmCash: !!currentCash,

        // pms.filter(pm => pm.paymentMethodKey === "cash").length > 0
        //   ? true
        //   : false,
        pmSwishId: currentSwish ? currentSwish.id : "",
        pmSwish: !!currentSwish,
        pmSwishRecipient: currentSwish ? currentSwish.paymentDetails : "",
        // pms.filter(pm => pm.paymentMethodKey === "swish").length > 0
        //   ? true
        //   : false,
        pmExternalId: currentCard ? currentCard.id : "",
        pmExternal: !!currentCard,
        // pms.filter(pm => pm.paymentMethodKey === "externalcard").length > 0
        //   ? true
        //   : false,
        // pmSwishRecipient: ""
        // pms.filter(pm => pm.paymentMethodKey === "swish")[0].paymentDetails ||
        // ""
        // type: item.type,
      }}
      onSubmit={(data, { setSubmitting }) => {
        if (data.pmCash) {
          if (!!currentCash) {
          } else {
            axios.post(
              `${API_ULRS.url}/organization/${organizationStore.organizationId}/pointofsale/${pointOfSaleStore.pointofSaleId}/paymentmethodsession/add`,
              JSON.stringify({ method: "cash" })
            );
          }
        } else {
          if (!!currentCash) {
            axios
              .delete(
                `${API_ULRS.url}/organization/${organizationStore.organizationId}/pointofsale/${pointOfSaleStore.pointofSaleId}/paymentmethodsession/cash`
              )
              .then((res) => {
                console.log("tog bort cash");
              });
          }
        }
        // if

        if (data.pmSwish) {
          // aktivera/iuppdatera swish
          if (!!currentSwish) {
            // uppdatera swish
            axios.put(
              `${API_ULRS.url}/organization/${organizationStore.organizationId}/pointofsale/${pointOfSaleStore.pointofSaleId}/paymentmethodsession/swish`,
              JSON.stringify({
                recipient: data.pmSwishRecipient,
              })
            );
          } else {
            // lägga dit swih
            axios.post(
              `${API_ULRS.url}/organization/${organizationStore.organizationId}/pointofsale/${pointOfSaleStore.pointofSaleId}/paymentmethodsession/add`,
              JSON.stringify({
                method: "swish",
                recipient: data.pmSwishRecipient,
              })
            );
          }
        } else {
          // ta bort swish om det finns
          if (!!currentSwish) {
            axios
              .delete(
                `${API_ULRS.url}/organization/${organizationStore.organizationId}/pointofsale/${pointOfSaleStore.pointofSaleId}/paymentmethodsession/swish`
              )
              .then((res) => {
                console.log("tog bort swish");
              });
          }
        }

        if (data.pmExternal) {
          if (!!currentCard) {
          } else {
            axios.post(
              `${API_ULRS.url}/organization/${organizationStore.organizationId}/pointofsale/${pointOfSaleStore.pointofSaleId}/paymentmethodsession/add`,
              JSON.stringify({ method: "externalcard" })
            );
          }
        } else {
          if (!!currentCard) {
            axios
              .delete(
                `${API_ULRS.url}/organization/${organizationStore.organizationId}/pointofsale/${pointOfSaleStore.pointofSaleId}/paymentmethodsession/externalcard`
              )
              .then((res) => {
                console.log("tog bort kort");
              });
          }
        }
        // refresh session
        setTimeout(() => {
          pointOfSaleStore._refetchPointOfSale();
          setSubmitting(false);
          setDone(true);
        }, 300);
      }}
    >
      {({
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue,
      }) => (
        <View>
          <Text h3>Betalsätt</Text>
          <Text style={{ marginVertical: 15 }}></Text>
          {/* <Text style={{ fontWeight: "bold", marginBottom: 15 }}>
            Aktiva betalsätt
          </Text>

          {pms.map((pm, idx) => {
            if (pm.paymentMethodKey === "cash")
              return (
                <>
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center"
                    }}
                  >
                    <Text>Kontanter</Text>
                    <TouchableOpacity>
                      <TpIconTrash
                        color="rgba(0,0,0,0.3)"
                        style={{ marginRight: 5 }}
                      />
                    </TouchableOpacity>
                  </View>
                </>
              );
          })}

          <Text style={{ fontWeight: "bold", marginBottom: 15 }}>
            Lägg till betalsätt
          </Text> */}
          {/* {!values.pmSwish && (
            <>
              <Text>Lägg till swish</Text>
              <View style={{ marginBottom: 15 }}>
                <Text style={{ fontWeight: "bold" }}>
                  Swishnummer{" "}
                  <Text style={{ fontWeight: "normal" }}>
                    som ska ta emot betalningar
                  </Text>
                </Text>
                <Input
                  value={values.pmSwishRecipient}
                  onChangeText={text => {
                    setFieldValue("pmSwishRecipient", text);
                  }}
                />
              </View>
            </>
          )} */}
          <View style={{ flexDirection: "row", marginBottom: 15 }}>
            <Switch
              value={values.pmCash}
              onValueChange={(value) => setFieldValue("pmCash", value)}
            />
            <Text style={{ marginLeft: 20 }}>Kontanter</Text>
          </View>

          <View style={{ flexDirection: "row", marginBottom: 15 }}>
            <Switch
              value={values.pmSwish}
              onValueChange={(value) => setFieldValue("pmSwish", value)}
            />
            <Text style={{ marginLeft: 20 }}>Swish</Text>
          </View>
          {values.pmSwish && (
            <View style={{ marginBottom: 15 }}>
              <Text style={{ fontWeight: "bold" }}>
                Swishnummer{" "}
                <Text style={{ fontWeight: "normal" }}>
                  som ska ta emot betalningar
                </Text>
              </Text>
              <Input
                value={values.pmSwishRecipient}
                onChangeText={(text) => {
                  setFieldValue("pmSwishRecipient", text);
                }}
              />
            </View>
          )}

          <View style={{ flexDirection: "row", marginBottom: 15 }}>
            <Switch
              value={values.pmExternal}
              onValueChange={(value) => setFieldValue("pmExternal", value)}
            />
            <Text style={{ marginLeft: 20 }}>Extern kortläsare</Text>
          </View>

          <Button
            title={isSubmitting ? "Sparar..." : "Spara"}
            onPress={() => handleSubmit()}
            disabled={isSubmitting}
          />
        </View>
      )}
    </Formik>
  );
};
export default PaymentMethodModal;
