import { action, observable } from "mobx";
import { persist } from "mobx-persist";
import { API_ULRS, DEBUG_PRIVILEGES } from "../config";
import axios from "../lib/axios";
import { Group } from "../lib/types/users/group";
import { Organization } from "../lib/types/users/organization";
import { sortByName } from "../lib/utils/sort";
import { RootStore } from "./RootStore";
export class OrganizationStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @persist @observable organizationId: string | null;
  @persist("object") @observable organization: Organization | null;
  @observable organizationStoreLoading: boolean = false;

  @observable organizationGroups = (): Group[] | null => {
    if (!this.organization.groups) return null;
    return this.organization.groups.sort(sortByName);
  };

  @observable organizationAdmins: any[] | null;

  @action
  _fetchOrganization = () => {
    this.organizationStoreLoading = true;
    const reqUrl = `${API_ULRS.url}/organization/${this.organizationId}`;
    // // try {
    //+ // console.log("sending request: ", reqUrl);
    axios.get(reqUrl).then((res) => {
      this.organization = res.data.data;
      // this._getAllOrganizationAdmins().then(
      //   res => (this.organizationAdmins = res)
      // );
      // console.log(res.data.data);
      this.organizationStoreLoading = false;
    });

    if (DEBUG_PRIVILEGES) {
      axios
        .get(`${API_ULRS.url}/privileges/${this.organizationId}`)
        .then((res) => {
          console.log("ORG PRIVILEGES", res.data.data);
        });
    }

    if (DEBUG_PRIVILEGES && this.organization.groups.length) {
      this.organization.groups.map((group) => {
        axios.get(`${API_ULRS.url}/privileges/${group.id}`).then((res) => {
          console.log(`GROUP PRIVILEGES ${group.name}: `, res.data.data);
        });
      });
    }
  };

  @action
  _refetchOrganization = () => {
    const reqUrl = `${API_ULRS.url}/organization/${this.organizationId}`;
    axios.get(reqUrl).then((res) => {
      this.organization = res.data.data;
      // this._getAllOrganizationAdmins().then(
      //   res => (this.organizationAdmins = res)
      // );
    });
  };

  @action
  _updateOrganization = async (data) => {
    const reqUrl = `${API_ULRS.url}/organization/${this.organizationId}`;
    this.organizationStoreLoading = true;
    axios
      .post(reqUrl, data)
      .then((res) => {
        this.organization = res.data.data;
        this.organizationStoreLoading = false;

        return true;
      })
      .catch((error) => {
        console.error(error);
        return false;
      });
  };

  @action
  _createOrganization = async (values) => {
    // let history = useHistory();
    try {
      // IF there is a logo, upload it
      console.log("LOGODATA:", values.logo);
      console.log(
        "LOGODATA SENT:",
        JSON.stringify({
          fileData: values.logo || null,
        })
      );
      let logoData;
      if (!!values.logo) {
        const { data: logoUploadData } = await axios.post(
          `${API_ULRS.url}/upload/file`,
          JSON.stringify({
            fileData: values.logo || null,
          })
        );
        console.log("IMAGE-DATA I GOT: ", logoUploadData);
        logoData = logoUploadData;
      }
      let orgValues = { ...values };
      if (logoData && logoData.id)
        orgValues = {
          ...values,
          logoFileId: logoData.id,
        };

      // 1. Create the organization
      const { data: orgData } = await axios.post(
        `${API_ULRS.url}/organization/create/`,
        JSON.stringify({ ...orgValues })
      );
      console.log("ORG-DATA I GOT: ", orgData);

      if (values.pm_description || values.pm_recipient) {
        const { data: pmData } = await axios.post(
          `${API_ULRS.url}/organization/${orgData.data.id}/paymentmethod/add`,
          JSON.stringify({
            method: "swish",
            recipient: values.pm_recipient,
            description: values.pm_description,
          })
        );
        console.log("PM-DATA I GOT: ", pmData);
      }

      if (values.selectedTemplate) {
        // 2. Create the point of sale
        const { data: posData } = await axios.post(
          `${API_ULRS.url}/organization/${orgData.data.id}/pointofsale`,
          JSON.stringify({
            name: values.selectedTemplate.name,
            items: values.selectedTemplate.items,
          })
        );
        console.log("POS-DATA I GOT: ", posData);

        console.log("ITEMS I'm SENDING: ", values.selectedTemplate.items);

        // 3. Create items in pos
        const { data: itemData } = await axios.post(
          `${API_ULRS.url}/organization/${orgData.data.id}/pointofsale/${posData.data.id}/createItems`,
          JSON.stringify({
            items: values.selectedTemplate.items,
          })
        );
        console.log("ITEM DATA I GOT: ", itemData);
      }
      // values.selectedTemplate.items.map(async item => {
      //   const { data: itemData } = await axios.post(
      //     `${tpConfig.apiUrl}/organization/${orgData.data.id}/pointofsale/${posData.data.id}/createItem`,
      //     JSON.stringify(item)
      //   );
      //   console.log("ITE<-DATA I GOT: ", itemData);
      // });

      console.log("DONE! Let's go");
      // history.push(`/home/${orgData.data.id}`);

      // if (orgData && posData && itemData) {
      if (orgData) {
        this.organizationId = orgData.data.id;
        this.rootStore.userStore._refetchUser();
        return orgData.data.id;
      }
      // 2. Uplaod the logo
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  @action
  _addUser = async (data, addAdmin = false): Promise<boolean> => {
    return new Promise((resolve, reject) => {
      console.log("ADD USER DATA: ", data);

      // 1. lägg till användaren i gruppen
      const addReqUrl = `${API_ULRS.url}/users/createorupdate`;
      let addReqData = {};

      if (data.role === "Admin") {
        addReqData = {
          groupId: data.groupId || null,
          phone: data.phone,
          role: data.role === "Admin" ? "Admin" : null,
        };
      } else {
        addReqData = {
          organizationId: this.organizationId || null,
          phone: data.phone,
        };
      }

      console.log("POSTDATA: ", addReqData);
      try {
        axios.post(addReqUrl, JSON.stringify(addReqData)).then((res) => {
          // 2. skicka sms

          // ADD ADMIN IF APPLICAABLE
          if (addAdmin) {
            axios.post(
              `${API_ULRS.url}/organization/${data.adminOrganizationId}/updateuser/${res.data.data.user.id}`,
              JSON.stringify({ role: "Admin" })
            );
            this._refetchOrganization();
          }

          // Alert user
          const reqUrl = `${API_ULRS.proxybase}/sendsms`;
          const reqData = {
            recipient: data.phone,
            string: `Hej! ${this.organization.name} har bjudit in dig att använda Teampay.
Anslut via ${API_ULRS.app}/invited/${this.organization.id}`,
          };

          try {
            axios.post(reqUrl, JSON.stringify(reqData)).then((res) => {
              // console.log("SMS RES: ", res);
              // if (res.status === 200) {
              // } else {
              //   reject(false);
              // }
            });
            this._refetchOrganization();
            resolve(true);
          } catch (error) {
            console.log(error);
            reject(false);
          }
        });
      } catch (error) {
        console.error(error);
        reject(false);
      }
    });
  };

  @action
  _returnGroupNameFromId = (groupID): Group | null => {
    let group = this.organization.groups.filter((g) => {
      // console.log("IN THE FILTER", g, "GID: ", groupID);
      return g.id == groupID.id;
    });

    return group[0] || null;
    // console.log("group", group);
  };

  @action
  _addPaymentMethodToOrganization = async (data): Promise<boolean> => {
    const reqUrl = `${API_ULRS.url}/organization/${this.rootStore.organizationStore.organizationId}/paymentmethod/add`;
    return new Promise((resolve, reject) => {
      axios
        .post(reqUrl, JSON.stringify(data))
        .then((res) => {
          // console.log("TRANSACTIONID: ", res.data.key);
          this._refetchOrganization();
          resolve(true);
        })
        .catch((err) => {
          console.error(err);
          reject(false);
        });
    });
  };

  _removePaymentMethodFromOrganization = async (pmId): Promise<boolean> => {
    const reqUrl = `${API_ULRS.url}/organization/${this.organizationId}/paymentmethod/${pmId}`;
    return new Promise((resolve, reject) => {
      axios
        .delete(reqUrl)
        .then((res) => {
          this._refetchOrganization();
          resolve(true);
        })
        .catch((err) => {
          console.error(err);
          reject(false);
        });
    });
  };

  @action
  _updatePaymentMethodInOrganization = async (data): Promise<boolean> => {
    const reqUrl = `${API_ULRS.url}/organization/${this.rootStore.organizationStore.organizationId}/paymentmethod/${data.pmId}`;
    return new Promise((resolve, reject) => {
      axios
        .post(reqUrl, JSON.stringify(data))
        .then((res) => {
          // console.log("TRANSACTIONID: ", res.data.key);
          this._refetchOrganization();
          resolve(true);
        })
        .catch((err) => {
          console.error(err);
          reject(false);
        });
    });
  };

  @action
  _deleteGroup = async (data): Promise<boolean> => {
    const reqUrl = `${API_ULRS.url}/organization/${this.rootStore.organizationStore.organizationId}/groups/${data}`;
    return new Promise((resolve, reject) => {
      axios
        .delete(reqUrl)
        .then((res) => {
          // console.log("TRANSACTIONID: ", res.data.key);
          this._refetchOrganization();
          resolve(true);
        })
        .catch((err) => {
          console.error(err);
          reject(false);
        });
    });
  };

  @action
  _getPosNameListAsString = (idArr: string[]): string => {
    let returnString = "";
    let returnCount = 0;
    idArr.map((posId) => {
      const pos = this.organization.pointOfSales.filter(
        (pos) => pos.id === posId
      );
      returnString += returnCount ? `, ${pos[0].name}` : pos[0].name;
      returnCount++;
    });
    return returnString;
  };

  @observable
  _getAllOrganizationAdmins = (): Promise<any | null> => {
    return new Promise((resolve, reject) => {
      try {
        let allGroupsAdmins = [];

        if (this.organization.groups && this.organization.groups.length > 0) {
          Promise.all(
            this.organization.groups.slice().map((group, idx) => {
              axios
                .get(
                  `${API_ULRS.url}/organization/${this.organizationId}/groups/${group.id}`
                )
                .then(
                  ({
                    data: {
                      data: { members },
                    },
                  }) => {
                    if (members) {
                      const tmpResponse = [];
                      // loop through groumembers and find admins
                      // console.log("FUNC MEMBERS: ", members);
                      members.map((member) => {
                        if (member.role === "Admin") {
                          console.log("ADMIN TO ADD: ", member);
                          tmpResponse.push({
                            userName: member.user.name,

                            userId: member.userId,
                            userRole: member.role,
                            userMobile: member.user.mobileNumber,
                            userEmail: member.user.emailAddress,
                          });
                        }
                      });
                      // add tmp array to resp;
                      if (tmpResponse.length > 0) {
                        allGroupsAdmins.push({
                          groupId: group.id,
                          title: group.name,
                          data: tmpResponse,
                        });
                      }
                    }
                  }
                );
            })
          ).then((res) => {
            console.log("APREZ PROMISE ALL: ", res);
            this.organizationAdmins = allGroupsAdmins;
            resolve(allGroupsAdmins);
          });
        } else {
          // no groups is impossible
          reject(false);
        }
      } catch (error) {
        console.error(error);
        reject(false);
      }

      // or shortcircut
    });
  };

  @action
  _removeUserFromGroup = (groupId, userId) => {
    let reqUrl = `${API_ULRS.url}/organization/${this.organizationId}/groups/${groupId}/removeuser/${userId}`;

    axios.get(reqUrl).then((res) => {
      console.log("REMOVE REQUEST RESPONSE: ", res);
      this._refetchOrganization();
    });
  };

  @action
  _sendPrintMaterial = (email = "developer@teampay.se") => {
    axios.post(
      `https://tp-com.dev.teampay.se/sendprintmaterial/${this.organizationId}`,
      { email }
    );
    // let reqUrl = `https://api.sendgrid.com/v3/mail/send`;

    // axios
    //   .post(
    //     reqUrl,
    //     {
    //       templateId: " d-0d08faffba9f4aa7bc000133022db19d",
    //       personalizations: [
    //         {
    //           to: [
    //             {
    //               email,
    //             },
    //           ],
    //           subject: "Ditt printmaterial!",
    //         },
    //         // substitutions: {
    //         //   ""
    //         // }
    //       ],
    //       from: {
    //         email: "noreply@teampay.se",
    //       },
    //     },
    //     {
    //       headers: {
    //         Authorization:
    //           "Bearer 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",
    //       },
    //     }
    //   )
    //   .then((res) => {
    //     console.log(res);
    //   });
  };

  @action
  _groupOrSuperAdmin = (userId) => {
    let res: null | "group" | "super" = null;

    this.organization.groups.map((group) => {
      console.log(`checking ${group.name} | ${group.id}`);

      const uig = group.userInGroups.filter((uig) => uig.userId === userId);
      console.log("UIG:", uig);
      console.log("UIG length", uig.length);

      if (uig && uig.length > 0) {
        if (uig[0].role === "Admin") {
          // check if everyonegorup
          if (group.id === this.organization.everyoneGroupId) {
            res = "super";
            return res;
          } else {
            if (res !== "super") res = "group";
          }
        }
      }
    });

    return res;
  };
}

// export const OrganizationStoreContext = createContext(new OrganizationStore());
