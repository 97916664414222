import { Formik } from "formik";
import React, { useContext, useState } from "react";
import { View } from "react-native";
import { Button, Input, Text } from "react-native-elements";
import ModalDoneCheck from "../components/modal/ModalDoneCheck";
import { RootStoreContext } from "../stores/RootStore";

interface UserProfileFormProps {
  doneAction?: (b: boolean) => void;
  forcedUpdate?: boolean;
}

const UserProfileForm: React.FC<UserProfileFormProps> = ({
  doneAction,
  forcedUpdate = false
}) => {
  const [done, setDone] = useState(false);
  const { userStore } = useContext(RootStoreContext);
  const { user } = userStore;

  if (done) {
    return <ModalDoneCheck doneAction={doneAction} />;
  }

  return (
    <Formik
      initialValues={{
        firstName: user.firstName,
        lastName: user.lastName,
        emailAddress: user.emailAddress,
        mobileNumber: user.mobileNumber
      }}
      onSubmit={(data, { setSubmitting }) => {
        if (userStore._updateUser(data)) {
          setSubmitting(false);
          setDone(true);
        }
      }}
    >
      {({
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue
      }) => (
        <View>
          {forcedUpdate ? (
            <>
              <Text h3>Hej främling!</Text>
              <Text style={{ marginVertical: 15 }}>
                För att göra det lättare för din förening att veta vem som är
                vem skulle vi vilja att du fyllde i resten av dina uppgifter här
                under.
              </Text>
            </>
          ) : (
            <Text h3>Redigera profil</Text>
          )}
          <View style={{ paddingVertical: 15 }}>
            <Input
              label="Förnamn"
              value={values.firstName}
              onChangeText={handleChange("firstName")}
              onBlur={handleBlur("firstName")}
            />
          </View>
          <View>
            <Input
              label="Efternamn"
              value={values.lastName}
              onChangeText={handleChange("lastName")}
              onBlur={handleBlur("lastName")}
            />
          </View>
          <View style={{ paddingVertical: 15 }}>
            <Input
              label="Mobilnummer"
              value={values.mobileNumber}
              onChangeText={handleChange("mobileNumber")}
              onBlur={handleBlur("mobileNumber")}
            />
          </View>
          <View style={{ paddingBottom: 15 }}>
            <Input
              label="Epostadress"
              value={values.emailAddress}
              onChangeText={handleChange("emailAddress")}
              onBlur={handleBlur("emailAddress")}
            />
          </View>
          <Button
            title={isSubmitting ? "Sparar.." : "Spara"}
            onPress={() => handleSubmit()}
            disabled={isSubmitting}
          />
        </View>
      )}
    </Formik>
  );
};

export default UserProfileForm;
