import { observer } from "mobx-react";
import React, { useContext } from "react";
import { Redirect, RouteComponentProps } from "react-router";
import { APP_PATHS } from "../config";
import BasketContainer from "../containers/register/BasketContainer";
import PaymentContainer from "../containers/register/PaymentContainer";
import RecieptContainer from "../containers/register/RecieptContainer";
import RegsiterContainer from "../containers/register/RegsiterContainer";
import ReturnContainer from "../containers/register/ReturnContainer";
import { RegisterSteps } from "../stores/RegisterStore";
import { RootStoreContext } from "../stores/RootStore";

interface RegisterProps extends RouteComponentProps {}
const RegisterScreen: React.FC<RegisterProps> = observer(({ history }) => {
  const { pointOfSaleStore, registerStore } = useContext(RootStoreContext);

  if (
    !pointOfSaleStore.pointofSaleId ||
    !pointOfSaleStore.pointOfSale.session
  ) {
    return <Redirect to={APP_PATHS.organization.home} />;
  }

  let toRender;
  // console.log(registerStore.currentStep);
  switch (registerStore.currentStep) {
    // BASKET
    case RegisterSteps.Basket:
      toRender = <BasketContainer />;
      break;

    // PAYMENT
    case RegisterSteps.Payment:
      toRender = <PaymentContainer />;
      break;

    // Return
    case RegisterSteps.Return:
      toRender = <ReturnContainer />;
      break;

    // RECIEPT
    case RegisterSteps.Reciept:
      toRender = <RecieptContainer />;
      break;

    // SHOPPING
    default:
      toRender = (
        <RegsiterContainer />
        // <>
        //   <Button
        //     title="x"
        //   />
        //   <View
        //     style={{ flexDirection: "row", justifyContent: "space-between" }}
        //   >
        //     <Button
        //       title="Insättning"
        //       type="outline"
        //       onPress={() => setShowCashInModal(true)}
        //     />
        //     <Button
        //       title="Uttag"
        //       type="outline"
        //       onPress={() => setShowCashOutModal(true)}
        //     />
        //   </View>
        // </>
      );
  }

  return <>{toRender}</>;
});

export default RegisterScreen;
