import { Theme } from "react-native-elements";
import { elevationShadowStyle } from "../utils/elevationShadowStyle";
import { theme } from "./theme";

export const reactNativeElementsTheme: Theme = {
  colors: {
    primary: theme.colors.primary
  },

  Button: {
    titleStyle: {
      textTransform: "uppercase",
      fontSize: 12
    },
    buttonStyle: {
      display: "flex",
      height: 40,
      paddingHorizontal: 20,
      borderRadius: 25,
      justifyContent: "center",
      alignItems: "center",

      ...elevationShadowStyle(10, theme.colors.primary)
    }
  }
};
