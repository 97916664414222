import React from "react";
import { View } from "react-native";

interface Props {}

export const Header: React.FC<Props> = ({ children }) => {
  return (
    <View
      style={{
        height: 60,
        // backgroundColor: "red",
        flexDirection: "row",
        alignItems: "center"
      }}
    >
      {children}
    </View>
  );
};
