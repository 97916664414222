import React from "react";
import { View } from "react-native";

interface Props {
  row?: boolean;
}

export const Footer: React.FC<Props> = ({ children, row = true }) => {
  if (!row) {
    return <View style={{ height: 60 }}>{children}</View>;
  }
  return (
    <View style={{ height: 60 }}>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          alignSelf: "stretch",
          paddingHorizontal: 15
        }}
      >
        {children}
      </View>
    </View>
  );
};

// Example Children Content
// ************
// <View>
//   <Button title="kassor" onPress={() => null} />
// </View>
// <View>
//   <Button outline title="grupper" onPress={() => null} />
// </View>
