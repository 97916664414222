import React from "react";
import { ScrollView, View } from "react-native";

interface Props {
  center?: boolean;
  centerV?: boolean;
  centerH?: boolean;
}

const useCenterStyle = (center?, centerH?, centerV?) => {
  let centerStyle = {};
  if (centerH) {
    centerStyle = {
      alignItems: "center"
    };
  }
  if (centerV) {
    centerStyle = {
      justifyContent: "center"
    };
  }
  if (center) {
    centerStyle = {
      alignItems: "center",
      justifyContent: "center"
    };
  }

  return centerStyle;
};

// General content, non scrollable
export const Content: React.FC<Props> = ({
  children,
  center,
  centerH,
  centerV
}) => {
  const centerStyle = useCenterStyle(center, centerH, centerV);
  return <View style={[{ flexGrow: 1 }, centerStyle]}>{children}</View>;
};

// Scrollable conent
export const ContentScroll: React.FC<Props> = ({
  children,
  center,
  centerH,
  centerV
}) => {
  const centerStyle = useCenterStyle(center, centerH, centerV);
  return (
    <ScrollView style={[{ flexGrow: 1, padding: 15 }, centerStyle]}>
      {children}
    </ScrollView>
  );
};

// FIXED content. Doesn't shrink but adjusts to own children
// Example content
// ***********
// <View style={{ alignSelf: "flex-end" }}>
//   <Button title="test" onPress={() => null} />
//   <Button title="test" onPress={() => null} />
// </View>;
export const ContentFixed: React.FC<Props> = ({
  children,
  center,
  centerH,
  centerV
}) => {
  const centerStyle = useCenterStyle(center, centerH, centerV);
  return (
    <View
      style={[
        {
          flexShrink: 0
          // backgroundColor: "purple"
        },
        centerStyle
      ]}
    >
      {children}
    </View>
  );
};
