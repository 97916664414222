import React, { ReactNode, useState } from "react";
import { ActivityIndicator, View } from "react-native";
import { Button, Text } from "react-native-elements";

interface StepProps {
  history?: any;
  currentIndex: number;
  isLast: boolean;
  nextStep: () => void;
  prevStep: () => void;
  onSubmit: ({}: any) => void | {};
  afterSubmit: (s: string) => void;
  onChangeValue: (n: string, v: any) => void;
  backLabel: string;
  nextLabel: string;
  finishLabel: string;
  values: any;
  children: ({}) => void;
  errComponent: ReactNode | null;
}
export const DefaultWizardStep: React.FC<StepProps> = (props) => {
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState(false);
  // const { children } = props;
  console.log("STEPPROPS: ", props);

  return (
    <View style={{ flex: 1 }}>
      {/* <View
        style={{
          flexDirection: "row",
          zIndex: 0,
          height: 30,
          position: "relative",
          alignSelf: "stretch",
          justifyContent: "space-between",
          backgroundColor: "rgba(200,200,200,0.5)"
        }}
      >
        {React.Children.count(props.children)}
        {React.Children.map(props.children, (child, idx) => {
          console.log("But I'm a child!");
          return (
            <View
              style={{
                width: 10,
                height: 10,
                borderRadius: 5,
                backgroundColor: "#000"
              }}
            ></View>
          );
        })}
      </View> */}
      {err &&
        (!!props.errComponent ? (
          props.errComponent
        ) : (
          <>
            <Text>Ooops!</Text>
          </>
        ))}
      {!err &&
        props.children({
          onChangeValue: props.onChangeValue,
          currentIndex: props.currentIndex,
          nextStep: props.nextStep,
          values: props.values,
        })}
      {loading ? (
        <View>
          <ActivityIndicator />
        </View>
      ) : (
        <View
          style={{
            marginTop: 30,
            flexDirection: "row",
            alignSelf: "stretch",
            alignItems: "flex-end",
            justifyContent:
              props.currentIndex === 0 ? "flex-end" : "space-between",
          }}
        >
          {props.currentIndex > 0 && (
            <View>
              <Button
                type="outline"
                title={props.backLabel || "Bakåt"}
                onPress={props.prevStep}
              />
            </View>
          )}
          {!props.isLast && (
            <View>
              <Button
                title={props.nextLabel || "Nästa"}
                onPress={props.nextStep}
              />
            </View>
          )}
          {props.isLast && !err && (
            <View>
              <Button
                title={props.finishLabel || "Spara!"}
                onPress={async () => {
                  setLoading(true);
                  const complete = await props.onSubmit(props.values);
                  if (complete) {
                    console.log("Klart!");
                    setLoading(false);
                    props.afterSubmit(`${complete}`);
                  } else {
                    console.log("Inte klart!");
                    setLoading(false);
                    // props.prevStep();
                    setErr(true);
                  }
                }}
              />
            </View>
          )}
        </View>
      )}
    </View>
  );
};
