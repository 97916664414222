import React, { useContext, useEffect, useState } from "react";
import { View } from "react-native";
import { Button, Text } from "react-native-elements";
import RegisterHeader from "../../components/register/RegisterHeader";
import { RegisterSteps } from "../../stores/RegisterStore";
import { RootStoreContext } from "../../stores/RootStore";

const ReturnContainer = () => {
  const { registerStore } = useContext(RootStoreContext);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const initAsync = async () => {
      registerStore.paymentMethod = {
        id: "",
        paymentMethodId: "",
        paymentDetails: "",
        paymentMethodKey: "cash"
      };
      // registerStore
      //   ._getTransactionId()
      //   .then(() => registerStore._createOrder().then(() => setLoading(false)));
    };

    initAsync();
  }, []);

  return (
    <>
      <RegisterHeader />
      <View
        style={{
          flex: 1,
          alignItems: "center",
          justifyContent: "space-between"
        }}
      >
        <View style={{ alignSelf: "stretch", justifyContent: "center" }}>
          <View
            style={{
              paddingHorizontal: 15,
              justifyContent: "space-between",
              alignContent: "center",
              flexDirection: "row"
            }}
          >
            {/* <Button
              type="outline"
              title="Avbryt återköp!"
              onPress={() => registerStore._emptyCart()}
            /> */}
          </View>
          <View style={{ alignSelf: "center" }}>
            <View
              style={{
                marginTop: 30,
                paddingHorizontal: 30,
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <Text style={{ marginBottom: 15 }}>Lämna tillbaka</Text>
              <Text h1 style={{ marginVertical: 15 }}>
                {/* {registerStore.totalSum} kr */}
                {Math.abs(registerStore.totalSum)} kr
              </Text>
              <Text style={{ marginVertical: 15 }}>i kontanter. </Text>
              <Text style={{ marginTop: 15, textAlign: "center" }}>
                Om kontanter inte finns tillgängliga, vänligen ta tillräckligt
                med detaljer för att kunna genomföra återköp elektroniskt vid
                ett senare tillfälle.
              </Text>
            </View>
          </View>
        </View>

        <View style={{ paddingVertical: 15 }}>
          <Button
            disabled={loading}
            title="Genomför återköp"
            onPress={() => {
              // if (registerStore._settleOrder()) {
              registerStore.currentStep = RegisterSteps.Reciept;
              // } else {
              // console.log("Uh uh!");
              // }
            }}
          />
        </View>
      </View>
    </>
  );
};

export default ReturnContainer;

{
  /*
<View
      style={{
        flex: 1,
        alignItems: "center",
        justifyContent: "center"
      }}
    >
      <Text h1>Bekräfta återköp!</Text>
      <Button
        type="outline"
        title="Avbryt"
        onPress={() => registerStore._emptyCart()}
      />
      <Button
        disabled={loading}
        title="Nästa"
        onPress={() => {
          if (registerStore._settleOrder()) {
            registerStore.currentStep = RegisterSteps.Reciept;
          } else {
            // console.log("Uh uh!");
          }
        }}
      />
    </View> */
}
