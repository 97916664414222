import React from "react";
import { TouchableOpacity, View } from "react-native";
import { Text } from "react-native-elements";
import { useHistory } from "react-router";
import { TpIconArrow } from "../Icon";

interface Props {
  path: string;
  title: string;
  subtitle: string;
}

const SettingsRow: React.FC<Props> = ({ path, title, subtitle }) => {
  const history = useHistory();
  return (
    <TouchableOpacity
      onPress={() => history.push(path)}
      style={{ marginVertical: 10 }}
    >
      <View
        style={{
          flexDirection: "row",
          alignSelf: "stretch",
          alignItems: "center",
          justifyContent: "space-between"
        }}
      >
        <View>
          <Text style={{ fontWeight: "bold" }}>{title}</Text>
          <Text style={{ fontStyle: "italic" }}>{subtitle}</Text>
        </View>
        <TpIconArrow size={18} />
      </View>
    </TouchableOpacity>
  );
};

export default SettingsRow;
