import { observer } from "mobx-react";
import React, { useContext } from "react";
import { Image } from "react-native";
import { RouteComponentProps } from "react-router";
import BackButton from "../components/common/BackButton";
import { HeaderTitleSubtitle } from "../components/headerItems/HeaderTitleSubtitle";
import { LargeHeaderIcon } from "../components/headerItems/LargeHeaderIcon";
import { TpIconTeam3 } from "../components/Icon";
import { ContentScroll } from "../components/layout/Content";
import { FullPageWrapper } from "../components/layout/FullPageWrapper";
import { Header } from "../components/layout/Header";
import { APP_PATHS } from "../config";
import PointOfSaleDetailsForm from "../containers/PointOfSaleDetailsForm";
import { RootStoreContext } from "../stores/RootStore";

interface Props extends RouteComponentProps {}

const PointOfSaleSettingsDetailsScreen: React.FC<Props> = observer(
  ({ history }) => {
    const { organizationStore, pointOfSaleStore } = useContext(
      RootStoreContext
    );

    let orgLogo;
    if (organizationStore.organization) {
      if (organizationStore.organization.logoUrl128x128) {
        orgLogo = (
          <Image
            style={{ width: 64, height: 64 }}
            source={{
              uri: organizationStore.organization.logoUrl128x128.replace(
                "http:",
                "https:"
              )
            }}
          />
        );
      } else {
        orgLogo = <TpIconTeam3 size={64} />;
      }
    }

    return (
      <FullPageWrapper>
        <Header>
          <BackButton to={APP_PATHS.pointofsale.settings.home} />
          <LargeHeaderIcon>{orgLogo}</LargeHeaderIcon>
          <HeaderTitleSubtitle
            subtitle={
              pointOfSaleStore.pointOfSale
                ? pointOfSaleStore.pointOfSale.name
                : " "
            }
            title="Inställningar"
          />
          {/* <HeaderMenuButton /> */}
        </Header>
        <ContentScroll>
          <PointOfSaleDetailsForm
            doneAction={() => history.push(APP_PATHS.pointofsale.settings.home)}
          />
        </ContentScroll>
      </FullPageWrapper>
    );
  }
);

export default PointOfSaleSettingsDetailsScreen;
