import { observer } from "mobx-react";
import React, { useContext, useEffect, useState } from "react";
import {
  Dimensions,
  StyleSheet,
  // Switch,
  TouchableOpacity,
  View
} from "react-native";
import { Button, Input, Text } from "react-native-elements";
import { Redirect, RouteComponentProps } from "react-router";
import FullPageActivityIndicator from "../components/common/FullPageActivityIndicator";
import { DisplayHeading, Heading2 } from "../components/common/Headings";
import { TitlePage } from "../components/layout/TitlePage";
import { OnboardingClosepointofsale } from "../components/OnBoardingIcon";
import { DefaultWizard } from "../components/wizards/DefaultWizard";
import { APP_PATHS } from "../config";
import { theme } from "../lib/styles/theme";
import { RootStoreContext } from "../stores/RootStore";

interface Props extends RouteComponentProps {}

const ClosePointOfSaleScreen: React.FC<Props> = observer(({ history }) => {
  const [loading, setLoading] = useState(true);
  const smallScreen = Dimensions.get("window").width < 375;

  const [wizardActive, setWizardActive] = useState(false);
  const [wizardDone, setWizardDone] = useState({ done: false, redirect: null });

  const { pointOfSaleStore } = useContext(RootStoreContext);
  const { pointOfSale } = pointOfSaleStore;

  const _onWizardComplete = str => {
    // console.log("I GOT HERE I GOT HERE!");
    setWizardDone({
      done: true,
      redirect: APP_PATHS.pointofsale.reports
    });
  };

  useEffect(() => {
    pointOfSaleStore._prepareClosePointOfSale().then(res => {
      console.log(pointOfSaleStore.closeData);
      setLoading(false);
    });
  }, []);

  if (wizardDone && wizardDone.done && wizardDone.redirect) {
    return <Redirect to={wizardDone.redirect} />;
  }

  if (wizardActive) {
    if (loading) return <FullPageActivityIndicator />;
    return (
      <DefaultWizard
        initialValues={{
          cashCounted: "",
          comment: ""
        }}
        onSubmit={pointOfSaleStore._closePointOfSale}
        // onSubmit={console.log(this.values)}
        cancel={() => history.push(APP_PATHS.register.register)}
        afterSubmit={_onWizardComplete}
      >
        <DefaultWizard.Step>
          {({ onChangeValue, values, onSubmit, afterSubmit }) => (
            <>
              <Heading2 text="1/3. Kontanter" />
              <Text style={{ paddingTop: 15, fontWeight: "bold" }}>
                Ange kontanter i kassan totalt
              </Text>
              <Text style={{ paddingBottom: 15 }}>
                (räkna IN ingående växelkassa på{" "}
                {pointOfSaleStore.closeData.cashIn})
              </Text>
              <Input
                value={values.cashCounted}
                onChangeText={text => {
                  onChangeValue("cashCounted", text);
                }}
              />
            </>
          )}
        </DefaultWizard.Step>

        <DefaultWizard.Step>
          {({ onChangeValue, values, onSubmit, afterSubmit }) => (
            <>
              <Heading2 text="2/3. Kommentar" />
              <Text style={{ paddingTop: 15, fontWeight: "bold" }}>
                Vill du lägga till en kommentar?
              </Text>
              <Text style={{ paddingBottom: 15 }}>
                Dela gärna med dig av info som påverkat försäljningen i stort.
                Vi tar tacksamt även emot förslag och idéer på hur ditt och
                gruppens arbetsutförande kan förenklas eller förbättras.
              </Text>
              <Input
                value={values.comment}
                onChangeText={text => {
                  onChangeValue("comment", text);
                }}
              />
            </>
          )}
        </DefaultWizard.Step>
      </DefaultWizard>
    );
  }

  return (
    <>
      <TitlePage reverse padding={smallScreen ? 15 : 45}>
        <OnboardingClosepointofsale
          width={smallScreen ? 128 : 192}
          height={smallScreen ? 128 : 192}
        />

        <DisplayHeading color="#ffffff" text="Dagsavslut" />
        <View style={{ alignSelf: "stretch", maxWidth: 400 }}>
          <Text
            style={{ color: "#fff", textAlign: "center", marginBottom: 15 }}
          >
            Vill du göra avslut för dagen? Då avslutas försäljningen för alla
            användare. Ett avslut skapar en sammanställning av försäljningen i
            en rapport.
          </Text>
          <Text
            style={{ color: "#fff", textAlign: "center", marginBottom: 15 }}
          >
            Det enda som behövs vid avslut är att räkna kontanterna.
          </Text>
          <Text
            style={{
              color: "#fff",
              textAlign: "center",
              marginBottom: 15,
              fontWeight: "bold"
            }}
          >
            OBS! Ett dagsavslut går inte att ångra eller redigera i efterhand.
          </Text>
        </View>
        <Button
          type="outline"
          containerStyle={{ backgroundColor: "#fff" }}
          title="Sätt igång!"
          onPress={() => setWizardActive(true)}
        />
      </TitlePage>
      <TouchableOpacity
        style={styles.close}
        onPress={() => history.push(APP_PATHS.register.register)}
      >
        <Text style={{ color: "#ffffff", fontWeight: "bold" }}>X</Text>
      </TouchableOpacity>
    </>
  );
});

export default ClosePointOfSaleScreen;

const styles = StyleSheet.create({
  close: {
    position: "absolute",
    top: 20,
    left: 20,
    width: 40,
    height: 40,
    borderRadius: 20,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: theme.colors.danger
  }
});
