import axios from "axios";
import promise from "promise";
import { AsyncStorage } from "react-native";

const user = {
  languageSetting: "se"
};
// Add a request interceptor
var axiosInstance = axios.create();

axiosInstance.interceptors.request.use(
  async function(config) {
    // Do something before request is sent
    //If the header does not contain the token and the url not public, redirect to login
    const accessToken = (await AsyncStorage.getItem("authToken")) || null;
    //  ||
    // "E87C10E6-00C4-4E85-A400-D26CCE8A4905";
    // console.log("checked, found: ", accessToken);
    //if token is found add it to the header
    if (config.method !== "OPTIONS") {
      config.headers["Content-Type"] = "application/json";
      config.headers["Language"] = "se";
      config.headers["X-App-Version"] = "1.1";
      if (accessToken) {
        config.headers.authorization = accessToken;
      }
    }
    return config;
  },
  function(error) {
    // Do something with request error
    return promise.reject(error);
  }
);

export default axiosInstance;
