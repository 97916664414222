import { Formik } from "formik";
import React, { useContext, useState } from "react";
import { Switch, View } from "react-native";
import { Button, Input, Text } from "react-native-elements";
import ModalDoneCheck from "../components/modal/ModalDoneCheck";
import { theme } from "../lib/styles/theme";
import { PaymentMethod } from "../lib/types/interfaces/shopping/payment-method";
import { RootStoreContext } from "../stores/RootStore";

interface EditPaymentMethodFormProps {
  doneAction?: (b: boolean) => void;
  objectId: string;
  objectType: "organization" | "pointOfSale";
  pm: PaymentMethod;
}

const EditPaymentMethodForm: React.FC<EditPaymentMethodFormProps> = ({
  doneAction,
  objectId,
  objectType,
  pm,
}) => {
  // const [item, setItem] = useState();
  const [done, setDone] = useState(false);
  const { pointOfSaleStore, organizationStore } = useContext(RootStoreContext);
  if (done) return <ModalDoneCheck doneAction={doneAction} />;
  return (
    <Formik
      initialValues={{
        pmId: pm.id,
        method: pm.method || "swish",
        active: pm.active || true,
        description: pm.description || "",
        recipient: pm.recipient || "",
        locked: pm.locked || false,
        objectId,
        // type: item.type,
      }}
      onSubmit={(data, { setSubmitting }) => {
        // console.log("FORMIK DATA: ", data);
        if (objectType === "organization") {
          organizationStore
            ._updatePaymentMethodInOrganization(data)
            .then((res) => setDone(true));
        } else {
          pointOfSaleStore
            ._updatePaymentMethodInPointOfSale(data)
            .then((res) => setDone(true));
        }
      }}
    >
      {({
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue,
      }) => (
        <View>
          <Text style={{ fontWeight: "bold" }}>Lägg till swishnummer</Text>
          <View style={{ paddingVertical: 15 }}>
            <Input
              label="Swishnummer"
              value={values.recipient}
              onChangeText={handleChange("recipient")}
              onBlur={handleBlur("recipient")}
            />
          </View>
          <View style={{ paddingVertical: 15 }}>
            <Input
              label="Beskrivning"
              value={values.description}
              onChangeText={handleChange("description")}
              onBlur={handleBlur("description")}
            />
          </View>
          {objectType === "pointOfSale" && (
            <View style={{ paddingVertical: 15, marginBottom: 20 }}>
              <View style={{ flexDirection: "row", alignItems: "center" }}>
                <Switch
                  value={values.locked}
                  onValueChange={(val) => setFieldValue("locked", val)}
                />
                <View style={{ paddingLeft: 15 }}>
                  <Text style={{ fontWeight: "bold" }}>
                    Lås detta nummer för denna kassa
                  </Text>
                </View>
              </View>
            </View>
          )}

          <Button
            title={isSubmitting ? "Sparar..." : "Spara"}
            onPress={() => handleSubmit()}
            disabled={isSubmitting}
          />

          <Button
            buttonStyle={{
              backgroundColor: theme.colors.danger,
              marginTop: 20,
            }}
            title="Ta bort detta betalsätt"
            onPress={() => {
              console.log("removing pm");

              if (
                objectType === "organization" &&
                organizationStore._removePaymentMethodFromOrganization(pm.id)
              ) {
                setDone(true);
              } else if (
                objectType === "pointOfSale" &&
                pointOfSaleStore._removePaymentMethodFromPointOfSale(pm.id)
              ) {
                setDone(true);
              }
            }}
          />
        </View>
      )}
    </Formik>
  );
};

export default EditPaymentMethodForm;
