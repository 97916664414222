import React, { useContext } from "react";
import { Image } from "react-native";
import { RouteComponentProps } from "react-router";
import BackButton from "../components/common/BackButton";
import FullPageActivityIndicator from "../components/common/FullPageActivityIndicator";
import { HeaderTitleSubtitle } from "../components/headerItems/HeaderTitleSubtitle";
import { LargeHeaderIcon } from "../components/headerItems/LargeHeaderIcon";
import { TpIconTeam3 } from "../components/Icon";
import { ContentScroll } from "../components/layout/Content";
import { FullPageWrapper } from "../components/layout/FullPageWrapper";
import { Header } from "../components/layout/Header";
import { APP_PATHS } from "../config";
import OrganizationAddressForm from "../containers/OrganizationAddressForm";
import { RootStoreContext } from "../stores/RootStore";

interface Props extends RouteComponentProps {}

const OrganizationSettingsContactScreen: React.FC<Props> = ({ history }) => {
  const { organizationStore } = useContext(RootStoreContext);

  if (organizationStore.organizationStoreLoading) {
    return <FullPageActivityIndicator />;
  }

  let orgLogo;
  if (organizationStore.organization) {
    if (organizationStore.organization.logoUrl128x128) {
      orgLogo = (
        <Image
          style={{ width: 64, height: 64 }}
          source={{
            uri: organizationStore.organization.logoUrl128x128.replace(
              "http:",
              "https:"
            )
          }}
        />
      );
    } else {
      orgLogo = <TpIconTeam3 size={64} />;
    }
  }

  return (
    <FullPageWrapper>
      <Header>
        <BackButton to={APP_PATHS.organization.settings.home} />
        <LargeHeaderIcon>{orgLogo}</LargeHeaderIcon>
        <HeaderTitleSubtitle
          subtitle={
            organizationStore.organization
              ? organizationStore.organization.name
              : " "
          }
          title="Kontaktuppgifter"
        />
        {/* <HeaderMenuButton /> */}
      </Header>
      <ContentScroll>
        <OrganizationAddressForm
          doneAction={() => history.push(APP_PATHS.organization.settings.home)}
        />
      </ContentScroll>
    </FullPageWrapper>
  );
};

export default OrganizationSettingsContactScreen;
