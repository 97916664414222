// prettier-ignore
export default [
  {
    category: "Dryck",
    icons: [

      { name: "tp-bottle", description: "Flaska", keywords: [""], category: "Dryck" },
      { name: "tp-can", description: "Burk", keywords: [""], category: "Dryck" },
      { name: "tp-coffee", description: "Kaffe", keywords: [""], category: "Dryck" },
      { name: "tp-energydrink", description: "Energidryck", keywords: [""], category: "Dryck" },
      { name: "tp-glas", description: "Dryck", keywords: [""], category: "Dryck" },
      { name: "tp-juice", description: "Festis", keywords: [""], category: "Dryck" },
      { name: "tp-tea", description: "Te", keywords: [""], category: "Dryck" },
    ]
  },
  {
    category: "Godis",
    icons:[

      { name: "tp-candy", description: "Små godissaker", keywords: [""], category: "Godis" },
      { name: "tp-candybag-large", description: "Godispåse stor", keywords: [""], category: "Godis" },
      { name: "tp-candybag", description: "Godispåse liten", keywords: [""], category: "Godis" },
      { name: "tp-candybar-large", description: "Kexchoklad", keywords: [""], category: "Godis" },
      { name: "tp-candybar", description: "Godis - styckesak", keywords: [""], category: "Godis" },
      { name: "tp-chips", description: "Chips", keywords: [""], category: "Godis" },
      { name: "tp-chocolatebar", description: "Chokladkaka", keywords: [""], category: "Godis" },
      { name: "tp-icecream", description: "Glass", keywords: [""], category: "Godis" },
      { name: "tp-lollipop", description: "Klubba", keywords: [""], category: "Godis" },
      { name: "tp-popcorn", description: "Popcorn", keywords: [""], category: "Godis" },
    ]
  },{
    category: "Mat",
    icons: [

      { name: "tp-baguette", description: "Baguette", keywords: [""], category: "Mat" },
      { name: "tp-coldsandwich", description: "Smörgås", keywords: [""], category: "Mat" },
      { name: "tp-bun", description: "Bulle", keywords: [""], category: "Mat" },
      { name: "tp-food", description: "Måltid", keywords: [""], category: "Mat" },
      { name: "tp-fries", description: "Pommes frites", keywords: [""], category: "Mat" },
      { name: "tp-fruit", description: "Frukt", keywords: [""], category: "Mat" },
      { name: "tp-gamedeal", description: "Matchfika", keywords: [""], category: "Mat" },
      { name: "tp-hamburger", description: "Hamburgare", keywords: [""], category: "Mat" },
      { name: "tp-hamburgermeal", description: "Hamburgare + flaska", keywords: [""], category: "Mat" },
      { name: "tp-hamburgermealcan", description: "Hamburgare + burk", keywords: [""], category: "Mat" },
      { name: "tp-hamburgermealglas", description: "Hamburgare + dryck", keywords: [""], category: "Mat" },
      { name: "tp-warmsandwich", description: "Varm smörgås", keywords: [""], category: "Mat" },
      { name: "tp-hotdog", description: "Varmkorv", keywords: [""], category: "Mat" },
      { name: "tp-meal", description: "Måltid + flaska", keywords: [""], category: "Mat" },
      { name: "tp-mealcan", description: "Måltid + burk", keywords: [""], category: "Mat" },
      { name: "tp-mealglas", description: "Måltid + dryck", keywords: [""], category: "Mat" },
      { name: "tp-sallad", description: "Sallad", keywords: [""], category: "Mat" },
      { name: "tp-pizza", description: "Pizza", keywords: [""], category: "Mat" },
    ]
  },{
    category: "Övrigt",
    icons: [



  { name: "tp-bag", description: "Påse", keywords: [""], category: "Övrigt" },
  { name: "tp-box", description: "Kartong", keywords: [""], category: "Övrigt" },
  { name: "tp-calendar", description: "Kalender", keywords: [""], category: "Övrigt" },
  { name: "tp-clothing", description: "Profilkläder", keywords: [""], category: "Övrigt" },
  { name: "tp-crate", description: "Låda", keywords: [""], category: "Övrigt" },
  { name: "tp-discountbooklet", description: "Rabatthäfte", keywords: [""], category: "Övrigt" },
  { name: "tp-lotteryticket", description: "Lott", keywords: [""], category: "Övrigt" },
  { name: "tp-matchprogram", description: "Matchprogram", keywords: [""], category: "Övrigt" },
  { name: "tp-parking", description: "Parkering", keywords: [""], category: "Övrigt" },
  { name: "tp-shopping", description: "Påse med pryttlar i", keywords: [""], category: "Övrigt" },
  { name: "tp-ticket", description: "Biljett", keywords: [""], category: "Övrigt" },
  { name: "tp-key", description: "Nyckel", keywords: [""], category: "Övrigt" },
  { name: "tp-rent", description: "Hyra", keywords: [""], category: "Övrigt" },
  { name: "tp-rent-sign", description: "Hyra", keywords: [""], category: "Övrigt" },
    ]
  }
];
