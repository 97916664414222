import { Formik } from "formik";
import { observer } from "mobx-react";
import React, { useContext, useState } from "react";
import { ScrollView, View } from "react-native";
import { Button, Input, Text } from "react-native-elements";
import ModalDoneCheck from "../components/modal/ModalDoneCheck";
import { RootStoreContext } from "../stores/RootStore";

interface Props {
  doneAction?: (b: boolean) => void;
}

const OrganizationAddressForm: React.FC<Props> = observer(({ doneAction }) => {
  const { organizationStore } = useContext(RootStoreContext);
  const [done, setDone] = useState(false);
  if (done) return <ModalDoneCheck doneAction={doneAction} />;
  return (
    <Formik
      initialValues={{
        id: organizationStore.organization.id,
        address1: organizationStore.organization.address1,
        address2: organizationStore.organization.address2,
        zip: organizationStore.organization.zip,
        city: organizationStore.organization.city
      }}
      onSubmit={(data, { setSubmitting }) => {
        // console.log("FORMIK DATA: ", data);
        if (organizationStore._updateOrganization(data)) {
          setSubmitting(false);
          setDone(true);
        } else {
          // console.log("CRAP!");
        }
      }}
    >
      {({ values, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
        <ScrollView>
          <Text h4>Kontaktuppgifter</Text>
          <View style={{ paddingVertical: 15 }}>
            <Input
              label="Adressrad 1"
              value={values.address1}
              onChangeText={handleChange("address1")}
              onBlur={handleBlur("address1")}
            />
          </View>
          <View style={{ paddingVertical: 15 }}>
            <Input
              label="Adressrad 2"
              value={values.address2}
              onChangeText={handleChange("address2")}
              onBlur={handleBlur("address2")}
            />
          </View>
          <View style={{ paddingVertical: 15 }}>
            <Input
              label="Postnummer"
              value={values.zip}
              onChangeText={handleChange("zip")}
              onBlur={handleBlur("zip")}
            />
          </View>
          <View style={{ paddingVertical: 15 }}>
            <Input
              label="Postort"
              value={values.city}
              onChangeText={handleChange("city")}
              onBlur={handleBlur("city")}
            />
          </View>
          <Button
            title={isSubmitting ? "Sparar..." : "Spara"}
            onPress={() => handleSubmit()}
            disabled={isSubmitting}
          />
        </ScrollView>
      )}
    </Formik>
  );
});

export default OrganizationAddressForm;
