import React, { useState } from "react";
import { ScrollView, View } from "react-native";
import { Text } from "react-native-elements";
import { Group } from "../../lib/types/users/group";
import { Heading4 } from "../common/Headings";
import PointOfSaleList from "./PointOfSaleList";

interface GroupsPointOfSaleListProps {
  groups: Group[];
  openOnly?: boolean;
}
const GroupsPointOfSaleList: React.FC<GroupsPointOfSaleListProps> = ({
  groups,
  openOnly = false
}) => {
  const [openPos, setOpenPos] = useState(0);

  return (
    <ScrollView horizontal={false}>
      {groups.map(group => {
        if (!group.name) {
          return null;
        }
        if (!group.pointOfSales || group.pointOfSales.length === 0) {
          return null;
        }
        if (
          openOnly &&
          group.pointOfSales.filter(pos => pos.session).length === 0
        ) {
          return null;
          // return (
          //   <>
          //     <View
          //       style={{
          //         flex: 1,
          //         justifyContent: "center",
          //         alignItems: "center"
          //       }}
          //     >
          //       <Text>Just nu har ni inga öppna kassor.</Text>
          //     </View>
          //   </>
          // );
        }
        // if (openOnly) {
        //   setOpenPos(
        //     openPos + group.pointOfSales.filter(pos => pos.session).length
        //   );
        // }
        return (
          <React.Fragment key={group.id}>
            <View
              style={{
                alignSelf: "stretch",
                marginTop: 20
                // alignItems: "center"
              }}
            >
              <Heading4 text={group.name} />
            </View>
            <Text>
              {openOnly
                ? group.pointOfSales.filter(pos => pos.session).length
                : group.pointOfSales.length}{" "}
              kass
              {group.pointOfSales.length === 1 ? "a" : "or"}
            </Text>
            <PointOfSaleList
              pointOfSales={group.pointOfSales}
              openOnly={openOnly}
            />
          </React.Fragment>
        );
      })}
      {openOnly && openPos !== 0 && (
        <>
          <View
            style={{
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
              marginTop: 40
            }}
          >
            <Text>Just nu har ni inga öppna kassor.</Text>
          </View>
        </>
      )}
    </ScrollView>
  );
};

export default GroupsPointOfSaleList;
