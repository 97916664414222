import React from "react";
import {
  Platform,
  SafeAreaView,
  StatusBar,
  Text,
  TouchableOpacity,
  View
} from "react-native";
import { useSafeArea } from "react-native-safe-area-context";
import { RouteComponentProps, withRouter } from "react-router";
// import TpIconArrow from "../assets/icons/system/TpIconArrow";
import { TpIconArrow } from "../Icon";

interface Props {
  safe?: boolean;
  center?: boolean;
  centerV?: boolean;
  centerH?: boolean;
  backButton?: boolean;
  backButtonLabel?: string | JSX.Element;
}

export const FullPageWrapper: React.FC<Props> = ({
  safe = true,
  children,
  center,
  centerH,
  centerV,
  backButton = false,
  backButtonLabel = null
}) => {
  let centerStyle = {};
  if (centerV) {
    centerStyle = {
      alignItems: "center"
    };
  }
  if (centerH) {
    centerStyle = {
      justifyContent: "center"
    };
  }
  if (center) {
    centerStyle = {
      alignItems: "center",
      justifyContent: "center"
    };
  }
  if (!safe) {
    return <View style={[{ flex: 1 }, centerStyle]}>{children}</View>;
  }
  return (
    <>
      <SafeAreaView
        style={[
          {
            // backgroundColor: "rgba(224,224,244,0.5)",
            flex: 1,
            alignSelf: "stretch",
            position: "relative",
            paddingTop: Platform.OS === "android" ? StatusBar.currentHeight : 0
          },
          centerStyle
        ]}
      >
        {/* <View style={globalStyles.containerFill}>{children}</View> */}
        <View style={[{ flex: 1 }, centerStyle]}>{children}</View>
        {/* {children} */}
        {backButton && <BackButton backButtonLabel={backButtonLabel} />}
      </SafeAreaView>
    </>
  );
};

interface BackButtonProps extends RouteComponentProps {
  backButtonLabel: string | JSX.Element;
}
const backButtonWithoutRouter: React.FC<BackButtonProps> = ({
  history,
  backButtonLabel
}) => {
  let label = (
    <View style={{ transform: [{ rotate: "180deg" }] }}>
      <TpIconArrow size={18} />
    </View>
  );
  if (typeof backButtonLabel === "string") {
    label = <Text>{backButtonLabel}</Text>;
  }
  const insets = useSafeArea();
  return (
    <TouchableOpacity
      style={{
        position: "absolute",
        top: insets.top + 15,
        left: insets.left + 15
        // backgroundColor: "blue"
      }}
      onPress={() => {
        // console.log("BACKING!");
        history.goBack();
      }}
    >
      <View
        style={{
          borderRadius: 20,
          backgroundColor: "#fff",
          justifyContent: "center",
          alignItems: "center",
          padding: 10
        }}
      >
        {label}
      </View>
    </TouchableOpacity>
  );
};

const BackButton = withRouter(backButtonWithoutRouter);
