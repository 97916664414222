import React from "react";
import { ActivityIndicator, ActivityIndicatorProps, View } from "react-native";

interface Props extends ActivityIndicatorProps {}

const FullPageActivityIndicator: React.FC<Props> = props => {
  return (
    <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
      <ActivityIndicator {...props} />
    </View>
  );
};

export default FullPageActivityIndicator;
