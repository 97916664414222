import React, { useContext, useState } from "react";
import { Dimensions, View } from "react-native";
import { Button, Input, Text } from "react-native-elements";
import { GeneratedQRCode } from "../../components/GeneratedQRCode";
import Icon from "../../components/Icon/Icon";
import { ContentFixed, ContentScroll } from "../../components/layout/Content";
import Modal from "../../components/modal/Modal";
import RegisterHeader from "../../components/register/RegisterHeader";
import { API_ULRS } from "../../config";
import axios from "../../lib/axios";
import { RootStoreContext } from "../../stores/RootStore";

const RecieptContainer = () => {
  const { registerStore } = useContext(RootStoreContext);
  const recieptUrl = `${API_ULRS.link}/Home/Pdf/${registerStore.transactionReference}`;
  const qrSize = Dimensions.get("window").width * 0.45;
  const [showSendModal, setShowSendModal] = useState(false);
  const [recieptNumber, setRecieptNumber] = useState("");

  return (
    <>
      <RegisterHeader />
      <View style={{ flex: 1, justifyContent: "space-between" }}>
        <ContentFixed>
          <View style={{ paddingHorizontal: 45, paddingVertical: 15 }}>
            <Text>
              Låt kunden scanna koden nedan med sin kamera för att se sitt
              kvitto om så önskas.
            </Text>
          </View>
        </ContentFixed>
        <ContentFixed>
          <View style={{ alignSelf: "center" }}>
            <GeneratedQRCode url={recieptUrl} size={qrSize} />
          </View>
          <Button
            title="Skicka som sms"
            type="outline"
            onPress={() => setShowSendModal(true)}
            style={{ marginVertical: 15, marginHorizontal: 30 }}
          />
        </ContentFixed>
        <ContentScroll>
          <View style={{ paddingHorizontal: 30, paddingVertical: 15 }}>
            <Text style={{ fontWeight: "bold" }}>
              {registerStore.isReturn ? "Återköpet" : "Köpet"} bestod av
            </Text>
            {registerStore.cartItems.map(node => {
              console.log("CartItem: ", node);
              return (
                <View
                  key={node.id}
                  style={{
                    alignSelf: "stretch",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginVertical: 5
                  }}
                >
                  {node.item ? (
                    <View
                      style={{ flexDirection: "row", alignItems: "center" }}
                    >
                      <Text style={{ marginRight: 10 }}>
                        {node.quantity} st
                      </Text>
                      <Icon name={node.item.icon} size={24} />
                      <Text style={{ marginLeft: 15 }}>
                        {node.name} ({node.pricePerItemInclVat} kr)
                      </Text>
                    </View>
                  ) : (
                    <View
                      style={{ flexDirection: "row", alignItems: "center" }}
                    >
                      <Text style={{ marginRight: 10 }}>
                        {node.quantity} st
                      </Text>
                      <Icon name={node.icon} size={24} />
                      <Text style={{ marginLeft: 15 }}>
                        {node.description} ({node.priceInclVat} kr)
                      </Text>
                    </View>
                  )}
                </View>
              );
            })}
          </View>
        </ContentScroll>
        <ContentFixed>
          <View style={{ padding: 15 }}>
            <Button title="KLART!" onPress={() => registerStore._emptyCart()} />
          </View>
        </ContentFixed>
        <Modal
          isVisible={showSendModal}
          closeFn={setShowSendModal}
          direction="toTop"
        >
          <Text h3>Skicka kvitto som sms</Text>
          <View style={{ marginVertical: 15 }}>
            <Input
              label="Mottagarens mobilnummer"
              value={recieptNumber}
              onChangeText={e => {
                setRecieptNumber(e);
              }}
            />
          </View>
          <Button
            title="Skicka"
            onPress={() => {
              const reqData = {
                recipient: recieptNumber.replace(/\D/g, ""),
                string: `Här kommer ditt ${
                  registerStore.isReturn ? "återköp" : ""
                }kvitto. Du hittar det på följade adress: ${recieptUrl}`
              };

              axios
                .post(`${API_ULRS.proxybase}/sendsms`, JSON.stringify(reqData))
                .then(res => {
                  setShowSendModal(false);
                });

              // const smsString = `https://se-1.cellsynt.net/sms.php?username=teamtools&password=F33Eknpe&destination=${recieptNumber}&originatortype=alpha&originator=TeamPay&charset=UTF-8&text=H%C3%A4r%20kommer%20ditt%20kvitto.%20Du%20hittar%20det%20p%C3%A5%20f%C3%B6ljade%20adress%3A%20${recieptUrl}`;

              // axios.post(smsString).then(res => {
              //   setShowSendModal(false);
              // });
            }}
          />
        </Modal>
      </View>
    </>
  );
};

export default RecieptContainer;
