import { observer } from "mobx-react";
import React, { useContext, useState } from "react";
import { Image, TouchableOpacity, View } from "react-native";
import { Button, Text } from "react-native-elements";
import { RouteComponentProps } from "react-router";
import BackButton from "../components/common/BackButton";
import { HeaderTitleSubtitle } from "../components/headerItems/HeaderTitleSubtitle";
import { LargeHeaderIcon } from "../components/headerItems/LargeHeaderIcon";
import { TpIconCog, TpIconTeam3, TpIconUsers } from "../components/Icon";
import { ContentFixed, ContentScroll } from "../components/layout/Content";
import { FullPageWrapper } from "../components/layout/FullPageWrapper";
import { Header } from "../components/layout/Header";
import Modal from "../components/modal/Modal";
import APP_PATHS from "../config/appPaths";
import AddGroupForm from "../containers/AddGroupForm";
import EditGroupForm from "../containers/EditGroupForm";
import { theme } from "../lib/styles/theme";
import { Group } from "../lib/types/users/group";
import { elevationShadowStyle } from "../lib/utils/elevationShadowStyle";
import { RootStoreContext } from "../stores/RootStore";
interface Props extends RouteComponentProps {}

const OrganizationSettingsGroupsScreen: React.FC<Props> = observer(
  ({ history }) => {
    const { organizationStore } = useContext(RootStoreContext);
    const [showGroupModal, setShowGroupModal] = useState(false);
    const [showAddGroupModal, setShowAddGroupModal] = useState(false);
    const [currentGroupId, setCurrentGroupId] = useState("");
    const [currentGroup, setCurrentGroup] = useState<Group>();

    let orgLogo;
    if (organizationStore.organization) {
      if (organizationStore.organization.logoUrl128x128) {
        orgLogo = (
          <Image
            style={{ width: 64, height: 64 }}
            source={{
              uri: organizationStore.organization.logoUrl128x128.replace(
                "http:",
                "https:"
              )
            }}
          />
        );
      } else {
        orgLogo = <TpIconTeam3 size={64} />;
      }
    }
    return (
      <FullPageWrapper>
        <Modal isVisible={showGroupModal} closeFn={setShowGroupModal}>
          {showGroupModal && (
            <EditGroupForm
              group={currentGroup}
              groupId={currentGroupId}
              doneAction={setShowGroupModal}
            />
          )}
        </Modal>
        <Modal
          isVisible={showAddGroupModal}
          closeFn={setShowAddGroupModal}
          direction="toTop"
        >
          {showAddGroupModal && (
            <AddGroupForm doneAction={setShowAddGroupModal} />
          )}
        </Modal>
        <Header>
          <BackButton to={APP_PATHS.organization.settings.home} />
          <LargeHeaderIcon>{orgLogo}</LargeHeaderIcon>
          <HeaderTitleSubtitle
            subtitle={
              organizationStore.organization
                ? organizationStore.organization.name
                : " "
            }
            title="Grupper"
          />
          {/* <HeaderMenuButton /> */}
        </Header>
        <ContentFixed>
          <View
            style={{ marginTop: 40, paddingHorizontal: 15, paddingBottom: 20 }}
          >
            <Text>Här kan du hantera de grupper som finns i föreningen.</Text>
            <Button
              title="Lägg till grupp"
              style={{ marginTop: 15 }}
              onPress={() => {
                setShowAddGroupModal(true);
              }}
            />
          </View>
        </ContentFixed>
        <ContentScroll>
          {organizationStore.organization.groups &&
            organizationStore.organization.groups.length &&
            organizationStore.organization.groups.map(group => {
              if (group.systemManaged) return null;
              return (
                <View style={{ marginVertical: 5 }} key={group.id}>
                  <TouchableOpacity
                    // style={{ paddingVertical: 10 }}
                    onPress={() => {
                      setCurrentGroupId(group.id);
                      setCurrentGroup(group);
                      setShowGroupModal(true);
                    }}
                  >
                    <GroupItem group={group} />
                  </TouchableOpacity>
                </View>
              );
            })}
        </ContentScroll>
      </FullPageWrapper>
    );
  }
);

export default OrganizationSettingsGroupsScreen;

interface GroupItemProps {
  group: Group;
}
const GroupItem: React.FC<GroupItemProps> = ({ group }) => {
  return (
    <View
      style={{
        marginHorizontal: 10,
        marginVertical: 5,
        backgroundColor: "#fff",
        paddingHorizontal: 15,
        paddingVertical: 10,
        borderRadius: 8,
        justifyContent: "flex-start",
        alignItems: "center",
        flexDirection: "row",
        ...elevationShadowStyle(10),
        alignSelf: "stretch"
      }}
    >
      <View
        style={{
          width: "100%",
          alignSelf: "stretch",
          flexDirection: "row"
        }}
      >
        <View
          style={{
            width: "100%",
            flexDirection: "row",
            alignSelf: "stretch",
            alignItems: "center",
            justifyContent: "space-between"
          }}
        >
          <View style={{ flexDirection: "row" }}>
            <View>
              <TpIconUsers size={40} />
            </View>
            <View style={{ paddingVertical: 5, paddingHorizontal: 15 }}>
              <View>
                <Text style={{ fontWeight: "bold" }}>{group.name}</Text>
                <Text style={{ fontStyle: "italic" }}>
                  {group.pointOfSales && group.pointOfSales.length
                    ? group.pointOfSales.length === 1
                      ? "1 kassa"
                      : `${group.pointOfSales.length} kassor`
                    : "Inga kassor"}
                </Text>
              </View>
            </View>
          </View>
          <View
            style={{
              width: 60,
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <TpIconCog size={18} color={theme.colors.primary} />
          </View>
        </View>
      </View>
    </View>
  );
};
