import React from "react";
import { Text } from "react-native";
import { theme } from "../../lib/styles/theme";

interface Props {
  center?: boolean;
  color?: string;
}
export const Paragraph: React.FC<Props> = ({
  children,
  center = false,
  color = theme.colors.text
}) => {
  return (
    <Text
      style={{
        color: color,
        marginVertical: 8,
        textAlign: center ? "center" : null
      }}
    >
      {children}
    </Text>
  );
};
