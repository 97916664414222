import React from "react";
import { Platform, View } from "react-native";
import AndroidQRCode from "react-native-qrcode";
import QRCode from "react-native-qrcode-svg";
import { theme } from "../lib/styles/theme";
import { elevationShadowStyle } from "../lib/utils/elevationShadowStyle";

interface Props {
  url?: string;
  color?: string;
  size?: number;
}

export const GeneratedQRCode: React.FC<Props> = ({
  url,
  color = theme.colors.primary,
  size = 192
}) => {
  if (size > 300) size = 300;
  return (
    <View
      style={{
        borderRadius: 15,
        padding: 15,
        backgroundColor: "#ffffff",
        marginHorizontal: 15,
        ...elevationShadowStyle(),
        maxWidth: 400
      }}
    >
      {Platform.select({
        android: <AndroidQRCode size={size} fgColor={color} value={url} />,
        default: <QRCode ecl="H" size={size} color={color} value={url} />
      })}
    </View>
  );
};
