function sortOn(arr: [], prop: string) {
  return arr.sort(function(a, b) {
    if (a[prop] < b[prop]) {
      return -1;
    } else if (a[prop] > b[prop]) {
      return 1;
    } else {
      return 0;
    }
  });
}
export const sortByPaymentMethod = (a, b) => {
  const aa = a.paymentMethodKey.toLowerCase();
  const bb = b.paymentMethodKey.toLowerCase();
  return aa < bb ? -1 : aa > bb ? 1 : 0;
};
export const sortByName = (a, b) => {
  const aa = a.name.toLowerCase();
  const bb = b.name.toLowerCase();
  return aa < bb ? -1 : aa > bb ? 1 : 0;
};

export const sortByNameInOrganzitionArray = (a, b) => {
  const aa = a.organization.name.toLowerCase();
  const bb = b.organization.name.toLowerCase();
  return aa < bb ? -1 : aa > bb ? 1 : 0;
};

export const sortByCode = (a, b) => {
  const aa = a.code.toLowerCase();
  const bb = b.code.toLowerCase();
  return aa < bb ? -1 : aa > bb ? 1 : 0;
};

export const sortByDescription = (a, b) => {
  const aa = a.description.toLowerCase();
  const bb = b.description.toLowerCase();
  return aa < bb ? -1 : aa > bb ? 1 : 0;
};

export const sortByQuantity = (a, b) => {
  const aa = a.quantity;
  const bb = b.quantity;
  return aa > bb ? -1 : aa > bb ? 1 : 0;
};
