import React, { useContext } from "react";
import { Alert, Platform, TouchableOpacity, View } from "react-native";
import { Text } from "react-native-elements";
import { Category } from "../../lib/types/product/category";
import { elevationShadowStyle } from "../../lib/utils/elevationShadowStyle";
import { RootStoreContext } from "../../stores/RootStore";
import { TpIconCog, TpIconTrash } from "../Icon";
import Icon from "../Icon/Icon";

interface Props {
  item: Category;
}
const PointOfSaleCategoryListItem = ({ item }) => {
  console.log(item);
  const { pointOfSaleStore } = useContext(RootStoreContext);
  return (
    <View
      key={`item-list-item-${item.id}`}
      style={{
        justifyContent: "space-between",
        flexDirection: "row",
        marginBottom: 8
      }}
    >
      <View
        style={{
          backgroundColor: "#fff",
          paddingHorizontal: 15,
          paddingVertical: 10,
          borderRadius: 8,
          width: "70%",
          justifyContent: "flex-start",
          alignItems: "center",
          flexDirection: "row",
          ...elevationShadowStyle(10)
        }}
      >
        <Icon name={item.icon} size={40} />
        <View
          style={{
            marginHorizontal: 10,
            flexGrow: 1
          }}
        >
          <Text style={{ fontSize: 13, lineHeight: 12 }}>
            {item.description}
          </Text>
        </View>
        <TpIconCog size={20} color="rgba(0,0,0,0.3)" />
      </View>
      <View
        style={{
          width: "30%",
          justifyContent: "center",
          alignItems: "flex-end"
        }}
      >
        <TouchableOpacity
          style={{
            justifyContent: "center",
            // backgroundColor: "rgba(0,255,0,0.4)",
            height: 40
          }}
          onPress={() => {
            // console.log("Delete clicked!");
            if (Platform.OS === "web") {
              if (confirm(`Vill du verkligen ta bort ${item.description}?`)) {
                pointOfSaleStore._removeCategory(item.id);
              } else {
                // console.log("Cancel Pressed");
              }
            } else {
              Alert.alert(
                "Är du säker?",
                `Vill du verkligen ta bort ${item.description}?`,
                [
                  {
                    text: "Avbryt",
                    onPress: () => console.log("Cancel Pressed"),
                    style: "cancel"
                  },
                  {
                    text: "Ta bort",
                    onPress: () => pointOfSaleStore._removeCategory(item.id)
                  }
                ],
                { cancelable: false }
              );
            }
          }}
        >
          <View
            style={{
              flexDirection: "row",
              alignItems: "center"
            }}
          >
            <TpIconTrash color="rgba(0,0,0,0.3)" style={{ marginRight: 5 }} />
            <Text
              style={{
                color: "rgba(0,0,0,0.3)",
                fontSize: 11,
                fontWeight: "bold"
              }}
            >
              RADERA
            </Text>
          </View>
        </TouchableOpacity>
      </View>
    </View>
  );
};

export default PointOfSaleCategoryListItem;
