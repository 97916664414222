import React from "react";
import { Text, TouchableOpacity, View } from "react-native";
import { RouteComponentProps, withRouter } from "react-router";
import { Template } from "../../lib/types/product/template";

interface Props extends RouteComponentProps {
  template: Template;
  onPress?: (a: any) => void;
  selected?: boolean;
}

export const TemplateCardWithoutRouter: React.FC<Props> = ({
  template,
  onPress,
  selected = false,
  history
}) => {
  return (
    <View
      style={{
        shadowColor: "#000",
        shadowOffset: {
          width: 0,
          height: 0
        },
        shadowOpacity: 0.15,
        shadowRadius: 3.84,

        elevation: 3,
        backgroundColor: selected ? "rgb(0, 161, 58);" : "#fff",
        borderRadius: 8,
        marginLeft: 10,
        marginRight: 10,
        flexGrow: 1,
        width: 220,
        height: 90
      }}
    >
      <TouchableOpacity
        style={{ flex: 1 }}
        onPress={() => onPress(selected ? "" : template.id)}
      >
        <View
          style={{
            flex: 1,
            paddingVertical: 20,
            paddingHorizontal: 15,
            justifyContent: "center",
            alignItems: "center",
            overflow: "hidden"
          }}
        >
          <Text
            style={{
              alignSelf: "stretch",
              textAlign: "center"
            }}
          >
            {template.name}
          </Text>
        </View>
      </TouchableOpacity>
    </View>
  );
};

export const TemplateCard = withRouter(TemplateCardWithoutRouter);
