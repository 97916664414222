import { action, observable } from "mobx";
import { persist } from "mobx-persist";
import { AsyncStorage } from "react-native";
import { API_ULRS } from "../config";
import { default as axios, default as axois } from "../lib/axios";
import { User } from "../lib/types/users/user";
import { RegisterSteps } from "./RegisterStore";
import { RootStore } from "./RootStore";

export interface UserSettings {
  defaultDisplayOpenPos?: boolean;
  contractOnlyOrganizations?: boolean;
}
export class UserStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.settings.defaultDisplayOpenPos = false;
  }

  @observable isInComplete: boolean = false;
  @persist @observable authToken: string | null;
  @persist @observable userId: string | null;
  @persist @observable loggedIn: boolean = false;
  @persist("object") @observable user: User | null;
  @persist("object") @observable settings: UserSettings = {
    defaultDisplayOpenPos: false,
    contractOnlyOrganizations: false
  };
  @persist @observable authVerificationToken: string = null;
  @persist @observable authMobileNumber: string = null;

  @observable userStoreloading = false;

  _connect = async mobileNumber => {
    //+ // console.log("STARTING VERIFICAITON @", API_ULRS.authlink);
    //+ // console.log(mobileNumber);
    //+ // console.log(JSON.stringify(mobileNumber));
    const reqUrl = `${API_ULRS.authbase}/sendverification`;
    const { data } = await axios.post(reqUrl, JSON.stringify(mobileNumber));
    //+ // console.log(data);

    this.authMobileNumber = mobileNumber.mobileNumber;
    this.authVerificationToken = data.verificationCode;

    //+ // console.log("SETTING verificationToken = ", this.authVerificationToken);
    // this.setState({
    //   mobileNumber: mobileNumber.mobileNumber,
    //   verificationToken: data.verificationCode,
    //   loggingIn: true
    // });
    return true;
  };

  _verify = (token): Promise<boolean> => {
    // console.log(`${token.code} = ${this.authVerificationToken}`);
    return new Promise((resolve, reject) => {
      if (parseInt(token.code) !== parseInt(this.authVerificationToken)) {
        reject(false);
      }

      const reqUrl = `${API_ULRS.url}/users/createorupdate`;

      const userData = {
        phone: this.authMobileNumber,
        organizationId: token.organizationId
      };

      console.log(userData);

      axios
        .post(reqUrl, JSON.stringify(userData))
        .then(async ({ data }) => {
          if (data.success) {
            // console.log(data.data);
            this.authMobileNumber = null;
            this.authVerificationToken = null;

            await AsyncStorage.setItem("authToken", data.data.authorization);

            this.authToken = data.data.authorization;
            this.userId = data.data.user.id;
            this.user = data.data.user;

            this.isInComplete = !!(
              data.data.user.firstName === "" ||
              data.data.user.lastName === "" ||
              data.data.user.emailAddress === ""
            );
            resolve(true);
          } else {
            console.error("OH FFS!", data.error);
            reject(false);
          }
        })
        .catch(err => {
          console.error(err);
          reject(false);
        });
    });
  };

  _fetchUser = async () => {
    this.userStoreloading = true;
    const data = await axois.get(`${API_ULRS.url}/me`);

    if (data.status === 200) {
      this.userId = data.data.user.id;
      this.user = data.data.user;

      //
      this.isInComplete = !!(
        this.user.firstName === "" ||
        this.user.lastName === "" ||
        this.user.emailAddress === ""
      );
    }

    this.userStoreloading = false;
    // console.log("DATA FROM SERVER: ", data);
  };

  _refetchUser = async () => {
    this._fetchUser();
    // this.userStoreloading = true;
    // const data = await axois.get(`${API_ULRS.url}/me`);

    // if (data.status === 200) {
    // this.userId = data.data.user.id;
    // this.user = data.data.user;
    // this.userStoreloading = false;
    // }

    // console.log("DATA FROM SERVER: ", data);
  };

  @action
  _updateUser = (data): Promise<boolean> => {
    return new Promise((resolve, reject) => {
      try {
        const reqUrl = `${API_ULRS.url}/users/${this.userId}`;

        axios.post(reqUrl, JSON.stringify(data)).then(res => {
          if (res.data.success) {
            this._refetchUser();
            resolve(true);
          } else {
            reject(false);
          }
        });
      } catch (error) {
        console.error(error);
        reject(false);
      }
    });
  };

  _logout = (): Promise<boolean> => {
    this.userStoreloading = true;
    return new Promise((resolve, reject) => {
      AsyncStorage.multiRemove([
        "tpRegister",
        "tpUser",
        "tpRouter",
        "authToken",
        "tpPointOfSale",
        "tpOrganization"
      ])
        .then(() => {
          this.rootStore.organizationStore.organizationId = null;
          this.rootStore.organizationStore.organization = null;
          this.rootStore.organizationStore.organizationGroups = null;
          this.rootStore.pointOfSaleStore.pointOfSale = null;
          this.rootStore.pointOfSaleStore.pointofSaleId = null;
          this.rootStore.registerStore._emptyCart();
          this.rootStore.registerStore.cartItems = [];
          this.rootStore.registerStore.currentStep = RegisterSteps.Shopping;
          this.rootStore.registerStore.isReturn = false;
          this.rootStore.registerStore.isShopping = false;
          this.rootStore.registerStore.totalSum = 0;
          this.rootStore.registerStore.transactionId = null;
          this.rootStore.registerStore.transactionReference = null;
          this.user = null;
          this.userId = null;
          this.authToken = null;

          this.userStoreloading = false;

          resolve(true);
        })
        .catch(err => {
          console.error(err);
          this.userStoreloading = false;
          reject(false);
        });
    });
  };

  getUser = (userId): Promise<User> => {
    return new Promise((resolve, reject) => {
      axios.get(`${API_ULRS.url}/users/${userId}`).then(res => {
        if (res) {
          console.log("USER DATA: ", res.data);
          resolve(res.data.data);
        } else {
          console.error("NOOOOOO");
          reject(null);
        }
      });
    });
  };
  @action
  saveUser = (data): Promise<boolean> => {
    return new Promise((resolve, reject) => {
      try {
        const reqUrl = `${API_ULRS.url}/users/${data.id}`;

        axios.post(reqUrl, JSON.stringify(data)).then(res => {
          if (res.data.success) {
            this._refetchUser();
            this.rootStore.organizationStore._refetchOrganization();
            resolve(true);
          } else {
            reject(false);
          }
        });
      } catch (error) {
        console.error(error);
        reject(false);
      }
    });
  };

  removeUserFromOrganization = (userId: string): Promise<boolean> => {
    return new Promise((resolve, reject) => {
      try {
        axios
          .get(
            `${API_ULRS.url}/organization/${this.rootStore.organizationStore.organizationId}/removeuser/${userId}`
          )
          .then(res => {
            if (res.data.success) {
              this.rootStore.organizationStore._refetchOrganization();
              resolve(true);
            } else {
              reject(false);
            }
          });
      } catch (error) {
        console.error(error);
        reject(false);
      }
    });
  };
}

// export const UserStoreContext = createContext(new UserStore());
