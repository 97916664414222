import { observer } from "mobx-react";
import React, { useContext, useEffect, useState } from "react";
import { Image, Switch, View } from "react-native";
import { Button, Text } from "react-native-elements";
import { RouteComponentProps } from "react-router";
import BackButton from "../components/common/BackButton";
import FullPageActivityIndicator from "../components/common/FullPageActivityIndicator";
import { QuickButton } from "../components/common/QuickButton";
import { withAuthorization } from "../components/containers/withAuthorization";
import { HeaderTitleSubtitle } from "../components/headerItems/HeaderTitleSubtitle";
import { LargeHeaderIcon } from "../components/headerItems/LargeHeaderIcon";
import { TpIconTeam3 } from "../components/Icon";
import {
  Content,
  ContentFixed,
  ContentScroll,
} from "../components/layout/Content";
import { FullPageWrapper } from "../components/layout/FullPageWrapper";
import { Header } from "../components/layout/Header";
import Modal from "../components/modal/Modal";
import GroupsPointOfSaleList from "../components/pointofsale/GroupsPointOfSaleList";
import { APP_PATHS } from "../config";
import AddUserForm from "../containers/AddUserForm";
import { theme } from "../lib/styles/theme";
import { RootStoreContext } from "../stores/RootStore";

interface OrganizationHomeScreenProps extends RouteComponentProps {}

const OrganizationHomeScreen: React.SFC<OrganizationHomeScreenProps> = observer(
  ({ history }) => {
    // console.log("START HOME");
    const [loading, setLoading] = useState(true);
    const { organizationStore, userStore, pointOfSaleStore } = useContext(
      RootStoreContext
    );
    const [showAddUser, setShowAddUser] = useState(false);
    const [showDemoPopUp, setShowDemoPopUp] = useState(false);

    useEffect(() => {
      organizationStore._fetchOrganization();
      pointOfSaleStore.pointofSaleId = "";
      // console.log("Demomode: ", organizationStore.organization.demoMode);
      if (
        organizationStore.organization &&
        organizationStore.organization.demoMode
      ) {
        setShowDemoPopUp(true);
      }
    }, []);
    // console.log("ORG: ", organizationStore.organization);
    // useEffect(() => {
    //   if (DEBUG_PRIVILEGES) {
    //     if (organizationStore.organization.pointOfSales.length) {
    //       organizationStore.organization.pointOfSales.map(pos => {
    //         axios.get(`${API_ULRS.url}/privileges/${pos.id}`).then(res => {
    //           console.log(`POS PRIVILEGES ${pos.name}`, res.data.data);
    //         });
    //       });
    //     }
    //   }
    // }, [organizationStore.organization.pointOfSales]);
    if (
      (!organizationStore.organization &&
        organizationStore.organizationStoreLoading) ||
      userStore.userStoreloading
    )
      return (
        <View
          style={{ flex: 1, justifyContent: "center", alignItems: "center" }}
        >
          <View>
            <Text>{loading ? "loading" : null}</Text>
            <FullPageActivityIndicator color="#ff00ff" />
          </View>
        </View>
      );
    if (
      !organizationStore.organization &&
      !organizationStore.organizationStoreLoading
    ) {
      return (
        <View
          style={{ flex: 1, justifyContent: "center", alignItems: "center" }}
        >
          <Text>inte loading</Text>
        </View>
      );
    }
    let orgLogo;
    if (organizationStore.organization) {
      if (organizationStore.organization.logoUrl128x128) {
        orgLogo = (
          <Image
            style={{ width: 64, height: 64 }}
            source={{
              uri: organizationStore.organization.logoUrl128x128.replace(
                "http:",
                "https:"
              ),
            }}
          />
        );
      } else {
        orgLogo = <TpIconTeam3 size={64} />;
      }
    }
    return (
      <>
        <FullPageWrapper>
          <Modal
            isVisible={showDemoPopUp}
            closeFn={setShowDemoPopUp}
            direction="toTop"
          >
            {showDemoPopUp && (
              <View>
                <Text h3>Välkommen till Demot!</Text>
                <Text style={{ marginVertical: 15 }}>
                  Välkommen Du använder just nu en demoversion av TeamPay.
                </Text>
                <Text style={{ marginVertical: 15 }}>
                  Denna är begränsad vilket innebär att du inte kan ändra
                  inställningar på föreningen eller skapa nya kassor/produkter.
                  Däremot kan du kika runt och öppna en kassa och göra
                  försäljningar.
                </Text>
                <Text style={{ marginVertical: 15 }}>
                  Bjud gärna in dina vänner så att de också får pröva TeamPay.
                </Text>
                <Text style={{ marginVertical: 15 }}>
                  När du kikat runt är det bara att skapa en egen förening, det
                  tar bara ett par minuter!
                </Text>
                <Button
                  onPress={() => setShowDemoPopUp(false)}
                  title="Ok, vi kör!"
                />
              </View>
            )}
          </Modal>
          <Modal
            isVisible={showAddUser}
            closeFn={setShowAddUser}
            direction="toTop"
          >
            {showAddUser && <AddUserForm doneAction={setShowAddUser} />}
          </Modal>
          <Header>
            <BackButton to={APP_PATHS.organization.select} />
            <LargeHeaderIcon>{orgLogo}</LargeHeaderIcon>
            <HeaderTitleSubtitle
              subtitle={userStore.user.name ? userStore.user.name : " "}
              title={
                organizationStore.organization
                  ? organizationStore.organization.name
                  : " "
              }
            />
            {/* <HeaderMenuButton /> */}
          </Header>

          {organizationStore.organization ? (
            <>
              <ContentFixed>
                <View
                  style={{
                    alignSelf: "flex-end",
                    alignItems: "flex-end",
                    paddingVertical: 10,
                    marginTop: 10,
                  }}
                >
                  {/* SKAPA KASSA */}
                  {withAuthorization(
                    <QuickButton
                      onPress={() => history.push(APP_PATHS.pointofsale.create)}
                      title="Skapa en ny kassa"
                      outline
                    />,
                    organizationStore.organization.priviliges,
                    "Organization:CreatePointOfSale",
                    <QuickButton
                      disabled={true}
                      onPress={() => {}}
                      title="Skapa en ny kassa"
                      outline
                    />
                  )}
                  {/* BJUD IN ANVÄNDARE */}
                  {organizationStore.organization.active && (
                    <QuickButton
                      onPress={() => setShowAddUser(true)}
                      title="Bjud in användare"
                    />
                  )}
                  {/* INSTÄLLNINGAR */}
                  {withAuthorization(
                    <QuickButton
                      onPress={() =>
                        history.push(APP_PATHS.organization.settings.home)
                      }
                      title="Inställningar"
                      outline
                    />,
                    organizationStore.organization.priviliges,
                    "Organization:Modify",
                    <QuickButton
                      disabled={true}
                      onPress={() => {}}
                      title="Inställningar"
                      outline
                    />
                  )}
                  {/* INSTÄLLNINGAR */}
                  {withAuthorization(
                    <QuickButton
                      onPress={() =>
                        history.push(APP_PATHS.organization.reports)
                      }
                      title="Rapporter"
                      outline
                    />,
                    organizationStore.organization.priviliges,
                    "Organization:Reports",
                    <QuickButton
                      disabled={true}
                      onPress={() => {}}
                      title="Rapporter"
                      outline
                    />
                  )}
                </View>
              </ContentFixed>
              {/* LISTA KASSOR */}
              {organizationStore.organization.pointOfSales.length > 0 && (
                <ContentScroll>
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Text>Alla kassor</Text>
                    <Switch
                      style={{ marginHorizontal: 30 }}
                      value={userStore.settings.defaultDisplayOpenPos}
                      onValueChange={(value) => {
                        userStore.settings.defaultDisplayOpenPos = !userStore
                          .settings.defaultDisplayOpenPos;
                      }}
                    />
                    <Text>Öppna kassor</Text>
                  </View>
                  <GroupsPointOfSaleList
                    groups={organizationStore.organization.groups}
                    openOnly={userStore.settings.defaultDisplayOpenPos}
                  />
                </ContentScroll>
              )}
              {/* Har inga kassor */}
              {organizationStore.organization.pointOfSales.length < 1 && (
                <Content>
                  <View
                    style={{
                      flex: 1,
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {organizationStore.organization.active ? (
                      <View>
                        <Text h4>Välkommen till din förening!</Text>
                        <Text style={{ paddingVertical: 15 }}>
                          Nu är det dags att skapa din första kassa.
                        </Text>
                        <Button
                          title="Skapa en ny kassa"
                          onPress={() =>
                            history.push(APP_PATHS.pointofsale.create)
                          }
                        />
                      </View>
                    ) : (
                      <View style={{ padding: 30, alignItems: "center" }}>
                        <Text h4>Din testperiod har gått ut!</Text>
                        <Text style={{ paddingVertical: 15 }}>
                          För att fortsätta använda TeamPay behövs ett avtal.
                        </Text>
                        <Text style={{ paddingVertical: 15 }}>
                          Läs mer på hemsidan.
                        </Text>
                      </View>
                    )}
                  </View>
                </Content>
              )}
              {/* inget avtal */}
              {organizationStore.organization.active === false && (
                <ContentFixed>
                  <View
                    style={{
                      padding: 20,
                      backgroundColor: theme.colors.danger,
                    }}
                  >
                    <Text style={{ color: "white", textAlign: "center" }}>
                      Föreningen saknar ett aktivt avtal.
                    </Text>
                  </View>
                </ContentFixed>
              )}
            </>
          ) : (
            <Content center>
              <FullPageActivityIndicator />
            </Content>
          )}
        </FullPageWrapper>
      </>
    );
  }
);

export default OrganizationHomeScreen;

// <View
//   style={{
//     flex: 1
//   }}
// >
//   <Text h2>{organizationStore.organization.name}</Text>
//   <View style={{ justifyContent: "flex-end", alignSelf: "flex-end" }}>
//     <Button
//       title="< Bakåt"
//       type="clear"
//       onPress={() => history.push(APP_PATHS.organization.select)}
//     />
//     <Button
//       title="Inställningar"
//       type="outline"
//       onPress={() => history.push(APP_PATHS.organization.settings.home)}
//     />
//   </View>

//   <View
//     style={{
//       flexDirection: "row",
//       justifyContent: "center",
//       alignItems: "center"
//     }}
//   >
//     <Text>Alla kassor</Text>
//     <Switch
//       value={userStore.settings.defaultDisplayOpenPos}
//       onValueChange={value => {
//         userStore.settings.defaultDisplayOpenPos = !userStore.settings
//           .defaultDisplayOpenPos;
//       }}
//     />
//     <Text>Öppna kassor</Text>
//   </View>

//   <GroupsPointOfSaleList
//     groups={organizationStore.organization.groups}
//     openOnly={userStore.settings.defaultDisplayOpenPos}
//   />
// </View>;
