import { observer } from "mobx-react";
import React, { useContext, useEffect, useState } from "react";
import { Alert, Image, Platform, View } from "react-native";
import { Button, Text } from "react-native-elements";
import { RouteComponentProps } from "react-router";
import BackButton from "../components/common/BackButton";
import FullPageActivityIndicator from "../components/common/FullPageActivityIndicator";
import { withAuthorization } from "../components/containers/withAuthorization";
import { HeaderTitleSubtitle } from "../components/headerItems/HeaderTitleSubtitle";
import { LargeHeaderIcon } from "../components/headerItems/LargeHeaderIcon";
import { TpIconTeam3 } from "../components/Icon";
import Icon from "../components/Icon/Icon";
import { ContentFixed, ContentScroll } from "../components/layout/Content";
import { FullPageWrapper } from "../components/layout/FullPageWrapper";
import { Header } from "../components/layout/Header";
import Modal from "../components/modal/Modal";
import SettingsRow from "../components/settings/SettingsRow";
import { APP_PATHS } from "../config";
import PointOfSaleDetailsForm from "../containers/PointOfSaleDetailsForm";
import { theme } from "../lib/styles/theme";
import { RootStoreContext } from "../stores/RootStore";

interface Props extends RouteComponentProps {}

const PointOfSaleSettingsScreen: React.FC<Props> = observer(({ history }) => {
  const { pointOfSaleStore, organizationStore } = useContext(RootStoreContext);
  const [showPosIdentityModal, setShowPosIdentityModal] = useState(false);

  useEffect(() => {
    pointOfSaleStore._fetchPointOfSale();
  }, []);

  if (pointOfSaleStore.pointOfSaleStoreLoading)
    return <FullPageActivityIndicator color="green" />;

  const { pointOfSale } = pointOfSaleStore;

  let orgLogo;
  if (organizationStore.organization) {
    if (organizationStore.organization.logoUrl128x128) {
      orgLogo = (
        <Image
          style={{ width: 64, height: 64 }}
          source={{
            uri: organizationStore.organization.logoUrl128x128.replace(
              "http:",
              "https:"
            )
          }}
        />
      );
    } else {
      orgLogo = <TpIconTeam3 size={64} />;
    }
  }

  console.log("POSPRIV: ", pointOfSaleStore.pointOfSale.priviliges.slice());
  return (
    <FullPageWrapper>
      <Modal
        direction="toTop"
        isVisible={showPosIdentityModal}
        closeFn={setShowPosIdentityModal}
      >
        {showPosIdentityModal && (
          <PointOfSaleDetailsForm doneAction={setShowPosIdentityModal} />
        )}
      </Modal>

      {/* CONTENT */}
      <Header>
        <BackButton to={APP_PATHS.pointofsale.home} />
        <LargeHeaderIcon>{orgLogo}</LargeHeaderIcon>
        <HeaderTitleSubtitle
          // subtitle={user.name ? user.name : " "}
          subtitle={
            pointOfSaleStore.pointOfSale
              ? pointOfSaleStore.pointOfSale.name
              : " "
          }
          title="Inställningar"
        />
      </Header>
      <ContentFixed>
        <View style={{ marginTop: 40, paddingHorizontal: 15 }}>
          <Text>Här kan du göra diverse inställningar för den här kassan</Text>
        </View>
      </ContentFixed>
      <ContentScroll>
        {/* KASSNAS NAMN OCOH TILLHÖRIGHET */}
        <SettingsTitle title="Allmänt" icon="tp-icon-register" />
        <SettingsRow
          title="Kassans namn &amp; tillhörighet"
          subtitle="Ändra namn eller vilken grupp kassan tillhör."
          path={APP_PATHS.pointofsale.settings.details}
        />

        <SettingsTitle title="Betalsätt" icon="tp-icon-paymentmethods" />
        <SettingsRow
          title="Hantera betalsätt"
          subtitle="Redigera kassans betalsätt"
          path={APP_PATHS.pointofsale.settings.paymentmethods}
        />

        {withAuthorization(
          <>
            <SettingsTitle title="Radera kassa" icon="tp-icon-trash" />
            <Text style={{ paddingVertical: 15 }}>
              Här kan du radera din kassa. Observera att detta inte går att
              ångra. All data som hör till kassan, som produkter och alla
              kopplingar mot användare tas bort. Rapporterna kvartstår under
              föreningens sammanställningar.
            </Text>
            <Button
              buttonStyle={{ backgroundColor: theme.colors.danger }}
              title="Radera kassan"
              onPress={() => {
                console.log("ATTEMPTING TO REMOVE");
                if (Platform.OS === "web") {
                  if (
                    confirm(
                      `Vill du verkligen ta bort kassan ${pointOfSale.name}?`
                    )
                  ) {
                    pointOfSaleStore
                      ._deletePointOfSale(pointOfSale.id)
                      .then(res => {
                        organizationStore._refetchOrganization();
                        history.push(APP_PATHS.organization.home);
                      });
                  } else {
                    // console.log("Cancel Pressed");
                  }
                } else {
                  Alert.alert(
                    "Är du säker?",
                    `Vill du verkligen ta bort kassan ${pointOfSale.name}?`,
                    [
                      {
                        text: "Avbryt",
                        onPress: () => console.log("Cancel Pressed"),
                        style: "cancel"
                      },
                      {
                        text: "Ta bort",
                        onPress: () => {
                          pointOfSaleStore
                            ._deletePointOfSale(pointOfSale.id)
                            .then(res => {
                              history.push(APP_PATHS.organization.home);
                            });
                        }
                      }
                    ],
                    { cancelable: false }
                  );
                }
              }}
            />
          </>,
          pointOfSaleStore.pointOfSale.priviliges,
          "PointOfSale:Delete",
          <></>
        )}
      </ContentScroll>
    </FullPageWrapper>
  );
});

export default PointOfSaleSettingsScreen;

interface SettingsTitleProps {
  title: string;
  icon?: string;
}

const SettingsTitle: React.FC<SettingsTitleProps> = ({ title, icon }) => (
  <View
    style={{
      flexDirection: "row",
      alignItems: "center",
      marginTop: 30,
      backgroundColor: theme.colors.primary,
      borderRadius: 4,
      padding: 8
    }}
  >
    {icon && <Icon name={icon} color="#ffffff" size={24} />}
    <Text
      style={{
        color: "#ffffff",
        marginLeft: icon ? 20 : 0,
        textTransform: "uppercase",
        fontSize: 14,
        fontWeight: "bold"
      }}
    >
      {title}
    </Text>
  </View>
);
