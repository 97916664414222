import { observer } from "mobx-react";
import React, { useContext, useEffect, useState } from "react";
import { FlatList, Image, TouchableOpacity, View } from "react-native";
import { Button, Input, Text } from "react-native-elements";
import { RouteComponentProps } from "react-router";
import BackButton from "../components/common/BackButton";
import FullPageActivityIndicator from "../components/common/FullPageActivityIndicator";
import { HeaderTitleSubtitle } from "../components/headerItems/HeaderTitleSubtitle";
import { LargeHeaderIcon } from "../components/headerItems/LargeHeaderIcon";
import { TpIconCheck, TpIconTeam3, TpIconUser } from "../components/Icon";
import { ContentFixed } from "../components/layout/Content";
import { FullPageWrapper } from "../components/layout/FullPageWrapper";
import { Header } from "../components/layout/Header";
import Modal from "../components/modal/Modal";
import { API_ULRS, APP_PATHS } from "../config";
import AddUserForm from "../containers/AddUserForm";
import UserForm from "../containers/UserForm";
import axiosInstance from "../lib/axios";
import { theme } from "../lib/styles/theme";
import { UserInOrganization } from "../lib/types/users/user-in-organization";
import { elevationShadowStyle } from "../lib/utils/elevationShadowStyle";
import { RootStoreContext } from "../stores/RootStore";

interface Props extends RouteComponentProps {}

const OrganizationUsersScreen: React.FC<Props> = observer(({ history }) => {
  // const [loading, setLoading] = useState(true);
  const [showAdmins, setShowAdmins] = useState(false);
  const [userFilterText, setUserFilterText] = useState("");
  const { organizationStore } = useContext(RootStoreContext);
  const [editUserId, setEditUserId] = useState<string | null>(null);
  const [showEditUser, setShowEditUser] = useState<boolean>(false);
  const [showAddUser, setShowAddUser] = useState<boolean>(false);

  // if (organizationStore.organizationStoreLoading) {
  //   return <FullPageActivityIndicator />;
  // }

  let orgLogo;
  if (organizationStore.organization) {
    if (organizationStore.organization.logoUrl128x128) {
      orgLogo = (
        <Image
          style={{ width: 64, height: 64 }}
          source={{
            uri: organizationStore.organization.logoUrl128x128.replace(
              "http:",
              "https:"
            ),
          }}
        />
      );
    } else {
      orgLogo = <TpIconTeam3 size={64} />;
    }
  }

  console.log("I rendered!");
  return (
    <FullPageWrapper>
      <Modal isVisible={showAddUser} closeFn={setShowAddUser} direction="toTop">
        {showAddUser && <AddUserForm doneAction={setShowAddUser} />}
      </Modal>
      <Modal
        direction="toTop"
        isVisible={showEditUser}
        closeFn={setShowEditUser}
      >
        {showEditUser && (
          <UserForm userId={editUserId} doneAction={setShowEditUser} />
        )}
      </Modal>
      <Header>
        <BackButton to={APP_PATHS.organization.settings.home} />
        <LargeHeaderIcon>{orgLogo}</LargeHeaderIcon>
        <HeaderTitleSubtitle
          subtitle={
            organizationStore.organization
              ? organizationStore.organization.name
              : " "
          }
          title="Användare"
        />
        {/* <HeaderMenuButton /> */}
      </Header>
      <ContentFixed>
        <View
          style={{
            // alignSelf: "stretch",
            alignItems: "flex-end",
            justifyContent: "space-between",
            paddingVertical: 10,
            paddingHorizontal: 15,
            marginTop: 10,
            flexDirection: "row",
          }}
        >
          <Input
            placeholder="Sök ..."
            containerStyle={{ width: "50%" }}
            value={userFilterText}
            onChangeText={(text) => {
              console.log(text);
              setUserFilterText(text);
            }}
          />
          <Button
            type="outline"
            onPress={() => setShowAddUser(true)}
            title={"Lägg till"}
          />
        </View>
      </ContentFixed>
      <View style={{ flex: 1 }}>
        <View
          style={{
            marginVertical: 10,
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <TpIconCheck size={18} color={"#cdcdcd"} />
          <Text style={{ marginHorizontal: 5 }}>Gruppadministratör</Text>
          <TpIconCheck size={18} color={theme.colors.primary} />
          <Text style={{ marginHorizontal: 5 }}>Föreningsadministratör</Text>
        </View>
        {showAdmins ? (
          <AdminsList />
        ) : (
          <UserList
            userFilterText={userFilterText}
            setEditUserId={setEditUserId}
            setShowEditUser={setShowEditUser}
          />
        )}
      </View>
    </FullPageWrapper>
  );
});
export default OrganizationUsersScreen;

interface UserListProps {
  userFilterText?: string;
  setEditUserId: (s: string) => void;
  setShowEditUser: (s: boolean) => void;
}
const UserList: React.FC<UserListProps> = observer(
  ({ userFilterText, setEditUserId, setShowEditUser }) => {
    const { organizationStore } = useContext(RootStoreContext);
    const [orgMembers, setOrgMembers] = useState<UserInOrganization[] | []>([]);
    const [filteredOrgMembers, setFilteredOrgMembers] = useState<
      UserInOrganization[] | []
    >([]);

    useEffect(() => {
      if (organizationStore.organization.members) {
        console.log("ALL MEMBERS: ", organizationStore.organization.members);
        let tmpOrgMembers = [];
        organizationStore.organization.members.map((member) => {
          tmpOrgMembers.push({
            userName: member.user.name,

            userId: member.userId,
            userRole: member.role,
            userMobile: member.user.mobileNumber,
            userEmail: member.user.emailAddress,
          });
        });
        setOrgMembers(tmpOrgMembers.slice());
        setFilteredOrgMembers(tmpOrgMembers.slice());
        // setOrgMembers(organizationStore.organization.members.slice());
      }
    }, [organizationStore.organization.members]);

    useEffect(() => {
      console.log("FILTERTEXTEFFEKT");
      if (userFilterText) {
        console.log("FILTERTEXTEFFEKT med filter");
        setFilteredOrgMembers(
          orgMembers.filter((item) => {
            console.log("ITEM: ", item);
            if (item.userMobile) {
              return item.userMobile.includes(userFilterText);
            }
          })
        );
      }
      // else {
      //   console.log("FILTERTEXTEFFEKT utan filter");
      //   setFilteredOrgMembers(orgMembers);
      // }
    }, [userFilterText]);

    return (
      <>
        {userFilterText && (
          <Text style={{ textAlign: "right" }}>
            {filteredOrgMembers.length} resultat
          </Text>
        )}
        <View
          style={{
            padding: 10,
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <View>
            <Text style={{ fontWeight: "bold" }}>Alla användare</Text>
          </View>
          <View>
            <Text style={{ fontWeight: "bold" }}>Admin</Text>
          </View>
        </View>
        <FlatList
          windowSize={7}
          removeClippedSubviews={true}
          data={filteredOrgMembers.slice()}
          renderItem={({ item }) => {
            console.log(item);
            return (
              <UserItem
                member={item}
                setEditUserId={setEditUserId}
                setShowEditUser={setShowEditUser}
              />
            );
          }}
          keyExtractor={(item) => item.userId}
        />
      </>
    );
  }
);

interface UserItemProps {
  member: UserInOrganization;

  setEditUserId: (s: string) => void;
  setShowEditUser: (s: boolean) => void;
}
const UserItem: React.FC<UserItemProps> = ({
  member,
  setEditUserId,
  setShowEditUser,
}) => {
  // console.log(member);
  const { organizationStore } = useContext(RootStoreContext);

  const admin = organizationStore._groupOrSuperAdmin(member.userId);
  console.log("ADMIN? ", admin);
  return (
    <TouchableOpacity
      key={member.userId}
      onPress={() => {
        setEditUserId(member.userId);
        setShowEditUser(true);
      }}
    >
      <View
        style={{
          marginHorizontal: 10,
          marginVertical: 5,
          backgroundColor: "#fff",
          paddingHorizontal: 15,
          paddingVertical: 10,
          borderRadius: 8,
          justifyContent: "flex-start",
          alignItems: "center",
          flexDirection: "row",
          ...elevationShadowStyle(10),
        }}
      >
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            flex: 1,
            alignSelf: "stretch",
          }}
        >
          <View
            style={{
              flexDirection: "row",
              alignSelf: "stretch",
              alignItems: "center",
              flex: 1,
            }}
          >
            <View>
              <TpIconUser size={40} />
            </View>
            <View style={{ paddingVertical: 5, paddingHorizontal: 15 }}>
              {member.userName ? (
                <Text>
                  {member.userName}
                  {/* {`${member.user.firstName ? member.user.firstName : ""} ${
                  // member.user.lastName ? member.user.lastName : ""
                  mem
                }`} */}
                </Text>
              ) : (
                <Text style={{ color: "#aeaeae", fontStyle: "italic" }}>
                  Ej angett
                </Text>
              )}
              <Text>
                {member.userMobile}
                {/* {member.user.mobileNumber ? member.user.mobileNumber : ""} */}
              </Text>
              {member.userEmail ? (
                <Text>
                  {member.userEmail}
                  {/* {`${member.user.firstName ? member.user.firstName : ""} ${
                  // member.user.lastName ? member.user.lastName : ""
                  mem
                }`} */}
                </Text>
              ) : (
                <Text style={{ color: "#aeaeae", fontStyle: "italic" }}>
                  Ej angett
                </Text>
              )}
            </View>
          </View>
          {admin && (
            <View
              style={{
                flexShrink: 1,
                width: 60,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <TpIconCheck
                size={18}
                color={admin === "super" ? theme.colors.primary : "#cdcdcd"}
              />
            </View>
          )}
        </View>
      </View>
    </TouchableOpacity>
  );
};

interface AdminsListProps {
  userFilterText?: string;
}
const AdminsList: React.FC<AdminsListProps> = () => {
  const [loading, setLoading] = useState(true);
  const { organizationStore } = useContext(RootStoreContext);
  const [orgAdmins, setOrgAdmins] = useState();
  const [done, setDone] = useState(false);

  // fetch all members for each group

  useEffect(() => {
    const fetchGroup = async (groupId) => {
      return new Promise(async (resolve, reject) => {
        let tmpResponse = [];
        const { data } = await axiosInstance.get(
          `${API_ULRS.url}/organization/${organizationStore.organizationId}/groups/${groupId}`
        );
        // console.log("RESPONSEDATA", data);
        if (data.data.members) {
          data.data.members.map((member) => {
            if (member.role === "Admin") {
              tmpResponse.push({
                userName: member.user.name,

                userId: member.userId,
                userRole: member.role,
                userMobile: member.user.mobileNumber,
                userEmail: member.user.emailAddress,
              });
            }
          });
          // console.log(groupId, tmpResponse);
          resolve(tmpResponse);
        } else {
          reject(null);
        }
      });
    };
    const setup = async () => {
      let tmpGroupedAdmins = [];
      organizationStore.organization.groups.map(async (group) => {
        tmpGroupedAdmins.push({
          groupId: group.id,
          title: group.name,
          data: await fetchGroup(group.id),
        });
      });
      console.log("******", tmpGroupedAdmins);

      setOrgAdmins(tmpGroupedAdmins);
      // setFilteredOrgAdmins(tmpGroupedAdmins);
      setLoading(false);
      setDone(true);
    };
    setup();
  }, []);

  if (loading) {
    return <FullPageActivityIndicator />;
  }
  // useEffect(() => {
  //   if (organizationStore.organization.members) {
  //     let tmpOrgMembers = [];
  //     organizationStore.organization.members.map(member => {
  //       tmpOrgMembers.push({
  //         userName: member.user.name,

  //         userId: member.userId,
  //         userRole: member.role,
  //         userMobile: member.user.mobileNumber,
  //         userEmail: member.user.emailAddress
  //       });
  //     });
  //     setOrgMembers(tmpOrgMembers.slice());
  //     setFilteredOrgMembers(tmpOrgMembers.slice());
  //     // setOrgMembers(organizationStore.organization.members.slice());
  //   }
  // }, []);

  // useEffect(() => {
  //   console.log("FILTERTEXTEFFEKT");
  //   if (userFilterText) {
  //     console.log("FILTERTEXTEFFEKT med filter");
  //     setFilteredOrgMembers(
  //       orgMembers.filter(item => item.userMobile.includes(userFilterText))
  //     );
  //   }
  //   // else {
  //   //   console.log("FILTERTEXTEFFEKT utan filter");
  //   //   setFilteredOrgMembers(orgMembers);
  //   // }
  // }, [userFilterText]);
  console.log("pre render", orgAdmins);
  console.log("render");
  return (
    <>
      <Text>{done ? "Klar" : "funderar"}</Text>
      {/* {userFilterText && (
        <Text style={{ textAlign: "right" }}>
          {filteredOrgMembers.length} resultat
        </Text>
      )} */}
      <View
        style={{
          padding: 10,
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <View>
          <Text style={{ fontWeight: "bold" }}>Alla admins</Text>
        </View>
        <View>
          <Text style={{ fontWeight: "bold" }}>Admin</Text>
        </View>
      </View>
      <Text>{orgAdmins.length}</Text>
      {orgAdmins.map((oa, idx) => (
        <View key={oa.groupId}>
          <Text style={{ fontSize: 13, textTransform: "uppercase" }}>
            {oa.title} - titel
          </Text>
          {oa.data.length &&
            oa.data.map((admin) => (
              <UserItem key={admin.userId} member={admin} />
            ))}
        </View>
      ))}
      <Text>end</Text>
      {/* <FlatList
        windowSize={7}
        removeClippedSubviews={true}
        data={filteredOrgMembers.slice()}
        renderItem={({ item }) => <UserItem member={item} />}
        keyExtractor={item => item.userId}
      /> */}
      {/* <SectionList
        sections={orgAdmins}
        keyExtractor={(item, index) => item.groupId}
        renderItem={({ item }) => {
          console.log("ITEM: ", item);
          return <UserItem member={item} />;
        }}
        renderSectionHeader={({ section: { title } }) => {
          console.log(title);
          return <Text>{title}</Text>;
        }}
      /> */}
    </>
  );
};
