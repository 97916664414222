import React from "react";
import { Image } from "react-native";
import OnboardingClosepointofsalePng from "../assets/icons/OnBoarding_banor_tpOnboardingClosepointofsale.png";
import OnboardingCreategroupPng from "../assets/icons/OnBoarding_banor_tpOnboardingCreategroup.png";
import OnboardingCreateorganizationPng from "../assets/icons/OnBoarding_banor_tpOnboardingCreateorganization.png";
import OnboardingCreatepointofsalePng from "../assets/icons/OnBoarding_banor_tpOnboardingCreatepointofsale.png";
import OnboardingCreateprofilePng from "../assets/icons/OnBoarding_banor_tpOnboardingCreateprofile.png";
import OnboardingCreateuserPng from "../assets/icons/OnBoarding_banor_tpOnboardingCreateuser.png";
import OnboardingForsaljningPng from "../assets/icons/OnBoarding_banor_tpOnboardingForsaljning.png";
import OnboardingIdrottPng from "../assets/icons/OnBoarding_banor_tpOnboardingIdrott.png";
import OnboardingKassaimobilenPng from "../assets/icons/OnBoarding_banor_tpOnboardingKassaimobilen.png";
import OnboardingMagisktPng from "../assets/icons/OnBoarding_banor_tpOnboardingMagiskt.png";
import OnboardingOpenpointofsalePng from "../assets/icons/OnBoarding_banor_tpOnboardingOpenpointofsale.png";
import OnboardingOveralltPng from "../assets/icons/OnBoarding_banor_tpOnboardingOverallt.png";
import OnboardingPengarPng from "../assets/icons/OnBoarding_banor_tpOnboardingPengar.png";
import OnboardingSakerhetPng from "../assets/icons/OnBoarding_banor_tpOnboardingSakerhet.png";
import OnboardingStatistikPng from "../assets/icons/OnBoarding_banor_tpOnboardingStatistik.png";
import OnboardingTillsammansPng from "../assets/icons/OnBoarding_banor_tpOnboardingTillsammans.png";

interface Props {
  width?: number;
  height?: number;
  color?: string;
}

const OnboardingClosepointofsale: React.FC<Props> = ({
  width = 512,
  height = 512,
  color
}) => (
  <Image source={OnboardingClosepointofsalePng} style={{ width, height }} />
);
const OnboardingCreategroup: React.FC<Props> = ({
  width = 512,
  height = 512,
  color
}) => <Image source={OnboardingCreategroupPng} style={{ width, height }} />;
const OnboardingCreateorganization: React.FC<Props> = ({
  width = 512,
  height = 512,
  color
}) => (
  <Image source={OnboardingCreateorganizationPng} style={{ width, height }} />
);
const OnboardingCreatepointofsale: React.FC<Props> = ({
  width = 512,
  height = 512,
  color
}) => (
  <Image source={OnboardingCreatepointofsalePng} style={{ width, height }} />
);
const OnboardingCreateprofile: React.FC<Props> = ({
  width = 512,
  height = 512,
  color
}) => <Image source={OnboardingCreateprofilePng} style={{ width, height }} />;
const OnboardingCreateuser: React.FC<Props> = ({
  width = 512,
  height = 512,
  color
}) => <Image source={OnboardingCreateuserPng} style={{ width, height }} />;
const OnboardingForsaljning: React.FC<Props> = ({
  width = 512,
  height = 512,
  color
}) => <Image source={OnboardingForsaljningPng} style={{ width, height }} />;
const OnboardingIdrott: React.FC<Props> = ({
  width = 512,
  height = 512,
  color
}) => <Image source={OnboardingIdrottPng} style={{ width, height }} />;
const OnboardingKassaimobilen: React.FC<Props> = ({
  width = 512,
  height = 512,
  color
}) => <Image source={OnboardingKassaimobilenPng} style={{ width, height }} />;
const OnboardingMagiskt: React.FC<Props> = ({
  width = 512,
  height = 512,
  color
}) => <Image source={OnboardingMagisktPng} style={{ width, height }} />;
const OnboardingOpenpointofsale: React.FC<Props> = ({
  width = 512,
  height = 512,
  color
}) => <Image source={OnboardingOpenpointofsalePng} style={{ width, height }} />;
const OnboardingOverallt: React.FC<Props> = ({
  width = 512,
  height = 512,
  color
}) => <Image source={OnboardingOveralltPng} style={{ width, height }} />;
const OnboardingPengar: React.FC<Props> = ({
  width = 512,
  height = 512,
  color
}) => <Image source={OnboardingPengarPng} style={{ width, height }} />;
const OnboardingSakerhet: React.FC<Props> = ({
  width = 512,
  height = 512,
  color
}) => <Image source={OnboardingSakerhetPng} style={{ width, height }} />;
const OnboardingStatistik: React.FC<Props> = ({
  width = 512,
  height = 512,
  color
}) => <Image source={OnboardingStatistikPng} style={{ width, height }} />;
const OnboardingTillsammans: React.FC<Props> = ({
  width = 512,
  height = 512,
  color
}) => <Image source={OnboardingTillsammansPng} style={{ width, height }} />;

export {
  OnboardingClosepointofsale,
  OnboardingCreategroup,
  OnboardingCreateorganization,
  OnboardingCreatepointofsale,
  OnboardingCreateprofile,
  OnboardingCreateuser,
  OnboardingForsaljning,
  OnboardingIdrott,
  OnboardingKassaimobilen,
  OnboardingMagiskt,
  OnboardingOpenpointofsale,
  OnboardingOverallt,
  OnboardingPengar,
  OnboardingSakerhet,
  OnboardingStatistik,
  OnboardingTillsammans
};
