import React from "react";
import { StyleSheet, Text, TouchableOpacity, View } from "react-native";
import { theme } from "../../lib/styles/theme";
import { elevationShadowStyle } from "../../lib/utils/elevationShadowStyle";

interface Props {
  title?: string;
  style?: {};
  textStyle?: {};
  onPress?: any | null;
  outline?: boolean;
  disabled?: boolean;
}

export const Button: React.FC<Props> = props => {
  const {
    title = "Nästa",
    style,
    textStyle,
    onPress = null,
    outline = false,
    disabled = false
  } = props;

  return (
    <View>
      <TouchableOpacity
        disabled={disabled}
        onPress={disabled ? null : onPress}
        style={{
          alignSelf: "center",
          marginVertical: 10,
          height: 40,

          justifyContent: "center",
          alignItems: "center",
          opacity: disabled ? 0.5 : 1
        }}
      >
        <View
          style={[
            outline ? styles.buttonOutline : styles.buttonDefault,
            styles.button,
            style
          ]}
        >
          <Text
            style={[
              outline ? styles.textOutline : styles.textDefault,
              styles.text,
              textStyle
            ]}
          >
            {title}
          </Text>
        </View>
      </TouchableOpacity>
    </View>
  );
};

// export const DisabledButton:React.FC<Props> = props => {
//   return (

//   )
// }

const styles = StyleSheet.create({
  text: {
    textTransform: "uppercase",
    fontSize: 12
  },
  textOutline: {
    color: theme.colors.primary
  },
  textDefault: {
    color: "white"
  },
  button: {
    display: "flex",
    height: 40,
    paddingHorizontal: 20,
    borderRadius: 25,
    justifyContent: "center",
    alignItems: "center",

    ...elevationShadowStyle(10, theme.colors.primary)

    // shadowColor: theme.colors.primary,
    // shadowOpacity: 0.4,
    // shadowOffset: { height: 5, width: 0 },
    // shadowRadius: 10
  },

  buttonOutline: {
    backgroundColor: "#FFFFFF",
    borderColor: theme.colors.primary,
    borderWidth: 1,
    borderStyle: "solid"
  },
  buttonDefault: {
    backgroundColor: theme.colors.primary
  }
});
