import { observer } from "mobx-react";
import React, { useContext, useEffect, useState } from "react";
import { Image, TouchableOpacity, View } from "react-native";
import { RouteComponentProps } from "react-router";
import BackButton from "../components/common/BackButton";
import FullPageActivityIndicator from "../components/common/FullPageActivityIndicator";
import { QuickButton } from "../components/common/QuickButton";
import { withAuthorization } from "../components/containers/withAuthorization";
import { HeaderTitleSubtitle } from "../components/headerItems/HeaderTitleSubtitle";
import { LargeHeaderIcon } from "../components/headerItems/LargeHeaderIcon";
import { TpIconTeam3 } from "../components/Icon";
import { ContentFixed, ContentScroll } from "../components/layout/Content";
import { FullPageWrapper } from "../components/layout/FullPageWrapper";
import { Header } from "../components/layout/Header";
import Modal from "../components/modal/Modal";
import PointOfSaleItemsListItem from "../components/pointofsale/PointOfSaleItemsListItem";
import { APP_PATHS } from "../config";
import AddItemForm from "../containers/AddItemForm";
import EditItemForm from "../containers/EditItemForm";
import { sortByDescription } from "../lib/utils/sort";
import { RootStoreContext } from "../stores/RootStore";

interface Props extends RouteComponentProps {}

const PointOfSaleItemsScreen: React.FC<Props> = observer(({ history }) => {
  const [showEditItem, setShowEditItem] = useState(false);
  const [showEditItemId, setShowEditItemId] = useState();

  const [showAddItem, setShowAddItem] = useState(false);
  const { pointOfSaleStore, organizationStore } = useContext(RootStoreContext);

  useEffect(() => {
    pointOfSaleStore._fetchPointOfSale();
  }, []);

  if (pointOfSaleStore.pointOfSaleStoreLoading)
    return <FullPageActivityIndicator color="green" />;

  const { pointOfSale } = pointOfSaleStore;

  let orgLogo;
  if (organizationStore.organization) {
    if (organizationStore.organization.logoUrl128x128) {
      orgLogo = (
        <Image
          style={{ width: 64, height: 64 }}
          source={{
            uri: organizationStore.organization.logoUrl128x128.replace(
              "http:",
              "https:"
            )
          }}
        />
      );
    } else {
      orgLogo = <TpIconTeam3 size={64} />;
    }
  }
  return (
    <FullPageWrapper>
      <Modal
        isVisible={showEditItem}
        closeFn={setShowEditItem}
        direction="toTop"
      >
        {showEditItem && (
          <EditItemForm doneAction={setShowEditItem} itemId={showEditItemId} />
        )}
      </Modal>
      <Modal isVisible={showAddItem} closeFn={setShowAddItem} direction="toTop">
        {showAddItem && <AddItemForm doneAction={setShowAddItem} />}
      </Modal>
      <Header>
        <BackButton to={APP_PATHS.pointofsale.home} />
        <LargeHeaderIcon>{orgLogo}</LargeHeaderIcon>
        <HeaderTitleSubtitle
          // subtitle={user.name ? user.name : " "}
          subtitle={
            pointOfSaleStore.pointOfSale
              ? pointOfSaleStore.pointOfSale.name
              : " "
          }
          title="Produkter"
        />
        {/* <HeaderMenuButton /> */}
      </Header>
      <ContentFixed>
        <View
          style={{
            alignSelf: "flex-end",
            alignItems: "flex-end",
            paddingVertical: 10,
            marginTop: 10
          }}
        >
          {withAuthorization(
            <QuickButton
              onPress={() => setShowAddItem(true)}
              title="Lägg till ny produkt"
            />,
            pointOfSaleStore.pointOfSale.priviliges,
            "PointOfSale:CreateItem",
            <></>
          )}
          {withAuthorization(
            <QuickButton
              onPress={() => history.push(APP_PATHS.pointofsale.categories)}
              title="Kategorier"
              outline
            />,
            pointOfSaleStore.pointOfSale.priviliges,
            "PointOfSale:CreateItem",
            <></>
          )}
        </View>
      </ContentFixed>
      <ContentScroll>
        {pointOfSale &&
          pointOfSale.items.length > 0 &&
          pointOfSale.items
            .slice()
            .sort(sortByDescription)
            .map(node => {
              return (
                <TouchableOpacity
                  onPress={() => {
                    setShowEditItemId(node.id);
                    setShowEditItem(true);
                  }}
                  key={node.id}
                >
                  <PointOfSaleItemsListItem item={node} />
                </TouchableOpacity>
              );
            })}
      </ContentScroll>
    </FullPageWrapper>
  );
});

export default PointOfSaleItemsScreen;
