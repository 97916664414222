import { Formik } from "formik";
import { observer } from "mobx-react";
import React, { useContext, useEffect, useState } from "react";
import { Image, View } from "react-native";
import { Button, Input, Text } from "react-native-elements";
import { RouteComponentProps } from "react-router";
import BackButton from "../components/common/BackButton";
import { HeaderTitleSubtitle } from "../components/headerItems/HeaderTitleSubtitle";
import { LargeHeaderIcon } from "../components/headerItems/LargeHeaderIcon";
import { TpIconTeam3, TpIconUser } from "../components/Icon";
import { ContentFixed, ContentScroll } from "../components/layout/Content";
import { FullPageWrapper } from "../components/layout/FullPageWrapper";
import { Header } from "../components/layout/Header";
import { APP_PATHS } from "../config";
import { UserInOrganization } from "../lib/types/users/user-in-organization";
import { elevationShadowStyle } from "../lib/utils/elevationShadowStyle";
import { RootStoreContext } from "../stores/RootStore";

interface Props extends RouteComponentProps {}

const OrganizationSuperAdminScreen = observer(({ history }) => {
  const { organizationStore } = useContext(RootStoreContext);
  const [admins, setAdmins] = useState([]);

  useEffect(() => {
    // filter admins
    const tmpAdmins = organizationStore.organization.members.filter(
      member => member.role === "Admin"
    );
    setAdmins(tmpAdmins.slice());
  }, []);

  let orgLogo;
  if (organizationStore.organization) {
    if (organizationStore.organization.logoUrl128x128) {
      orgLogo = (
        <Image
          style={{ width: 64, height: 64 }}
          source={{
            uri: organizationStore.organization.logoUrl128x128.replace(
              "http:",
              "https:"
            )
          }}
        />
      );
    } else {
      orgLogo = <TpIconTeam3 size={64} />;
    }
  }

  // console.log("Superadmins", admins);
  return (
    <FullPageWrapper>
      <Header>
        <BackButton to={APP_PATHS.organization.settings.home} />
        <LargeHeaderIcon>{orgLogo}</LargeHeaderIcon>
        <HeaderTitleSubtitle
          subtitle={
            organizationStore.organization
              ? organizationStore.organization.name
              : " "
          }
          title="Inställningar"
        />
        {/* <HeaderMenuButton /> */}
      </Header>
      <ContentFixed>
        <View style={{ paddingHorizontal: 15, paddingVertical: 30 }}>
          <Text>
            Superadministratörer har tillgång till alla föreningens
            inställningar.
          </Text>
          <Formik
            initialValues={{
              phone: "",
              adminOrganizationId: organizationStore.organizationId,
              role: "Admin"
            }}
            onSubmit={(data, { setSubmitting }) => {
              console.log("FORMIK DATA: ", data);
              const formData = {
                ...data
              };
              organizationStore._addUser(formData, true).then(res => {
                if (res) {
                  setSubmitting(false);
                } else {
                  console.log("CRAP!");
                }
              });
            }}
          >
            {({
              values,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue
            }) => (
              <View style={{ alignSelf: "stretch" }}>
                <View>
                  <Text>Här kan du lägga till en ny Superadministratör</Text>
                  <View style={{ marginVertical: 15 }}>
                    <Input
                      keyboardType="phone-pad"
                      label="Mobilnummer"
                      value={values.phone}
                      // onChangeText={handleChange("phone")}
                      onChangeText={text =>
                        setFieldValue("phone", text.replace(/\D/g, ""))
                      }
                      onBlur={handleBlur("phone")}
                    />
                  </View>
                </View>
                <Button
                  title={isSubmitting ? "Bjuder in..." : "Bjud in"}
                  onPress={() => handleSubmit()}
                  disabled={isSubmitting}
                />
              </View>
            )}
          </Formik>
        </View>
      </ContentFixed>
      <ContentScroll>
        <View>
          <Text>Superadministratörer</Text>
          {admins &&
            admins.length &&
            admins.map(admin => {
              return <UserItem key={admin.userId} member={admin} />;
            })}
        </View>
      </ContentScroll>
    </FullPageWrapper>
  );
});

export default OrganizationSuperAdminScreen;

interface UserItemProps {
  member: UserInOrganization;
}
const UserItem: React.FC<UserItemProps> = ({ member }) => {
  // console.log(member);
  return (
    <View
      style={{
        marginHorizontal: 10,
        marginVertical: 5,
        backgroundColor: "#fff",
        paddingHorizontal: 15,
        paddingVertical: 10,
        borderRadius: 8,
        justifyContent: "flex-start",
        alignItems: "center",
        flexDirection: "row",
        ...elevationShadowStyle(10)
      }}
      key={member.userId}
    >
      <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
        <View
          style={{
            flexDirection: "row",
            alignSelf: "stretch",
            alignItems: "center"
          }}
        >
          <View>
            <TpIconUser size={40} />
          </View>
          <View style={{ paddingVertical: 5, paddingHorizontal: 15 }}>
            {member.user.name ? (
              <Text>
                {member.user.name}
                {/* {`${member.user.firstName ? member.user.firstName : ""} ${
            // member.user.lastName ? member.user.lastName : ""
            mem
          }`} */}
              </Text>
            ) : (
              <Text style={{ color: "#aeaeae", fontStyle: "italic" }}>
                Ej angett
              </Text>
            )}
            <Text>
              {member.user.mobileNumber}
              {/* {member.user.mobileNumber ? member.user.mobileNumber : ""} */}
            </Text>
            {member.user.emailAddress ? (
              <Text>
                {member.user.emailAddress}
                {/* {`${member.user.firstName ? member.user.firstName : ""} ${
            // member.user.lastName ? member.user.lastName : ""
            mem
          }`} */}
              </Text>
            ) : (
              <Text style={{ color: "#aeaeae", fontStyle: "italic" }}>
                Ej angett
              </Text>
            )}
          </View>
        </View>
        {/* {member.role === "Admin" && (
          <View
            style={{
              width: 60,
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <TpIconCheck size={18} color={theme.colors.primary} />
          </View>
        )} */}
      </View>
    </View>
  );
};
