
import React from 'react';
import {
  Svg,
  Circle,
  Ellipse,
  G,
  LinearGradient,
  RadialGradient,
  Line,
  Path,
  Polygon,
  Polyline,
  Rect,
  Symbol,
  Text,
  Use,
  Defs,
  Stop
} from 'react-native-svg';

export default function TpIconArrow(props) {
  return (
    <Svg height="18" id="Lager_1" width="18" viewBox="0 0 18 18" data-name="Lager 1">
    	<Path d="M17.35,8.65,13,4.33a.5.5,0,0,0-.35-.14H9a.5.5,0,0,0-.47.31A.51.51,0,0,0,8.67,5l1.95,2H1a.5.5,0,0,0-.5.5v3A.5.5,0,0,0,1,11h9.62L8.67,13a.51.51,0,0,0-.11.54.5.5,0,0,0,.47.31h3.66a.5.5,0,0,0,.35-.14l4.31-4.32A.48.48,0,0,0,17.35,8.65Z"/>
    </Svg>
  );
}
