import { LinearGradient } from "expo-linear-gradient";
import { observer } from "mobx-react";
import React, { useContext } from "react";
import { Platform, View } from "react-native";
import Onboarding from "react-native-onboarding-swiper";
import {} from "react-native-safe-area-context";
import SafeAreaView from "react-native-safe-area-view";
import { RouteComponentProps } from "react-router";
import styled from "styled-components/native";
import {
  OnboardingKassaimobilen,
  OnboardingOverallt,
  OnboardingPengar,
  OnboardingStatistik,
  OnboardingTillsammans,
} from "../components/OnBoardingIcon";
import { Button } from "../components/primitives/Button";
import { Paragraph } from "../components/primitives/Paragraph";
import { APP_PATHS } from "../config";
import { RootStoreContext } from "../stores/RootStore";

interface Props extends RouteComponentProps {}

const OnboardingScreen: React.FC<Props> = observer(({ history }) => {
  const { userStore } = useContext(RootStoreContext);
  const _complete = () => {
    if (userStore.authToken) {
      if (userStore.isInComplete) {
        history.push(APP_PATHS.onboarding.profile);
      } else {
        history.push(APP_PATHS.organization.select);
      }
    } else {
      history.push(APP_PATHS.onboarding.login);
    }
  };
  return (
    <LinearGradient
      start={[0, 0]}
      end={[1, 1]}
      colors={["#5FC4E1", "#00A13A"]}
      style={{ flex: 1 }}
    >
      <SafeAreaView style={{ flex: 1 }}>
        <Onboarding
          showNext={false}
          // showSkip={true}
          // showPagination={Platform.OS != "web"}
          // showPagination={false}
          // bottomBarColor="transparent"
          bottomBarHighlight={false}
          skipLabel="Hoppa över"
          onSkip={() => {
            // setSkipOnboarding(true);
            _complete();
          }}
          onDone={() => _complete()}
          containerStyles={{
            flex: 1,
            paddingBottom: 0,
            paddingHorizontal: 30,
            // backgroundColor: "rgba(255,255,0,0.4)"
            // justifyContent: "center",
            // alignItems: "center"
            // justifyContent: "flex-start",
            // position: "relative"
          }}
          imageContainerStyles={{
            // justifyContent: "flex-end",
            // flex: "auto",
            // flex: 0.5,
            flex: Platform.OS === "web" ? 0.4 : null,
            paddingBottom: 30,
            // flexBasis: "auto"
            // flexBass: 0.6
          }}
          // titleContainerStyles={{}}
          // subtitleContainerStyles={{}}
          pages={[
            {
              backgroundColor: "transparent",
              // image: <Image source={require("./images/circle.png")} />,
              // image: <Image />,
              image: <Image1 />,
              title: "Välkommen",
              subtitle: <Onboarding1 />,
            },
            {
              backgroundColor: "transparent",
              // prettier-ignore
              image: <Image2 />,
              title: "Öka intäkterna",
              subtitle:
                "Med TeamPay går försäljningen fortare och det är enkelt att snabbt öppna fler kassor när köerna är långa.",
            },
            {
              backgroundColor: "transparent",
              // image: <Image source={require("./images/triangle.png")} />,
              image: <Image3 />,
              title: "Ta betalt överallt",
              subtitle:
                "TeamPay är kassaapparaten som alltid finns nära till hands, redo att snabbt starta upp en försäljning, vart du än är.",
            },
            {
              backgroundColor: "transparent",
              // image: <Image source={require("./images/triangle.png")} />,
              image: <Image4 />,
              title: "Få koll på ekonomin",
              subtitle:
                "Med sammanställningar och uppföljning blir det snabbt och enkelt att ordning och reda i föreningens ekonomi. Säljstatistik minskar matsvinn och förenklar inköp.",
            },
            {
              backgroundColor: "transparent",
              // image: <Image source={require("./images/triangle.png")} />,
              image: <Image5 />,
              title: "Vinnare - Tillsammans",
              subtitle: <Onboarding5 action={() => _complete()} />,
            },
          ]}
        />
      </SafeAreaView>
    </LinearGradient>
  );
});

export default OnboardingScreen;

const Image1 = () => (
  <ImageWrapper>
    <OnboardingKassaimobilen width={200} height={200} />
  </ImageWrapper>
);
const Image2 = () => (
  <ImageWrapper>
    <OnboardingPengar width={200} height={200} />
  </ImageWrapper>
);
const Image3 = () => (
  <ImageWrapper>
    <OnboardingOverallt width={200} height={200} />
  </ImageWrapper>
);
const Image4 = () => (
  <ImageWrapper>
    <OnboardingStatistik width={200} height={200} />
  </ImageWrapper>
);
const Image5 = () => (
  <ImageWrapper>
    <OnboardingTillsammans width={200} height={200} />
  </ImageWrapper>
);

const Onboarding1 = () => (
  <View style={{ alignItems: "center" }}>
    <Paragraph center color="#fff">
      TeamPay är en mobil kassa-app som är enkel att använda, lätt att ta med
      och helt och hållet byggd för att möta föreningars unika behov och
      utmaningar.
    </Paragraph>
  </View>
);

interface Onboarding5Props {
  action: () => void;
}
const Onboarding5: React.FC<Onboarding5Props> = ({ action }) => (
  <View style={{ alignItems: "center" }}>
    <Paragraph center color="#fff">
      TeamPay är intuitiv och rolig att använda samtidigt som ni får feedback på
      vad som uppnås tillsammans. Hjälp din förening att jobba enklare, roligare
      och mer lönsamt!
    </Paragraph>
    <View>
      <Button outline title="Sätt igång!" onPress={() => action()} />
    </View>
  </View>
);

const ImageWrapper = styled(View)`
  width: 200px;
  height: 200px;
  flex-grow: 1;
  /* justify-content: flex-end; */
  /* align-items: center; */
  /* flex-shrink: 0;
  flex: 0; */
  /* align-self: stretch; */
  /* background-color: "blue"; */
`;
