import React, { useContext, useState } from "react";
import { Image, View } from "react-native";
import { Button, Input, Text } from "react-native-elements";
import { RouteComponentProps } from "react-router";
import ForkopImage from "../assets/documents/forkop.jpg";
import InbjudanImage from "../assets/documents/inbjudan.jpg";
import KoImage from "../assets/documents/koaffisch-bla.jpg";
import PrislistaImage from "../assets/documents/prislista.jpg";
import StandigforbattringImage from "../assets/documents/standigforbattring.jpg";
import TipsforattlyckasImage from "../assets/documents/tipsforattlyckas.jpg";
import BackButton from "../components/common/BackButton";
import { HeaderTitleSubtitle } from "../components/headerItems/HeaderTitleSubtitle";
import { LargeHeaderIcon } from "../components/headerItems/LargeHeaderIcon";
import { TpIconTeam3 } from "../components/Icon";
import { ContentFixed, ContentScroll } from "../components/layout/Content";
import { FullPageWrapper } from "../components/layout/FullPageWrapper";
import { Header } from "../components/layout/Header";
import { APP_PATHS } from "../config";
import { elevationShadowStyle } from "../lib/utils/elevationShadowStyle";
import { RootStoreContext } from "../stores/RootStore";

interface Props extends RouteComponentProps {}

const OrganizationPrintMaterialScreen = ({ history }) => {
  const { organizationStore, userStore } = useContext(RootStoreContext);
  const [recipientEmail, setRecipientEmail] = useState(
    userStore.user.emailAddress || ""
  );

  let orgLogo;
  if (organizationStore.organization) {
    if (organizationStore.organization.logoUrl128x128) {
      orgLogo = (
        <Image
          style={{ width: 64, height: 64 }}
          source={{
            uri: organizationStore.organization.logoUrl128x128.replace(
              "http:",
              "https:"
            ),
          }}
        />
      );
    } else {
      orgLogo = <TpIconTeam3 size={64} />;
    }
  }

  return (
    <FullPageWrapper>
      <Header>
        <BackButton to={APP_PATHS.organization.home} />
        <LargeHeaderIcon>{orgLogo}</LargeHeaderIcon>
        <HeaderTitleSubtitle
          subtitle={
            organizationStore.organization
              ? organizationStore.organization.name
              : " "
          }
          title="Utskrivsmaterial"
        />
        {/* <HeaderMenuButton /> */}
      </Header>
      <ContentFixed>
        <View style={{ marginTop: 40, paddingHorizontal: 15 }}>
          <Text>
            Här kan du hämta en massa material som hjälper både införande och
            utförande i din förening.
          </Text>
          <Text style={{ marginTop: 15 }}>
            Fyll i din epost nedan så kommer ett paket på mejlen.
          </Text>
          <View style={{ marginTop: 15, alignItems: "flex-end" }}>
            <Input
              placeholder="Din epostadress"
              value={recipientEmail}
              onChangeText={(text) => setRecipientEmail(text)}
            />
            <Button
              containerStyle={{ marginTop: 5 }}
              title="Skicka"
              onPress={
                () => {
                  console.log(`Skickar printmaterial till ${recipientEmail}`);
                  organizationStore._sendPrintMaterial(recipientEmail);
                }
                // history.push(APP_PATHS.organization.settings.printmaterial)
              }
            />
          </View>
        </View>
      </ContentFixed>
      <ContentScroll>
        <View>
          <Text style={{ fontWeight: "bold", fontSize: 18 }}>
            Paketet innehåller bl.a.
          </Text>
          {/* Inbjudan */}
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              marginVertical: 15,
            }}
          >
            <View
              style={{
                marginRight: 20,
                width: 60,
                ...elevationShadowStyle(8),
              }}
            >
              <img
                src={InbjudanImage}
                alt="Inbjudanaffisch"
                style={{ width: "100%", height: "auto" }}
              />
            </View>
            <View style={{ alignSelf: "stretch", flex: 1 }}>
              <Text style={{ fontWeight: "bold", marginTop: 10 }}>
                Inbjudanaffisch
              </Text>
              <Text>
                En QR-inbjudan till just din förening, med enkla instruktioner
                för att ansluta och de viktigaste stegen i försäljningen.
              </Text>
            </View>
          </View>
          {/* Köaffisch */}
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              marginVertical: 15,
            }}
          >
            <View
              style={{
                marginRight: 20,
                width: 60,
                ...elevationShadowStyle(8),
              }}
            >
              <img
                src={KoImage}
                alt="Köaffisch"
                style={{ width: "100%", height: "auto" }}
              />
            </View>
            <View style={{ alignSelf: "stretch", flex: 1 }}>
              <Text style={{ fontWeight: "bold", marginTop: 10 }}>
                Kö-affisch
              </Text>
              <Text>
                Snabba på köflödet genom att sätta upp en affisch som uppmanar
                kunderna att förbereda sin swish-app.
              </Text>
            </View>
          </View>
          {/* Prislista */}
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              marginVertical: 15,
            }}
          >
            <View
              style={{
                marginRight: 20,
                width: 60,
                ...elevationShadowStyle(8),
              }}
            >
              <img
                src={PrislistaImage}
                alt="Word-mall för prislista "
                style={{ width: "100%", height: "auto" }}
              />
            </View>
            <View style={{ alignSelf: "stretch", flex: 1 }}>
              <Text style={{ fontWeight: "bold", marginTop: 10 }}>
                Mall för prislista
              </Text>
              <Text>
                En enkel prislistemall där det är enkelt att lägga till
                produkter och priser. Bara fylla i.
              </Text>
            </View>
          </View>
          {/* Förköp */}

          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              marginVertical: 15,
            }}
          >
            <View
              style={{
                marginRight: 20,
                width: 60,
                ...elevationShadowStyle(8),
              }}
            >
              <img
                src={ForkopImage}
                alt="Förköpslista"
                style={{ width: "100%", height: "auto" }}
              />
            </View>
            <View style={{ alignSelf: "stretch", flex: 1 }}>
              <Text style={{ fontWeight: "bold", marginTop: 10 }}>
                Förköpslista
              </Text>
              <Text>
                Ta enkelt upp beställningar från tex hemma- och bortalag innan
                match.
              </Text>
            </View>
          </View>

          {/* Matsvinn */}

          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              marginVertical: 15,
            }}
          >
            <View
              style={{
                marginRight: 20,
                width: 60,
                ...elevationShadowStyle(8),
              }}
            >
              <img
                src={ForkopImage}
                alt="Matsvinnslista"
                style={{ width: "100%", height: "auto" }}
              />
            </View>
            <View style={{ alignSelf: "stretch", flex: 1 }}>
              <Text style={{ fontWeight: "bold", marginTop: 10 }}>
                Matsvinnslista
              </Text>
              <Text>
                Få bättre koll på svinn och vilka resurser som krävs vid vilken
                typ av evenmang.
              </Text>
            </View>
          </View>
          {/* Matsvinn */}

          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              marginVertical: 15,
            }}
          >
            <View
              style={{
                marginRight: 20,
                width: 60,
                ...elevationShadowStyle(8),
              }}
            >
              <img
                src={StandigforbattringImage}
                alt="Ständig förbättring"
                style={{ width: "100%", height: "auto" }}
              />
            </View>
            <View style={{ alignSelf: "stretch", flex: 1 }}>
              <Text style={{ fontWeight: "bold", marginTop: 10 }}>
                Ständig förbättring
              </Text>
              <Text>
                Uppmuntra till att alla med engagemang i föreningen kan lämna in
                små som stora förbättringstips. Här finns möjlighet att vinna
                både tid, pengar och kraft.
              </Text>
            </View>
          </View>
          {/* Matsvinn */}

          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              marginVertical: 15,
            }}
          >
            <View
              style={{
                marginRight: 20,
                width: 60,
                ...elevationShadowStyle(8),
              }}
            >
              <img
                src={TipsforattlyckasImage}
                alt="Tips för att lyckas"
                style={{ width: "100%", height: "auto" }}
              />
            </View>
            <View style={{ alignSelf: "stretch", flex: 1 }}>
              <Text style={{ fontWeight: "bold", marginTop: 10 }}>
                Tips för att lyckas tillsammans
              </Text>
              <Text>
                En peppande punktlista över vad som behövs för att ni ska lyckas
                bättre tillsammans vid försäljning i samband med arrangemang.
              </Text>
            </View>
          </View>

          {/* Avslut */}
          <Text
            style={{
              fontWeight: "bold",
              fontSize: 18,
              marginTop: 20,
              marginBottom: 10,
            }}
          >
            Lycka till!
          </Text>
          <Text>
            Och håll ett öga på vår blogg, teampay.se/blog - där vi löpande
            publicerar tips och trix för att öka och förenkla era inkomster!
          </Text>
        </View>
      </ContentScroll>
    </FullPageWrapper>
  );
};

export default OrganizationPrintMaterialScreen;
