import { Formik } from "formik";
import React, { useContext, useState } from "react";
import { Alert, Platform, View } from "react-native";
import { Button, Input, Text } from "react-native-elements";
import ModalDoneCheck from "../components/modal/ModalDoneCheck";
import { API_ULRS } from "../config";
import axiosInstance from "../lib/axios";
import { theme } from "../lib/styles/theme";
import { Group } from "../lib/types/users/group";
import { RootStoreContext } from "../stores/RootStore";

interface EditItemFormProps {
  groupId?: string;
  group?: Group;
  doneAction?: (b: boolean) => void;
}

const EditGroupForm: React.FC<EditItemFormProps> = ({
  groupId,
  group,
  doneAction
}) => {
  // console.log("EDIT FORM ITEMID:", groupId);

  // const [loading, setLoading] = useState(true);
  const [done, setDone] = useState(false);
  const { organizationStore } = useContext(RootStoreContext);

  // console.log("EDIT FORM ITEM:", item);

  // if (loading) return <FullPageActivityIndicator />;
  if (done) return <ModalDoneCheck doneAction={doneAction} />;
  return (
    <Formik
      initialValues={{
        id: group.id,
        name: group.name
      }}
      onSubmit={(data, { setSubmitting }) => {
        // console.log("FORMIK DATA: ", data);
        const reqUrl = `${API_ULRS.url}/organization/${organizationStore.organizationId}/groups/${group.id}`;

        axiosInstance.post(reqUrl, JSON.stringify(data)).then(res => {
          organizationStore._refetchOrganization();
          setSubmitting(false);
          setDone(true);
        });
      }}
    >
      {({
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue
      }) => (
        <View>
          <Text h3>Redigera Grupp</Text>
          <View style={{ height: 20 }} />
          <Input
            label="Namn"
            value={values.name}
            onChangeText={handleChange("name")}
            onBlur={handleBlur("name")}
          />
          <Button
            containerStyle={{
              marginVertical: 15
            }}
            title={isSubmitting ? "Sparar..." : "Spara"}
            onPress={() => handleSubmit()}
            disabled={!values.name || isSubmitting}
          />
          <View style={{ marginTop: 40 }}>
            <Text style={{ fontWeight: "bold", textAlign: "center" }}>
              Varning!
            </Text>
            <Text style={{ textAlign: "center" }}>
              Här kan du ta bort en grupp, detta går inte att ångra och alla
              kassor och dess historik flyttas till hela föreningen.
            </Text>
            <Button
              containerStyle={{
                backgroundColor: theme.colors.danger,
                marginVertical: 15
              }}
              buttonStyle={{ backgroundColor: theme.colors.danger }}
              title={isSubmitting ? "Sparar..." : "Ta bort"}
              onPress={() => {
                console.log("ATTEMPTING TO REMOVE");
                if (Platform.OS === "web") {
                  if (
                    confirm(`Vill du verkligen ta bort gruppen ${group.name}?`)
                  ) {
                    organizationStore._deleteGroup(group.id).then(res => {
                      organizationStore._refetchOrganization();
                      setDone(true);
                    });
                  } else {
                    // console.log("Cancel Pressed");
                  }
                } else {
                  Alert.alert(
                    "Är du säker?",
                    `Vill du verkligen ta bort gruppen ${group.name}?`,
                    [
                      {
                        text: "Avbryt",
                        onPress: () => console.log("Cancel Pressed"),
                        style: "cancel"
                      },
                      {
                        text: "Ta bort",
                        onPress: () => {
                          organizationStore._deleteGroup(group.id).then(res => {
                            organizationStore._refetchOrganization();
                            setDone(true);
                          });
                        }
                      }
                    ],
                    { cancelable: false }
                  );
                }
              }}
            />
          </View>
        </View>
      )}
    </Formik>
  );
};

export default EditGroupForm;
