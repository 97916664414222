// import { Provider as MobxProvider } from "mobx-react";
// import { RouterStore } from "mobx-react-router";
import { observer } from "mobx-react";
import React, { useContext, useEffect, useState } from "react";
import { View } from "react-native";
// import { AsyncStorage } from "react-native";
import { ThemeProvider } from "react-native-elements";
import { SafeAreaProvider } from "react-native-safe-area-context";
import SafeAreaView from "react-native-safe-area-view";
import FullPageActivityIndicator from "./components/common/FullPageActivityIndicator";
import { reactNativeElementsTheme } from "./lib/styles/reactNativeElementsTheme";
import Routes from "./router/Routes";
import { RootStoreContext } from "./stores/RootStore";

// const stores = {
//   routing: routingStore
// };

const App = observer(() => {
  const [loading, setLoading] = useState(true);
  const { routerStore, hydrated } = useContext(RootStoreContext);

  useEffect(() => {
    const initAsync = async () => {
      // await AsyncStorag  e.multiRemove(["tpRouter", "tpUser", "tpOrganization"]);

      // console.log(hydrated);
      if (hydrated) {
        setLoading(false);
        // console.log(routerStore.pathname);
        // console.log("ROUTES RERENDERED");
      }
    };
    initAsync();
  }, [hydrated]);

  if (loading) return <FullPageActivityIndicator color="#ff0000" />;
  return (
    // <MobxProvider {...stores}>
    <SafeAreaProvider>
      <ThemeProvider theme={reactNativeElementsTheme}>
        <SafeAreaView
          style={{
            flex: 1
          }}
          forceInset={{ top: "always" }}
        >
          {/* <View style={{ flexGrow: 1, overflow: "hidden" }}> */}
          <View
            style={{
              position: "absolute",
              flex: 1,
              width: "100%",
              height: "100%",
              // top: 0,
              // right: 0,
              // bottom: 0,
              // left: 0,
              overflow: "hidden",
              // backgroundColor: "pink",
              alignSelf: "stretch"
              // position: "relative"
            }}
          >
            <Routes initialRoute={routerStore.pathname || null} />
          </View>
        </SafeAreaView>
      </ThemeProvider>
    </SafeAreaProvider>
    // </MobxProvider>
  );
});

export default App;
