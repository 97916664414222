import React from "react";
import { View } from "react-native";

interface Props {}

export const LargeHeaderIcon: React.FC<Props> = ({ children }) => {
  return (
    <View
      style={{
        // width: 60,
        // height: 60,
        // justifyContent: "center",
        // alignItems: "center",
        // backgroundColor: "rgba(0,0,0,0.3)",
        // marginRight: 10,
        // position: "relative",
        flexShrink: 0,

        width: 90,
        height: 90,
        justifyContent: "center",
        alignItems: "center",
        // backgroundColor: "rgba(0,0,0,0.3)",
        // marginRight: 5,
        marginBottom: -30
      }}
    >
      {children}
    </View>
  );
};

// Example Content
// ***************
// <TpIconTeam3 width={64} height={64} color="#ff0000" />
