import { observable } from "mobx";
import { persist } from "mobx-persist";
import { RootStore } from "./RootStore";

export class CounterStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }
  @persist @observable count = 0;
}
