import { Formik } from "formik";
import { observer } from "mobx-react";
import React, { useContext, useState } from "react";
import { Switch, View } from "react-native";
import { Button, Input, Text } from "react-native-elements";
import ModalDoneCheck from "../components/modal/ModalDoneCheck";
import { RootStoreContext } from "../stores/RootStore";

interface Props {
  doneAction?: (b: boolean) => void;
}

const OrganizationUserSettings: React.FC<Props> = observer(({ doneAction }) => {
  const { organizationStore } = useContext(RootStoreContext);
  const [done, setDone] = useState(false);
  if (done) return <ModalDoneCheck doneAction={doneAction} />;

  return (
    <Formik
      initialValues={{
        id: organizationStore.organization.id,
        defaultRoleOnInviteBoolean:
          organizationStore.organization.defaultRoleOnInvite === "Guest"
            ? true
            : false,
        defaultInviteValidHoursString: organizationStore.organization.defaultInviteValidHours.toLocaleString()
      }}
      onSubmit={(data, { setSubmitting }) => {
        // console.log("FORMIK DATA: ", data);
        const formData = {
          id: data.id,
          defaultRoleOnInvite: data.defaultRoleOnInviteBoolean
            ? "Guest"
            : "User",
          defaultInviteValidHours: parseInt(data.defaultInviteValidHoursString)
        };
        // console.log("FORM DATA: ", formData);
        if (organizationStore._updateOrganization(formData)) {
          setSubmitting(false);
          setDone(true);
        } else {
          // console.log("CRAP!");
        }
      }}
    >
      {({
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue
      }) => (
        <View style={{ paddingTop: 15, paddingBottom: 30 }}>
          <Text>
            Här kan ni välja om ni vill jobba med permanenta användare som
            stannar kvar och har tillgång till systemet på obegränsad tid, eller
            gästanvändare där användarna bara har tillgång under en viss tid.
          </Text>
          <Text style={{ marginTop: 15 }}>
            Gäst-hantering innebär att inbjudna användare automatiskt raderas
            från föreningen efter en viss tid (24 timmar som standard). Detta
            kan vara fördelaktigt i större föreningar där många föräldrar säljer
            vid få tillfällen.
          </Text>
          <View
            style={{
              marginVertical: 15,
              flexDirection: "row",
              justifyContent: "space-between"
            }}
          >
            <View style={{ alignSelf: "stretch" }}>
              <Text style={{ fontWeight: "bold" }}>Använd gäst-hantering</Text>
            </View>
            <Switch
              value={values.defaultRoleOnInviteBoolean}
              onValueChange={value =>
                setFieldValue("defaultRoleOnInviteBoolean", value)
              }
            />
          </View>
          {values.defaultRoleOnInviteBoolean && (
            <Input
              containerStyle={{ marginBottom: 15 }}
              label="Antal timmar en gäst blir inbjuden"
              value={values.defaultInviteValidHoursString}
              onChangeText={handleChange("defaultInviteValidHoursString")}
              onBlur={handleBlur("defaultInviteValidHoursString")}
            />
          )}
          <Button
            title={isSubmitting ? "Sparar..." : "Spara"}
            onPress={() => handleSubmit()}
            disabled={isSubmitting}
          />
        </View>
      )}
    </Formik>
  );
});

export default OrganizationUserSettings;
