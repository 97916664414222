import { Formik } from "formik";
import { observer } from "mobx-react";
import React, { useContext, useState } from "react";
import { ScrollView, View } from "react-native";
import { Button, Input, Text } from "react-native-elements";
import ModalDoneCheck from "../components/modal/ModalDoneCheck";
import { RootStoreContext } from "../stores/RootStore";

interface Props {
  doneAction?: (b: boolean) => void;
}

const OrganizationDetailsForm: React.FC<Props> = observer(({ doneAction }) => {
  const { organizationStore } = useContext(RootStoreContext);
  const [done, setDone] = useState(false);
  if (done) return <ModalDoneCheck doneAction={doneAction} />;
  return (
    <Formik
      initialValues={{
        id: organizationStore.organization.id,
        name: organizationStore.organization.name || "",
        orgNumber: organizationStore.organization.orgNumber || "",
        phone: organizationStore.organization.phone || "",
        email: organizationStore.organization.email || "",
        web: organizationStore.organization.web || ""
      }}
      onSubmit={(data, { setSubmitting }) => {
        // console.log("FORMIK DATA: ", data);
        if (organizationStore._updateOrganization(data)) {
          setSubmitting(false);
          setDone(true);
        } else {
          // console.log("CRAP!");
        }
      }}
    >
      {({ values, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
        <ScrollView>
          <Text h4>Föreningsinfo</Text>
          <View style={{ paddingVertical: 15 }}>
            <Input
              label="Namn"
              value={values.name}
              onChangeText={handleChange("name")}
              onBlur={handleBlur("name")}
            />
          </View>
          <View style={{ paddingVertical: 15 }}>
            <Input
              label="Organisationsnummber"
              value={values.orgNumber}
              onChangeText={handleChange("orgNumber")}
              onBlur={handleBlur("orgNumber")}
            />
          </View>
          <View style={{ paddingVertical: 15 }}>
            <Input
              label="Telefonnummer"
              value={values.phone}
              onChangeText={handleChange("phone")}
              onBlur={handleBlur("phone")}
            />
          </View>
          <View style={{ paddingVertical: 15 }}>
            <Input
              label="Epost"
              value={values.email}
              onChangeText={handleChange("email")}
              onBlur={handleBlur("email")}
            />
          </View>
          <View style={{ paddingVertical: 15 }}>
            <Input
              label="Hemsida"
              value={values.web}
              onChangeText={handleChange("web")}
              onBlur={handleBlur("web")}
            />
          </View>
          <Button
            title={isSubmitting ? "Sparar..." : "Spara"}
            onPress={() => handleSubmit()}
            disabled={isSubmitting}
          />
        </ScrollView>
      )}
    </Formik>
  );
});

export default OrganizationDetailsForm;
