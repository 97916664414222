import React from "react";
import { Text, TouchableOpacity, View } from "react-native";
import { useSafeArea } from "react-native-safe-area-context";
import { RouteComponentProps, withRouter } from "react-router";
// import { TpIconArrow } from "../Icon";
import TpIconArrow from "../../assets/icons/system/TpIconArrow"; //"../../assets/icons/system/TpIconArrow";

interface BackButtonProps extends RouteComponentProps {
  backButtonLabel?: string | JSX.Element;
  to?: string;
}
const backButtonWithoutRouter: React.FC<BackButtonProps> = ({
  history,
  backButtonLabel,
  to
}) => {
  let label = (
    <View style={{ transform: [{ rotate: "180deg" }] }}>
      <TpIconArrow size={24} />
    </View>
  );
  if (typeof backButtonLabel === "string") {
    label = <Text>{backButtonLabel}</Text>;
  }
  const insets = useSafeArea();
  return (
    <TouchableOpacity
      style={
        {
          // position: "absolute",
          // top: insets.top + 15,
          // left: insets.left + 15
          // alignSelf: "flex-end",
          // backgroundColor: "blue"
        }
      }
      onPress={() => {
        // console.log("BACKING!");
        to ? history.push(to) : history.goBack();
      }}
    >
      <View
        style={{
          borderRadius: 20,
          backgroundColor: "#fff",
          justifyContent: "center",
          alignItems: "center",
          padding: 10
        }}
      >
        {label}
      </View>
    </TouchableOpacity>
  );
};

const BackButton = withRouter(backButtonWithoutRouter);

export default BackButton;
