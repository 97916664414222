import React from "react";
import { Text, View } from "react-native";
import { Heading2 } from "../common/Headings";

interface Props {
  title: string;
  subtitle: string;
}

export const HeaderTitleSubtitle: React.FC<Props> = ({ title, subtitle }) => {
  return (
    <View
      style={{
        // backgroundColor: "purple",
        flex: 1,
        overflow: "hidden"
      }}
    >
      <Heading2 text={title} />
      <Text
        style={{
          textTransform: "uppercase",
          fontSize: 12
        }}
      >
        {subtitle}
      </Text>
    </View>
  );
};
