import React from "react";
import { View } from "react-native";
import { GradientWrapper } from "./GradientWrapper";

interface Props {
  padding?: number;
  backButton?: boolean;
  backButtonLabel?: string | JSX.Element;
  reverse?: boolean;
}

export const TitlePage: React.FC<Props> = ({
  children,
  backButton = false,
  backButtonLabel,
  padding = 30,
  reverse = false
}) => {
  let renderChildren = children;

  if (children) {
    renderChildren = React.Children.map(children, Child => (
      <View style={{ marginVertical: 10 }}>{Child}</View>
    ));
  }
  return (
    <GradientWrapper
      reverse={reverse}
      padding={padding}
      backButton={backButton}
      backButtonLabel={backButtonLabel}
    >
      {/* {children} */}
      {renderChildren}
    </GradientWrapper>
  );
};
