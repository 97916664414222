import React, { useContext, useEffect, useState } from "react";
import {
  Dimensions,
  Picker,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
  View,
} from "react-native";
import { Button, Input, Text } from "react-native-elements";
import { Redirect, RouteComponentProps } from "react-router";
import FullPageActivityIndicator from "../components/common/FullPageActivityIndicator";
import {
  DisplayHeading,
  Heading2,
  Heading4,
} from "../components/common/Headings";
import { TitlePage } from "../components/layout/TitlePage";
import { OnboardingCreatepointofsale } from "../components/OnBoardingIcon";
import { TemplateCard } from "../components/templates/TemplateCard";
// import { TemplateCard } from "../components/template/TemplateCard";
import { DefaultWizard } from "../components/wizards/DefaultWizard";
import { API_ULRS, APP_PATHS } from "../config";
// import { TitlePage } from "../components/wrappers/TitlePage";
// import { Button } from "../elements/Button";
// import { DisplayHeading, Heading2, Heading4 } from "../elements/Headings";
// import { Text } from "../elements/Paragraph";
// import { Input } from "../elements/TextInput";
// import { useCreatePointOfSale } from "../lib/api/createPointOfSale";
import axios from "../lib/axios";
// import { OrganizationContext } from "../lib/contexts/OrganizationContext";
import { theme } from "../lib/styles/theme";
import { elevationShadowStyle } from "../lib/utils/elevationShadowStyle";
import { sortByName } from "../lib/utils/sort";
import { RootStoreContext } from "../stores/RootStore";

interface Props extends RouteComponentProps {}

const PointOfSaleCreateScreen: React.FC<Props> = ({ history }) => {
  const smallScreen = Dimensions.get("window").width < 375;

  // const { currentOrganizationData } = useContext(OrganizationContext);
  const { organizationStore, pointOfSaleStore } = useContext(RootStoreContext);

  const [wizardActive, setWizardActive] = useState(false);
  const [wizardDone, setWizardDone] = useState({ done: false, redirect: null });

  const _onWizardComplete = (str) => {
    console.log("I GOT HERE I GOT HERE!");
    setWizardDone({
      done: true,
      redirect: APP_PATHS.pointofsale.home,
    });
  };

  const findGroupName = (groupId, groups) => {
    console.log("GroupdId: ", groupId, "Groups: ", groups);
    const group = groups.filter((group) => group.id === groupId);

    if (group[0] === undefined) {
      return "";
    }
    return group[0].name;
  };

  if (wizardDone && wizardDone.done && wizardDone.redirect) {
    return <Redirect to={wizardDone.redirect} />;
  }

  if (wizardActive) {
    // useCreateOrganization({ name: "Test89" }).then(data => {
    //   console.log(data);
    // });

    return (
      <DefaultWizard
        initialValues={{
          organizationId: organizationStore.organizationId,
          name: "",
          groupId: organizationStore.organization.groups[0].id,
          createGroup: "",
          selectedTemplate: "",
          selectedTemplateId: "",
          seletedTemplateItems: [],
          copyPosId: "",
          posCreationMode: "empty",
        }}
        cancel={() => history.push(APP_PATHS.organization.home)}
        onSubmit={pointOfSaleStore._createPointOfsale}
        afterSubmit={_onWizardComplete}
      >
        <DefaultWizard.Step>
          {({ onChangeValue, values, onSubmit, afterSubmit }) => (
            <>
              <Heading2 text="1/4. Allmänt om kassan" />
              <Text style={{ paddingVertical: 15 }}>
                Vi börjar enkelt, kassans namn och vilken del av föreningen som
                den tillhör.
              </Text>
              <Text>
                'Hela föreningen' betyder att kassan är en föreningsövergripande
                kassa, t.ex 'Kiosk'. Väljer du däremot att kassan ska tillhöra
                en viss grupp så markeras den som en t.ex 'F10'-kassa, vilket
                gör den enklare att hitta och följa upp.
              </Text>
              <Text style={{ marginTop: 30, fontWeight: "bold" }}>
                Kassans namn
              </Text>
              <Input
                value={values.name}
                onChangeText={(text) => {
                  onChangeValue("name", text);
                }}
              />
              <Text style={{ marginTop: 30, fontWeight: "bold" }}>
                Kassan tillhör
              </Text>
              <Picker
                selectedValue={values.groupId}
                onValueChange={(value) => {
                  onChangeValue("groupId", value);
                  onChangeValue("createGroup", "");
                }}
              >
                {organizationStore.organization.groups.length > 0 &&
                  organizationStore.organization.groups.map((group) => (
                    <Picker.Item
                      key={group.id}
                      value={group.id}
                      label={group.name}
                    />
                  ))}
              </Picker>
              {/* <TouchableOpacity
                onPress={() => {
                  onChangeValue("groupId", group.id);
                  onChangeValue("createGroup", "");
                }}
              >
                <View
                  key={group.id}
                  style={{
                    marginVertical: 2,
                    borderRadius: 4,
                    borderWidth: 1,
                    borderColor: theme.colors.brand,
                    paddingHorizontal: 15,
                    backgroundColor:
                      values.groupId === group.id ? theme.colors.brand : "#fff"
                  }}
                >
                  <Text
                    style={{
                      color:
                        values.groupId === group.id ? "#fff" : theme.colors.text
                    }}
                  >
                    {group.name}
                  </Text>
                </View>
              </TouchableOpacity>
              ))}*/}
              <Text style={{ marginTop: 30 }}>
                Du kan även här skapa en ny grupp
              </Text>
              <Input
                value={values.createGroup}
                onChangeText={(text) => {
                  if (text) {
                    onChangeValue("groupId", "");
                  }
                  onChangeValue("createGroup", text);
                }}
              />
            </>
          )}
        </DefaultWizard.Step>
        <DefaultWizard.Step>
          {({ onChangeValue, values, onSubmit, afterSubmit }) => {
            return (
              <>
                <Heading2 text="2/4. Betalsätt" />
                <Text style={{ paddingVertical: 15 }}>
                  Här kan du lägga till ett swishnummer för kassan som uppkommer
                  per automatik vid varje öppning.
                </Text>
                <View>
                  <Text>
                    Välj ett övergripande swishnummer eller fyll i ett nedan
                  </Text>
                  <Text style={{ fontWeight: "bold" }}>
                    Sparade swishnummer
                  </Text>
                  <Picker
                    selectedValue={values.pm_recipient}
                    onValueChange={(value, idx) => {
                      onChangeValue("pm_recipient", value);
                      onChangeValue(
                        "pm_description",
                        organizationStore.organization.paymentMethods[idx]
                          .description
                      );
                    }}
                  >
                    {organizationStore.organization.paymentMethods.length > 0 &&
                      organizationStore.organization.paymentMethods.map(
                        (pm) => (
                          <Picker.Item
                            key={pm.id}
                            value={pm.recipient}
                            label={pm.description}
                          />
                        )
                      )}
                  </Picker>
                </View>
                <Text style={{ fontWeight: "bold", marginTop: 30 }}>
                  Swishnummer
                </Text>
                <Input
                  value={values.pm_recipient}
                  onChangeText={(text) => onChangeValue("pm_recipient", text)}
                />
                <Text style={{ fontWeight: "bold", marginTop: 30 }}>
                  Beskrivning (t.ex 'F10 swish')
                </Text>
                <Input
                  value={values.pm_description}
                  onChangeText={(text) => onChangeValue("pm_description", text)}
                />
                <Text style={{ fontStyle: "italic", marginTop: 30 }}>
                  Du kan även lämna detta blankt och senare lägga till ett
                  swishnummer under kassans 'Inställningar'.
                </Text>
              </>
            );
          }}
        </DefaultWizard.Step>
        <DefaultWizard.Step>
          {({ onChangeValue, values }) => {
            return (
              <>
                <Heading2 text="3/4. Produkter" />
                <Text style={{ paddingVertical: 15 }}>
                  Här kan du välja att skapa en tom kassa och lägga till
                  produkterna senare en och en, eller utgå från en befintlig
                  kassa och kopiera dess produkter, eller skapa kassan utifrån
                  en mall.
                </Text>
                <Text style={{ paddingBottom: 15 }}>
                  Väljer du kopiera eller mall får du välja detta i nästa steg.
                </Text>
                <Text style={{ paddingBottom: 15, fontWeight: "bold" }}>
                  Jag vill...
                </Text>
                <Button
                  type={
                    values.posCreationMode === "empty" ? "solid" : "outline"
                  }
                  title="Skapa en tom kassa"
                  onPress={() => {
                    onChangeValue("posCreationMode", "empty");
                  }}
                  style={{ marginBottom: 15 }}
                />

                <Button
                  disabled={
                    organizationStore.organization.pointOfSales.length < 1
                  }
                  type={values.posCreationMode === "copy" ? "solid" : "outline"}
                  title="Kopiera en befintlig kassa"
                  onPress={() => {
                    onChangeValue("posCreationMode", "copy");
                  }}
                  style={{ marginBottom: 15 }}
                />
                <Button
                  type={
                    values.posCreationMode === "template" ? "solid" : "outline"
                  }
                  title="Utgå från en mall"
                  onPress={() => {
                    onChangeValue("posCreationMode", "template");
                  }}
                  style={{ marginBottom: 15 }}
                />
                <Text style={{ paddingBottom: 15, fontStyle: "italic" }}>
                  Det går att korrigera pris och utbud i en befintlig mall.
                </Text>
              </>
            );
          }}
        </DefaultWizard.Step>
        <DefaultWizard.Step finishLabel="Skapa kassan">
          {({ onChangeValue, values, nextStep, onSubmit, afterSubmit }) => {
            const [loadingTemplates, setLoadingTemplates] = useState(true);
            const [templates, setTemplates] = useState(null);
            useEffect(() => {
              const getTemplates = async () => {
                const { data } = await axios.get(
                  // `https://backend-stage.teampay.se/products/78157ef3-ad96-43ee-ae68-7f202c3c9f5c/templates`
                  `${API_ULRS.url}/products/78157ef3-ad96-43ee-ae68-7f202c3c9f5c/templates`
                );
                // console.log(data);
                setTemplates(data.data.sort(sortByName));
                setLoadingTemplates(false);
              };
              getTemplates();
            }, []);
            useEffect(() => {
              if (values.selectedTemplateId != "") {
                const getTemplate = async () => {
                  const { data } = await axios.get(
                    // `https://backend-stage.teampay.se/products/78157ef3-ad96-43ee-ae68-7f202c3c9f5c/templates`
                    `${API_ULRS.url}/products/78157ef3-ad96-43ee-ae68-7f202c3c9f5c/templates/${values.selectedTemplateId}`
                  );

                  onChangeValue("selectedTemplate", data.data);
                  console.log("FETCHED: ", data.data);
                };
                getTemplate();
              }
            }, [values.selectedTemplateId]);
            // if (!templates) {
            //   return null;
            // }

            if (values.posCreationMode === "empty") {
              return (
                <>
                  <Heading2 text="4/4. Tom kassa" />
                  <Text style={{ paddingVertical: 15 }}>
                    Ett tips när man skapar många produkter är att använda
                    mappning av olika sortimentsgrupper. Det går att göra under
                    Hantera produkter – Kategorier.
                  </Text>
                  <Text style={{ paddingVertical: 15, fontWeight: "bold" }}>
                    Lycka till!
                  </Text>
                </>
              );
            }

            if (values.posCreationMode === "copy") {
              return (
                <>
                  <Heading2 text="4/4. Kopiera kassa" />
                  <Text style={{ paddingVertical: 15 }}>
                    Genom att kopiera en befintlig kassa så kopieras samtliga
                    produkter över från den kassan till den här nyskapade
                    kassan.
                  </Text>
                  <View style={{ height: 30 }} />
                  {organizationStore.organization.pointOfSales.length > 0 && (
                    <>
                      <Heading4 text="Välj kassa att kopiera" />
                      <ScrollView
                        style={{ marginHorizontal: -30, paddingVertical: 20 }}
                        horizontal={true}
                        showsHorizontalScrollIndicator={false}
                        decelerationRate="fast"
                      >
                        {organizationStore.organization.pointOfSales.map(
                          (pos) => {
                            return (
                              <View
                                style={{
                                  backgroundColor:
                                    pos.id === values.copyPosId
                                      ? "rgb(0, 161, 58);"
                                      : "#fff",
                                  borderRadius: 8,
                                  marginLeft: 10,
                                  marginRight: 10,
                                  flexGrow: 1,
                                  width: 220,
                                  height: 90,
                                  ...elevationShadowStyle(
                                    10,
                                    theme.colors.brand
                                  ),
                                }}
                              >
                                <TouchableOpacity
                                  key={`select-pos-to-copy-${pos.id}`}
                                  onPress={() => {
                                    onChangeValue("copyPosId", pos.id);
                                    onChangeValue("selectedTemplateId", "");
                                    onChangeValue("selectedTemplate", "");
                                  }}
                                >
                                  <View
                                    style={{
                                      paddingVertical: 20,
                                      paddingHorizontal: 15,
                                      overflow: "hidden",
                                    }}
                                  >
                                    <Text>{pos.name}</Text>
                                    <Text>
                                      {findGroupName(
                                        pos.groupId,
                                        organizationStore.organization.groups
                                      )}
                                    </Text>
                                  </View>
                                  {/*       <View
                              style={{
                                marginHorizontal: 5,
                                marginVertical: 2,
                                borderRadius: 4,
                                borderWidth: 1,
                                borderColor: theme.colors.brand,
                                paddingHorizontal: 15,
                                backgroundColor:
                                  values.copyPosId === pos.id
                                    ? theme.colors.brand
                                    : "#fff"
                              }}
                            >
                              <Text
                                style={{
                                  color:
                                    values.copyPosId === pos.id
                                      ? "#fff"
                                      : theme.colors.text
                                }}
                              >
                                {pos.name}
                              </Text>
                            </View> */}
                                </TouchableOpacity>
                              </View>
                            );
                          }
                        )}
                      </ScrollView>
                    </>
                  )}
                </>
              );
            }

            return (
              <>
                <Heading2 text="4/4. Utgå från mall" />
                <Text style={{ paddingVertical: 15 }}>
                  Välj en mall nedan för att få en utgångspunkt att fortsätta
                  skapa dina produkter ifrån.
                </Text>
                <Text style={{ paddingBottom: 15, fontStyle: "italic" }}>
                  Det går att korrigera pris och utbud i en befintlig mall.
                </Text>
                <View style={{ height: 30 }} />

                {/* templates */}
                {loadingTemplates && (
                  <>
                    <Heading4 text="Välj en mall att utgå ifrån" />
                    <FullPageActivityIndicator />
                  </>
                )}
                {templates && (
                  <>
                    <Heading4 text="Välj en mall att utgå ifrån" />
                    <ScrollView
                      style={{ marginHorizontal: -30, paddingVertical: 20 }}
                      horizontal={true}
                      showsHorizontalScrollIndicator={false}
                      decelerationRate="fast"
                    >
                      {/* <TouchableOpacity onPress={() => null}> */}
                      <View
                        style={{ flexDirection: "row", paddingHorizontal: 20 }}
                      >
                        {templates &&
                          templates.map((template) => {
                            let active = false;
                            if (
                              values &&
                              !!values.selectedTemplateId &&
                              template.id === values.selectedTemplateId
                            ) {
                              active = true;
                            }
                            return (
                              <TemplateCard
                                selected={active}
                                key={`template-list-item-${template.id}`}
                                template={template}
                                onPress={() => {
                                  onChangeValue("copyPosId", "");
                                  onChangeValue(
                                    "selectedTemplateId",
                                    template.id
                                  );
                                  onChangeValue("selectedTemplate", template);
                                }}
                              />
                            );
                          })}
                      </View>
                      {/* </TouchableOpacity> */}
                    </ScrollView>
                    {values && values.selectedTemplate && (
                      <ScrollView horizontal={false} decelerationRate="fast">
                        <Heading4 text={values.selectedTemplate.name} />

                        {values.selectedTemplate.items &&
                          values.selectedTemplate.items.length && (
                            <>
                              <Text>{`${values.selectedTemplate.items.length} ${
                                values.selectedTemplate.items.length === 1
                                  ? " vara"
                                  : " varor"
                              }`}</Text>

                              {values.selectedTemplate.items.map((item) => (
                                <View key={`item-list-${item.id}`}>
                                  <Text style={{ fontWeight: "bold" }}>
                                    {item.description}{" "}
                                    <Text style={{ fontWeight: "normal" }}>
                                      {" "}
                                      ({item.priceInclVat} kr)
                                    </Text>
                                  </Text>
                                </View>
                              ))}
                            </>
                          )}
                      </ScrollView>
                    )}
                  </>
                )}
              </>
            );
          }}
        </DefaultWizard.Step>
      </DefaultWizard>
    );
  }
  return (
    <>
      <TitlePage reverse padding={smallScreen ? 15 : 45}>
        <OnboardingCreatepointofsale
          width={smallScreen ? 128 : 192}
          height={smallScreen ? 128 : 192}
        />

        <DisplayHeading color="#ffffff" text="Skapa en ny kassa" />
        <View style={{ alignSelf: "stretch", maxWidth: 400 }}>
          <Text style={{ color: "#fff", textAlign: "center" }}>
            En ny kassa? Hurra! Det går väldigt fort att skapa ytterligare
            kassor, du kan utgå från en helt tom och själv lägga till produkter,
            utgå från en mall eller kopiera produkter från en befintlig kassa.
          </Text>
        </View>
        <Button
          type="outline"
          containerStyle={{ backgroundColor: "#fff" }}
          title="Sätt igång!"
          onPress={() => setWizardActive(true)}
        />
      </TitlePage>
      <TouchableOpacity
        style={styles.close}
        onPress={() => history.push(APP_PATHS.organization.home)}
      >
        <Text style={{ color: "#ffffff", fontWeight: "bold" }}>X</Text>
      </TouchableOpacity>
    </>
  );
};

export default PointOfSaleCreateScreen;

const styles = StyleSheet.create({
  close: {
    position: "absolute",
    top: 20,
    left: 20,
    width: 40,
    height: 40,
    borderRadius: 20,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: theme.colors.danger,
  },
});
