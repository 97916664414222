import React, { useContext } from "react";
import { Dimensions, StyleSheet, TouchableOpacity, View } from "react-native";
import { Badge, Text } from "react-native-elements";
import { theme } from "../../lib/styles/theme";
import { Category } from "../../lib/types/product/category";
import { elevationShadowStyle } from "../../lib/utils/elevationShadowStyle";
import { RootStoreContext } from "../../stores/RootStore";
import Icon from "../Icon/Icon";

const screenWidth = Dimensions.get("window").width;
let itemCols = 3;
let itemIconSize = 24;
if (screenWidth > 360) {
  itemCols = 4;
}
if (screenWidth > 450) itemCols = 5;
if (screenWidth > 768) {
  itemCols = 6;
  itemIconSize = 32;
}
if (screenWidth > 960) {
  itemCols = 7;
  itemIconSize = 48;
}
const itemWidth = Math.floor((screenWidth - 20 - 10 * itemCols) / itemCols);

interface Props {
  category: Category;
  setRegisterItemsCategory: (s: string) => void;
  badgeCount?: number;
}

const RegisterCategoryButton: React.FC<Props> = ({
  category,
  setRegisterItemsCategory,
  badgeCount
}) => {
  const { registerStore } = useContext(RootStoreContext);

  return (
    <TouchableOpacity
      style={[styles.wrapper, { backgroundColor: theme.colors.primary }]}
      // onLongPress={() => registerStore._removeFromCart(item)}
      onPress={() => {
        setRegisterItemsCategory(category.id);
        console.log("SHOW CATEGORY");
      }}
      // onPress={() => registerStore._addToCart(item)}
    >
      {badgeCount > 0 && (
        <Badge
          badgeStyle={styles.bagdeStyle}
          containerStyle={styles.badge}
          textStyle={styles.badgeText}
          value={badgeCount}
        />
      )}
      <View style={styles.container}>
        {/* ICON */}
        <Icon name="tp-icon-folder" size={itemIconSize} color="#ffffff" />
        {/* PRICE */}
        <Text style={[styles.price, { color: "#fff" }]}>{category.name}</Text>
        {/* DESCRIPTION */}
        <Text style={[styles.description, { color: "#fff" }]}>Flerval</Text>
      </View>
    </TouchableOpacity>
  );
};

export default RegisterCategoryButton;

const styles = StyleSheet.create({
  wrapper: {
    position: "relative",

    width: itemWidth,
    height: itemWidth,
    margin: 5,
    paddingTop: 5,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: "#000",
    backgroundColor: "white",
    ...elevationShadowStyle(3, "rgba(0,0,0,0.3)")
  },

  badge: {
    position: "absolute",
    top: -8,
    right: 2,
    padding: 3
  },
  bagdeStyle: {
    backgroundColor: theme.colors.danger
  },
  badgeText: {
    // fontSize: 13
    fontWeight: "bold"
  },
  container: {
    width: itemWidth,
    height: itemWidth,
    flex: 1,
    alignItems: "center",
    paddingTop: 3
  },
  price: {
    marginVertical: 3,
    fontSize: 14
    // fontWeight: "bold"
  },
  description: {
    fontSize: 11
  }
});
