import { LinearGradient } from "expo-linear-gradient";
import { Formik } from "formik";
import { observer } from "mobx-react";
import React, { useContext, useState } from "react";
import {
  Image,
  Keyboard,
  KeyboardAvoidingView,
  TouchableWithoutFeedback,
  View
} from "react-native";
import { Button, Input, Text } from "react-native-elements";
import { RouteComponentProps } from "react-router";
import { Heading2 } from "../components/common/Headings";
import { FullPageWrapper } from "../components/layout/FullPageWrapper";
import { APP_PATHS } from "../config";
import { useHistory } from "../router";
import { RootStoreContext } from "../stores/RootStore";

interface LoginScreenProps extends RouteComponentProps {}
const LoginScreen: React.FC<LoginScreenProps> = observer(({ history }) => {
  const [verifying, setVerifying] = useState(false);
  let toRender;
  if (verifying) {
    toRender = <ConnectPage setVerifying={setVerifying} />;
  } else {
    toRender = <LoginPage setVerifying={setVerifying} />;
  }

  return (
    <FullPageWrapper>
      <KeyboardAvoidingView behavior="padding" style={{ flex: 1, flexGrow: 1 }}>
        <TouchableWithoutFeedback
          style={{ flex: 1, flexGrow: 1 }}
          onPress={Keyboard.dismiss}
          accessible={false}
        >
          <>
            <View style={{ flex: 4 }}>
              <LinearGradient
                colors={["#00A13A", "#5FC4E1"]}
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  padding: 15,
                  flex: 1,
                  flexGrow: 1
                }}
              >
                {/* {image && ( */}
                <Image
                  source={require("../assets/teampay.png")}
                  style={{ width: 225, height: 92, marginTop: 15 }}
                />
                {/* )} */}
              </LinearGradient>
            </View>
            <View style={{ flex: 6 }}>{toRender}</View>
          </>
        </TouchableWithoutFeedback>
      </KeyboardAvoidingView>
    </FullPageWrapper>
  );
});

export default LoginScreen;

interface LoginAndConnectPageProps {
  setVerifying: (b: boolean) => void;
}
const LoginPage: React.FC<LoginAndConnectPageProps> = observer(
  ({ setVerifying }) => {
    const { userStore } = useContext(RootStoreContext);
    return (
      <View style={{ padding: 30 }}>
        <Heading2 text="Välkommen till TeamPay!" />
        <View style={{ height: 10 }} />
        <Text style={{ marginVertical: 10, textAlign: "center" }}>
          Anslut genom att skriva in ditt mobilnummer, har du ingen användare
          sedan tidigare kommer en att skapas åt dig.
        </Text>
        <Text style={{ marginVertical: 10, textAlign: "center" }}>
          När du klickar på Skicka får du en kod att skriva in.
        </Text>
        <Formik
          initialValues={{
            mobileNumber: ""
          }}
          onSubmit={(data, { setSubmitting }) => {
            if (userStore._connect(data)) {
              // console.log(data);
              setSubmitting(false);
              setVerifying(true);
            } else {
              setSubmitting(false);
              console.error("CRAAAP!");
            }
          }}
        >
          {({
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting
          }) => (
            <>
              <View style={{ marginVertical: 15 }}>
                <Input
                  label="Ditt mobilnummer"
                  autoFocus
                  value={values.mobileNumber}
                  onBlur={handleBlur("mobileNumber")}
                  onChangeText={handleChange("mobileNumber")}
                  onSubmitEditing={() => handleSubmit()}
                />
              </View>
              <Button
                loading={isSubmitting}
                onPress={() => handleSubmit()}
                disabled={isSubmitting}
                title={isSubmitting ? "Skickar..." : "Skicka min kod!"}
              />
            </>
          )}
        </Formik>
      </View>
    );
  }
);

const ConnectPage: React.FC<LoginAndConnectPageProps> = observer(
  ({ setVerifying }) => {
    const { userStore } = useContext(RootStoreContext);
    let history = useHistory();

    return (
      <View style={{ padding: 30 }}>
        <Heading2 text="Perfekt!" />
        <View style={{ height: 10 }} />
        <Text style={{ marginVertical: 10, textAlign: "center" }}>
          Fyll i koden du precis fick som sms till {userStore.authMobileNumber}.
        </Text>
        <Formik
          initialValues={{
            code: ""
          }}
          onSubmit={(data, { setSubmitting }) => {
            userStore
              ._verify(data)
              .then(() => {
                // console.log(data);
                setSubmitting(false);
                if(userStore.isInComplete){
                  history.push(APP_PATHS.onboarding.profile);

                }else{

                  history.push(APP_PATHS.organization.select);
                }
              })
              .catch(err => {
                console.error(err);
                setVerifying(false);
              });
          }}
        >
          {({
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting
          }) => (
            <>
              <View style={{ marginVertical: 15 }}>
                <Input
                  label="Din verifieringskod"
                  autoFocus
                  value={values.code}
                  onBlur={handleBlur("code")}
                  onChangeText={handleChange("code")}
                />
              </View>
              <Button
                loading={isSubmitting}
                onPress={() => handleSubmit()}
                disabled={isSubmitting}
                title={isSubmitting ? "Verifierar..." : "Verifiera!"}
              />
            </>
          )}
        </Formik>
      </View>
    );
  }
);
