import React, { useContext } from "react";
import { View } from "react-native";
import { Button, Text } from "react-native-elements";
import { RouteComponentProps } from "react-router";
import FullPageActivityIndicator from "../components/common/FullPageActivityIndicator";
import GroupsPointOfSaleList from "../components/pointofsale/GroupsPointOfSaleList";
import { APP_PATHS } from "../config";
import { RootStoreContext } from "../stores/RootStore";

interface Props extends RouteComponentProps {}

const PointOfSaleSelectScreen: React.FC<Props> = ({ history }) => {
  const { organizationStore } = useContext(RootStoreContext);
  if (organizationStore.organizationStoreLoading)
    return <FullPageActivityIndicator />;
  return (
    <View>
      <Text h2>Kassor</Text>
      <View style={{ flexDirection: "row" }}>
        <Button
          title="Tillbaka"
          type="clear"
          onPress={() => history.push(APP_PATHS.organization.home)}
        />
      </View>
      <GroupsPointOfSaleList groups={organizationStore.organization.groups} />
    </View>
  );
};

export default PointOfSaleSelectScreen;
