import React from "react";
import { View } from "react-native";
import { Button } from "react-native-elements";
import { theme } from "../../lib/styles/theme";
import { TpIconCheckO } from "../Icon";

interface Props {
  doneAction: (b: boolean) => void;
}

const ModalDoneCheck: React.FC<Props> = ({ doneAction }) => {
  return (
    <View>
      <View
        style={{
          alignSelf: "stretch",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: 40
        }}
      >
        <TpIconCheckO size={60} color={theme.colors.primary} />
      </View>
      <Button onPress={() => doneAction(false)} title="Klar, stäng" />
    </View>
  );
};

export default ModalDoneCheck;
