import { observer } from "mobx-react";
import React, { useContext, useEffect, useState } from "react";
import {
  Dimensions,
  Image,
  Switch,
  TouchableOpacity,
  View,
} from "react-native";
import { Button, Text } from "react-native-elements";
import { RouteComponentProps } from "react-router";
import FullPageActivityIndicator from "../components/common/FullPageActivityIndicator";
import { HeaderTitleSubtitle } from "../components/headerItems/HeaderTitleSubtitle";
import { LargeHeaderIcon } from "../components/headerItems/LargeHeaderIcon";
import { TpIconMenu } from "../components/Icon";
import { ContentFixed } from "../components/layout/Content";
import { FullPageWrapper } from "../components/layout/FullPageWrapper";
import { Header } from "../components/layout/Header";
import Modal from "../components/modal/Modal";
import { OrganizationSelectList } from "../components/organization/OrganizationSelectList";
import { API_ULRS, APP_PATHS } from "../config";
import UserProfileForm from "../containers/UserProfileForm";
import axiosInstance from "../lib/axios";
import { UserInOrganization } from "../lib/types/users/user-in-organization";
import { sortByNameInOrganzitionArray } from "../lib/utils/sort";
import { RootStoreContext } from "../stores/RootStore";

interface OrganizationSelectScreenProps extends RouteComponentProps {}

const screenWidth = Math.round(Dimensions.get("window").width);
const numColumns = Math.floor(screenWidth / 160);

const OrganizationSelectScreen: React.SFC<OrganizationSelectScreenProps> = observer(
  ({ history }) => {
    const { userStore, organizationStore } = useContext(RootStoreContext);
    const myOrgs = userStore.user
      ? userStore.user.memberInOrganizations
          .slice()
          .sort(sortByNameInOrganzitionArray)
      : [];

    const [filterText, setFilterText] = useState("");
    useEffect(() => {
      organizationStore.organization = null;
    }, []);
    const [filteredOrgs, setFilteredOrgs] = useState<UserInOrganization[]>(
      myOrgs
    );
    const [showProfileMenu, setShowProfileMenu] = useState(false);
    const [showMainMenu, setShowMainMenu] = useState(false);

    useEffect(() => {
      organizationStore.organizationId = null;
      console.log(userStore.isInComplete);
      // if (userStore.isInComplete) {
      //   setShowProfileMenu(true);
      // }
    }, []);

    useEffect(() => {
      // console.log("length: ", filterText.length);
      if (filterText.length > 1) {
        setFilteredOrgs(
          myOrgs.filter((org) => {
            // console.log(`f: ${filterText} = n: ${org.name}`);
            return org.organization.name
              .toLowerCase()
              .includes(filterText.toLowerCase());
          })
        );
      } else {
        setFilteredOrgs(myOrgs);
      }
      // console.log(filteredOrgs);
    }, [filterText]);

    if (
      userStore.userStoreloading ||
      organizationStore.organizationStoreLoading
    )
      return <FullPageActivityIndicator />;

    console.log(myOrgs);
    if (!filteredOrgs) setFilteredOrgs(userStore.user.memberInOrganizations);
    return (
      <FullPageWrapper>
        <Modal
          isVisible={showMainMenu}
          closeFn={setShowMainMenu}
          direction="toLeft"
        >
          <View style={{ marginBottom: 30 }}>
            <Text
              style={{
                textAlign: "center",
                fontWeight: "bold",
                marginBottom: 15,
              }}
            >
              Min profil
            </Text>
            <Button
              title="Redigera profil"
              onPress={() => {
                setShowMainMenu(false);
                setShowProfileMenu(true);
              }}
            />
          </View>
          <View style={{ marginBottom: 30 }}>
            <Button
              type="outline"
              title="Logga ut på den här enheten"
              onPress={() => {
                setShowMainMenu(false);
                userStore
                  ._logout()
                  .then(history.push(APP_PATHS.onboarding.onboarding));
              }}
            />
          </View>
        </Modal>
        <Modal
          isVisible={showProfileMenu}
          closeFn={setShowProfileMenu}
          direction="toTop"
        >
          {showProfileMenu && (
            <UserProfileForm doneAction={setShowProfileMenu} forcedUpdate />
          )}
        </Modal>
        <Header>
          <LargeHeaderIcon>
            <Image
              source={require("../assets/teampay-icon.png")}
              style={{ width: 64, height: 64 }}
            />
          </LargeHeaderIcon>
          <HeaderTitleSubtitle
            subtitle={` `}
            title={`Hej${
              userStore.user.firstName
                ? " " + userStore.user.firstName + "!"
                : "!"
            }`}
          />
          <View
            style={{
              width: 40,
              height: 60,
              justifyContent: "center",
              alignItems: "center",
              // backgroundColor: "blue",
              // backgroundColor: "rgba(0,0,0,0.3)",
              // marginLeft: 10
            }}
          >
            <TouchableOpacity
              onPress={() => setShowMainMenu(!showMainMenu)}
              style={{
                width: 40,
                height: 40,
                justifyContent: "center",
                alignItems: "center",
                // backgroundColor: "pink"
              }}
            >
              <TpIconMenu size={18} />
            </TouchableOpacity>
          </View>
        </Header>
        <ContentFixed center>
          <View
            style={{
              paddingTop: 30,
              alignSelf: "center",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text>Vilken förening vill du jobba med idag?</Text>
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                paddingHorizontal: 15,
              }}
            >
              <Button
                title="TeamPay Demo"
                onPress={() => {
                  // lägg till användaren i demoföreningen
                  axiosInstance
                    .post(
                      `${API_ULRS.url}/users/createorupdate`,
                      JSON.stringify({
                        organizationId: "aeac9118-e0c1-41d2-b5b5-36ac4625db36",
                        phone: userStore.user.mobileNumber,
                      })
                    )
                    .then((res) => {
                      organizationStore.organizationId =
                        "aeac9118-e0c1-41d2-b5b5-36ac4625db36";
                      userStore._refetchUser();
                      history.push(APP_PATHS.organization.home);
                    });
                }}
                style={{
                  marginVertical: 10,
                  paddingHorizontal: 10,
                  alignItems: "stretch",
                }}
              />
              <Button
                type="outline"
                title="Skapa en förening"
                onPress={() => history.push(APP_PATHS.organization.create)}
                style={{
                  marginVertical: 10,
                  paddingHorizontal: 10,
                  alignItems: "stretch",
                }}
              />
            </View>
            <Text>eller välj en befintlig nedan</Text>
          </View>
        </ContentFixed>
        {userStore.user.memberInOrganizations.length > 0 ? (
          <>
            {userStore.user.role === "Admin" && (
              <View
                style={{
                  marginTop: 15,
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Text>Alla föreningar</Text>
                <Switch
                  style={{ marginHorizontal: 30 }}
                  value={userStore.settings.contractOnlyOrganizations}
                  onValueChange={(value) => {
                    userStore.settings.contractOnlyOrganizations = !userStore
                      .settings.contractOnlyOrganizations;
                  }}
                />
                <Text>Föreningar med avtal</Text>
              </View>
            )}
            <OrganizationSelectList
              orgs={userStore.user.memberInOrganizations

                .slice()
                .filter((org) => {
                  if (userStore.settings.contractOnlyOrganizations) {
                    if (org.organization.active === true) return org;
                  } else {
                    return org;
                  }
                })
                .sort(sortByNameInOrganzitionArray)}
              filter={false}
              // filter={userStore.user.memberInOrganizations.length > 10}
              filterText={filterText}
              setFilterText={setFilterText}
            />
          </>
        ) : (
          <View>
            <Text>Du verkar inte vara ansluten till någon förening. </Text>
            <Text>
              Vill du skapa ett konto för din förening så kan du klicka på
              "Skapa en föreningen" uppe till höger, alternativt väntar du på en
              inbjudan från en kollega.{" "}
            </Text>
          </View>
        )}
      </FullPageWrapper>
    );
  }
);

export default OrganizationSelectScreen;

// <Text h1>Välj förening</Text>
// <View style={{ flexDirection: "row" }}>
//   <Button
//     title="Login"
//     // onPress={async () =>
//     //   await AsyncStorage.setItem(
//     //     "authToken",
//     //     "E87C10E6-00C4-4E85-A400-D26CCE8A4905"
//     //   ).then(() => {
//     //     // console.log("LOGGED IN & UPDATED");
//     //   })
//     // }
//     onPress={() => history.push(APP_PATHS.onboarding.login)}
//   />
//   {/* <Button title="Fetch me" onPress={() => userStore._fetchUser()} /> */}
//   <Button
//     title="OnBoarding"
//     onPress={() => history.push(APP_PATHS.onboarding.onboarding)}
//   />
//   <Button
//     title="Logout"
//     onPress={() => {
//       userStore
//         ._logout()
//         .then(() => history.push(APP_PATHS.onboarding.onboarding));
//     }}
//   />
// </View>
// <ScrollView
//   horizontal={false}
//   decelerationRate="fast"
//   style={{ alignSelf: "stretch" }}
// >
//   {userStore.user &&
//     userStore.user.memberInOrganizations
//       .slice()
//       .sort(sortByNameInOrganzitionArray)
//       .map(node => {
//         return (
//           <TouchableOpacity
//             // title={node.organization.name}
//             key={node.organization.id}
//             onPress={() => {
//               organizationStore.organizationId = node.organization.id;
//               history.push(APP_PATHS.organization.home);
//             }}
//           >
//             <View style={{ padding: 8 }}>
//               <Text>{node.organization.name}</Text>
//             </View>
//           </TouchableOpacity>
//         );
//       })}
// </ScrollView>
