import { observer } from "mobx-react";
import React, { useContext, useEffect, useState } from "react";
import { Image, TouchableOpacity, View } from "react-native";
import { Button, Input, Text } from "react-native-elements";
import { RouteComponentProps } from "react-router";
import BackButton from "../components/common/BackButton";
import { HeaderTitleSubtitle } from "../components/headerItems/HeaderTitleSubtitle";
import { LargeHeaderIcon } from "../components/headerItems/LargeHeaderIcon";
import { TpIconTeam3, TpIconUser } from "../components/Icon";
import { ContentFixed, ContentScroll } from "../components/layout/Content";
import { FullPageWrapper } from "../components/layout/FullPageWrapper";
import { Header } from "../components/layout/Header";
import Modal from "../components/modal/Modal";
import { APP_PATHS } from "../config";
import AddUserForm from "../containers/AddUserForm";
import UserForm from "../containers/UserForm";
import { UserInOrganization } from "../lib/types/users/user-in-organization";
import { elevationShadowStyle } from "../lib/utils/elevationShadowStyle";
import { RootStoreContext } from "../stores/RootStore";

interface Props extends RouteComponentProps {}

const OrganizationAdminsScreen: React.FC<Props> = observer(({ history }) => {
  const { organizationStore } = useContext(RootStoreContext);
  const [loading, setLoading] = useState(true);
  const [adminList, setAdminList] = useState<any[]>([]);

  const [showAdmins, setShowAdmins] = useState(true);
  const [userFilterText, setUserFilterText] = useState("");
  const [orgAdmins, setOrgAdmins] = useState([]);
  const [done, setDone] = useState(false);
  const [showAddUser, setShowAddUser] = useState<boolean>(false);
  const [showEditUser, setShowEditUser] = useState<boolean>(false);
  const [editUserId, setEditUserId] = useState<string>("");
  const [removeAdminFrom, setRemoveAdminFrom] = useState<string>("");

  useEffect(() => {
    let tmpGroupsArray = [];
    organizationStore.organization.groups.map((group) => {
      let tmpUserInGroups = group.userInGroups.filter((user) => {
        return user.role === "Admin";
      });

      // if group has admin
      if (tmpUserInGroups.length > 0) {
        tmpGroupsArray.push({ group, admins: tmpUserInGroups });
      }
      // console.log(group.name, tmpUserInGroups);
    });
    setOrgAdmins(tmpGroupsArray);
    setAdminList(tmpGroupsArray);
    setLoading(false);
    // console.log("TMPGROUPS: ", tmpGroupsArray);
  }, [organizationStore.organization]);

  useEffect(() => {
    if (userFilterText) {
      if (orgAdmins.length > 0) {
        let tmpGroupsArray = [];
        organizationStore.organization.groups.map((group) => {
          let tmpUserInGroups = group.userInGroups.filter((user) => {
            const tmpUser = organizationStore.organization.users.filter(
              (orgUser) => orgUser.id === user.userId
            )[0];
            console.log("tmpUser: ", tmpUser);
            //  return user.role === "Admin";
            if (user.role !== "Admin") return null;
            if (!tmpUser.mobileNumber) return null;
            return tmpUser.mobileNumber.includes(userFilterText) ? user : null;
          });

          // if group has admin
          if (tmpUserInGroups.length > 0) {
            tmpGroupsArray.push({ group, admins: tmpUserInGroups });
          }
          // console.log(group.name, tmpUserInGroups);
        });
        setAdminList(tmpGroupsArray);
      }
    } else {
      if (orgAdmins.length > 0) {
        setAdminList(orgAdmins);
      }
    }
  }, [userFilterText]);

  let orgLogo;
  if (organizationStore.organization) {
    if (organizationStore.organization.logoUrl128x128) {
      orgLogo = (
        <Image
          style={{ width: 64, height: 64 }}
          source={{
            uri: organizationStore.organization.logoUrl128x128.replace(
              "http:",
              "https:"
            ),
          }}
        />
      );
    } else {
      orgLogo = <TpIconTeam3 size={64} />;
    }
  }

  return (
    <FullPageWrapper>
      <Modal isVisible={showAddUser} closeFn={setShowAddUser} direction="toTop">
        {showAddUser && (
          <AddUserForm doneAction={setShowAddUser} addAdminInitially />
        )}
      </Modal>
      <Modal
        direction="toTop"
        isVisible={showEditUser}
        closeFn={setShowEditUser}
      >
        {showEditUser && (
          <UserForm
            userId={editUserId}
            doneAction={setShowEditUser}
            removeAdmin
            removeAdminFrom={removeAdminFrom}
          />
        )}
      </Modal>
      <Header>
        <BackButton to={APP_PATHS.organization.settings.home} />
        <LargeHeaderIcon>{orgLogo}</LargeHeaderIcon>
        <HeaderTitleSubtitle
          subtitle={
            organizationStore.organization
              ? organizationStore.organization.name
              : " "
          }
          title="Administratörer"
        />
      </Header>
      <ContentFixed>
        <View
          style={{
            // alignSelf: "stretch",
            alignItems: "flex-end",
            justifyContent: "space-between",
            paddingVertical: 10,
            paddingHorizontal: 15,
            marginTop: 10,
            flexDirection: "row",
          }}
        >
          <Input
            placeholder="Sök ..."
            containerStyle={{ width: "50%" }}
            value={userFilterText}
            onChangeText={(text) => {
              console.log(text);
              setUserFilterText(text);
            }}
          />
          <Button
            type="outline"
            onPress={() => setShowAddUser(true)}
            title={"Lägg till"}
          />
        </View>
      </ContentFixed>
      <ContentScroll>
        <View style={{ flex: 1 }}>
          <>
            <View
              style={{
                padding: 10,
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <View>
                <Text style={{ fontWeight: "bold" }}>Alla admins</Text>
              </View>
              {/* <View>
                <Text style={{ fontWeight: "bold" }}>Admin</Text>
              </View> */}
            </View>
            {adminList &&
              adminList.length > 0 &&
              adminList.map((oa, idx) => {
                // if (oa.data.length < 1) return null;
                return (
                  <View key={oa.group.id} style={{ marginBottom: 20 }}>
                    <Text
                      style={{
                        marginLeft: 15,
                        fontSize: 13,
                        textTransform: "uppercase",
                      }}
                    >
                      {oa.group.name}
                    </Text>
                    {oa.admins.length &&
                      oa.admins.map((admin) => (
                        <UserItem
                          key={admin.userId}
                          member={admin}
                          setEditUserId={setEditUserId}
                          setShowEditUser={setShowEditUser}
                          setRemoveAdminFrom={setRemoveAdminFrom}
                          groupId={oa.group.id}
                        />
                      ))}
                  </View>
                );
              })}
          </>
        </View>
      </ContentScroll>
    </FullPageWrapper>
  );
});

export default OrganizationAdminsScreen;

interface UserItemProps {
  member: UserInOrganization;
  setEditUserId: (s: string) => void;
  setShowEditUser: (b: boolean) => void;
  setRemoveAdminFrom: (s: string) => void;
  groupId: string;
}
const UserItem: React.FC<UserItemProps> = ({
  member,
  setEditUserId,
  setShowEditUser,
  setRemoveAdminFrom,
  groupId,
}) => {
  const { organizationStore } = useContext(RootStoreContext);

  const user = organizationStore.organization.users.filter(
    (user) => user.id === member.userId
  )[0];

  console.log("User", user);
  // console.log("MEMBER: ", member);

  if (user === undefined)
    return (
      <TouchableOpacity
        onPress={() => {
          setEditUserId(member.userId);
          setShowEditUser(true);
        }}
      >
        <View
          style={{
            marginHorizontal: 10,
            marginVertical: 5,
            backgroundColor: "#fff",
            paddingHorizontal: 15,
            paddingVertical: 10,
            borderRadius: 8,
            justifyContent: "flex-start",
            alignItems: "center",
            flexDirection: "row",
            ...elevationShadowStyle(10),
          }}
          key={member.userId}
        >
          <View>
            <Text>Ej längre medlem</Text>
          </View>
        </View>
      </TouchableOpacity>
    );
  return (
    <TouchableOpacity
      onPress={() => {
        console.log("Gruppid: ", groupId);
        setRemoveAdminFrom(groupId);
        setEditUserId(member.userId);
        setShowEditUser(true);
      }}
    >
      <View
        style={{
          marginHorizontal: 10,
          marginVertical: 5,
          backgroundColor: "#fff",
          paddingHorizontal: 15,
          paddingVertical: 10,
          borderRadius: 8,
          justifyContent: "flex-start",
          alignItems: "center",
          flexDirection: "row",
          ...elevationShadowStyle(10),
        }}
        key={user.id}
      >
        <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
          <View
            style={{
              flexDirection: "row",
              alignSelf: "stretch",
              alignItems: "center",
              flex: 1,
            }}
          >
            <View>
              <TpIconUser size={40} />
            </View>
            <View style={{ paddingVertical: 5, paddingHorizontal: 15 }}>
              {user.name ? (
                <Text>
                  {user.name}
                  {/* {`${member.user.firstName ? member.user.firstName : ""} ${
            // member.user.lastName ? member.user.lastName : ""
            mem
          }`} */}
                </Text>
              ) : (
                <Text style={{ color: "#aeaeae", fontStyle: "italic" }}>
                  Ej angett
                </Text>
              )}
              <Text>
                {user.mobileNumber}
                {/* {member.user.mobileNumber ? member.user.mobileNumber : ""} */}
              </Text>
              {user.emailAddress ? (
                <Text>
                  {user.emailAddress}
                  {/* {`${member.user.firstName ? member.user.firstName : ""} ${
            // member.user.lastName ? member.user.lastName : ""
            mem
          }`} */}
                </Text>
              ) : (
                <Text style={{ color: "#aeaeae", fontStyle: "italic" }}>
                  Ej angett
                </Text>
              )}
            </View>
          </View>
          {/* {member.role === "Admin" && (
            <View
              style={{
                width: 60,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <TpIconCheck size={18} color={theme.colors.primary} />
            </View>
          )} */}
        </View>
      </View>
    </TouchableOpacity>
  );
};
