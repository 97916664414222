import * as Font from "expo-font";
import React from "react";
import Index from "./src/index";

export default class App extends React.Component {
  state = {
    fontLoaded: false
  };

  async componentDidMount() {
    // console.log("STARTING FONT LOADING");
    // await Font.loadAsync({
    //   "custom-font-icon": require("./src/assets/fonts/custom-font-icon.ttf")
    // });
    const sucess = await Font.loadAsync({
      TeamPay: require("./src/assets/fonts/teampay.ttf")
    });
    console.log(sucess);
    this.setState({ fontLoaded: true });
    // console.log("STARTING FONT LOADING");
  }

  render() {
    if (!this.state.fontLoaded) {
      return null;
    }
    return <Index />;
  }
}

// export default App;
