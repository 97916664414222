import React, { useEffect, useState } from "react";
import {
  Animated,
  Dimensions,
  KeyboardAvoidingView,
  StyleSheet,
  TouchableOpacity,
  View
} from "react-native";
import { Text } from "react-native-elements";
import { theme } from "../../lib/styles/theme";

const { height: screenHeight, width: screenWidth } = Dimensions.get("window");

interface Props {
  direction?: "toLeft" | "toTop";
  isVisible: boolean;
  closeFn: (b: boolean) => void;
  zIndex?: number;
}

const Modal: React.FC<Props> = ({
  children,
  isVisible,
  closeFn,
  direction = "toLeft",
  zIndex
}) => {
  const [animValue, setAnimValue] = useState(
    new Animated.Value(direction === "toLeft" ? screenWidth : screenHeight)
  );
  useEffect(() => {
    const toValue = isVisible
      ? 0
      : direction === "toLeft"
      ? screenWidth
      : screenHeight;
    // if (isVisible) {
    Animated.spring(animValue, {
      toValue: toValue
    }).start();
    // }
  }, [isVisible]);

  // const _toggleMenu = () => {
  //   Animated.spring(animValue, {
  //     toValue:500
  //   }).start()
  // }

  // if (!isVisible) return null;
  return (
    <Animated.View
      style={[
        direction === "toLeft"
          ? { top: 0, left: animValue }
          : { left: 0, top: animValue },
        zIndex && { zIndex },
        styles.modal
      ]}
    >
      {/* <TouchableWithoutFeedback
        style={{
          width: screenWidth,
          height: screenHeight
        }}
        onPress={() => Keyboard.dismiss()}
      > */}
      <KeyboardAvoidingView
        behavior="padding"
        style={{
          flex: 1,
          alignSelf: "stretch",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <View
          style={{
            backgroundColor: "#fff",
            paddingHorizontal: 30,
            paddingVertical: 60,
            alignSelf: "stretch"
          }}
        >
          {children}
        </View>
        <TouchableOpacity style={styles.close} onPress={() => closeFn(false)}>
          <Text style={{ color: "#ffffff", fontWeight: "bold" }}>X</Text>
        </TouchableOpacity>
      </KeyboardAvoidingView>
      {/* </TouchableWithoutFeedback> */}
    </Animated.View>
  );
};

export default Modal;

const styles = StyleSheet.create({
  modal: {
    justifyContent: "center",
    alignItems: "center",
    zIndex: 100,
    position: "absolute",

    width: screenWidth,
    height: screenHeight,
    backgroundColor: "#ffffff"
  },
  close: {
    position: "absolute",
    top: 20,
    right: 20,
    width: 40,
    height: 40,
    borderRadius: 20,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: theme.colors.danger
  }
});
