import React, { useContext, useEffect, useState } from "react";
import { Dimensions, View } from "react-native";
import { Button, Text } from "react-native-elements";
import { GeneratedQRCode } from "../../components/GeneratedQRCode";
import RegisterHeader from "../../components/register/RegisterHeader";
import { theme } from "../../lib/styles/theme";
import { RegisterSteps } from "../../stores/RegisterStore";
import { RootStoreContext } from "../../stores/RootStore";

const PaymentContainer = () => {
  const { pointOfSaleStore, registerStore } = useContext(RootStoreContext);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const initAsync = async () => {
      registerStore._createOrder().then(() => setLoading(false));
    };
    initAsync();
  }, []);

  switch (registerStore.paymentMethod.paymentMethodKey) {
    //  Swish
    case "swish":
      const swishContent = `C${registerStore.paymentMethod.paymentDetails};${registerStore.totalSum};REF%3A+%23${registerStore.transactionId};0`;
      const qrSize = Dimensions.get("window").width * 0.5;
      // console.log("SWISHCONTENT:", swishContent);
      return (
        <>
          <RegisterHeader />
          <View
            style={{
              flex: 1,
              alignItems: "center",
              justifyContent: "space-between"
            }}
          >
            <View style={{ alignSelf: "stretch", justifyContent: "center" }}>
              <View
                style={{
                  paddingHorizontal: 15,
                  justifyContent: "space-between",
                  alignContent: "center",
                  flexDirection: "row"
                }}
              >
                <Button
                  type="outline"
                  title="Avbryt"
                  onPress={() => registerStore._emptyCart()}
                />
                {pointOfSaleStore.pointOfSale.session.paymentMethods.length >
                  1 && (
                  <Button
                    type="outline"
                    title="Byt betalsätt"
                    onPress={() =>
                      (registerStore.currentStep = RegisterSteps.Basket)
                    }
                  />
                )}
              </View>
              <View style={{ alignSelf: "center" }}>
                <Text style={{ paddingVertical: 15, fontWeight: "bold" }}>
                  Swishbetalning
                </Text>
              </View>
            </View>
            <View
              style={{
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              {registerStore.totalSum > 0 ? (
                <>
                  <Text style={{ marginBottom: 15 }}>
                    Låt kunden scanna koden nedan.
                  </Text>
                  <GeneratedQRCode
                    color={theme.colors.danger}
                    url={swishContent}
                    size={qrSize}
                  />
                  <Text h4 style={{ marginTop: 15 }}>
                    Totalt: {registerStore.totalSum} kr
                  </Text>
                  <Text style={{ marginTop: 15 }}>
                    Vid manuell swish, ange:
                  </Text>
                  <Text style={{ marginTop: 15 }}>
                    Meddelande{" "}
                    <Text style={{ fontWeight: "bold" }}>
                      #{registerStore.transactionId}
                    </Text>
                  </Text>
                  <Text>
                    till{" "}
                    <Text style={{ fontWeight: "bold" }}>
                      {registerStore.paymentMethod.paymentDetails}
                    </Text>
                  </Text>
                </>
              ) : (
                <>
                  <Text
                    style={{
                      marginBottom: 15,
                      paddingHorizontal: 30,
                      textAlign: "center"
                    }}
                  >
                    Swish kan inte användas vid 0-summeköp, men bekräfta köpet
                    ändå för att registrera det.
                  </Text>
                </>
              )}
            </View>
            <View style={{ paddingVertical: 15 }}>
              <ConfimPaymentButton loading={loading} />
            </View>
          </View>
        </>
      );
    // Kort
    case "externalcard":
      return (
        <>
          <RegisterHeader />
          <View
            style={{
              flex: 1,
              alignItems: "center",
              justifyContent: "space-between"
            }}
          >
            <View style={{ alignSelf: "stretch", justifyContent: "center" }}>
              <View
                style={{
                  paddingHorizontal: 15,
                  justifyContent: "space-between",
                  alignContent: "center",
                  flexDirection: "row"
                }}
              >
                <Button
                  type="outline"
                  title="Avbryt"
                  onPress={() => registerStore._emptyCart()}
                />
                {pointOfSaleStore.pointOfSale.session.paymentMethods.length >
                  1 && (
                  <Button
                    type="outline"
                    title="Byt betalsätt"
                    onPress={() =>
                      (registerStore.currentStep = RegisterSteps.Basket)
                    }
                  />
                )}
              </View>
              <View style={{ alignSelf: "center" }}>
                <Text style={{ paddingVertical: 15, fontWeight: "bold" }}>
                  Kortbetalning
                </Text>
              </View>
            </View>
            <View
              style={{
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <Text style={{ marginBottom: 15 }}>Kunden ska betala</Text>
              <Text h1 style={{ marginVertical: 15 }}>
                {registerStore.totalSum} kr
              </Text>
              <Text
                style={{
                  marginTop: 15,
                  marginHorizontal: 40,
                  textAlign: "center"
                }}
              >
                Använd din externa kortläsare och när köpet är genomfört
                bekräftar du nedan.
              </Text>
            </View>
            <View style={{ paddingVertical: 15 }}>
              <ConfimPaymentButton loading={loading} />
            </View>
          </View>
        </>
      );

    //  CASH
    default:
      return (
        <>
          <RegisterHeader />
          <View
            style={{
              flex: 1,
              alignItems: "center",
              justifyContent: "space-between"
            }}
          >
            <View style={{ alignSelf: "stretch", justifyContent: "center" }}>
              <View
                style={{
                  paddingHorizontal: 15,
                  justifyContent: "space-between",
                  alignContent: "center",
                  flexDirection: "row"
                }}
              >
                <Button
                  type="outline"
                  title="Avbryt"
                  onPress={() => registerStore._emptyCart()}
                />
                {pointOfSaleStore.pointOfSale.session.paymentMethods.length >
                  1 && (
                  <Button
                    type="outline"
                    title="Byt betalsätt"
                    onPress={() =>
                      (registerStore.currentStep = RegisterSteps.Basket)
                    }
                  />
                )}
              </View>
              <View style={{ alignSelf: "center" }}>
                <Text style={{ paddingVertical: 15, fontWeight: "bold" }}>
                  Kontantbetalning
                </Text>
              </View>
            </View>
            <View
              style={{
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <Text style={{ marginBottom: 15 }}>Kunden ska betala</Text>
              <Text h1 style={{ marginTop: 15 }}>
                {registerStore.totalSum} kr
              </Text>
            </View>
            <View style={{ paddingVertical: 15 }}>
              <ConfimPaymentButton loading={loading} />
            </View>
          </View>
        </>
      );
  }
};

export default PaymentContainer;

interface ConfimPaymentButtonProps {
  loading?: boolean;
}
const ConfimPaymentButton: React.FC<ConfimPaymentButtonProps> = ({
  loading = true
}) => {
  const { registerStore } = useContext(RootStoreContext);
  return (
    <Button
      disabled={loading}
      loading={loading}
      title="Bekräfta köp"
      onPress={() => {
        if (registerStore._settleOrder()) {
          registerStore.currentStep = RegisterSteps.Reciept;
        } else {
          // console.log("Uh uh!");
        }
      }}
    />
  );
};
