import React, { useContext } from "react";
import { TouchableWithoutFeedback, View } from "react-native";
import { Text } from "react-native-elements";
import { RouteComponentProps, withRouter } from "react-router";
import { RegisterSteps } from "../../stores/RegisterStore";
import { RootStoreContext } from "../../stores/RootStore";
import { TpIconMenu } from "../Icon";

interface Props extends RouteComponentProps {
  onMenuClick?: (b: boolean | any) => void;
  showMenuIcon?: boolean;
}

const RegisterHeaderWithoutRouter: React.FC<Props> = ({
  showMenuIcon = false,
  onMenuClick = console.log,
  history
}) => {
  const { registerStore, pointOfSaleStore } = useContext(RootStoreContext);
  let toRender;
  switch (registerStore.currentStep) {
    case RegisterSteps.Basket:
      toRender = <Text>Varukorg</Text>;
      break;
    case RegisterSteps.Return:
      toRender = <Text>Återköp</Text>;

      break;
    case RegisterSteps.Payment:
      toRender = <Text>Betalning</Text>;
      break;
    case RegisterSteps.Reciept:
      if (registerStore.isReturn) {
        toRender = <Text>Återköpskvitto</Text>;
        break;
      }
      toRender = <Text>Kvitto</Text>;
      break;

    default:
      toRender = <Text>{pointOfSaleStore.pointOfSale.name}</Text>;
      break;
  }
  return (
    <View
      style={{
        paddingVertical: 15,
        paddingHorizontal: 5,
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        alignSelf: "stretch"
      }}
    >
      <View style={{ marginHorizontal: "auto", alignSelf: "center" }}>
        {toRender}
      </View>
      {showMenuIcon && (
        <TouchableWithoutFeedback
          style={{ marginRight: 0 }}
          onPress={() => onMenuClick(true)}
        >
          <TpIconMenu size={20} />
        </TouchableWithoutFeedback>
      )}
    </View>
  );
};

const RegisterHeader = withRouter(RegisterHeaderWithoutRouter);
export default RegisterHeader;
